import moment from "moment";
import isEmail from "validator/lib/isEmail";
import isValidUrl from "validator/lib/isURL";
import CommonMixin from "../Mixins/common";

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { networkSecurityOneState } from "../store/cyberSecurityCapabilities";
import { phishingTrainingState } from "../store/organizationLandscape";
import localService from "@/services/localService";

import RequestQueue from "@/helpers/requestQueue";
const ApiQueue = new RequestQueue();

export default {
  mixins: [CommonMixin],

  computed: {
    ...mapFields("login", ["currentUser"]),

    ...mapFields("companySetup", [
      "debounce",
      "companySetupSteps",
      "teamMembers",
      "fetchingAnswers",
      "questionnaire",
      "showInsurabilityMenuItem",
      "currentQuestionnaireVersion",
      "initialQuestionnaireAllScreenData",
    ]),

    ...mapFields("quickStart", ["quickStartSteps"]),

    ...mapFields("indicationSetup", ["indicationSetupSteps"]),

    ...mapFields("buildTeam", [
      "inviteCoAdmins",
      "technologyTeammates",
      "cyberSecurityTeammates",
      "operationProcessTeammates",
      "hrTrainingTeammates",
      "legalContractTeammates",
      "cyberInsuranceBrokers",
    ]),

    ...mapFields("buildCompanyProfile", [
      "policiesPlanningQuestions",
      "companyDetailsQuestions",
      "companyWebsitesQuestions",
      "companyLogoQuestions",
      "industryDetailsQuestions",
      "employeesDetailsQuestions",
      "cyberSecurityInvestmentQuestions",
      "revenueGrowthQuestions",
      "revenueProductsQuestions",
      "revenueCustomersQuestions",
    ]),

    ...mapFields("organizationalLandscape", [
      "emailSecurityQuestions",
      "emailSecurityControlsQuestions",
      "phishingTrainingQuestions",
      "physicalHardwareQuestions",
      "backupCapabilitiesQuestions",
      "backupContinuityQuestions",
      "serviceAccountQuestions",
      "chosenDevicesAndSystemsQuestions",
      "applicationInfrastructureQuestions",
      "onPremiseInfrastructureQuestions",
      "legacySoftwareInUseQuestions",
      "personallyIdentifiableQuestions",
      "mediaUsageQuestions",
      "financialControlsQuestions",
      "telecommunicationQuestions",
      "paymentCardsQuestions",
      "privacyConcernsQuestions",
      "orgDataBackedUpQuestions",
      "orgSecurityHandleQuestions",
      "orgRecentIncidentsExperiencedQuestions",
      "systemRecordsProtectQuestions",
    ]),

    ...mapFields("cyberSecurityCapabilities", [
      "cyberSecurityOrganizationQuestions",
      "cyberSecurityVendorsQuestions",
      "networkSecurityOneQuestions",
      "networkSecurityTwoQuestions",
      "networkSecurityCapabilitiesQuestions",
      "networkSecurityControlsQuestions",
      "generalSecurityQuestions",
      "vulnerabilityScanningQuestions",
    ]),

    isAccountFree() {
      return this.currentUser.user.payment_complete === 0;
    },

    isNetworkSecurityCapabilitiesFilled() {
      const answers = [];
      const keys = [
        "do_you_have_endpoint_detection_&_response_edr",
        "do_you_have_security_operations_center_soc",
        "do_you_have_virtual_network_computing_vnc_or_remote_desktop_protocol_rdp",
      ];

      this.networkSecurityCapabilitiesQuestions.questions.forEach((question) => {
        if (keys.includes(question.key)) {
          answers.push(question.choice);
        }
      });

      return answers.includes("yes");
    },

    showInsurabilityReport() {
      return this.showInsurabilityMenuItem || this.questionnaire?.extraDetails?.userActivities?.csgStarted || false;
    },
  },

  data() {
    return {
      // All the screens which are sent to the api
      allScreens: {
        "invite-co-admins": "inviteCoAdmins",
        "add-technology-expertise": "technologyTeammates",
        "add-cybersecurity-expertise": "cyberSecurityTeammates",
        "add-operations-process-expertise": "operationProcessTeammates",
        "add-hr-training-expertise": "hrTrainingTeammates",
        "add-legal-contract-expertise": "legalContractTeammates",
        "add-cyber-insurance-broker": "cyberInsuranceBrokers",
        "policies-planning": "policiesPlanningQuestions",
        "company-details": "companyDetailsQuestions",
        "company-websites": "companyWebsitesQuestions",
        "company-logo": "companyLogoQuestions",
        "industry-details": "industryDetailsQuestions",
        "employees-details": "employeesDetailsQuestions",
        "cybersecurity-investment": "cyberSecurityInvestmentQuestions",
        "revenue-growth": "revenueGrowthQuestions",
        "revenue-products": "revenueProductsQuestions",
        "revenue-customers": "revenueCustomersQuestions",
        phishing: "phishingTrainingQuestions",
        "email-security": "emailSecurityQuestions",
        "email-security-controls": "emailSecurityControlsQuestions",
        "physical-hardware-on-premise": "physicalHardwareQuestions",
        "backup-capabilities": "backupCapabilitiesQuestions",
        "backups-and-business-continuity": "backupContinuityQuestions",
        "service-accounts": "serviceAccountQuestions",
        "cyber-security-organization": "cyberSecurityOrganizationQuestions",
        "network-security-one": "networkSecurityOneQuestions",
        "network-security-two": "networkSecurityTwoQuestions",
        "network-security-capabilities": "networkSecurityCapabilitiesQuestions",
        "network-security-controls": "networkSecurityControlsQuestions",
        "general-security-controls": "generalSecurityQuestions",
        "vulnerability-scanning": "vulnerabilityScanningQuestions",
        "devices-systems-used-remotely": "chosenDevicesAndSystemsQuestions",
        "applications-infrastructure-cloud": "applicationInfrastructureQuestions",
        "on-premise-infrastructure-software": "onPremiseInfrastructureQuestions",
        "legacy-software": "legacySoftwareInUseQuestions",
        "personally-identifiable-information": "personallyIdentifiableQuestions",
        "media-usage": "mediaUsageQuestions",
        "financial-controls": "financialControlsQuestions",
        telecommunication: "telecommunicationQuestions",
        "payment-cards": "paymentCardsQuestions",
        "privacy-concerns": "privacyConcernsQuestions",
        "cyber-security-vendors": "cyberSecurityVendorsQuestions",
        "systems-records-for-protect-them": "systemRecordsProtectQuestions",
        "org-data-backed-up": "orgDataBackedUpQuestions",
        "org-security-handle": "orgSecurityHandleQuestions",
        "org-recent-incidents-experienced": "orgRecentIncidentsExperiencedQuestions",
      },

      defaultStates: {
        networkSecurityOneState: networkSecurityOneState(),
        phishingTrainingState: phishingTrainingState(),
      },
    };
  },

  methods: {
    ...mapActions("companySetup", ["saveQuestionnaire", "getQuestionnaire"]),

    handleQuestionToggling(obj, key, index, val, stateKeyName) {
      const objCopy = structuredClone(obj);

      let showIds = objCopy[key][index].show;

      if (showIds && showIds.length) {
        const indexMap = {};

        objCopy[key].forEach((item, index) => {
          indexMap[item.id] = index;
        });

        showIds.forEach((item) => {
          const ind = indexMap[item.id];
          const dependants = objCopy.questions[ind]["show"];

          // CHOICE AND SHOW ON CAN ALSO BE AN ARRAY i.e a question can be shown on multiple choices
          if (Array.isArray(item.showOn) && val && val.length > 0) {
            let isValid = null;
            if (Array.isArray(val) && val.length) {
              // IF VAL WHICH IS THE ANSWER IS TYPE = ARRAY
              isValid = val.some((choice) => item.showOn.includes(choice));
            } else {
              // IF VAL WHICH IS THE ANSWER IS TYPE = STRING
              isValid = item.showOn.includes(val);
            }
            objCopy[key][ind].visible = isValid ? true : false;
          }

          // IF THE DEPENDANT NEEDS TO BE CHECKED WITHIN A RANGE FOR EX BETWEEN 0 to 100
          else if (item.hasOwnProperty("type")) {
            const [lowerLimit, upperLimit] = item.showOn.split(",");
            const value = parseInt(val, 10);
            const valid = value > parseInt(lowerLimit, 10) && value <= parseInt(upperLimit, 10);
            objCopy[key][ind].visible = valid ? true : false;
          }

          // IT CAN ALSO BE A SIMPLE STRING YES|NO
          else {
            const isValid = val === item.showOn;

            objCopy[key][ind].visible = isValid ? true : false;

            if (!isValid && stateKeyName) {
              // Reset Main Dependant Question to default
              objCopy[key][ind] = this.defaultStates[stateKeyName][ind];

              // Reset dependant questions dependants
              if (dependants && dependants.length) {
                dependants.forEach((question) => {
                  const index = indexMap[question.id];
                  objCopy.questions[index] = this.defaultStates[stateKeyName][index];
                });
              }
            }
          }
        });

        return objCopy;
      }
    },

    getClickedOption(object, key, val, index, stateKey) {
      let obj = structuredClone(object);

      let showIds = obj[key][index].show;

      if (showIds && showIds.length) obj = this.handleQuestionToggling(obj, key, index, val, stateKey);

      obj[key][index].choice = val;

      return obj;
    },

    /* 
      This function is responsible for updating a property inside an object.
      It updates the object passed to it with the data.

      Arguments: 
        1. object = the object that needs to be updated.
        2. parentKey = which key in object needs to be updated, it is usually an array.
        3. index = which index of selected object[parentKey].
        4. childKey = which key in the selected index of that array. e.g, object['questions'][1][choice].
        5. data = the data which is supposed to replace the existing one.
    */
    updateProp(object, parentKey, index, childKey, data) {
      let obj = structuredClone(object);

      // WILL ONLY RUN WHEN THERE ARE DEPENDENCIES ON A QUESTION
      let showIds = obj[parentKey][index].show;

      if (showIds && showIds.length) obj = this.handleQuestionToggling(obj, parentKey, index, data);

      obj[parentKey][index][childKey] = data;
      return obj;
    },

    //
    // WILL CHECK IF THE USER SUPPLIED FORM VALUES AS PER THE RULES DEFINED IN THE FORM DATA
    //

    isInvalidForm(el) {
      if (!el) return;

      const invalid = [];

      // LOOP THROUGH THE FORM DATA AND CHECK IF CORRECT AND VALID VALUES WERE PROVIDED BY THE USER
      let value = null;
      let validationType = null;
      let valueType = null;
      let valueKey = null;
      for (let key in el.formData) {
        if (el.requiredFormData[key]) {
          if (el.deepRequiredFormData && el.deepRequiredFormData.includes(key)) {
            Object.keys(el.requiredFormData[key]).map((formKey) => {
              value = el.formData[key][formKey];
              validationType = el.requiredFormData[key][formKey].validationType;
              valueType = el.requiredFormData[key][formKey].type;
              valueKey = el.requiredFormData[key][formKey]["key"];

              if (this.$route.path.includes("indication-setup")) {
                if (
                  (!el.hiddenIndicationFormData ||
                    (el.hiddenIndicationFormData && !el.hiddenIndicationFormData.includes(valueKey))) &&
                  this.hasFormInvalidData(key, value, valueKey, valueType, validationType)
                ) {
                  invalid.push(el);
                }
              } else if (this.hasFormInvalidData(key, value, valueKey, valueType, validationType)) {
                invalid.push(el);
              }
            });
          } else {
            value = el.formData[key];
            validationType = el.requiredFormData[key].validationType;
            valueType = el.requiredFormData[key].type;
            valueKey = el.requiredFormData[key]["key"];

            if (this.$route.path.includes("indication-setup")) {
              if (
                (!el.hiddenIndicationFormData ||
                  (el.hiddenIndicationFormData && !el.hiddenIndicationFormData.includes(valueKey))) &&
                this.hasFormInvalidData(key, value, valueKey, valueType, validationType)
              ) {
                invalid.push(el);
              }
            } else if (this.hasFormInvalidData(key, value, valueKey, valueType, validationType)) {
              invalid.push(el);
            }
          }
        }
      }

      return invalid.length ? true : false;
    },

    isValidUSAPhoneNumber(phoneNumber) {
      // Define a regular expression to match the pattern
      const regex = /^\([2-9]\d{2}\) \d{3}-\d{4}$/;
      return regex.test(phoneNumber);
    },

    hasFormInvalidData(key, value, valueKey, valueType, validationType) {
      if (valueType && valueType == "Array") {
        value.map((val) => {
          if (validationType == "required" && (!val[key] || (val[valueKey] && !val[valueKey]?.trim().length))) {
            return true;
          }

          if (validationType === "isEmail" && !isEmail(val[valueKey])) {
            return true;
          }

          if (validationType === "isUrl" && val[valueKey] && !isValidUrl(val[valueKey])) {
            return true;
          }

          if (validationType === "isPastDate" && !this.isPastDate(val[valueKey])) {
            return true;
          }

          if (validationType === "isPastYear" && !this.isPastYear(value)) {
            return true;
          }
        });
      } else {
        if (!value || (value && !value?.toString()?.trim().length)) return true;

        if (validationType === "isEmail" && !isEmail(value)) return true;

        if (validationType === "isUrl" && !isValidUrl(value)) {
          return true;
        }

        if (validationType === "isPastDate" && !this.isPastDate(value)) {
          return true;
        }

        if (validationType === "isPastYear" && !this.isPastYear(value)) {
          return true;
        }

        if (validationType === "isDateValidAndInFormat" && !this.isDateValidAndInFormat(value)) {
          return true;
        }

        if (validationType === "isPhoneNumber" && !this.isValidUSAPhoneNumber(value)) {
          return true;
        }
      }
      return false;
    },
    //
    // WILL CHECK IF ALL THE QUESTIONS WERE ANSWERED AS PER THE REQUIREMENT OF THE PAGE
    //

    areAllQuestionsAnswered(itemsArray, key = "choice", callBack) {
      let isUnanswered = [];
      itemsArray.forEach((el) => {
        // IF QUESTION HAS A FORM DATA THEN CHECK IF THE REQUIRED VALUES ARE FILLED OR NOT
        if (el.visible && el.formData && el.requiredFormData && Object.keys(el.requiredFormData)?.length) {
          // IF QUESTION IS OF TYPE CHOICE, I.E, IT HAS CHOICE AND FORM DATA IN THE SAME QUESTION
          if (el[key] && el[key] === el.formVisibleOn && this.isInvalidForm(el)) isUnanswered.push(el);

          // IF QUESTION IS NOT OF TYPE CHOICE I.E, IT DOES NOT HAVE CHOICE KEY & FORM DATA IS A SEPARATE QUESTION
          if (!el[key] && !el.formVisibleOn && this.isInvalidForm(el)) {
            isUnanswered.push(el);
          }

          if (typeof callBack != "undefined" && !callBack()) {
            isUnanswered.push(el);
          }

          // If question formData has array type
          if (el.requiredFormData[key]?.type == "Array") {
            console.log("array");
          }
        }

        if (
          !(this.$route.path.includes("indication-setup") && el.indicationSetupHidden) ||
          (this.$route.path.includes("build-risk-profile") && el.indicationAdditionalInfoShow)
        ) {
          if (
            el.visible &&
            ((!this.currentUser.user.showQuickQuotes && !el.csgHidden) ||
              (this.currentUser.user.showQuickQuotes && !el.quickStartHidden) ||
              (el.csgHidden && el.indicationAdditionalInfoShow && this.$route.path.includes("build-risk-profile"))) &&
            el[key] === null &&
            !el?.optional
          ) {
            isUnanswered.push(el);
          }

          if (Array.isArray(el[key]) && el[key].filter(Boolean).length === 0 && el.visible && !el?.optional) {
            isUnanswered.push(el);
          }

          if (el.type === "hasVendors" && el.visible && !this.areVendorsValid(el.choice) && !el?.optional) {
            isUnanswered.push(el);
          }

          if (el.type == "hasWebAddress" && el.visible && el[key] && !el?.optional && !isValidUrl(el[key])) {
            isUnanswered.push(el);
          }

          if (
            el.type === "hasVendorsWithType" &&
            el.visible &&
            !this.areVendorsValid(el.choice, true) &&
            !el?.optional
          ) {
            isUnanswered.push(el);
          }

          if (el.type === "hasProviders" && el.visible && !this.areProvidersValid(el.choice) && !el?.optional) {
            isUnanswered.push(el);
          }

          if (el.validationType === "isPastYear" && el.visible && !this.isPastYear(el.choice)) {
            isUnanswered.push(el);
          }
        }
      });

      return isUnanswered.length ? false : true;
    },

    areCompanyWebsitesValid() {
      const questions = this.companyWebsitesQuestions.questions;

      // FUNCTION TO GET A QUESTION BY KEY
      const getQuestion = (key) => questions.find((e) => e.key === key);

      // FUNCTION TO VALIDATE WEBSITES
      const validateWebsites = (websites) => {
        if (!websites || websites.length === 0) return true;
        if (websites.length === 1 && (!websites[0].web_address || websites[0].web_address.trim() === "")) return true;
        return websites.every((site) => isValidUrl(site.web_address));
      };

      const hasWebsitesPublicly = getQuestion("does_organization_have_websites_publicly").choice === "yes";

      // If answer is no return true because nothing was answered by the user to validate
      if (!hasWebsitesPublicly) return true;

      const primaryWebAddress = getQuestion("primary_web_address")?.choice;

      // If primary web address is invalid return false
      if (!isValidUrl(primaryWebAddress)) return false;

      const { company_owned_websites: companyWebsites } = getQuestion("company_owned_websites")?.formData;

      const { honey_pot_websites: honeyPotWebsites } = getQuestion("honey_pot_websites")?.formData;

      if (!validateWebsites(companyWebsites)) return false;

      if (!validateWebsites(honeyPotWebsites)) return false;

      // Check for duplicates in all categories
      const allWebsites = [
        ...honeyPotWebsites.map((el) => this.getDomainDetailsFromUrl(el.web_address)).filter(Boolean),
        ...companyWebsites.map((el) => this.getDomainDetailsFromUrl(el.web_address)).filter(Boolean),
        this.getDomainDetailsFromUrl(primaryWebAddress),
      ].filter(Boolean);

      const hasDuplicates = new Set(allWebsites).size !== allWebsites.length;

      if (hasDuplicates) return false;

      return true;
    },

    getDomainDetailsFromUrl(webAddress) {
      if (!webAddress) return null;
      let address = webAddress.replace(/^https?:\/\//, "").replace(/^www\./, "");
      return address.toLowerCase();
    },

    revenueGrowthCustomValidation() {
      let isValid = true;
      if (
        this.revenueGrowthQuestions.questions.find(
          (e) => e.key == "does_organization_engage_following_business_activities"
        )
      ) {
        isValid = false;
        Object.keys(
          this.revenueGrowthQuestions.questions.find(
            (e) => e.key == "does_organization_engage_following_business_activities"
          ).formData
        ).map((key) => {
          if (
            this.revenueGrowthQuestions.questions.find(
              (e) => e.key == "does_organization_engage_following_business_activities"
            ).formData[key]
          ) {
            isValid = true;
          }
        });
      }
      return isValid;
    },

    areProvidersValid(choices) {
      return choices?.every((item) => {
        if (item.choice === null) return false;
        if (item.hasOwnProperty("description") && (item.description === null || item.description.trim() === ""))
          return false;
        return true;
      });
    },

    handlePlatformClickWithNone(itemsArray, key, noneKeyName = "none") {
      let arr = structuredClone(itemsArray) || [];

      if (key === noneKeyName) {
        arr = arr.includes(noneKeyName) ? [] : [key];
      } else {
        if (arr.includes(noneKeyName)) {
          arr = arr.filter((el) => el !== noneKeyName);
        }

        arr = arr.includes(key) ? arr.filter((el) => el !== key) : [...arr, key];
      }

      return arr;
    },

    areVendorsValid(vendors, isType = false) {
      function normal(el) {
        return el.name?.trim().length && el.website?.trim().length && isValidUrl(el.website);
      }

      function withType(el) {
        return el.name?.trim().length && el.website?.trim().length && el.type && isValidUrl(el.website);
      }

      const func = isType ? withType : normal;

      return vendors?.filter(func).length === vendors?.length;
    },

    getQuestionnairePayload() {
      const payload = {};

      Object.values(this.allScreens).forEach((screen) => {
        payload[screen] = this.makePayloadForServer(this[screen]);
      });

      return payload;
    },

    saveAnswers() {
      if (this.readOnlyView) {
        return;
      }
      const payload = {};

      Object.values(this.allScreens).forEach((screen) => {
        payload[screen] = this.makePayloadForServer(this[screen], screen);
      });

      if (this.questionnaire && this.questionnaire.markets) {
        payload["markets"] = this.questionnaire.markets;
      }

      if (this.questionnaire && this.questionnaire.extraDetails) {
        payload["extraDetails"] = this.questionnaire.extraDetails;
      }

      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        ApiQueue.enqueue(() => this.saveQuestionnaire({ questionnaire: payload }));
      }, 350);
    },

    setInitialQuestionnaireDataForAllScreen() {
      if (this.initialQuestionnaireAllScreenData) return;
      this.initialQuestionnaireAllScreenData = {};
      let screen = null;
      Object.keys(this.allScreens).forEach((keyName) => {
        screen = this.allScreens[keyName];
        if (this[screen]) {
          this.initialQuestionnaireAllScreenData[screen] = Object.assign({}, this[screen]);
        }
      });
    },

    resetQuestionnaireToInitialState() {
      if (!this.initialQuestionnaireAllScreenData) return;
      let screen = null;
      Object.keys(this.allScreens).forEach((keyName) => {
        screen = this.allScreens[keyName];
        this[screen] = Object.assign({}, this.initialQuestionnaireAllScreenData[screen]);
      });
    },

    // FETCH ANSWERS JSON FROM BACKEND
    async getAnswers(organizationId = null) {
      this.setInitialQuestionnaireDataForAllScreen();
      this.fetchingAnswers = true;

      const data = await this.getQuestionnaire(organizationId);

      this.questionnaire = data.company_setup.questionnaire;

      this.currentQuestionnaireVersion = data.company_setup.version;

      if (!this.questionnaire) return (this.fetchingAnswers = false);

      let screen = null;

      // LOOP THROUGH SCREENS AND UPDATE THE VUEX STORE
      Object.keys(this.allScreens).forEach((keyName) => {
        screen = this.allScreens[keyName];
        if (this.questionnaire[screen]) {
          this[screen] = this.setPayloadFromServer(this[screen], this.questionnaire[screen]);

          this.companySetupSteps.map((val) => {
            if (val.details && val.details.find((e) => e.key == keyName)) {
              val.details.find((e) => e.key == keyName).status = this.questionnaire[screen].completed ? 1 : 0;
              val.details.find((e) => e.key == keyName).hidden = this.questionnaire[screen].hidden ? 1 : 0;
            }
          });

          this.quickStartSteps.map((val) => {
            if (val.details && val.details.find((e) => e.key == keyName)) {
              val.details.find((e) => e.key == keyName).status = this.questionnaire[screen].completed ? 1 : 0;
              val.details.find((e) => e.key == keyName).hidden = this.questionnaire[screen].hidden ? 1 : 0;
            }
          });

          this.indicationSetupSteps.map((val) => {
            if (val.details && val.details.find((e) => e.key == keyName)) {
              val.details.find((e) => e.key == keyName).status = this.questionnaire[screen].completed ? 1 : 0;
              val.details.find((e) => e.key == keyName).hidden = this.questionnaire[screen].hidden ? 1 : 0;
            }
          });
        }
      });
      this.fetchingAnswers = false;

      return data.company_setup;
    },

    setQuestionStatus(keyName, isCompleted) {
      this[this.allScreens[keyName]].completed = isCompleted;
    },

    // DONT SEND FULL PAYLOAD
    // SEND ONLY id, choice, visibility to the server to keep the payload compact
    makePayloadForServer(jsonObject, screen) {
      // IF THE PASSED OBJECT DOESN'T HAVE QUESTIONS JUST RETURN AS IS
      if (!jsonObject.questions) return jsonObject;

      //  IF IT HAS QUESTIONS PROCESS AND SEND ONLY LIMITED DATA
      const obj = {
        ...jsonObject,

        questions: jsonObject.questions.map((el) => {
          let payload = { key: el.key };
          // if question has a choice then add it to the backend request
          if (el.hasOwnProperty("choice")) payload.choice = el.choice;

          // if question has a formdata then add it to the backend request
          if (el.hasOwnProperty("formData")) payload.formData = el.formData;
          return payload;
        }),

        completed:
          screen && screen == "networkSecurityControlsQuestions" && !this.isNetworkSecurityCapabilitiesFilled
            ? 1
            : jsonObject.completed || 0,
        hidden:
          screen && screen == "networkSecurityControlsQuestions" && !this.isNetworkSecurityCapabilitiesFilled ? 1 : 0,
      };

      return obj;
    },

    // UPDATE THE VUEX STORE
    setPayloadFromServer(original, serverPayload) {
      // IF THE QUESTION TYPE DOESN'T HAVE QUESTIONS THEN RETURN IT AS IS
      if (!serverPayload.questions) return serverPayload;

      // IF IT HAS QUESTIONS ARRAY THE PROCESS THEM FOR VUEX BECAUSE WE ONLY UPDATE VISIBILITY AND CHOICE
      let originalQuestions = [...original.questions];
      let serverDict = {};
      let indexes = {};

      serverPayload.questions.forEach((question) => (serverDict[question.key] = question));
      originalQuestions.forEach((el, index) => (indexes[el.key] = index));

      const updated = originalQuestions.map((question) => {
        let payload = { key: question.key };

        if (this.readOnlyView) {
          payload.readOnly = true; // for show everything as read only view - eg: admin customer instance view
        }

        // If question vuex has choice them replace with server choice
        if (question.hasOwnProperty("choice")) {
          if (!serverDict[question.key]?.choice) {
            if (question.hasOwnProperty("type") && question.type && question.type === "hasVendors") {
              payload.choice = question.choice;
            } else if (question.hasOwnProperty("type") && question.type && question.type === "hasVendorsWithType") {
              payload.choice = question.choice;
            } else {
              payload.choice = null;
            }
          } else {
            payload.choice = serverDict[question.key].choice;
          }
        }

        // If question in vuex has hidden form data for indication setup then replace with server form data
        if (question.hasOwnProperty("hiddenIndicationFormData")) {
          const hiddenIndicationFormData = serverDict[question.key]?.hiddenIndicationFormData;
          payload.hiddenIndicationFormData = hiddenIndicationFormData
            ? hiddenIndicationFormData
            : question.hiddenIndicationFormData;
        }

        // If question in vuex has form data then replace with server form data
        if (question.hasOwnProperty("formData")) {
          const formData = serverDict[question.key]?.formData;
          payload.formData = formData ? formData : question.formData;
          Object.keys(question.formData).map((questionKey) => {
            if (formData && !formData.hasOwnProperty(questionKey)) {
              payload.formData[questionKey] = question.formData[questionKey];
            }
          });
        }

        let finalObject = {
          ...question,
          ...payload,
        };

        return finalObject;
      });

      originalQuestions = updated;

      updated.forEach((question) => {
        if (question.show) {
          question.show.forEach((el) => {
            let index = indexes[el.key];

            if (!originalQuestions[index]) {
              return;
            }

            // If answer and showOn both are array type
            if (
              question.choice &&
              Array.isArray(question.choice) &&
              question.choice.length &&
              Array.isArray(el.showOn)
            ) {
              originalQuestions[index].visible = question.choice.some((ele) => el.showOn.includes(ele));
            }

            // If answer is string but showOn is array type
            else if (question.choice && !Array.isArray(question.choice) && Array.isArray(el.showOn)) {
              originalQuestions[index].visible = el.showOn.includes(question.choice);
            }

            // If answer is string but of type = range for eg 0 to 100
            else if (question.choice && el.hasOwnProperty("type")) {
              const [lowerLimit, upperLimit] = el.showOn.split(",");
              const value = parseInt(question.choice, 10);
              const valid = value > parseInt(lowerLimit, 10) && value <= parseInt(upperLimit, 10);
              originalQuestions[index].visible = valid ? true : false;
            }

            // If answer and showOn are both string tyoe
            else {
              originalQuestions[index].visible = question.choice === el.showOn;
            }
          });
        }

        const isIndication = this.$route.path.includes("indication-setup");
        if (isIndication && question.indicationAdditionalInfoShow && !question.visible) {
          question.visible = true;
        }
      });
      return { questions: originalQuestions, completed: serverPayload?.completed, hidden: serverPayload?.hidden };
    },

    getAddedVendor(questionsObject, index, childKey = "choice", type = "normal") {
      let updatedVendors = questionsObject.questions[index][childKey];

      if (type === "normal") updatedVendors.push({ name: "", website: "" });
      if (type === "withType") updatedVendors.push({ name: "", type: null, website: "" });

      return updatedVendors;
    },

    getRemovedVendor(questionsObject, mainIndex, indexOfVendor, key = "questions", childKey = "choice") {
      let question = { ...questionsObject[key][mainIndex] };

      const updated = question[childKey].filter((el, index) => index !== indexOfVendor);

      question.choice = updated;

      return updated;
    },

    getUpdatedVendor(questionsObject, vendorData, parentIndex, inputIndex, parentKey, childKey) {
      let questions = { ...questionsObject };

      let existingVendors = questions[parentKey][parentIndex][childKey];

      existingVendors[inputIndex] = {
        ...existingVendors[inputIndex],
        ...vendorData,
      };

      return existingVendors;
    },

    // HELPERS FOR CSG
    handleNumberValidation(e) {
      let charCode = e.which ? e.which : e.keyCode;

      // Allow arrow keys (left: 37, up: 38, right: 39, down: 40)
      if ([37, 38, 39, 40].includes(charCode)) {
        return;
      }

      // Allow numeric keypad digits
      if (charCode >= 96 && charCode <= 105) {
        return;
      }

      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) e.preventDefault();

      if ([189, 69].includes(charCode)) e.preventDefault();
    },

    handleLimit(val, index, property, keyName, questionsKey) {
      this.$nextTick(() => {
        let value = Number(val);
        if (value > 100) value = 100;
        this[questionsKey] = this.updateProp(this[questionsKey], keyName, index, property, value);
      });
    },

    isPastDate(dateString) {
      const isValid = moment(dateString, "M/YY", true).isValid();
      const expirationDate = moment(dateString, "M/YY").endOf("month");
      const endOfMonth = moment().endOf("month");
      if (!isValid || expirationDate.isAfter(endOfMonth)) return false;
      return true;
    },

    isPastYear(yearString) {
      const isValid = moment(yearString, "YYYY", true).isValid();
      const expirationDate = moment(yearString, "YYYY").endOf("month");
      const endOfMonth = moment().endOf("month");
      if (!isValid || expirationDate.isAfter(endOfMonth)) return false;
      return true;
    },

    isDateValidAndInFormat(dateString) {
      if (!dateString || !dateString?.trim()) return false;

      const patterns = ["MM/DD", "M/D", "MM/D"];
      const valid = patterns.some((pattern) => {
        return moment(dateString, pattern, true).isValid();
      });

      return valid;
    },

    async saveQuickStartSetBrokerAnswers() {
      if (this.readOnlyView) {
        return;
      }
      // using for set quick start status if second section updated after sent notification to broker
      if (this.questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_quick_quote") {
        this.questionnaire.extraDetails.quickQuotes.update_broker_section = true;
      }
      await this.saveAnswers();
      return true;
    },

    async saveQuickStartApplicationProcessAnswers() {
      if (this.readOnlyView) {
        return;
      }
      // using for set quick start status if third section updated after sent notification to broker
      if (this.questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        this.questionnaire.extraDetails.quickQuotes.update_application_process_section = true;
      }
      await this.saveAnswers();
      return true;
    },

    redirectToCSG() {
      if (this.questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        let currentUser = this.currentUser;
        currentUser.user.showQuickQuotes = false;
        this.$store.commit("login/setState", { key: "currentUser", value: currentUser }, { root: true });
        localService.setItem("userInfo", currentUser);
        this.$router.push("/user/company-setup");
      }
    },
  },
};

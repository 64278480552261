<template>
  <v-row>
    <v-col cols="12" class="pb-0 d-flex justify-end">
      VERSION DATED FEBRUARY 1, 2024
    </v-col>
    <v-col cols="12" class="text-justify">
      <p class="font-700 text-center">
        SOFTWARE AS A SERVICE AGREEMENT<br />
        (COMPANY WORKBENCH)
      </p>
      <p>
        This Software as a Service Agreement (“Agreement”) is a legally binding agreement and defines the terms and conditions pursuant to which SecondSight provides to you and you agree to use on the terms and conditions provided SecondSight’s Company Workbench, a hosted suite of software tools and services that help businesses uncover, understand, and manage digital risk more effectively. Company Workbench is also referred to as the “SaaS” in this Agreement, and constitutes a SecondSight Offering (as defined below and in the Main Customer Agreement (“MCA”) between you and SecondSight). This Agreement is incorporated into the MCA in its entirety, and constitutes an Incorporated Agreement. The terms and conditions of this Agreement are in addition to and, unless explicitly stated, do not supersede, replace, or otherwise limit similar terms and conditions in the Incorporated Agreements; provided, however, that in the event of a conflict between the terms of this Agreement and the MCA, the MCA shall control. Capitalized terms not defined in this Agreement, including in Section 1 below, have the meaning given to them in the MCA. For the avoidance of doubt, this Agreement may be referred to by defined terms other than “Agreement” in the Incorporated Agreement, and the term “Agreement” shall refer to this Agreement only in this document.
      </p>
      <p>
        In order to use the SaaS, you must agree to be bound by these terms, the terms of the MCA, expressly including its arbitration provision, and the terms of any other Incorporated Agreement. By accepting this Agreement, you agree to and accept its terms and conditions. If you do not accept these terms and conditions, you may not use the SaaS.
      </p>
    </v-col>

    <v-col cols="12">
      <ol class="custom-numbering parent-node">
        <li>
          <strong>Definitions </strong>

          <ol class="custom-numbering">
            <li>
              <strong>“Authorized Insurance Entity”</strong>
              means an Insurance Entity expressly appointed by the Customer to receive Customer Data for the Cyber Insurance Purpose.
            </li>
            <li>
              <strong>“Authorized Service Provider” </strong>
              means a party providing services, software, and any other equipment, goods, products, or licenses provided by third parties concurrently with or in coordination with the SecondSight Offerings, including the SaaS.
            </li>

            <li>
              <strong>“Collected Customer Data” </strong>
              means any and all Customer data reviewed, analyzed, and collected in connection with Customer’s use of the SaaS, expressly including, but not limited to, any Cyber Risk Data and any Cyber Insurance Data.
            </li>

            <li>
              <strong>“Company Workbench” </strong>
              means the hosted software, offline or mobile components, updates, corrections, and upgrades constituting the Company Workbench hosted suite of software tools as may be provided from time to time by SecondSight under the name “Company Workbench” or such other name utilized for substantively the same software suite, including the non-Insurance-Entity-facing suite of tools known as “SecondSight Workbench.”
            </li>

            <li>
              <strong>“Confidential Information” </strong>
              means information that is not public knowledge, or any competitively sensitive information and materials that are the property of or related to Customer or its business (excepting only information and materials already known by the general public), as follows: (a) trade secrets, (b) the names and addresses of Customer’s current customers (and all information relating to such customers) to the extent such information is not publicly known or ascertainable, (c) information imperative for the good-will of Customer, (d) financial statements, budgets and projections, and (e) strategies, marketing plans, business know-how, business plans, learned by SecondSight in light of SecondSight’s provision of SecondSight Offerings to Customer, including information concerning Customer’s clients, suppliers, vendors, financing sources, financial condition, pricing, or techniques.
            </li>

            <li>
              <strong>“Cyber Insurance” </strong>
              means insurance offering coverage against liability from Cyber Risk.
            </li>

            <li>
              <strong>“Cyber Insurance Application” </strong>
              means an application for Cyber Insurance coverage, and all forms, reports, or other documents created or submitted in connection with the Cyber Insurance Application process.
            </li>

            <li>
              <strong>“Cyber Insurance Data” </strong>
              means Collected Customer Data that may be used to evaluate Customer’s ability to obtain Cyber Insurance, including pertinent Cyber Risk Data. This includes any Collected Customer Data utilized, required or requested by an Insurance Entity in connection with a Cyber Insurance Application, including the Cyber Insurance Application.
            </li>

            <li>
              <strong>“Cyber Insurance Purpose” </strong>
              means evaluation of Cyber Risk, management of Cyber Risk, and submission of related data and information to Insurance Entities in connection with applications and binding coverage for Cyber Insurance.
            </li>

            <li>
              <strong>“Cyber Risk” </strong>
              means risk for events of financial loss, damages, or loss of use of systems or data related to cybersecurity crimes and torts, including from hacking, ransomware attacks, and other similar concerns, as well as inadvertent or negligent data loss.
            </li>

            <li>
              <strong>“Cyber Risk Data” </strong>
              means Collected Customer Data related to Customer’s Cyber Risk, and expressly includes all information, data, and reports created by the SaaS in connection with the evaluation of Customer’s Cyber Risk.
            </li>

            <li>
              <strong>“Cyber Risk Professional” </strong>
              means a third party providing services related to the evaluation and mitigation of Cyber Risk in connection with the SecondSight Offerings, which may include attorneys providing advice related to privacy, data, and Cyber Risk and related compliance.
            </li>

            <li>
              <strong>“Fees” </strong>
              means all fees charged in accordance with the MCA, including as provided in the Invoicing Documents.
            </li>

            <li>
              <strong>“Insurance Entity” </strong>
              means an insurance agent, broker, or carrier.
            </li>

            <li>
              <strong>“Intellectual Property Rights” </strong>
              means the Work Product as well as any and all rights in and to copyrights, trade secrets, trademarks (and related goodwill), mask works, patents and other intellectual property rights arising in any jurisdiction throughout the world and all related rights of priority under international conventions, including all pending and future applications and registrations, and continuations, divisions, continuations-in-part, reissues, extensions and renewals.
            </li>

            <li>
              <strong>“Other Offerings” </strong>
              means, without limitation, any services, software, and any other equipment, goods, products, or licenses provided by third parties concurrently with or in coordination with SecondSight Offerings.
            </li>

            <li>
              <strong>“Permitted SaaS Purpose” </strong>
              means evaluating customer’s Cyber Risk, mitigating Cyber Risk, and all Cyber Insurance PurposeSaas. <strong>“SaaS”</strong> means the Broker Workbench.
            </li>

            <li>
              <strong>“SecondSight Offerings” </strong>
              means, without limitation, any services, software, and any other equipment, goods, products, or licenses provided by SecondSight or its agenSaaSThe SaaS is a SecondSight Offering.
            </li>

            <li>
              <strong>“Software Agreement” </strong>
              means an end user license agreement, a software as a service agreement, or such other substantially similar agreement granting the Customer the express right to use certain Software.
            </li>

            <li><strong>“Term”</strong> is defined in Section 4.</li>

            <li><strong>“Website”</strong> means https://www.secondsight.ai.</li>

            <li>
              <strong>“Work Product” </strong>
              means all writings, works of authorship, technology, inventions, discoveries, ideas and other work product of any nature whatsoever, that are created, prepared, produced, authored, edited, amended, conceived or reduced to practice by SecondSight and/or its employees, individually or jointly with others in connection with Customer’s use of the SaaS  (regardless of when or where the Work Product is prepared or whose equipment or other resources is used in preparing the same) and all printed, physical and electronic copies, all improvements, rights and claims related to the foregoing, and other tangible embodiments.
            </li>
          </ol>
        </li>

        <li>
          <strong>Right to Access and Use SaaS. </strong>

          <ol class="custom-numbering">
            <li>
              <strong>“SaaS” </strong>
              Company Workbench is a suite of software tools that help businesses uncover, understand, and manage Cyber Risk more effectively. Company Workbench may be used in connection with other SecondSight Offerings or Other Offerings, including the provision of services by Authorized Service Providers such as Cyber Risk Professionals. Company Workbench may be utilized in connection with evaluating and managing Cyber Risk for the purpose of obtaining Cyber Insurance, including to submit Cyber Insurance Applications to Insurance Entities providing Cyber Insurance.
            </li>

            <li>
              <strong>Grant of Right to Access and Use SaaS. </strong>
              Subject to Customer’s payment of Fees, SecondSight grants to Customer a non-exclusive, non-assignable, and non-transferable right to access and use the SaaS, solely for the Permitted SaaS Purpose, and subject to the terms and conditions of this Agreement, together with the Incorporated Agreements.
            </li>

            <li>
              <strong>Collected Customer Data. </strong>
              Without limiting the other terms and conditions of this Agreement or the Incorporated Agreements, Customer acknowledges and agrees that the SaaS will access Collected Customer Data. Customer acknowledges and agrees that Authorized Service Providers may be provided Collected Customer Data in connection with the performance of applicable SecondSight Offerings. Customer acknowledges and agrees that Authorized Insurance Entities may be provided with Cyber Insurance Data, and that such Authorized Insurance Entities may provide Cyber Insurance Data to other Insurance Entities in connection with evaluation of Cyber Risk, Customer’s ability to obtain Cyber Insurance, and Cyber Insurance Applications. As additionally provided in this Agreement, including at Sections 6.1 and 8.1, and in other Incorporated Agreements, certain Collected Customer Data may be aggregated and anonymized and then maintained, processed, used, or sold by SecondSight both during and after the Term.
            </li>

            <li>
              <strong>Subsequent SaaS Versions. </strong>
              Customer’s rights to use the SaaS includes the SaaS as initially provided, and any subsequent or alternate versions or updates provided to Customer.
            </li>

            <li>
              <strong>No Reproduction. </strong>
              Unless you receive written permission from SecondSight, you cannot copy any portion of the SaaS. Without written permission, you cannot modify the SaaS, attempt to view, reverse engineer, decompile, or otherwise gain access to the source code of the SaaS, bypass or breach any security device or protection included in the SaaS. You shall not permit, assist, or enable any third party from doing the same.
            </li>

            <li>
              <strong>Provision of Accurate Information. </strong>
              Your right to use the SaaS is contingent on your provision of information requested by SecondSight related to your use of the product, which may including the size of your entity, the amount of data you store and maintain, other qualities, quantities, and characteristics of your hardware or network systems and servers, among other factors. You must accurately disclose any such details when requested. You may be required to verify that all information disclosed in connection with the use of the SaaS, or any information disclosed in connection with a Cyber Insurance Application is true and accurate.
            </li>

            <li>
              <strong>No Implied Rights. </strong>
              There are no implied licenses under this Agreement, and SecondSight and its licensors reserve all rights not expressly granted in this Agreement or the other Incorporated Agreements.
            </li>

            <li>
              <strong>Equipment. </strong>
              Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access, or otherwise use the SaaS, including, without limitation, hardware, servers, software, operating, systems, and networks or network connection (“Equipment”). Customer shall use industry standard web browsers and a high speed, reliable internet connection to enable full functionality when accessing the SaaS and further agrees to comply with other infrastructure or software environment requirements as specified by SecondSight from time to time.
            </li>
          </ol>
        </li>

        <li>
          <strong>Fees. </strong>

          <ol class="custom-numbering">
            <li>
              Customer shall pay Fees shall be paid as provided in the MCA and the Invoicing Documents. Customer must otherwise abide with the terms and conditions of the Incorporated Agreements to receive and continue to receive access to the SaaS.
            </li>
          </ol>
        </li>

        <li>
          <strong>Term. </strong>
          Unless the Invoicing Documents provide that the term of this Agreement (the “Term”) shall begin on another date, the Term shall begin on the date when Customer has (a) accepted this Agreement and (b) accepted all other Incorporated Agreements required by SecondSight in connection with the provision of the SaaS, and (c) has paid all Fees then due in accordance with the Invoicing Documents. If the applicable Invoicing Documents permit the payment of Fees at a later date, Customer will be considered to be in compliance with this latter provision. The Term shall continue for as long as Customer pays Fees for the SaaS as provided in the Invoicing Documents, and otherwise remains in compliance with the Incorporated Agreements. SecondSight may terminate this Agreement upon Customer’s breach of the Incorporated Agreements. After the Term has ended, Customer may not use the SaaS; however, all other provisions of this Agreement shall remain binding and in effect, expressly included, but not limited to, those pertaining to Confidential Information, Work Product, and Intellectual Property Rights.  The Invoicing Documents may otherwise provide that the Term shall commence on a date other than as detailed above or upon the satisfaction of other conditions, which shall control regardless of reference to this Agreement or this section; provided, however, that the Invoicing Documents shall not limit the conditions that may cause the end of the Term unless express reference is made in the Invoicing Documents that the parties intend to modify those rights as provided in the Incorporated Agreements. At its complete discretion, SecondSight may waive any condition precedent to the commencement of the Term as detailed in this section.
        </li>

        <li>
          <strong>Intellectual Property Rights; Ownership. </strong>

          <ol class="custom-numbering">
            <li>
              <strong>Work Product. </strong>
              Customer acknowledges and agrees that all Work Product and all Intellectual Property Rights for the SecondSight Offerings, including the SaaS and the Software, shall be the sole and exclusive property of SecondSight.
            </li>

            <li>
              <strong>No License. </strong>
              Customer understands that this Agreement does not, and shall not be construed to, grant Customer any license or right of any nature with respect to any Work Product or Intellectual Property Rights, materials, software or other tools made available to Customer by SecondSight outside or in addition to the rights Customer might have with SecondSight under any separate Software Agreements.
            </li>
          </ol>
        </li>

        <li>
          <strong>Confidential Information. </strong>

          <ol class="custom-numbering">
            <li>
              Notwithstanding the foregoing, Confidential Information is the exclusive property of Customer. The foregoing shall not limit the ability of SecondSight, the SaaS, the Software, or the Other Software to collect, maintain, process, use, and sell diagnostic, technical, usage, and related information, including information about your computer systems and software, and the analytic data produced by the SaaS, Software, or the Other Software as a result of Customer’s use (provided that such data shall not include the Confidential Information, but only the results of the SaaS, Software, or Other Software’s analysis of such Confidential Information), which shall be aggregated and anonymized.
            </li>

            <li>
              <strong>Restriction. </strong>
              Subject to Section 7.1, SecondSight shall use Confidential Information solely in connection with its provision of SecondSight Offerings to Customer and shall not directly or indirectly disclose, use, or exploit any Confidential Information for SecondSight’s own benefit or for the benefit of any person or entity, other than Customer, both during and after the Term, unless or until such time as: (i) Confidential Information becomes publicly known through no action or inaction of SecondSight, or (ii) SecondSight provides Customer with written notice that Confidential Information became available to SecondSight on a non-confidential basis before its disclosure to SecondSight by Customer.
            </li>

            <li>
              <strong>Exemption. </strong>
              Nothing herein shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to the valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of disclosure required by such law, regulation, or order. SecondSight agrees to provide written notice of any such order to an authorized officer of Customer within forty-eight (48) hours of receiving such order, but in any event sufficiently in advance of making any disclosure to permit Customer to contest the order or seek confidentiality protections, as determined in Customer’s sole discretion. SecondSight will not be held criminally or civilly liable under any federal or state trade secret law for any disclosure of a trade secret that: (i) is made: (a) in confidence to a federal, state, or local government official, either directly or indirectly, or to an attorney; and (b) solely for the purpose of reporting or investigating a suspected violation of law; or (ii) is made in a complaint or other document that is filed under seal in a lawsuit or other proceeding. If SecondSight files a lawsuit for retaliation by Customer for reporting a suspected violation of law, SecondSight may disclose Customer’s trade secrets to SecondSight’s attorney and use the trade secret information in the court proceeding if SecondSight: (i) files any document containing the trade secret under seal; and (ii) does not disclose the trade secret, except pursuant to court order.
            </li>
          </ol>
        </li>

        <li>
          <strong>Non-Exclusivity. </strong>
          SecondSight retains the right to offer the SaaS and to perform the SecondSight Offerings or similar type of services for third parties.
        </li>

        <li>
          <strong>Security. </strong>

          <ol class="custom-numbering">
            <li>
              SecondSight agrees and covenants: (i) to comply with all security policies and procedures of Customer which are in force from time to time including without limitation those regarding computer equipment, telephone systems, voicemail systems, access codes, Customer internet, social media and instant messaging systems, computer systems, e-mail systems, computer networks, document storage systems, software, data security, encryption, firewalls, passwords and any and all other facilities, IT resources and communication technologies (“Facilities Information Technology and Access Resources”); (ii) not to access or use any Facilities and Information Technology Resources except as authorized by Customer; and (iii) not to access or use any Facilities and Information Technology Resources in any manner after the termination or expiration of the this SPA, the MCA, or Incorporated Agreements, except that SecondSight shall be permitted to retain certain information on an aggregated and anonymized basis in accordance with Section 6.1. SecondSight agrees to notify Customer promptly in the event SecondSight learns of any violation of the foregoing by others, or of any other misappropriation or unauthorized access, use, reproduction or reverse engineering of, or tampering with any Facilities and Information Technology Access Resources or other Customer property or materials by others.
            </li>

            <li>
              SecondSight represents it has complied, and is presently in compliance, with applicable privacy and security policies and with all obligations under applicable laws and regulations regarding the collection, use, transfer, storage, protection, disposal or disclosure of personally identifiable information or any other legally protected information collected from or provided by third parties.
            </li>
          </ol>
        </li>
        <li>
          <strong>Privacy Policy. </strong>
          Customer has had the opportunity to review the Privacy Policy, which is available on the Website. Customer acknowledges and agrees that SecondSight is permitted to revise or amend the Privacy Policy without advance notice.
        </li>

        <li>
          <strong>Customer Support. </strong>
          Updated customer support contacts are available on the Website.
        </li>

        <li>
          <strong>Warranties and Disclaimers. </strong>
          SECONDSIGHT PROVIDES THE SAAS ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. SECONDSIGHT DISCLAIMS ANY EXPRESS, IMPLIED, OR STATUTORY WARRANTIES OF QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. IN ADDITION, SECONDSIGHT MAKES NO REPRESENTATION REGARDING, NOR DOES IT WARRANT OR ASSUME ANY RESPONSIBILITY FOR, ANY THIRD-PARTY APPLICATIONS (OR THE CONTENT THEREOF), DEVICE ON ANY PRODUCT OR SERVICES ADVERTISED, PROMOTED OR OFFERED BY A THIRD PARTY, AND SECONDSIGHT IS NOT RESPONSIBLE FOR ANY TRANSACTIONS BETWEEN THE USER AND ANY THIRD PARTY. NO ADVICE OR INFORMATION WHETHER ORAL OR IN WRITING OBTAINED BY YOU FROM SECONDSIGHT SHALL CREATE ANY WARRANTY ON BEHALF OF SECONDSIGHT. THIS SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. WITHOUT LIMITING THE FOREGOING, THE USER ACKNOWLEDGES AND UNDERSTANDS THAT THE SAAS ALONE DOES NOT PREVENT OR MITIGATE CYBER RISK, AND THAT ANY SECONDSIGHT OFFERINGS CANNOT ELIMINATE CYBER RISK NOR CAN IT ENSURE THAT CUSTOMER WILL RECEIVE CYBER INSURANCE AFTER USING THE SAAS. THE SAAS IS INTENDED TO PROVIDE YOU WITH DISCRETE DATA AND INFORMATION THAT YOU MAY THEN USE AND APPLY IN CONNECTION WITH YOUR OWN EFFORTS TO LIMIT OR MITIGATE YOUR CYBER RISK, OR TO ASSIST IN RECEIVING CYBER INSURANCE FROM A THIRD PARTY. SECONDSIGHT CANNOT BE RESPONSIBLE FOR ANY ACTUAL CRIMES OR TORTS COMMITTED BY THIRD PARTIES WHATSOEVER, EXPRESSLY INCLUDING THOSE ATTEMPTING TO EXPLOIT YOUR CYBER CAPABILITIES OR CYBER RISK, AND SECONDSIGHT DOES NOT REPRESENT OR WARRANT THAT THE SECONDSIGHT OFFERINGS CAN PREVENT CYBER CRIME OR OTHER LOSS, INJURY, OR DAMAGE.
        </li>

        <li>
          <strong>Limitation of Liability. </strong>
          In no event will SecondSight, its officers, owners, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for (a) any indirect, special, incidental, punitive, exemplary, or consequential damages arising out of this Agreement; (b) any loss of use, data, business, or profits (whether direct or indirect), in all cases arising out of the use or inability to use the SaaS, or (c) any aggregate liability relating to the SaaS greater than that amounts paid by Customer to SecondSight for use of the SaaS during the twelve months prior to the first claim.
        </li>

        <li>
          <strong>Assignment. </strong>
          SecondSight shall be permitted to assign this Agreement at its discretion; provided, however, that such assignee must agree to be bound by all obligations that would be the responsibility of SecondSight. Customer may not assign this agreement without prior written consent of SecondSight.
        </li>

        <li>
          <strong>Indemnification. </strong>
          You agree to indemnify and hold SecondSight harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of or related to: (a) your breach of any of this Agreement (including the Incorporated Agreements as incorporated into this Agreement) and (b) any events of financial loss, damages, or loss of use of systems or data related to cybersecurity crimes and torts, including from hacking, ransomware attacks, and other similar concerns.
        </li>

        <li>
          <strong>Governing Law, Jurisdiction, and Dispute Resolution. </strong>
          This Agreement and all Incorporated Agreements are governed by and construed in accordance with the internal laws of the State of Indiana without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of Indiana. Any controversy or claim arising out of or relating to the MCA, or its breach, shall be settled by arbitration to be held in Indianapolis, Indiana and administered by the American Arbitration Association (“AAA”) in accordance with its Commercial Arbitration Rules. Judgment on the award rendered by the arbitrator(s) may be entered in court having jurisdiction. This arbitration clause shall be interpreted to the broadest extent permissible under applicable law, expressly including the Federal Arbitration Act (9 U.S.C. ch. 1) (“FAA”), to mandate resolution of disputes through arbitration by the AAA. Any legal suit, action, or proceeding arising out of or related to this Agreement, the SaaS, or the SecondSight Offerings will be instituted exclusively in the federal courts of the United States or the courts of the State of Indiana, in each case located in the city of Indianapolis and County of Marion, shall have exclusive jurisdiction over the entry of any legal suit, action, or proceeding (a) to enter any award rendered by the AAA, (b) to enforce this arbitration provision, or (c) for matters, if any, arising out of or related to the MCA that would not be subject to arbitration pursuant to the FAA despite the express agreement to arbitrate. SecondSight and the Customer each irrevocably submit to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. Service of process, summons, notice, or other document by mail to such party’s address set forth herein will be effective service of process for any suit, action, or other proceeding brought in any such court.
        </li>

        <li>
          <strong> Miscellaneous. </strong>

          <ol class="custom-numbering">
            <li>
              Each party shall, upon the reasonable request, of the other party, promptly execute such documents and perform such acts as may be necessary to give full effect to this Agreement.
            </li>

            <li>
              SecondSight’s relationship to Customer is that of independent contractor. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever, except as necessary in accordance with the performance of the Purchased Services.
            </li>

            <li>
              All notices, requests, consents, claims, demands, waivers, and other communications pursuant to this Agreement shall be made in accordance with the notice provisions of the MCA.
            </li>

            <li>SecondSight may make changes to this Agreement as provided in the MCA.</li>

            <li>
              The headings in this Agreement are for reference only and shall not affect the interpretation of this Agreement.
            </li>

            <li>
              Each party acknowledges that a breach by a party of 5 (Intellectual Property Rights; Ownership) or 6 (Confidentiality) may cause the non-breaching party irreparable damages, for which an award of damages would not be adequate compensation and agrees that, in the event of such breach or threatened breach, the non-breaching party will be entitled to seek equitable relief, including a restraining order, injunctive relief, specific performance, and any other relief that may be available from any court, in addition to any other remedy to which the non-breaching party may be entitled at law or in equity. Such remedies shall not be deemed to be exclusive but shall be in addition to all other remedies available at law or in equity, subject to any express exclusions or limitations in the MCA or Incorporated Agreements to the contrary.
            </li>
          </ol>
        </li>
      </ol>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "S2RSaasAgreement",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
ol {
  li {
    margin-top: 12px;
    padding-left: 8px;
  }
}
ol.custom-numbering {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
  &.parent-node > li {
    padding-left: 4px;
    &::before {
      display: inline-block;
      content: counters(item, ".") ".";
      counter-increment: item;
      padding-right: 8px;
      margin-left: -2em;
    }
  }
  > li {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 2em;
    &::before {
      display: inline-block;
      content: counters(item, ".") " ";
      counter-increment: item;
      padding-right: 8px;
      margin-left: -2em;
    }
  }
}
</style>
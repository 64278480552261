import axios from "../plugins/axios";
import localService from "../services/localService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "../main";
import { uuid } from "vue-uuid";

export default {
  namespaced: true,
  state: () => ({
    jlpcClients: [],
    uploadedReport: null
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
  },
  actions: {
    async getClients({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/brokers/jlpc_clients");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "jlpcClients", value: data.reports });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async uploadReport({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/jlpc_report", payload);
        if (!data.success) {
          return;
        }

        if(data.report?.status == 'submitted') {
          dispatch(
              "showMessage",
              { ...data, messageType: "success", messageTitle: "Success" },
              { root: true }
          );
          commit("setState", { key: "uploadedReport", value: null });
        } else {
          commit("setState", { key: "uploadedReport", value: data.report });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getUploadedReportByOrgId({commit}, orgId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/jlpc_report/organization/${orgId}`);
        if (!data.success) {
          return;
        }
       return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async deleteUploadedReport({commit}, entityId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/brokers/jlpc_report/${entityId}`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "uploadedReport", value: null });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async downloadJLReport1({commit}, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/brokers/download_jl_report`, payload);
        if (!data.success) {
          return;
        }
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    }
  },
  getters: {
    getField,
    jlpcClients: (state) => state.jlpcClients,
    uploadedReport: (state) => state.uploadedReport,
    brokerOrganizations: (state) => state.brokerOrganizations,
    userTableHeaders: (state) => state.userTableHeaders,
    adminClientTableHeaders: (state) => state.adminClientTableHeaders,
    users: (state) => state.users,
    organizationIntent: (state) => state.organizationIntent,
  },
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

const selectedFilters = () => {
  return {
    selectedRevenueBands: [],
    selectedPartners: [],
    selectedNaics: [],
    selectedNaicsCodes: {},
    selectedYear: new Date().getFullYear().toString(),
  };
};

const restructureSummaryData = (organizations_info) => {
  let restructuredData = [];
  for (let org_id in organizations_info) {
    const dataArray = organizations_info[org_id];
    restructuredData.push({
      organization_id: org_id,
      naics_code: dataArray[0],
      total_revenue: dataArray[1],
    });
  }
  return restructuredData;
};

const restructurePrivacyData = (privacy_data) => {
  let restructuredData = {};
  for (let range in privacy_data) {
    if (privacy_data.hasOwnProperty(range)) {
      restructuredData[range] = [];
      privacy_data[range].forEach((item) => {
        for (let orgId in item) {
          if (item.hasOwnProperty(orgId)) {
            let oldFormatObj = {
              organization_id: orgId,
              issues_count: item[orgId],
            };
            restructuredData[range].push(oldFormatObj);
          }
        }
      });
    }
  }
  return restructuredData;
};

const restructureThreatData = (threat_data) => {
  const result = {};

  for (const severity in threat_data) {
    result[severity] = threat_data[severity].map((item) => {
      const orgId = Object.keys(item)[0];
      const [count = 0, organization_name] = item[orgId];
      return {
        organization_id: orgId,
        count: count || 0,
        organization_name: organization_name,
      };
    });
  }

  return result;
};

const restructureScatterData = (scatter_data) => {
  const restructuredData = [];

  for (const brokerageId in scatter_data) {
    const brokerageData = scatter_data[brokerageId];
    const brokerageName = brokerageData[0];
    const organizations = brokerageData[1];

    const boundPolicies = {};
    const privacyScore = {};
    const threatScore = {};

    for (const orgId in organizations) {
      const orgData = organizations[orgId];
      privacyScore[orgId] = orgData[0] || 0;
      threatScore[orgId] = orgData[1] || 0;
      boundPolicies[orgId] = orgData[2] || 0;
    }

    restructuredData.push({
      brokerage_id: brokerageId,
      brokerage_name: brokerageName,
      privacy_score: privacyScore,
      threat_score: threatScore,
      bound_policies: boundPolicies,
    });
  }

  return restructuredData;
};

const restructurePartnersData = (partnersData) => {
  const oldData = [];

  for (const [brokerageId, value] of Object.entries(partnersData)) {
    const distributorPartnerName = value[0];
    const orgData = value[1];

    const agents = {};
    const privacyRisks = {};
    const threatVulnerabilityRisks = {};
    const totalAssets = {};
    const totalProperties = {};

    for (const [orgKey, orgValues] of Object.entries(orgData)) {
      const privacyScore = orgValues[0];
      const lowThreats = orgValues[1];
      const mediumThreats = orgValues[2];
      const highThreats = orgValues[3];
      const criticalThreats = orgValues[4];
      const properties = orgValues[5];
      const assets = orgValues[6];
      const agentEmails = orgValues[7] || [];

      agents[orgKey] = agentEmails.map((email) => ({ email }));
      privacyRisks[orgKey] = privacyScore;
      threatVulnerabilityRisks[orgKey] = {
        critical: criticalThreats,
        high: highThreats,
        medium: mediumThreats,
        low: lowThreats,
      };
      totalAssets[orgKey] = assets;
      totalProperties[orgKey] = properties;
    }

    oldData.push({
      agents,
      brokerage_id: brokerageId,
      distributor_partner_name: distributorPartnerName,
      name: distributorPartnerName,
      privacy_risks: privacyRisks,
      threat_vulnerability_risks: threatVulnerabilityRisks,
      total_assets: totalAssets,
      total_properties: totalProperties,
    });
  }

  return oldData;
};

const restructureDistributionPartners = (partners) => {
  const data = [];
  for (let partner in partners) {
    data.push({
      brokerage_id: partner,
      distributor_partner_name: partners[partner][0],
      organization_ids: partners[partner][1],
    });
  }
  return data;
};

const restructureDigitalAssets = (digital_assets) => {
  const data = [];
  for (let asset in digital_assets) {
    data.push({
      name: asset,
      percentage: digital_assets[asset],
    });
  }
  return data;
};

const restructurePolicies = (policies) => {
  const data = [];

  for (let policy_org_id in policies) {
    data.push({
      organization_id: policy_org_id,
      expires_in_3_months: policies[policy_org_id][0],
      expires_in_6_months: policies[policy_org_id][1],
      bound_policies: policies[policy_org_id][2],
    });
  }

  return data;
};

const restructureProperties = (properties) => {
  const data = [];

  for (let org_id in properties) {
    data.push({
      organization_id: org_id,
      count: properties[org_id],
    });
  }

  return data;
};

export default {
  namespaced: true,

  state: () => ({
    loadingSummary: false,
    summary: {},

    loadingPrivacyData: false,
    privacyData: null,

    loadingScatter: false,
    scatterData: {},

    loadingThreatVulnerability: false,
    threatVulnerabilityData: null,

    digitalAssetLoading: false,
    digitalAssetProviders: {},

    loadingPartnersData: false,
    partnersData: null,

    selectedFilters: selectedFilters(),

    distributionOrganizations: [],

    loadingPolicies: false,
    policiesData: [],

    loadingProperties: false,
    propertiesData: [],
  }),

  mutations: {
    updateField,

    setPrivacyData(state, payload) {
      state.privacyData = payload;
    },

    setThreatVulnerabilityData(state, payload) {
      state.threatVulnerabilityData = payload;
    },

    setDigitalAssetProviders(state, payload) {
      state.digitalAssetProviders = payload;
    },

    setTvScatterData(state, payload) {
      state.scatterData = payload;
    },

    setPartnersData(state, payload) {
      state.partnersData = payload;
    },

    setSummary(state, payload) {
      state.summary = payload;
    },

    setDistributionOrganizations(state, payload) {
      state.distributionOrganizations = payload;
    },

    setPoliciesData(state, payload) {
      state.policiesData = payload;
    },

    setPropertiesData(state, payload) {
      state.propertiesData = payload;
    },

    resetFilters(state) {
      state.selectedFilters = selectedFilters();
    },
  },

  actions: {
    async getSummary({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/summary");
        commit("setSummary", { organizations_info: restructureSummaryData(data?.organizations_info) });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getPrivacyData({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/privacy");
        data.data = {
          ...data.data,
          counts: restructurePrivacyData(data.data.counts),
        };
        commit("setPrivacyData", data.data);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getThreatVulnerabilityData({ commit, state }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/threat-vulnerability");
        commit("setThreatVulnerabilityData", {
          ...state.threatVulnerabilityData,
          cves: restructureThreatData(data.data),
        });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getThreatVulnerabilityForBar({ state, commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/threat_distribution");
        const structuredData = {};
        for (let month in data.data) {
          structuredData[month] = restructureThreatData(data.data[month]);
        }
        commit("setThreatVulnerabilityData", {
          ...state.threatVulnerabilityData,
          threat_vulnerability: structuredData,
        });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getDigitalAssetProviders({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/wholesaler/dashboard/digital-asset-providers`);
        commit("setDigitalAssetProviders", {
          ...data.data,
          asset_list: restructureDigitalAssets(data.data.asset_list),
        });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getPrivacyScatterData({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/privacy_threat_score_distribution");
        commit("setTvScatterData", restructureScatterData(data.data));
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getPartnersData({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/partner_risk_threat_asset");
        commit("setPartnersData", { distribution_partner_data: restructurePartnersData(data.data) });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getDistributionOrganizations({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/distribution_partner");
        commit("setDistributionOrganizations", restructureDistributionPartners(data.data.distribution_partners));
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getPolicies({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/policies");
        commit("setPoliciesData", restructurePolicies(data.policies));
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getProperties({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/dashboard/properties");
        commit("setPropertiesData", restructureProperties(data.properties));
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    getField,
  },
};

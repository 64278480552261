import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import localService from "@/services/localService";
import {vm} from "@/main";

export default {
  namespaced: true,
  state: () => ({
    brokerStatus: null,
    originalData: [],
    carrierListNames: [],
    carrierList: [],
    carrierListSelection: [],
    companyData: null,
    quickQuoteListLoading: false,
    quickQuotes: null,
    readyToSubmit: false,
    quickQuotesSubmission: null,
    selectedQuoteForReview: null,
    reviewQuotesDialog: false,
    loadingQuoteDetailsForReview: false,
    selectedCarrierImageSrc: null,
    isQuickQuoteLoading: false,

    // FULL QUOTE
    fullQuotes: null,
    canSubmitFullQuotes: false,
    fullQuotesSubmission: null,
    selectedFullQuote: null,
    fullQuoteListLoading: false,
    isFullQuoteLoading: false,

    // BIND
    bindDialog: false,
    bindResponse: null,
  }),

  mutations: {
    updateField,

    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    updateQuoteDetail(state, data) {
      let index = null;

      if(data.quote_type == "quick_quotes") {
        index = state.quickQuotes?.quotes?.findIndex((e) => e.quoteAppId == data.quoteAppId);
        if (index > -1) {
          vm.$set(state.quickQuotes.quotes, index, data);
        }
      } else {
        index = state.fullQuotes?.quotes?.findIndex((e) => e.quoteAppId == data.quoteAppId);
        if (index > -1) {
          vm.$set(state.fullQuotes.quotes, index, data);
        }
      }
    },

    updateQuoteStatus(state, data) {
      state.readyToSubmit = data?.ready_for_quick_quote;
      state.quickQuotesSubmission = data?.quick_quotes_submission;
    },

    updateFullQuoteStatus(state, data) {
      state.canSubmitFullQuotes = data?.ready_for_full_quote;
      state.fullQuotesSubmission = data?.full_quotes_submission;
    },

    generateCarrierList(state, data) {
      let carrierList = Object.assign([], state.carrierList);
      data.map((carrierName) => {
        if (!carrierList.find((e) => e.name?.toLowerCase() == carrierName?.toLowerCase())) {
          carrierList.push({
            name: carrierName,
            selected: false,
          });
        }
      });
      state.carrierList = Object.assign([], carrierList);
    },

    updateCarrierState(state, payload) {
      let carrierList = Object.assign([], state.carrierList);
      if (!payload.isMma) {
        let chubbIndex = carrierList.findIndex((e) => e.name?.toLowerCase() == "chubb admitted");
        carrierList.splice(chubbIndex, 1);
      }
      let carrierFound = false;
      carrierList.map((data) => {
        carrierFound = false;
        if (data) {
          if (payload.carrierList) {
            if (payload.carrierList.find((e) => e.name?.toLowerCase() == data.name?.toLowerCase())) {
              carrierFound = true;
            }
            data.selected = payload.carrierList.find(
              (e) => e.name?.toLowerCase() == data.name?.toLowerCase()
            )?.selected;
          } else if (!payload.isMma) {
            data.selected = true;
          }
          if (
            !carrierFound &&
            payload.extractedCarriers &&
            payload.extractedCarriers.find((e) => e?.toLowerCase() == data.name?.toLowerCase())
          ) {
            data.selected = true;
          }
        }
      });
      state.carrierList = Object.assign([], carrierList);
    },

    setQuickQuoteToGenerating(state) {
      state.readyToSubmit = false;
      state.quickQuotesSubmission = { status: "GeneratingQuotes" };
      state.quickQuotes = null;
    },

    setQuickQuoteToDefault(state) {
      state.readyToSubmit = true;
      state.quickQuotesSubmission = {};
    },

    setFullQuoteToGenerating(state) {
      state.ready_for_full_quote = false;
      state.fullQuotesSubmission = { status: "GeneratingQuotes" };
      state.fullQuotes = null;
    },

    setFullQuoteToDefault(state) {
      state.ready_for_full_quote = true;
      state.fullQuotesSubmission = {};
    },
  },

  actions: {
    async saveCarrierList({ commit }, payload) {
      try {
        const { data } = await axios.post(`/carrier_market`, { carriers: payload });
        if (!data.success) {
          return;
        }
        commit("setState", { key: "carrierListNames", value: data?.carrier_markets?.carriers || [] });
        commit("generateCarrierList", data?.carrier_markets?.carriers || []);
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    async getCarrierList({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/carrier_market`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "carrierListNames", value: data?.carrier_markets?.carriers || [] });
        commit("generateCarrierList", data?.carrier_markets?.carriers || []);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getMshiftBrokerStatus({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/mshift/broker_status`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "brokerStatus", value: data.broker_info });
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getMShiftCarrierSettings({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/carrier_settings/${payload.organization_id}`);
        if (data.success) {
          let subdomain = null;
          let regexParse = new RegExp("([a-z-0-9]{2,63}).([a-z.]{2,5})$");
          let urlParts = regexParse.exec(window.location.hostname);
          subdomain = window.location.hostname.replace(urlParts[1] + "." + urlParts[2], "").slice(0, -1);
          const isMma = subdomain.includes("mma") || subdomain.includes("readiness") || subdomain.includes("localhos");
          if (data.carrier_settings && data.carrier_settings.carriers) {
            commit("updateCarrierState", {
              isMma: isMma,
              carrierList: data.carrier_settings?.carriers,
              extractedCarriers: payload.extracted_carriers,
            });
          } else {
            commit("updateCarrierState", { isMma: isMma, extractedCarriers: payload.extracted_carriers });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async saveMShiftCarrierSettings({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/carrier_settings/${payload.organization_id}`, payload);
        if (data.success) {
          commit("setState", { key: "carrierList", value: data.carrier_settings?.carriers });
        }
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getMShiftSubmittedQuotes({ commit, dispatch }, payload) {
      try {
        let { data } = await axios.get(`/submission/quick_quotes/${payload.organization_id}`);
        if (!data.success) {
          return;
        }
        commit("updateQuoteStatus", data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getMShiftQuotes({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("setState", { key: "quickQuoteListLoading", value: true });
        const { data } = await axios.get(`/quick_quotes/${payload.organization_id}`);
        if (data.quick_quotes?.quotes) {
          await dispatch("getMShiftSubmittedQuotes", payload);
        }
        commit("setState", { key: "quickQuotes", value: data.quick_quotes });
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
        commit("setState", { key: "quickQuoteListLoading", value: false });
      }
    },

    async submitMShiftQuotes({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("setQuickQuoteToGenerating");
        const { data } = await axios.post(`/submit/quick_quotes/${payload.organization_id}`);
        if (!data.success) {
          commit("setQuickQuoteToDefault");
          return;
        }
        commit("setState", { key: "quickQuotes", value: data });
        commit("updateQuoteStatus", data);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async reSubmitMShiftQuotes({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("setQuickQuoteToGenerating");
        const { data } = await axios.post(`/resubmit/quick_quotes/${payload.organization_id}`);
        if (!data.success) {
          commit("setQuickQuoteToDefault");
          return;
        }
        commit("setState", { key: "quickQuotes", value: data });
        commit("updateQuoteStatus", data);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async submitFullQuotes({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("setFullQuoteToGenerating");
        const { data } = await axios.post(`/submit/full_quotes/${payload.organization_id}`);
        if (!data.success) {
          commit("setFullQuoteToDefault");
          return;
        }
        commit("updateFullQuoteStatus", data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async reSubmitFullQuotes({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("setFullQuoteToGenerating");
        let { data } = await axios.post(`/resubmit/full_quotes/${payload.organization_id}`);
        if (!data.success) {
          commit("setFullQuoteToDefault");
          return;
        }
        commit("setState", { key: "fullQuotes", value: data });
        commit("updateFullQuoteStatus", data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async submitFullQuoteForAdditionalInfo({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post(`/company_setup/${payload.organization_id}/request_additional_info`);
        if (!data.success) return;
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async notifyBrokerQuickQuotes({ commit, rootState }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/company_setup/brokers/notify_quick_quotes`, payload);
        if (!data.success) {
          return;
        }
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async notifyBrokerFullQuotes({ commit, rootState }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/company_setup/brokers/notify_full_quotes`, payload);
        if (!data.success) {
          return;
        }
        let currentUser = rootState.login.currentUser;
        currentUser.user.showQuickQuotes = false;
        commit("login/setState", { key: "currentUser", value: currentUser }, { root: true });
        localService.setItem("userInfo", currentUser);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getQuoteDetailsById({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/quick_quotes/${payload.organization_id}/${payload.quote_app_id}`);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getFullQuotesSubmissionStatus({ commit, dispatch }, payload) {
      try {
        let { data } = await axios.get(`/submission/full_quotes/${payload.organization_id}`);
        if (!data.success) return;
        commit("updateFullQuoteStatus", data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getFullQuotes({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "fullQuoteListLoading", value: true });
        let { data } = await axios.get(`/full_quotes/${payload.organization_id}`);
        if (!data.success) return;
        commit("setState", { key: "fullQuotes", value: data.full_quotes });
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
        commit("setState", { key: "fullQuoteListLoading", value: false });
      }
    },

    async getQuickQuoteDetails({ commit, dispatch }, payload) {
      try {
        let { data } = await axios.get(`/quick_quotes/${payload.organization_id}/${payload.quote_app_id}`);
        if (!data.success) return;
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getFullQuoteDetails({ commit, dispatch }, payload) {
      try {
        let { data } = await axios.get(`/full_quotes/${payload.organization_id}/${payload.quote_app_id}`);
        if (!data.success) return;
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async bindQuickQuote({ commit }, payload) {
      try {
        let { data } = await axios.post(`/quick_quotes/bind/${payload.organization_id}/${payload.quote_app_id}`);
        if (data.success) {
          let resData = Object.assign({}, data.quick_quotes_detail);
          resData.quote_type = "quick_quotes";
          commit('updateQuoteDetail', resData)
        }
        return data;
      } catch (error) {
        console.error(error.response);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async bindFullQuote({ commit }, payload) {
      try {
        let { data } = await axios.post(`/full_quotes/bind/${payload.organization_id}/${payload.quote_app_id}`);
        if (data?.success) {
          let resData = data.full_quotes_detail;
          resData.quote_type = "full_quotes";
          commit('updateQuoteDetail', resData)
        }
        return data;
      } catch (error) {
        console.error(error.response);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async extractedCarriersList({ commit, dispatch, state }, payload) {
      let quotes =
        payload.type == "quick-quotes"
          ? state.quickQuotes?.quotes
          : payload.type == "full-quotes"
          ? state.fullQuotes?.quotes
          : [];
      let extractedCarriers = [];
      if (quotes && quotes.length) {
        let admittedStatus = null;
        let carrierName = null;
        let fullName = null;
        let foundCarrier = null;
        const organizationId = payload.organization_id;
        let carrierListForSave = [];
        quotes.map((quote) => {
          admittedStatus = quote.admittedInd ? "Admitted" : "Non-Admitted";
          carrierName = quote.payToCarrierName || "";
          fullName = `${carrierName} ${admittedStatus} ${quote.amBestRating || ""}`.trim();
          foundCarrier = state.carrierListNames.find((e) => e?.toLowerCase() == fullName?.toLowerCase());
          if (!foundCarrier) {
            carrierListForSave.push(fullName);
          }
          extractedCarriers.push(fullName);
        });

        if (carrierListForSave.length) {
          let updatedList = [...state.carrierListNames, ...carrierListForSave];
          await dispatch("saveCarrierList", updatedList).then(async () => {
            await dispatch("getMShiftCarrierSettings", {
              organization_id: organizationId,
              extracted_carriers: extractedCarriers,
            });
          });
        } else {
          await dispatch("getMShiftCarrierSettings", {
            organization_id: organizationId,
            extracted_carriers: extractedCarriers,
          });
        }
      }
    },

    async getQuotesByClient({ commit }, payload) {
      const type = payload.type || 'quick_quotes';
      const organizationId = payload.organization_id;
      commit("startLoading", null, { root: true });
      try {
        let { data } = await axios.get(`/organization/quotes/${type}/${organizationId}`);
        return data;
      } catch (error) {
        console.error(error.response);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    }
  },

  getters: {
    getField,
  },
};

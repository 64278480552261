<template>
  <v-overlay :value="showLoader" class="d-flex align-center justify-center" z-index="6">
    <div class="d-flex justify-center flex-column align-center">
      <v-progress-circular width="1" indeterminate />
      <div class="mt-4">Please wait...</div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    showLoader: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

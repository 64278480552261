import * as XLSX from "xlsx";
export const hideBranding = process.env.VUE_APP_HIDE_BRANDING === "true";
import isPostalCode from "validator/lib/isPostalCode";
import validator from "validator";
import moment from "moment";

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const allowedEmails = process.env.VUE_APP_USER_EMAILS.split(",").map((el) => el.trim());

// export const showFuture = process.env.VUE_APP_SHOW_FUTURE === "true";
export const showFuture = true;

export const insureTrustBrokerId = process.env.VUE_APP_INSURETRUST_BROKER_ID?.split(",").map((el) => el.trim());

export const jlpcBrokerId = process.env.VUE_APP_JLPC_BROKER_ID?.split(",").map((el) => el.trim());

export const groupBy = (arr, property) => {
  if (Array.isArray(arr) && property) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...(acc[cur[property]] || []), cur];
      return acc;
    }, {});
  }
};

export const deleteKeysFromObject = (obj, keys = []) => {
  if (keys.length === 0) return obj;
  keys.forEach((el) => delete obj[el]);
  return obj;
};

export const openInNewTab = (url) => {
  let fullURL = url;
  if (!url.startsWith("http://") && !url.startsWith("https://")) fullURL = "http://" + url;
  const win = window.open(fullURL, "_blank");
  win.focus();
};

export const removeDuplicates = (array, property) => {
  if (!array) return;

  const uniqueObject = {};

  array.forEach((el) => {
    let key = el[property];
    uniqueObject[key] = el;
  });

  return Object.values(uniqueObject);
};

export const getInitials = (name, showSingleChar = false) => {
  if (!name) return;
  const nameWords = name?.split(" ");

  if (nameWords?.length === 1 || showSingleChar) {
    // Single word name
    return name?.charAt(0)?.toUpperCase();
  } else {
    // First and last name initials
    const firstNameInitial = nameWords[0].charAt(0).toUpperCase();
    const lastNameInitial = nameWords[nameWords.length - 1].charAt(0).toUpperCase();
    return firstNameInitial + lastNameInitial;
  }
};

export const copyToClipboard = (textToCopy) => {
  return new Promise((resolve, reject) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        resolve(true);
      })
      .catch((err) => reject(err));
  });
};

export const createXLSXTemplate = (headers, filename = "temp") => {
  if (!Array.isArray(headers) || headers.length === 0) {
    console.error("Headers must be provided as a non-empty array.");
    return;
  }

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { type: "array" });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.xlsx`;
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFirstAndLastName = (fullName) => {
  if (!fullName) return "";
  const nameArray = fullName.trim().split(" ");
  const firstName = nameArray.shift();
  const lastName = nameArray.join(" ");
  return { firstName, lastName };
};

export const downloadLocalFile = (name) => {
  const fileUrl = `/files/${name}`;
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const isValidPostalCode = (postal, country) => {
  const availableLocales = validator.isPostalCodeLocales;
  if (postal && country) return isPostalCode(postal, availableLocales.includes(country) ? country : "any");
  return "";
};

export const calculateTablePages = (items, itemsPerPage) => {
  return Math.ceil(items / itemsPerPage);
};

export const getFormattedDate = (val, format = "MMM Do, YYYY") => {
  return moment(val).format(format);
};

export const formatNumberWithComma = (val) => {
  if (val) {
    return val
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const getLastMonths = (count) => {
  const lastMonths = [];
  for (let i = count - 1; i >= 0; i--) {
    const month = moment().subtract(i, "months").month();
    const monthName = moment.monthsShort(month);
    lastMonths.push(monthName);
  }
  return lastMonths;
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    loadingReport: false,
    selectedPrivacyProperties: [],
    chosenPrivacyProperty: null,
    reportViewer: false,
    payDialog: false,
    privacyProperties: [],
    trackingTechnologies: [],
    reportOrganizationDetails: null,
    subscriptionDetails: null,
    paymentRequestSubmit: false,
    paymentStatus: null,
  }),

  mutations: {
    updateField,

    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    SET_PRIVACY_PROPERTIES(state, payload) {
      const sorted = payload.map((item) => {
        if (item.reports_data && Array.isArray(item.reports_data) && item.reports_data.length > 1) {
          let newItem = { ...item, reports_data: [...item.reports_data] };
          newItem.reports_data.sort((a, b) => {
            return b.generation_date.localeCompare(a.generation_date);
          });
          return newItem;
        }
        return item;
      });
      state.privacyProperties = sorted;
    },

    SET_TRACKING_TECHNOLOGIES(state, payload) {
      state.trackingTechnologies = payload;
    },

    SET_REPORT_ORGANIZATION_DETAILS(state, payload) {
      state.reportOrganizationDetails = payload;
    },

    UPDATE_PRIVACY_PROPERTIES(state, payload) {
      const existingElementsMap = new Map(state.privacyProperties.map((el) => [el.entity_id, el]));
      payload.forEach((item) => {
        existingElementsMap.set(item.entity_id, item);
      });
      state.privacyProperties = Array.from(existingElementsMap.values());
    },

    UPDATE_PRIVACY_REPORT_DATA(state, payload) {
      const existingElementsMap = new Map(state.privacyProperties.map((el) => [el.entity_id, el]));
      const reportElement = existingElementsMap.get(payload.report_id);
      if (reportElement) {
        reportElement.reports_data = [...(reportElement.reports_data || []), payload];
        existingElementsMap.set(payload.report_id, reportElement);
      }
      state.privacyProperties = Array.from(existingElementsMap.values());
    },

    UPDATE_SUBSCRIPTION_DETAILS(state, payload) {
      if (Array.isArray(payload)) {
        // Api response sending as object but in get api response is array so updating here
        state.subscriptionDetails = payload;
      } else {
        let subscriptionDetails = [];
        subscriptionDetails.push(payload);
        state.subscriptionDetails = subscriptionDetails;
      }
    },
  },

  actions: {
    async getReports({ commit }, organizationId = null) {
      try {
        let url = "/privacy_reports";
        if (organizationId) {
          url = `/privacy_reports/${organizationId}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_PRIVACY_PROPERTIES", data.reports);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReportsSubscription({ commit }) {
      try {
        const { data } = await axios.get("/privacy_reports/subscription");
        if (!data.success) return;
        commit("setState", { key: "subscriptionDetails", value: data.subscriptions });
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReportData({ commit }, payload) {
      try {
        let url = `/privacy_reports/data?${payload.queryParams}`;
        if (payload.organizationId) {
          url = `/privacy_reports/data/${payload.organizationId}?${payload.queryParams}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_TRACKING_TECHNOLOGIES", data.reports_data);
        commit("SET_REPORT_ORGANIZATION_DETAILS", data.organization_detail);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async triggerScans({ commit }) {
      try {
        const { data } = await axios.post(`/trigger_reports`);
        if (!data.success) return;
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async submitReportsSubscription({ commit }, payload) {
      try {
        commit("setState", { key: "paymentRequestSubmit", value: true });
        const { data } = await axios.post("/privacy_reports/subscription", payload);
        commit("setState", { key: "paymentStatus", value: !data.success ? "failed" : "success" });
        if (!data.success) return;
        commit("UPDATE_SUBSCRIPTION_DETAILS", data.subscriptions);
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "paymentRequestSubmit", value: false });
      }
    },

    async triggerUpdateReports({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("/privacy_reports/trigger", payload);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    getField,
  },
};

<template>
  <div :class="!isAdmin ? 'd-flex align-center justify-center' : ''">
    <app-overlay-loader :show-loader="showLoader" />
    <slot name="button">
      <v-btn v-if="showUploadFilesButton" small depressed outlined color="#3ACA56" @click="openPicker()">
        Upload Files
      </v-btn>
    </slot>
  </div>
</template>

<script>
import { file_stack_api_key } from "@/config/constants";
import { mapGetters, mapActions } from "vuex";
import AppOverlayLoader from "../Base/AppOverlayLoader.vue";
import user from "@/Mixins/user";

export default {
  components: {
    AppOverlayLoader,
  },
  mixins: [user],
  props: {
    clientInfo: {
      type: Object,
      default: () => {},
    },
    uploadConfig: {
      type: Object,
      default: () => {},
    },
    onUploadDispatch: {
      type: Function,
    },
    onUploadFailed: {
      type: Function,
    },
    showUploadFilesButton: {
      type: Boolean,
      default: () => true,
    },
    acceptedFormats: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    client: null,
    showLoader: false,
    maxFiles: 10,
    acceptedFormat: null,
  }),
  computed: {
    ...mapGetters("files", ["fileUploaderOptions", "pickerCredentials"]),
    api_key() {
      return file_stack_api_key;
    },
    currentUser() {
      return this.$store.getters["login/currentUser"];
    },
  },
  mounted() {
    this.initializePicker();
  },
  methods: {
    ...mapActions("files", ["getPickerCredentials"]),
    async initializePicker() {
      const clientOptions = {};
      this.client = window.filestack.init(this.api_key, clientOptions);
    },
    openPicker(maxFiles = null, acceptedFormat = null) {
      /* Pass the value for no of allowed files */
      if (maxFiles) {
        this.maxFiles = maxFiles;
      }

      if (acceptedFormat) {
        this.acceptedFormat = acceptedFormat;
      }

      if (this.showUploadFilesButton) {
        this.showLoader = true;
      }

      /* Set config items for filestack - these are used in api's */
      /* If config is sent through props - config is just extra data sent to filestack */
      if (this.uploadConfig !== undefined) {
        if (Object.keys(this.uploadConfig).length > 0) {
          this.config = this.uploadConfig;
        }
      }

      /* Call instance of filestack and open the dialog */
      this.client.picker(this.getOptions()).open();
    },
    getOptions() {
      const options = {
        /* Option which you can use to upload files (instagram, facebook etc...) */
        fromSources: ["local_file_system", "googledrive", "onedrive"],

        /* Maximum number of files allowed */
        maxFiles: this.maxFiles,

        /* This will run when files upload is finished */
        onUploadDone: (data) => {
          const { filesFailed, filesUploaded } = data;

          /* If any of the selected files did not upload then this will run */
          if (filesFailed && filesFailed.length > 0) {
            this.$notify({
              type: "error",
              title: "Upload error",
              text: `${filesFailed.length} file/s didn't upload successfully.`,
            });
          }

          /* The response needs to be sent to the server - run it asyncronously */
          this.onUploadDispatch(filesUploaded);
        },

        /* If file upload was un-successful this will run  */
        onFileUploadFailed: () => {
          this.onUploadFailed();
        },

        /* This will run as soon as the file/s are selected */
        onFileSelected: () => {},

        /* This will run when Ui will mount on to the screen */
        onOpen: () => {
          this.showLoader = false;
        },
      };

      /* If it is passed in as a prop then add it with filestack upload as metadata */
      if (this.uploadConfig && Object.keys(this.uploadConfig).length > 0) {
        options["uploadConfig"] = {
          tags: this.config,
        };
      }

      if (this.acceptedFormat && this.acceptedFormat.length) {
        options["accept"] = this.acceptedFormat;
      }

      return options;
    },
  },
};
</script>

<template>
  <v-container fluid class="pa-0" style="background: #fff !important;">
    <v-row class="px-14 pt-4 pb-8">
      <v-col cols="12">
        <div class="relative" style="max-width: 670px; margin: 0 auto; z-index: 1">
          <h2 class="outfit-bold font-700 text-36 text--black text-center mb-3">Report Upload</h2>
          <v-card elevation="1" class="border-radius-16">
            <v-card-text class="px-7 pt-8 pb-10">
              <h4 class="outfit-bold font-700 text-16 text-light-black">Upload documents</h4>
              <v-card flat color="#F5F5F5" class="border-radius-16 mt-2 px-8 py-4">
                <v-card-text>
                  <div class="text-center" style="max-width: 430px; margin: 0 auto">
                    <img :src="require('@/assets/images/jlpc/file-icon.png')" style="max-width: 40px" />
                    <p class="outfit-regular font-400 text-14 text-grey mt-3 mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                  </div>
                  <div v-if="uploadedReport">
                    <div class="divider-line mb-2 mt-2" />
                    <div class="d-flex align-center justify-space-between py-2">
                      <div>{{ uploadedReport.filename }}</div>
                      <div>
                        <v-icon color="#111010" dark class="cursor-pointer" @click="viewFile(uploadedReport)">
                          mdi-eye
                        </v-icon>

                        <v-icon color="#111010" dark class="cursor-pointer mx-3" @click="downloadFile(uploadedReport)">
                          mdi-tray-arrow-down
                        </v-icon>

                        <v-icon color="#111010" dark class="cursor-pointer" @click="deleteFile(uploadedReport)">
                          mdi-delete
                        </v-icon>
                      </div>
                    </div>
                    <div class="divider-line mb-2 mt-2" />
                  </div>
                  <div class="text-center mt-4">
                    <v-btn
                      rounded
                      elevation="0"
                      class="text-capitalize light-grey-outlined disabled-dark-btn"
                      color="#fff"
                      @click="uploadLogo"
                      :disabled="uploadedReport && uploadedReport.upload_id != null"
                    >
                      <span class="d-flex align-center">
                        <v-icon size="18" class="mr-1 mt-1">mdi-tray-arrow-up</v-icon> <span class="outfit-bold text-12">Upload File</span>
                      </span>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <div class="mt-3">
                <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Company</label>
                <v-autocomplete
                  v-model="selectedCompany"
                  color="#3ACA56"
                  :items="filteredJlpcClients"
                  solo
                  flat
                  rounded
                  item-text="organization_name"
                  placeholder="Search or choose a client"
                  item-value="entity_id"
                  iem
                  clearable
                  required
                  validate-on-blur
                  return-object
                  class="light-grey-text-field-outlined"
                  :rules="[
                    v => !!v || 'Company is required'
                  ]"
                  outlined
                >
                  <template v-slot:item="{ item }">
                      <span data-cy="searched-state">
                        {{ item.organization_name }}
                        {{ item.matter_number ? ' - ' + item.matter_number : '' }}
                      </span>
                  </template>
                  <template #selection="{ item }">
                    <span data-cy="searched-state">
                      {{ item.organization_name }}
                      {{ item.matter_number ? ' - ' + item.matter_number : '' }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
              <div class="text-center mt-0">
                <v-btn
                  :loading="isFormSubmit"
                  large
                  depressed
                  rounded
                  color="#3ACA56"
                  data-cy="signup-button"
                  @click="submitReports"
                  :disabled="!(uploadedReport && uploadedReport.upload_id != null && selectedCompany && selectedCompany.entity_id != null)"
                  class="px-14 disabled-dark-btn"
                >
                  <span class="outfit-bold font-700 text-12 white--text text-capitalize px-6">Upload</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <img :src="require('@/assets/images/jlpc/green-black-group-icon.png')" class="my-14" style="max-width: 103px;position: absolute;left: -44px;top: 60%;z-index: -1"/>
        </div>
      </v-col>
    </v-row>
    <!-- DIALOGS -->
    <delete-confirmation ref="deleteConfirmation" @delete-clicked="deleteFileFromServer">
      <template #modalBody>
        <div class="text-12" v-if="selectedForDelete">
          Do you really want to delete <strong>{{ selectedForDelete.filename }}</strong> ?
        </div>
      </template>
    </delete-confirmation>
    <file-picker ref="file-evidence-picker" :showUploadFilesButton="false" :onUploadDispatch="handleUpdatePolicy" />
    <document-viewer :dialog="documentDialog" />
  </v-container>
</template>

<script>
import FilePicker from "@/components/SalesPerson/FilePicker.vue";
import DocumentViewer from "@/components/Common/DocumentViewer.vue";
import axios from "axios";
import DeleteConfirmation from "@/components/Common/DeleteConfirmation.vue";
import {hasJLPCBrokerId} from "@/router/general";
import {mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
export default {
  components: {
    DeleteConfirmation,
    DocumentViewer,
    FilePicker
  },
  data: () => ({
    documentDialog: {
      open: false,
      file: null,
      title: null,
    },
    selectedForDelete: null,
    isLoading: false,
    selectedCompany: null,
    selectedFile: null,
    jlpcCompanyList: [
      {
        entity_id: 1234,
        name: 'A Cool Company Inc - JL 2-239-3848-39'
      },
      {
        entity_id: 1235,
        name: 'A Great Co LLC - JL 2-241-1122-01'
      }
    ],
    isFormSubmit: false
  }),
  computed: {
    ...mapGetters("jlpcUser", ["jlpcClients"]),
    ...mapFields("jlpcUser", ["uploadedReport"]),
    filteredJlpcClients() {
      return this.jlpcClients.filter((e) => e.matter_number && e.matter_number != '')
    }
  },
  beforeMount() {
    this.$store.dispatch('jlpcUser/getClients');
  },
  methods: {
    uploadLogo() {
      this.$refs["file-evidence-picker"].openPicker(1, [".pdf"]);
    },
    handleUpdatePolicy(filesUploaded) {
      let selectedFile = filesUploaded[0];
      let formData = {
        filename: selectedFile.filename,
        mimetype: selectedFile.mimetype,
        url: selectedFile.url,
        handle: selectedFile.handle,
        upload_id: selectedFile.uploadId,
        status: "uploaded",
        organization_id: "",
        category: "",
        matter_number: ""
      }
      this.$store.dispatch('jlpcUser/uploadReport', formData);
    },
    async submitReports() {
      if(this.isFormSubmit) {
        return;
      }
      this.isFormSubmit = true;
      let formData = Object.assign({}, this.uploadedReport);
      const selectedCompany = Object.assign({}, this.selectedCompany);
      formData.organization_id = selectedCompany.organization_id;
      formData.entity_id = selectedCompany.entity_id;
      formData.category = selectedCompany.category ? selectedCompany.category : "";
      formData.matter_number = selectedCompany.matter_number ? selectedCompany.matter_number : "";
      formData.status = "submitted";
      await this.$store.dispatch('jlpcUser/uploadReport', formData);
      this.selectedCompany = null;
      this.isFormSubmit = false;
    },
    viewFile(data) {
      let title = "Uploaded Policy";
      this.documentDialog = {
        open: true,
        file: data?.handle,
        title: title,
      };
    },
    downloadFile(data) {
      axios({
        method: "get",
        url: data.url,
        responseType: "blob",
      }).then(function (response) {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        };
      });
    },
    deleteFile(file) {
      this.selectedForDelete = file;
      this.$refs.deleteConfirmation.toggle();
    },
    deleteFileFromServer() {
      if (this.selectedForDelete) {
        this.$store.dispatch('jlpcUser/deleteUploadedReport', this.uploadedReport.entity_id);
        this.uploadedReport = null;
        return this.$refs.deleteConfirmation.toggle();
      }
      this.$refs.deleteConfirmation.toggle();
    },
  }
};
</script>

<style scoped lang="scss">
.v-btn.v-btn--disabled.disabled-dark-btn {
  background-color: #838383 !important;
  .v-btn__content * {
    color: #fff !important;
  }
}
</style>
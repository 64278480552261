import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import localService from "@/services/localService";
import { vm } from "../main";
import { uuid } from "vue-uuid";
import Vue from "vue";
import moment from "moment";

const formData = () => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company_name: "",
    websites: [
      {
        website: "",
      },
    ],
    markets: [],
    sendInvite: null,
    specialMessage: "",
    payForSubscription: null,
  };
};

const contactForm = () => {
  return {
    first_name: "",
    last_name: "",
    email: "",
  };
};

const paymentForm = () => {
  return {
    save_payment: false,
    card: {
      number: "",
      exp: "",
      cvc: "",
    },
    billing_details: {
      name: "",
      address: {
        line1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    },
  };
};

export default {
  namespaced: true,
  state: () => ({
    /* Static Data */
    brokerTableHeaders: [
      {
        text: "Company Name",
        value: "name",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        text: "",
        value: "tokens",
        sortable: false,
        filterable: false,
        align: "left",
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        filterable: false,
        align: "right",
        width: "100px",
      },
      //{ text: '', value: 'options', sortable: false, filterable: false, align: 'right', width: '20px' },
    ],
    purchaseListTable: [
      {
        text: "Purchase Date",
        value: "purchase_date",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        text: "Purchase Quantity",
        value: "quantity",
        sortable: true,
        filterable: true,
        align: "left",
      },
    ],
    /* State */
    email: null,
    name: null,
    company: null,
    organization: null,
    brokers: [],
    brokersList: [],
    orgManagementBrokers: [],
    purchasedTokens: [],
    allocatedTokens: [],
    orgBrokerAllowance: [],
    brokerAllowance: [],
    defaultMappingKeys: [],
    defaultMappingHeaders: [],
    requiredMappingHeaders: [
      {
        name: "Primary Contact",
        value: "primary_contact",
        order: 1,
        required: true,
      },
      {
        name: "Contact Email",
        value: "work_email",
        order: 2,
        required: true,
      },
      {
        name: "Company Name",
        value: "company_name",
        order: 3,
        required: true,
      },
      {
        name: "Company URL",
        value: "company_url",
        order: 4,
        required: true,
      },
      {
        name: "Company State",
        value: "company_state",
        order: 5,
        required: true,
      },
      {
        name: "Policy Expiration",
        value: "policy_expiration",
        order: 6,
        required: true,
      },
      {
        name: "Revenue",
        value: "revenue",
        order: 7,
        required: true,
      },
      {
        name: "NAICS Code",
        value: "naics",
        order: 8,
        required: true,
      },
      {
        name: "Number of Employees",
        value: "number_of_employees",
        order: 9,
        required: true,
      },
      {
        name: "Broker Email",
        value: "broker_email",
        order: 10,
        required: false,
      },
    ],
    brokerMembers: [],
    totalBrokerMembers: 0,
    brokerExternalOrganizations: [],
    memberFilters: {
      premium: null,
      renewal: null,
      revenue: null,
      naics: null,
    },
    selectedRenewalCustomDate: null,

    // NEW DESIGN STATE
    loadingCredits: false,
    sidePanel: false,
    clientPanel: false,
    clientListPanel: false,
    selectedFilters: [],
    selectedView: "grid",
    selectedClient: null,
    marketDialog: false,
    uploadedMarketFiles: [],
    inviteDialog: false,
    formData: formData(),
    importedClientList: [],
    otherMarketName: "",
    upsellModal: false,
    paymentDialog: false,
    paymentOption: null,
    paymentStep: 1,
    paymentForm: paymentForm(),
    creditQuantity: null,
    savedPaymentMethods: [],
    availableCredits: 0,
    bookSharedCustomers: [],
    selectedCustomerBooksFilter: [],
    inviteColleagueDialog: false,
    addContactInfoDialog: false,
    contactForm: contactForm(),
    selectedContact: null,
  }),

  mutations: {
    updateField,

    resetContactForm(state) {
      state.contactForm = contactForm();
    },

    // updateOrganizationAllocatedTokens uses the payload.entity_id to find the index of the state.allocatedTokens item
    // and if found + payload.active is true, updates the object
    // if found + payload.active is false, removes the object
    // if not found + payload.active is true, adds the object
    updateOrganizationAllocatedTokens(state, payload) {
      // if there are no allocatedTokens items, return
      if (!state.allocatedTokens.length) {
        return;
      }

      const tokenIndex = state.allocatedTokens.findIndex((b) => b.entity_id === payload.entity_id);

      if (tokenIndex > -1) {
        if (!payload.active) {
          state.allocatedTokens.splice(tokenIndex, 1);
        } else {
          Vue.set(state.allocatedTokens, tokenIndex, payload);
        }
      } else {
        if (payload.active) {
          state.allocatedTokens.push(payload);
        }
      }
    },

    // updateOrganizationPurchasedTokens uses the payload.purchase_id to find the index of the purchasedTokens item
    // and if found, updates the objects allocated/consumed properties
    updateOrganizationPurchasedTokens(state, payload) {
      // if there are no purchasedTokens items, return
      if (!state.purchasedTokens.length) {
        return;
      }

      const purchaseIndex = state.purchasedTokens.findIndex((b) => b.entity_id === payload.purchase_id);

      if (purchaseIndex > -1) {
        // if payload.active is false, it means that a token was revoked, so we decrease the allocated count
        // otherwise, we increase the allocated count
        if (!payload.active) {
          state.purchasedTokens[purchaseIndex].allocated -= 1;
        } else {
          state.purchasedTokens[purchaseIndex].allocated += 1;

          // if payload.consumed_on is not null, also increase the consumed count
          if (payload.consumed_on) {
            state.purchasedTokens[purchaseIndex].consumed += 1;
          }
        }
      }
    },

    updateOrganizationBrokerAllocations(state, payload) {
      // if there are no orgBrokerAllowance items, return
      if (!state.orgBrokerAllowance.length) {
        return;
      }

      // find the index of the orgManagementBrokers item
      const brokerIndex = state.orgBrokerAllowance.findIndex((b) => b.entity_id === payload.broker_id);
      // if payload.active is false, allocated/consumed properties remove that item from orgManagementBrokers[brokerIndex].allocated
      if (!payload.active) {
        const allowanceIndex = state.orgBrokerAllowance[brokerIndex].allocated.findIndex(
          (a) => a.entity_id === payload.entity_id
        );

        state.orgBrokerAllowance[brokerIndex].allocated.splice(allowanceIndex, 1);
      } else {
        // if payload.active is true, add that item to orgBrokerAllowance[brokerIndex].allocated
        if (state.orgBrokerAllowance.length) {
          state.orgBrokerAllowance[brokerIndex].allocated.push(payload);

          if (payload.consumed_on) {
            state.orgBrokerAllowance[brokerIndex].consumed.push(payload);
          }
        }

        // if payload.consumed_on is not null, also add to the consumed list
        if (payload.consumed_on) {
          // also check if the payload.broker_id and payload.entity_id matches an item in the allocatedTokens array
          const allocatedIndex = state.allocatedTokens.findIndex(
            (a) => a.broker_id === payload.broker_id && a.entity_id === payload.entity_id
          );

          // and if so, use Vue.set to replace the item in the array with the payload
          if (allocatedIndex > -1) {
            Vue.set(state.allocatedTokens, allocatedIndex, payload);
          }
        }
      }
    },

    updateBrokerMembers(state, payload) {
      payload.forEach((member) => {
        const { entity_id, active } = member;
        const memberIndex = state.brokerMembers.findIndex((m) => m.entity_id === entity_id);
        if (memberIndex > -1) {
          if (!active) {
            state.brokerMembers.splice(memberIndex, 1);
          } else {
            state.brokerMembers[memberIndex] = { ...member };
          }
        } else if (active) {
          state.brokerMembers.unshift({ ...member });
        }
      });
    },

    updateAllowance(state, payload) {
      // find the index of the allowance
      const allowanceIndex = state.brokerAllowance.findIndex((a) => a.entity_id === payload.entity_id);

      // if the allowance is in the array, update it
      if (allowanceIndex > -1) {
        // if the allowance.active = false, remove it from the array
        if (!payload.active) {
          state.brokerAllowance.splice(allowanceIndex, 1);
        } else {
          Vue.set(state.brokerAllowance, allowanceIndex, payload);
        }
      } else {
        // if the allowance is not in the array, add it to the array
        state.brokerAllowance.push(payload);
      }
    },

    updatePurchasedTokens(state, token) {
      // check if the token is already in the purchasedTokens array
      const tokenIndex = state.purchasedTokens.findIndex((t) => t.entity_id === token.entity_id);
      // if the token is in the array
      if (tokenIndex > -1) {
        // if the token.active = false, remove it from the array
        if (!token.active) {
          state.purchasedTokens.splice(tokenIndex, 1);
        } else {
          Vue.set(state.purchasedTokens, tokenIndex, token);
        }
      } else {
        // if the token is not in the array, add it to the array
        state.purchasedTokens.push(token);
      }
    },

    setBrokerMembers(state, payload) {
      state.brokerMembers = state.brokerMembers.map((el) => ({
        ...el,
        copyEmail: el.work_email == payload,
      }));
    },
    setBrokerExternalOrganizations(state, payload) {
      state.brokerExternalOrganizations = state.brokerExternalOrganizations.map((el) => ({
        ...el,
        copyEmail: el.work_email == payload,
      }));
    },
    deleteBrokerMember(state, payload) {
      var foundIndex = state.brokerMembers.findIndex((x) => x.entity_id == payload.entity_id);
      if (foundIndex > -1) state.brokerMembers.splice(foundIndex, 1);
    },
    deleteBrokerExternalOrganizations(state, payload) {
      var foundIndex = state.brokerExternalOrganizations.findIndex((x) => x.entity_id == payload.entity_id);
      if (foundIndex > -1) state.brokerExternalOrganizations.splice(foundIndex, 1);
    },
    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    updateBrokerList(state, payload) {
      let data = Object.assign({}, payload);
      state.brokers.push(data);
    },
    updateBrokers(state, payload) {
      let data = Object.assign({}, payload);
      state.brokersList.push(data);
    },
    deleteBroker(state, payload) {
      var foundIndex = state.brokers.findIndex((x) => x.entity_id == payload.entity_id);
      if (foundIndex > -1) state.brokers.splice(foundIndex, 1);
    },
    setMappingHeaders(state, payload) {
      var headers = Object.assign({}, payload.mappingHeaders);
      var defaultMappingHeaders = Object.assign([], state.requiredMappingHeaders);
      Object.keys(headers).map((header) => {
        if (header) {
          if (defaultMappingHeaders.find((e) => e.name.toLowerCase() == header.toLowerCase())) {
            defaultMappingHeaders.find((e) => e.name.toLowerCase() == header.toLowerCase()).value = headers[header];
          }
        }
      });
      defaultMappingHeaders.map((header) => {
        if (payload.headers[header.name]) {
          header.disabled = true;
        }
      });
      state.defaultMappingHeaders = Object.assign([], defaultMappingHeaders);
      state.requiredMappingHeaders = Object.assign([], defaultMappingHeaders);
    },
    async updateMappingHeaders(state, payload) {
      let descSortIndex = 0;
      let mappingHeaders = JSON.parse(JSON.stringify(state.requiredMappingHeaders));

      await mappingHeaders.map((header, index) => {
        if (header.value === payload.mapBy) {
          if (header?.disabled) {
            delete header.disabled;
          } else {
            header.disabled = true;
          }
          header.order = state.requiredMappingHeaders.length - descSortIndex;
          header.name = payload.name;
          descSortIndex++;
        } else {
          if (header.disabled) {
            header.order = header.order + 1 - descSortIndex;
          } else {
            header.order = index + 1 - descSortIndex;
          }
        }
      });

      state.requiredMappingHeaders = mappingHeaders;
    },
    resetMappingHeaders(state) {
      if (state.defaultMappingHeaders && state.defaultMappingHeaders.length) {
        state.requiredMappingHeaders = Object.assign([], state.defaultMappingHeaders);
      }
    },
    addBrokerAllowance(state, data) {
      if (!data.user_id) {
        state.orgBrokerAllowance.push({
          entity_id: uuid.v4(),
          email: data.email,
          name: data.email,
          allowance: [
            {
              entity_id: uuid.v4(),
              allowance: data.allowance,
            },
          ],
        });
      }
    },

    updateBrokerAllowance(state, data) {
      var brokerIndex = state.orgBrokerAllowance.findIndex((e) => e.entity_id == data.user_id);
      var allowanceIndex = state.orgBrokerAllowance[brokerIndex].allowance.findIndex(
        (c) => c.entity_id == data.allowance_id
      );
      state.orgBrokerAllowance[brokerIndex].allowance[allowanceIndex].allowance = data.allowance;
    },

    deleteBrokerAllowance(state, data) {
      var brokerIndex = state.orgBrokerAllowance.findIndex((e) => e.entity_id == data.user_id);

      var allowanceIndex = state.orgBrokerAllowance[brokerIndex].allowance.findIndex(
        (c) => c.entity_id == data.allowance_id
      );

      state.orgBrokerAllowance[brokerIndex].allowance.splice(allowanceIndex, 1);
    },

    resetAddClientData(state) {
      state.formData = formData();
    },

    resetPaymentForm(state) {
      state.paymentForm = paymentForm();
    },

    setSavedPaymentMethods(state, payload) {
      state.savedPaymentMethods = payload;
    },

    setCardDetails(state, payload) {
      const { payment_method } = payload;
      if (!payment_method) return;

      const date = `${payment_method.card.exp_month}/${payment_method.card.exp_year}`;
      const parsedDate = moment(date, "MM/YYYY");

      state.paymentForm.card.number = payment_method.card.number;
      state.paymentForm.card.exp = parsedDate.format("MM/YY");
      state.paymentForm.billing_details = payment_method.billing_details;
    },

    setAvailableCredits(state, payload) {
      state.availableCredits = payload;
    },
  },

  actions: {
    async getBrokersList({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/brokers");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "brokersList", value: data.results });
        return data.results;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getOrgManagementBrokers({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/organization_management/broker");
        if (!data.success) {
          return;
        }
        commit("setState", {
          key: "orgManagementBrokers",
          value: data.results,
        });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("updateBrokers", data.user);
        commit("setState", { key: "name", value: null });
        commit("setState", { key: "email", value: null });
        localService.removeItem("s2r_signUpUserInfo");
        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addInvitedBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        let formData = Object.assign({}, payload);
        const token = formData.invitationToken;
        delete formData.invitationToken;
        const { data } = await axios.post(`/brokers/invitation/${token}`, formData);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        localService.removeItem("s2r_signUpUserInfo");
        commit("updateBrokers", data.user);
        commit("setState", { key: "name", value: null });
        commit("setState", { key: "email", value: null });
        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async inviteBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/invite", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });

        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async importBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/import", {
          members: payload,
        });
        if (!data.success) {
          return data;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });

        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async bulkImportBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/import/customers", {
          members: payload,
        });
        if (!data.success) {
          return data;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });

        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getBrokers({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/broker");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "brokers", value: data.results });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async createBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/broker", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("updateBrokerList", data.user);
        commit("setState", { key: "name", value: null });
        commit("setState", { key: "email", value: null });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/broker/${payload.entity_id}`);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("deleteBroker", payload);
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getPurchasedTokens({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/tokens/purchases`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "purchasedTokens", value: data.purchases });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getAllocatedTokens({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/tokens/allocations`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "allocatedTokens", value: data.allocations });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deallocateToken({ commit, dispatch }, entityId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/tokens/allocations/${entityId}`);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        dispatch("getAllocatedTokens");
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async buyTokens({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/tokens/purchase", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        if (payload.organization_id) {
          commit("client/updateBrokersPurchases", data.token, { root: true });
        } else {
          dispatch("client/getBrokerOrganizations", {}, { root: true });
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async allocateToken({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/tokens/allocate", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        dispatch("getAllocatedTokens");
        dispatch("getPurchasedTokens");
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteBrokerOverlay({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete("overlay/" + payload.entity_id);
        if (!data.success) {
          return;
        }
        let userInfo = localService.getItem("userInfo");
        let index = userInfo.user.overlays.findIndex((e) => e.entity_id == payload.entity_id);
        userInfo.user.overlays.splice(index, 1);
        localService.setItem("userInfo", userInfo);
        commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });
        if (payload.description == "general_navigation") {
          vm.$router.push({ path: "/broker/members" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async delegateAndInviteBroker({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/delegate", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getExtractDataFromFile({ commit, dispatch }, file) {
      try {
        commit("startLoading", null, { root: true });
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await axios.post("/brokers/extract_customers", formData);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("setMappingHeaders", {
          mappingHeaders: data.mapping,
          headers: data.headers,
        });
        return data;
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    updateBrokerMembers({ commit }, payload) {
      commit("updateBrokerMembers", payload);
    },

    async getBrokerMembers({ commit, state }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/members`);
        if (!data.success) {
          return;
        }

        commit("setState", { key: "brokerMembers", value: data.members });

        commit("setState", { key: "totalBrokerMembers", value: data.total_members });

        commit("setState", {
          key: "brokerExternalOrganizations",
          value: data.external_organizations,
        });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getBookSharedCustomers({ commit, dispatch, rootState }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/book_shared/customers`);
        if (!data.success) {
          return;
        }
        let customers = [];
        if (rootState.login.currentUser.user) {
          let customer = Object.assign({}, rootState.login.currentUser.user);
          customer.name = "My Book";
          customers.push(customer);
        }
        customers = [...customers, ...data.customers];
        commit("setState", { key: "bookSharedCustomers", value: customers });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteBrokerMember({ commit, dispatch }, payload) {
      commit("deleteBrokerMember", payload);
      const { data } = await axios.delete(`/brokers/members/${payload.entity_id}`);
      if (!data.success) {
        return;
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
    },

    updateAllowance({ commit, dispatch }, payload) {
      commit("updateAllowance", payload);
    },

    async getBrokerAllowance({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/allowance`);
        if (!data.success) {
          return;
        }
        commit("setState", {
          key: "brokerAllowance",
          value: data.token_allowance,
        });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getOrganizationBrokerAllowance({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/organization_allowance`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "orgBrokerAllowance", value: data.users });
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async submitBrokerAllowance({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("addBrokerAllowance", payload);
        const { data } = await axios.post("/brokers/allowance", payload);
        await dispatch("getOrganizationBrokerAllowance");
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        dispatch("getCredits");
        commit("stopLoading", null, { root: true });
      }
    },

    async updateBrokerAllowance({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.put("/brokers/allowance", payload);
        await dispatch("getOrganizationBrokerAllowance");
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        dispatch("getCredits");
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteBrokerAllowance({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });

        commit("deleteBrokerAllowance", payload);

        const { data } = await axios.delete("/brokers/allowance/" + payload.allowance_id);

        if (!data.success) return dispatch("getOrganizationBrokerAllowance");
        return data;
      } catch (e) {
        dispatch("getOrganizationBrokerAllowance");
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    updatePurchasedTokens({ commit }, payload) {
      commit("updatePurchasedTokens", payload);
    },

    updateOrganizationBrokerAllocations({ commit }, payload) {
      commit("updateOrganizationBrokerAllocations", payload);
      commit("updateOrganizationPurchasedTokens", payload);
      commit("updateOrganizationAllocatedTokens", payload);
    },

    // CALLED FROM ALLOWANCE PAGE TO REVOKE CREDITS
    async revokeCredits({ commit, dispatch }, allowance_id) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/brokers/allowance/revoke/${allowance_id}`);
        await dispatch("getOrganizationBrokerAllowance");
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        dispatch("getCredits");
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FROM CLIENT SIDE PANEL TO ADD MARKETS TO CSG DATA
    async addMarkets({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/company_setup/markets/${payload.organization_id}`, {
          markets: payload.markets,
        });
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FROM ADD CLIENT PANEL FOR ADDING CLIENTS
    async addClient({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/brokers/import/customers`, payload);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FROM BROKER SIDE PANEL FOR INVITE CLIENT
    async getInvitationLink({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(
          `/brokers/customer_invitation_url/${payload.organizationId}/${payload.customerId}`
        );
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FROM INVITE DIALOG
    async inviteClient({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(
          `/brokers/resend_invitation/customer/${payload.organizationId}/${payload.customerId}`,
          payload.data
        );
        if (!data.success) {
          return false;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        if (payload.data?.is_pre_check) {
          let userInfo = localService.getItem("userInfo");
          userInfo.user.organization_has_pre_check = 1;
          localService.setItem("userInfo", userInfo);
          commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });
        }
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FROM SIGNUP PAGE signup/invitation/<token>
    async acceptClientInviteByBroker({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/client/accept_invitation/${payload.token}`, payload.data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FOR INVITATION FO BROKER BY CUSTOMER
    async acceptBrokerInviteByClient({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/brokers/invitation/${payload.invitationToken}`, payload.data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // CALLED FOR INVITATION OF Team Member BY Broker
    async acceptTeamMemberInviteByBroker({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/brokers/team/invitation/${payload.invitationToken}`, payload.data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // RE-INVITE CSG BROKER
    async resendBrokerInvitation({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/resent_broker_invitation`, payload);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    //  USED TO GET THE SAVED PAYMENT METHODS
    async getSavedPaymentMethods({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/payment_methods");
        if (!data.success) return false;
        commit("setSavedPaymentMethods", data.payment_methods);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // USED TO REMOVE A PAYMENT METHOD
    async removeSavedPaymentMethod({ commit }, entity_id) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/payment_method/${entity_id}`);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // USED WHEN UPGRADING THE SUBSCRIPTION TYPE OF A CUSTOMER FROM SIDE PANEL
    async subscriptionViaAllocation({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(
          `/brokers/customer/subscription_via_allocation/${payload.organizationId}/${payload.entityId}`
        );
        dispatch("getCredits");
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // USED WHEN UPGRADING THE SUBSCRIPTION TYPE OF A CUSTOMER FROM SIDE PANEL
    async subscriptionViaPayment({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(
          `/brokers/customer/subscription_via_payment/${payload.organizationId}`,
          payload.data
        );
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // USED TO PURCHASE CREDITS BY A BROKER
    async purchaseCredits({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/tokens/broker/purchase`, payload);
        dispatch("getCredits");
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET CARD DETAILS FOR UPDATE
    async getCardDetails({ commit }, paymentMethodEntityId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/payment_method/${paymentMethodEntityId}`);
        commit("setCardDetails", data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET CARD DETAILS FOR UPDATE
    async updateCardDetails({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/payment_method/${payload.paymentMethodEntityId}`, payload.data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET CREDITS INFORMATION
    async getCredits({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`tokens/broker/credits`);
        commit("setAvailableCredits", data.remaining_credits);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // DOWNLOAD PRE CHECK REPORT
    async downloadCyberReport({ commit }, clientOrganizationId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/brokers/pre_check_form/${clientOrganizationId}`);
        console.log(data);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async inviteColleague({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/local_office/team/invite`, payload);
        if (!data.success) return false;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getInvitedColleagues({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/local_office/team`);
        console.log(data);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteInvitedColleague({ commit }, entityId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/local_office/team/${entityId}`);
        console.log(data);
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addCustomerContact({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(
          `/brokers/contact/customer/${payload.orgId}/${payload.customerId}`,
          payload.data
        );
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async uploadManagementCpcAccess({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/precheck_integration/cpc/get_admin_token`);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },
  getters: {
    getField,
    brokerTableHeaders: (state) => state.brokerTableHeaders,
    purchaseListTable: (state) => state.purchaseListTable,
    brokers: (state) => state.brokers,
    brokersList: (state) => state.brokersList,
    orgManagementBrokers: (state) => state.orgManagementBrokers,
    purchasedTokens: (state) => state.purchasedTokens,
    allocatedTokens: (state) => state.allocatedTokens,
    brokerMembers: (state) => state.brokerMembers,
    brokerExternalOrganizations: (state) => state.brokerExternalOrganizations,
    orgBrokerAllowance: (state) => state.orgBrokerAllowance,
    brokerAllowance: (state) => state.brokerAllowance,
  },
};

<template>
  <v-container fluid>
    <v-row class="main-bg">
      <v-col cols="6" class="px-6">
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between mt-1">
            <top-bar-without-auth
              :show-login="$vuetify.breakpoint.mdAndUp"
              :is-broker="true"
              custom-class="d-flex  justify-space-between pa-0"
            />
          </v-col>

          <v-row no-gutters class="d-flex justify-center mt-8">
            <v-col cols="12" md="8" sm="10">
              <div class="text-36 outfit-bold tight-line">
                Welcome to your <br />
                Broker Workbench!
              </div>

              <div class="light-text--text outfit-bold text-18 my-4">Create your account below.</div>

              <v-card outlined class="rounded-xl">
                <v-card-text class="px-6">
                  <v-form ref="form" v-model="valid" lazy-validation class="custom-error-details">
                    <v-row>
                      <v-col cols="6">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">First Name</label>

                        <v-text-field
                          v-model="signupForm.first_name"
                          type="text"
                          placeholder="Type here"
                          solo
                          flat
                          :rules="[(v) => !!v || 'First Name is required.']"
                          required
                          rounded
                          validate-on-blur
                          @blur="signupForm.first_name = signupForm.first_name?.trim()"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Last Name</label>

                        <v-text-field
                          v-model="signupForm.last_name"
                          type="text"
                          placeholder="Type here"
                          solo
                          rounded
                          flat
                          :rules="[(v) => !!v || 'Last Name is required.']"
                          required
                          validate-on-blur
                          @blur="signupForm.last_name = signupForm.last_name?.trim()"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Email</label>

                        <v-text-field
                          ref="email"
                          v-model="signupForm.email"
                          type="email"
                          placeholder="Type here"
                          solo
                          flat
                          rounded
                          :rules="[
                            (v) => !!v || 'Email is required.',
                            (v) => !v || !!checkEmail(v) || 'E-mail must be valid',
                          ]"
                          :disabled="autoFilled"
                          required
                          data-cy="login-input"
                          validate-on-blur
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="isAlpha">
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Brokerage</label>

                        <v-combobox
                          v-model="broker"
                          :items="searchList?.slice(0, 5)"
                          item-value="entity_id"
                          item-text="name"
                          required
                          :menu-props="!searchBrokerage ? { value: false } : {}"
                          :rules="[(v) => !!v || 'Company Name is required.']"
                          validate-on-blur
                          placeholder="Type here"
                          color="#3ACA56"
                          item-color="#3ACA56"
                          clearable
                          rounded
                          flat
                          solo
                          ref="broker-search"
                          @change="selectBroker(broker)"
                          @update:search-input="onUpdate($event)"
                          class="light-grey-outlined"
                          :disabled="subDomain.isPresent"
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row v-if="!isAlpha">
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2"
                          >Company Website</label
                        >

                        <v-text-field
                          ref="signupFormBrokerURL"
                          v-model="signupForm.broker_url"
                          type="text"
                          placeholder="Type here"
                          solo
                          flat
                          rounded
                          :rules="[(v) => checkUrlValid(v)]"
                          :disabled="selectedBroker?.entity_id && selectedBroker.website ? true : false"
                          validate-on-blur
                          @blur="signupForm.broker_url = signupForm.broker_url?.trim()"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="isAlpha">
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Title</label>

                        <v-text-field
                          ref="signupFormBrokerURL"
                          v-model="signupForm.title"
                          placeholder="Type here"
                          type="text"
                          validate-on-blur
                          solo
                          flat
                          rounded
                          :rules="[(v) => !!v || 'Title is required']"
                          required
                          @blur="signupForm.title = signupForm.title?.trim()"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Position</label>

                        <v-select
                          v-model="signupForm.position"
                          :items="positionItems"
                          clearable
                          rounded
                          flat
                          solo
                          class="light-grey-outlined"
                          :menuProps="{ offsetY: true }"
                          required
                          placeholder="Select"
                          :rules="[(v) => !!v || 'Position is required.']"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="showFuture">
                      <v-col cols="12" class="d-flex align-center">
                        <v-checkbox
                          v-model="termsEnabled"
                          required
                          color="#000"
                          class="text-primary dark-grey-disabled-checkbox mt-0 pt-0"
                          hide-details
                        >
                        </v-checkbox>
                        <div class="outfit-regular font-400 text-12 text-grey">
                          I have read and agreed to the
                          <span
                            class="outfit-bold font-700 text-decoration-underline cursor-pointer text-grey"
                            @click="showTerms = true"
                          >
                            Terms & Conditions
                          </span>
                          .
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-btn
                          :loading="isLoading"
                          block
                          depressed
                          rounded
                          color="#000"
                          class="text-primary text-14 font-700"
                          data-cy="signup-button"
                          :disabled="!valid || !termsEnabled"
                          @click="signup"
                        >
                          <span class="outfit-bold font-700 text-12 white--text tight-spacing text-capitalize">
                            {{ isAlpha || isSBA ? "Continue" : "Create Account" }}
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-col>

      <v-col cols="6" class="right-bg d-flex align-center">
        <app-carousel :slides="slides" />
      </v-col>
    </v-row>

    <terms :show-dialog="showTerms" @cancel-dialog="showTerms = false" />
    <app-footer direction="left" size="14" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import appSettings from "../../../app.settings";
import AppFooter from "@/components/Base/AppFooter.vue";
import validationMixin from "@/Mixins/validation";
import localService from "@/services/localService";
import BackgroundShapes from "@/components/General/BackgroundShapes.vue";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import { showFuture } from "@/helpers/index";
import { hideBranding } from "@/helpers";
import { getDomainDetails } from "@/router/general";
import { mapFields } from "vuex-map-fields";
import Terms from "@/components/Terms/Terms.vue";
import jwtDecode from "jwt-decode";
import CommonMixin from "@/Mixins/common";
import versionInfoMixin from "@/Mixins/versionInfo";
import AppCarousel from "@/components/Shared/AppCarousel.vue";

export default {
  mixins: [validationMixin, versionInfoMixin, CommonMixin],

  components: {
    Terms,
    TopBarWithoutAuth,
    BackgroundShapes,
    AppFooter,
    AppCarousel,
  },

  data: () => ({
    hideBranding,
    showFuture,
    showTerms: false,
    showPrivacyPolicy: false,
    valid: false,
    broker: null,
    elevate: false,
    selectedBroker: false,
    searchBrokerage: null,
    searchList: null,
    termsEnabled: false,
    invitationToken: null,
    autoFillFields: false,
    positionItems: [
      "Producer",
      "Account Executive",
      "Account Manager",
      "Practice Leader",
      "Cyber Leader",
      "Marketing",
      "Other",
    ],
    decodedToken: null,
    autoFilled: false,
    invitedTeamMemberForBook: false,
    slides: [
      {
        image: require("@/assets/images/bwbstep1.png"),
        title: "Plan:",
        detail: "Leverage analytics and AI to <br /> strategically plan and meet client needs.",
      },
      {
        image: require("@/assets/images/bwbstep2.png"),
        title: "Place:",
        detail: "Simplify placements with comparison tools <br /> and automated quoting while ensuring compliance.",
      },
      {
        image: require("@/assets/images/bwbstep3.png"),
        title: "Retain:",
        detail: "SecondSight ensures ongoing client protection with <br /> real-time AI-driven risk management.",
      },
    ],
    isWholesaler: false,
  }),

  computed: {
    ...mapGetters(["isLoading"]),

    ...mapFields("broker", ["brokersList"]),

    ...mapFields("login", ["signupForm"]),

    appInfo() {
      return appSettings;
    },

    isAlpha() {
      return this.$route.path.startsWith("/alpha");
    },

    isSBA() {
      return getDomainDetails()?.subdomain?.toLowerCase()?.includes("sba");
    },

    logoSrc() {
      return `../../assets/images/${this.getAppLogoUrl()}`;
    },
  },

  async mounted() {
    if (this.$route?.params?.userType == "team") {
      this.invitedTeamMemberForBook = true;
    }
    this.checkIfLocalSignupDataIsPresent();

    // IF THERE IS A CUSTOM SUBDOMAIN
    if (this.subDomain.isPresent) this.populateSignupForm(this.subDomain.brokerage);

    // NORMAL FLOW FOR BROKERS SIGNUP
    await this.$store.dispatch("broker/getBrokersList");

    if (!this.showFuture) {
      this.termsEnabled = true;
    }

    if (this.$route.params && this.$route.params.invitationToken) {
      this.invitationToken = this.$route.params.invitationToken;
      this.autoFillData(this.invitationToken);
    }
  },

  methods: {
    ...mapActions("broker", ["acceptBrokerInviteByClient", "acceptTeamMemberInviteByBroker"]),

    ...mapActions("login", ["alphaSignup"]),

    ...mapActions("alpha", ["getAlphaBrokerage"]),

    ...mapActions("wholesaleBroker", ["signupWholesaleBroker"]),

    checkIfLocalSignupDataIsPresent() {
      if (!this.isSBA) return;

      const localSignupData = localService.getItem("s2r_signUpUserInfo");

      if (localSignupData) {
        this.signupForm = {
          ...this.signupForm,
          ...localSignupData,
        };

        if (this.invitedTeamMemberForBook) {
          this.broker = {
            first_name: this.signupForm?.first_name,
            last_name: this.signupForm?.last_name,
          };
        } else {
          this.broker = {
            name: this.signupForm?.broker_name,
            entity_id: this.signupForm?.broker_id,
            website: this.signupForm?.broker_url,
          };
        }
      }
    },

    autoFillData(invitationToken) {
      this.decodedToken = jwtDecode(invitationToken); // Decode the jwt token

      const {
        wholesaler_first_name,
        wholesaler_last_name,
        wholesaler_email,
        wholesaler_organization_id,
        wholesaler_organization_name,
        broker_first_name,
        broker_last_name,
        broker_email,
        broker_organization_name,
        broker_organization_url,
      } = this.decodedToken;

      this.isWholesaler = wholesaler_organization_id ? true : false;
      const firstName = this.isWholesaler ? wholesaler_first_name : broker_first_name;
      const lastName = this.isWholesaler ? wholesaler_last_name : broker_last_name;
      const email = this.isWholesaler ? wholesaler_email : broker_email;
      const brokerName = this.isWholesaler ? wholesaler_organization_name : broker_organization_name;
      const organizationUrl = this.isWholesaler ? null : broker_organization_url;

      const broker = this.brokersList.find((el) => el.name === brokerName) || {};

      this.autoFilled = true;
      this.signupForm.first_name = firstName || "";
      this.signupForm.last_name = lastName || "";
      this.signupForm.email = email || "";
      this.signupForm.broker_name = broker.name || "";
      this.broker = broker || null;
      this.signupForm.broker_url = organizationUrl || "";
    },

    async signUpUsingInvitation() {
      // IF IT IS WHOLESALER ROLE
      if (this.isWholesaler) return this.handleWholesalerSignup();

      const payload = {
        invitationToken: this.invitationToken,
        data: {
          email: this.signupForm.email,
          first_name: this.signupForm.first_name,
          last_name: this.signupForm.last_name,
          broker_name: this.signupForm.broker_name || "",
          broker_url: this.signupForm.broker_url,
          broker_id: this.decodedToken.broker_organization_id || "",
          user_id: this.decodedToken.broker_user_id || "",
          terms_accepted: this.termsEnabled,
          position: this.signupForm.position,
          terms_version: this.termsVersion,
        },
      };

      let response = null;

      if (this.invitedTeamMemberForBook) {
        response = await this.acceptTeamMemberInviteByBroker(payload);
      } else {
        response = await this.acceptBrokerInviteByClient(payload);
      }

      if (response.success) {
        const { reset_token, user_uid } = response;
        this.$router.push({ path: `/reset-password/${reset_token}/${user_uid}` });
      }
    },

    async handleWholesalerSignup() {
      const payload = {
        invitation_token: this.invitationToken,
        data: {
          email: this.signupForm.email,
          first_name: this.signupForm.first_name,
          last_name: this.signupForm.first_name,
          terms_accepted: this.termsEnabled,
          terms_version: this.termsVersion,
          position: this.signupForm.position,
        },
      };

      const response = await this.signupWholesaleBroker(payload);

      if (response?.success) {
        const { reset_token, user_uid } = response;
        this.$router.push({ path: `/reset-password/${reset_token}/${user_uid}` });
      }
    },

    toggleTermsPolicy() {
      this.showPrivacyPolicy = false;
      this.showTerms = false;
    },

    checkUrlValid(v) {
      if (this.signupForm.broker_id) return true;

      if (!v) return "Company Url is required.";

      if (!this.validURL(v)) return "Company Url is invalid";

      return true;
    },

    onUpdate(e) {
      if (e && e.trim() == "") {
        this.$refs["broker-search"].clearableCallback();
      }
      this.searchBrokerage = this.brokersList.find((el) => el.name == e) || e;
      this.searchList = this.brokersList.filter((el) => {
        const searchText = el.name.trim().toLowerCase().includes(e?.toLowerCase());
        if (searchText) {
          el.index = el.name.trim().toLowerCase().indexOf(e?.toLowerCase());
        }
        return searchText;
      });
      this.searchList.sort((a, b) => a.name.localeCompare(b.name));
      this.searchList.sort((a, b) => (a.index >= b.index ? 1 : -1));
    },

    selectBroker(broker) {
      if (!broker) {
        this.signupForm.broker_id = null;
        this.signupForm.broker_name = null;
        this.signupForm.broker_url = null;
        this.selectedBroker = null;
        this.$refs["signupFormBrokerURL"]?.reset();
        return;
      } else {
        var brokerDetails = broker.entity_id
          ? Object.assign({}, broker)
          : this.brokersList.find((e) => e.name == broker);
        if (brokerDetails && brokerDetails.entity_id) {
          this.$refs["signupFormBrokerURL"]?.reset();
          this.signupForm.broker_id = brokerDetails.entity_id;
          this.signupForm.broker_name = brokerDetails.name;
          this.signupForm.broker_url = brokerDetails.website;
          this.selectedBroker = brokerDetails;
        } else {
          this.selectedBroker = null;
          this.signupForm.broker_id = null;
          this.signupForm.broker_name = broker;
        }
      }
    },

    async signup() {
      if (this.$refs.form.validate()) {
        let signupForm = {
          first_name: this.signupForm.first_name,
          last_name: this.signupForm.last_name,
          email: this.signupForm.email,
          position: this.signupForm.position,
        };

        if (this.autoFilled) return this.signUpUsingInvitation();

        if (this.signupForm.broker_id) {
          signupForm.broker_url = this.signupForm.broker_url;
          signupForm.broker_name = this.signupForm.broker_name;
          signupForm.broker_id = this.signupForm.broker_id;
        } else {
          signupForm.broker_id = "";
          signupForm.broker_name = this.signupForm.broker_name;
          signupForm.broker_url = this.signupForm.broker_url;
        }

        if (this.$route.query && this.$route.query.token) {
          signupForm.token = this.$route.query.token;
        }

        localService.setItem("s2r_signUpUserInfo", signupForm);

        // IF IT IS A SBA TYPE SIGNUP (Alpha: Single player mode)
        if (this.isSBA) return this.handleSBASignup();

        // IF IT IS AN ALPHA SIGNUP (url has /alpha/)
        if (this.isAlpha) return this.handleAlphaSignup();

        let url = "broker/addBroker";
        if (this.invitationToken) {
          url = "broker/addInvitedBroker";
          signupForm.invitationToken = this.invitationToken;
        }

        const payload = {
          ...signupForm,
          custom_subdomain: getDomainDetails().subdomain || "",
          terms_accepted: this.termsEnabled,
          terms_version: this.termsVersion,
        };

        this.$store.dispatch(url, payload).then((resp) => {
          if (resp?.success) {
            this.$router.push({ path: `/signup/thank-you/${signupForm.email}/invited` });
          }
        });
      }
    },

    populateSignupForm(broker) {
      if (!broker) throw new Error("Broker argument is missing");

      this.broker = broker;
      this.signupForm.broker_url = broker.website;
      this.signupForm.broker_name = broker.name;
      this.signupForm.broker_id = broker.entity_id;
    },

    handleAlphaSignup() {
      const payload = { ...this.signupForm, custom_subdomain: getDomainDetails().subdomain };
      delete payload.position;
      this.alphaSignup(payload);
    },

    handleSBASignup() {
      this.$router.push({ path: "/alpha/single/step/1" });
    },
  },
};
</script>

<style scoped lang="scss">
.main-bg {
  min-height: 100vh;
  background: #fff;
}

.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}

.signup-form-container {
  max-width: 700px;
}

.bgColor {
  @media (width <= 768px) {
    min-height: 100vh;
    background: rgba(243, 243, 243, 1);
  }
}

.right-bg {
  background: rgba(65, 69, 66, 1);
}
</style>

<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-6 mb-0 custom-error-details">
    <v-row v-if="sso === false || sso == null">
      <v-col cols="12" md="12" class="pb-0">
        <label v-if="showOutsideLabel" class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Email</label>

        <app-input
          type="email"
          name="email"
          required
          autocomplete="email"
          placeholder="Email"
          :error="emailError"
          @blur="validateEmail"
          @input="(val) => handleInput(val, 'email')"
          @enter="login"
        />

        <!-- <v-text-field
          v-model="email"
          type="email"
          placeholder="Email"
          solo
          flat
          :rules="[(v) => !!v || 'Email is required.', (v) => !v || !!checkEmail(v) || 'E-mail must be valid']"
          required
          @focus="elevate = true"
          @blur="elevate = false"
          ref="email"
          validate-on-blur
          v-on:keyup.enter="login"
          :class="outlined ? 'light-grey-outlined' : ''"
        >
        </v-text-field> -->
      </v-col>
    </v-row>

    <v-row v-if="view === 'login' && (sso === false || sso == null)">
      <v-col cols="12" md="12" class="pb-0">
        <div class="d-flex align-center justify-space-between mb-2">
          <label v-if="showOutsideLabel" class="outfit-bold text-14 font-700 text-light-black">Password</label>
          <span
            v-if="showForgotPasswordLink"
            class="outfit-bold text-16 font-700 cursor-pointer text-decoration-underline"
            @click="handleForgotLink"
          >
            Forgot Password?
          </span>
        </div>

        <app-input
          :type="passwordType"
          name="password"
          placeholder="Password"
          required
          autocomplete="current-password"
          :error="passwordError"
          @blur="validatePassword"
          @input="(val) => handleInput(val, 'password')"
          @enter="login"
        >
          <template #append-inner-icon>
            <v-tooltip right color="black">
              <template #activator="{ on }">
                <v-icon dark color="#000" class="cursor-pointer" v-on="on" @click="showPassword">
                  {{ passwordType === "text" ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
                </v-icon>
              </template>
              <span class="white--text">
                {{ passwordType === "text" ? "Hide password" : "Show password" }}
              </span>
            </v-tooltip>
          </template>
        </app-input>

        <!-- <v-text-field
          v-model="password"
          :type="passwordType"
          placeholder="Password"
          solo
          flat
          :rules="[(v) => !!v || 'Password is required.']"
          required
          @focus="elevate = true"
          @blur="elevate = false"
          validate-on-blur
          v-on:keyup.enter="login"
          :class="outlined ? 'light-grey-outlined' : ''"
        >
          <template #append>
            
          </template>
        </v-text-field> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="sso === true && view === 'login'" cols="12" md="12">
        <v-btn
          :loading="isLoading"
          color="#000"
          block
          depressed
          data-cy="login-button"
          prepend-icon="mdi-microsoft"
          @click="handleLoginWithMicrosoft"
        >
          <span class="outfit-bold font-700 text-12 white--text text-capitalize tight-spacing">
            Login with Microsoft
          </span>
        </v-btn>
      </v-col>
      <v-col v-else cols="12" md="12">
        <v-btn
          :loading="isLoading"
          color="#000"
          block
          depressed
          data-cy="login-button"
          :disabled="!loginFormValid"
          @click="login"
        >
          <span class="outfit-bold font-700 text-12 white--text text-capitalize tight-spacing">
            {{ view === "login" ? "Login" : "Reset password" }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { getMsalConfig } from "@/config/msalConfig";
import { mapFields } from "vuex-map-fields";
import isEmail from "validator/lib/isEmail";
import validationMixin from "@/Mixins/validation";
import AppInput from "./AppInput.vue";

export default {
  components: {
    AppInput,
  },

  mixins: [validationMixin],

  props: ["outlined", "showOutsideLabel", "showForgotPasswordLink"],

  data: () => ({
    msal: null,
    valid: false,
    elevate: false,
    emailError: null,
    passwordError: null,
  }),

  computed: {
    ...mapFields("login", [
      "isLoading",
      "sso",
      "ssoConfig",
      "view",
      "email",
      "password",
      "passwordType",
      "mfa_dialog",
      "twoFactor",
      "recoveryCodeForm",
      "authCode",
      "recoveryCode",
    ]),

    loginFormValid() {
      if (this.email == "" || !isEmail(this.email) || (this.password == "" && this.view == "login")) {
        return false;
      }
      return true;
    },
  },

  async mounted() {
    await this.$store.dispatch("login/ssoCheck");
    if (this.sso === true) {
      this.isLoading = true;
      this.msal = getMsalConfig(this.ssoConfig);
      this.msal.initialize();
      this.isLoading = false;
    }
    this.setInputType();
  },

  methods: {
    handleForgotLink() {
      this.$emit("handleForgotLink");
    },

    validateEmail() {
      const trimmedEmail = this.email?.trim();
      if (!trimmedEmail) {
        this.emailError = "Email is required";
      } else if (!isEmail(trimmedEmail)) {
        this.emailError = "Incorrect email provided";
      } else {
        this.emailError = null;
      }
    },

    validatePassword() {
      const password = this.password?.trim();
      if (!password) {
        this.passwordError = "Password is required";
      } else {
        this.passwordError = null;
      }
    },

    handleInput(val, type) {
      if (type === "email") {
        this.email = val;
        if (this.email && isEmail(val)) {
          this.emailError = null;
        }
      }

      if (type === "password") {
        this.password = val;
        if (this.password && this.password.length) {
          this.passwordError = null;
        }
      }
    },

    async handleLoginWithMicrosoft() {
      const scopes = { scopes: ["openid", "profile", "email"] };
      let loginResponse = await this.msal.loginPopup(scopes);
      console.log(loginResponse);
      this.$store.dispatch("login/loginUsingSSO", {
        access_token: loginResponse.accessToken,
      });
    },

    showPassword() {
      if (this.passwordType === "text") {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },

    getLoginCredentials() {
      return {
        email: this.email.trim(),
        password: this.password,
      };
    },

    login() {
      if (!this.loginFormValid) {
        return false;
      }

      if (this.view === "forgot" && this.checkEmail(this.email)) {
        return this.reset();
      }

      if (this.$refs.form.validate()) {
        this.$store.dispatch("login/login", this.getLoginCredentials()).then((res) => {
          if (res && res.showMfa) {
            this.view = "login";
            this.mfa_dialog = true;
            return;
          }
        });
      }
    },

    async reset() {
      const reset_password = await this.$store.dispatch("login/resetPassword", { email: this.email });
      if (reset_password) {
        this.email = "";
        this.password = "";
        this.$emit("updateView", "login");
        this.$router.replace("/login");
      }
    },

    setInputType() {
      this.passwordType = "password";
    },
  },
};
</script>

<style scoped></style>

import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";
import plainAxios from 'axios';
import localService from '@/services/localService';
import { vm } from '../main';

export default {
  namespaced: true,
  state: () => ({
    technologyList: [],
    technologyListLoading: false,
    verifiedStatusFilter: null
  }),

  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    SAVE_TECHNOLOGY(state, payload) {
      let existingIndex = state.technologyList.findIndex((tech) => tech.entity_id == payload.entity_id)
      let list = state.technologyList
      if (existingIndex >= 0) {
        list[existingIndex] = payload
      } else {
        list.unshift(payload)
      }
      state.technologyList = list
    }
  },

  getters: {
    getField,
    technologyList: state => state.technologyList,
    technologyListLoading: state => state.technologyListLoading,
    verifiedStatusFilter: state => state.verifiedStatusFilter,
  },

  actions: {
    async getTechnologyList({ commit }) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.get('/technology/');
        if (!data.success) {
          return ;
        }
        commit('setState', { key: 'technologyList', value: data.results });
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },
    async saveTechnology({ commit }, payload) {
      let imageFile = null
      let techObject = payload
      if (payload.logo_image instanceof File) {
        imageFile = payload.logo_image
        delete payload.logo_image
      }
      try {
        commit('startLoading', null, { root: true });
        const { data: saveResponse } = await axios.post('/technology/', payload);
        if (!saveResponse.success) {
          return dispatch('showMessage', { ...saveResponse, messageType: 'error', messageTitle: 'Error' }, { root: true });
        }
        techObject = saveResponse.data
        if (imageFile != null) {
          commit('startLoading', null, { root: true });
          let logoPayload = {}
          logoPayload.entity_id = techObject.entity_id
          logoPayload.content_type = imageFile.type
          const { data: logoResponse } = await axios.put('/technology/logo', logoPayload);
          if (!logoResponse.success) {
            return dispatch('showMessage', { ...logoResponse, messageType: 'error', messageTitle: 'Error' }, { root: true });
          }
          let uploadUrl = logoResponse.url
          const { data: uploadResponse } = await plainAxios.put(uploadUrl, imageFile, {
            headers: {
              'Content-Type': imageFile.type
            }
          })
          techObject.imageUpdated = true
        }
        commit('SAVE_TECHNOLOGY', techObject);
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    }
  }
};

import moment from "moment";
import axios from "axios";
import { mapActions } from "vuex";
import { isJLPCSubDomain, getDomainDetails } from "@/router/general";
import { mapFields } from "vuex-map-fields";
import { subDomains } from "@/helpers/subdomains";

export default {
  data() {
    return {
      supportedCompanies: ["bennie", "broker", "frp", "higginbotham", "insuretrust", "mma"],
      exportingAssets: false,
      updatedDigitalAssetsDescription: [
        // using for handle description only in UI so BE shouldn't need any changes
        {
          oldValue: "IT & Cyber Security",
          newValue: "IT & Cybersecurity",
        },
      ],
      alphaSingleDataset: {
        // USED FOR ALPHA SINGLE PLAYER PROGRAM (CYBER BOOK VALUE)
        revenueBands: {
          "<1m": {
            minPremium: 0,
            maxPremium: 9985,
          },

          "1-5m": {
            minPremium: 9985,
            maxPremium: 11735,
          },

          "5-10m": {
            minPremium: 11735,
            maxPremium: 7234,
          },

          "10-25m": {
            minPremium: 7234,
            maxPremium: 12605,
          },

          "25-50m": {
            minPremium: 12605,
            maxPremium: 20187,
          },

          "50-100m": {
            minPremium: 20187,
            maxPremium: 17855,
          },

          "100-250m": {
            minPremium: 17855,
            maxPremium: 42775,
          },

          "250-500m": {
            minPremium: 42775,
            maxPremium: 59922,
          },

          "500-1b": {
            minPremium: 59922,
            maxPremium: 59546,
          },

          ">1b": {
            minPremium: 59546,
            maxPremium: 59546,
          },
        },
      },
    };
  },

  computed: {
    ...mapFields("login", ["currentUser", "readOnlyView", "cwbInstanceDetails"]),

    appBarColor() {
      return this.cwbInstanceDetails && this.readOnlyView ? this.cwbInstanceDetails.appBarColor : "#fff";
    },
    subDomain() {
      const subdomain = getDomainDetails()?.subdomain;

      let item = null;

      for (const key in subDomains) {
        if (subdomain.includes(key)) {
          item = subDomains[key];
          break;
        }
      }

      const brokerage = {
        name: item?.organization_name,
        entity_id: item?.organization_id,
        website: item?.organization_url,
      };

      const subdomainDetails = {
        isPresent: item ? true : false,
        brokerage,
      };

      console.log(subdomainDetails);

      return subdomainDetails;
    },
  },

  methods: {
    ...mapActions("digitalAssets", ["exportDigitalAsset"]),
    getSubDomainLogoInitial() {
      // using for sub domain like JLPC
      if (isJLPCSubDomain()) {
        return "jlpc-logo.png";
      }
      return null;
    },
    getAppLogoUrl() {
      const supportedCompanies = this.supportedCompanies;
      let domainDetails = getDomainDetails();

      let subdomain = domainDetails.subdomain;
      let logo = null;

      supportedCompanies.map((val) => {
        if (subdomain.includes(val)) {
          logo = val + "-logo.png";
        }
      });

      return logo;
    },
    checkValidDate(date) {
      if (
        moment(date, "MM/DD/YYYY", true).isValid() ||
        moment(date, "M/D/YYYY", true).isValid() ||
        moment(date, "MM/DD/YYYY", true).isValid() ||
        moment(date, "M/D/YY", true).isValid()
      ) {
        return true;
      }
      return false;
    },
    formatDate(date, format) {
      if (!date) {
        return;
      }
      format = !format ? "MM/DD/YYYY" : format;
      if (moment(date, "YYYY-MM-DD", true)) {
        return moment(date).format(format);
      } else {
        if (date.split("T").length) {
          return moment(date.split("T")[0]).format(format);
        }
        return date;
      }
    },
    getDelegatedUserDetails(itemId, type, additionalData) {
      let clientDelegation = null;
      let foundFromAdditionalData = false;
      let user = null;
      if (itemId == "IT & Cybersecurity") {
        itemId = this.getMappingDigitalAssetDescription("IT & Cybersecurity").oldValue;
      }
      if (
        (this.clientDelegations && this.clientDelegations[type] && this.clientDelegations[type].length) ||
        type == "audit_answer"
      ) {
        if (type == "digital_asset_usage_type") {
          let usage = this.usageOverview.find((e) => e.description.toLowerCase() == itemId?.toLowerCase());
          clientDelegation = this.clientDelegations[type].find((e) => e.item_id == usage?.digital_asset_usage_type);
        } else {
          clientDelegation = this.clientDelegations[type]?.find((e) => e.item_id == itemId);
          if (type == "audit_answer") {
            if (!clientDelegation && additionalData) {
              foundFromAdditionalData = true;
              clientDelegation = this.clientDelegations.digital_asset?.find(
                (e) => e.item_id == additionalData.digital_asset_id
              );
            }
          }
        }
        if (clientDelegation) {
          user = this.users.find((e) => e.entity_id == clientDelegation.user_id);
          if (foundFromAdditionalData) {
            user.parentUserInitial = true;
          } else {
            if (user?.parentUserInitial) {
              user.parentUserInitial = false;
            }
          }
          return user;
        }
        return false;
      }
      return false;
    },
    getUserInitial(data) {
      let user = this.users.find((e) => e.entity_id === data.entity_id);
      let username = user?.name.trim().split(" ");
      let initials = "";
      if (username) {
        if (username.length > 1) {
          initials = username[0][0] + username[1][0];
        } else {
          initials = username[0][0];
        }
      } else {
        initials = "?";
      }
      return {
        initial: initials,
        user: user,
      };
    },

    formatNumberWithComma(val) {
      if (val) {
        return val
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "";
    },

    formatNumberWithCommaDecimal(val) {
      if (val) {
        const parts = val.toFixed(2).toString().split(".");
        const integerPart = parts[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const decimalPart = parts[1] ? "." + parts[1] : ""; // Keep decimal part
        return integerPart + decimalPart;
      }
      return "0.00";
    },

    formatStringToNumber(val) {
      if (val) {
        return parseInt(val.replace(/\D/g, "").replace(",", ""));
      }
      return "";
    },

    exportDigitalAssets() {
      this.exportingAssets = true;
      this.exportDigitalAsset(this.cwbInstanceDetails?.orgId)
        .then((response) => {
          let reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = function () {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Digital Asset Inventory.pdf"); // or any other extension
            document.body.appendChild(link);
            link.click();
          };
        })
        .finally(() => (this.exportingAssets = false));
    },

    getMappingDigitalAssetDescription(value) {
      if (!value) return;
      let data = this.updatedDigitalAssetsDescription.find(
        (e) => e.oldValue?.toLowerCase() == value?.toLowerCase() || e.newValue?.toLowerCase() == value?.toLowerCase()
      );
      if (data) {
        return data;
      }
      return;
    },

    handleNumberValidation(e) {
      let charCode = e.which ? e.which : e.keyCode;

      // Allow arrow keys (left: 37, up: 38, right: 39, down: 40)
      if ([37, 38, 39, 40].includes(charCode)) {
        return;
      }

      // Allow numeric keypad digits
      if (charCode >= 96 && charCode <= 105) {
        return;
      }

      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) e.preventDefault();

      if ([189, 69].includes(charCode)) e.preventDefault();
    },

    async downloadFileStackFile(file) {
      const { handle, file_name, filename, name, mimetype } = file;

      if (!handle) {
        throw new Error("File handle is a required argument.");
      }

      let finalFilename = file_name || filename || name || "download"; // Fallback to a generic name if filename and name are not provided

      try {
        this.isLoading = true;
        const response = await axios.get(`https://www.filestackapi.com/api/file/${handle}`, { responseType: "blob" });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(new Blob([response.data]));
        downloadLink.download = finalFilename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        return response;
      } catch (error) {
        console.error(error);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    viewFileStackFile(file) {
      if (!file.url) throw new Error("File url is a required argument.");
      window.open(file.url, "_blank");
    },

    getFileExtension(fileName, mimeType) {
      // Try to get the extension from the mime type
      let extension = mimeType ? mimeType.split("/")[1] : null;

      // If the mime type doesn't provide a valid extension, extract it from the file name
      if (!extension) {
        extension = fileName.split(".").pop();
      }

      return extension;
    },
  },
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import { uuid } from "vue-uuid";
import localService from "@/services/localService";
import { vm } from "../main";
import companySetupGuide from "@/Mixins/companySetupGuide";

export default {
  namespaced: true,
  state: () => ({
    fetchingAnswers: false,
    initialQuestionnaireAllScreenData: null, // It can use for reset questionnaire's screen data
    showInsurabilityMenuItem: false,
    questionnaire: null,
    screensList: {
      "build-team": [
        "invite-co-admins",
        "add-expertise-to-team",
        "add-technology-expertise",
        "add-cybersecurity-expertise",
        "add-operations-process-expertise",
        "add-hr-training-expertise",
        "add-legal-contract-expertise",
        "add-cyber-insurance-broker",
        "final-build-team",
      ],
      "build-company-profile": [
        "policies-planning",
        "company-details",
        "company-websites",
        "company-logo",
        "industry-details",
        "employees-details",
        "revenue-growth",
        "revenue-products",
        "revenue-customers",
        "org-recent-incidents-experienced",
        "cyber-security-organization",
        "cybersecurity-investment",
        "final-build-company-profile",
      ],
      "describe-organizational-landscape": [
        "email-security",
        "email-security-controls",
        "phishing",
        "physical-hardware-on-premise",
        "devices-systems-used-remotely",
        "applications-infrastructure-cloud",
        "on-premise-infrastructure-software",
        "legacy-software",
        "org-data-backed-up",
        "backup-capabilities",
        "backups-and-business-continuity",
        "systems-records-for-protect-them",
        "privacy-concerns",
        "personally-identifiable-information",
        "media-usage",
        "financial-controls",
        "telecommunication",
        "payment-cards",
        "service-accounts",
        "final-organizational-landscape",
      ],
      "describe-cybersecurity-capabilities": [
        "cyber-security-vendors",
        "org-security-handle",
        "network-security-one",
        "network-security-two",
        "network-security-capabilities",
        "network-security-controls",
        "general-security-controls",
        "vulnerability-scanning",
        "cybersecurity-good-work",
      ],
    },
    companySetupSteps: [
      {
        title: "Build Team",
        key: "build-team",
        description:
          "Bring in Co-Administrators to share your role, along with other leaders who have expertise in the technologies, policies and processes used in different parts of your business. (And don’t forget your cyber insurance broker, if you already have one.)",
        visible: false,
        details: [
          {
            key: "invite-co-admins",
            title: "Invite Co-Administrators",
            shortDescription: "Send invitations to other administrators for this process.",
            description:
              "It’s a good idea to have more than one Admin responsible for managing your SecondSight Workbench projects and users. These Co-Admins might be someone in another leadership role or a support staff member tasked with spearheading this initiative.",
            status: 0,
          },
          {
            key: "add-technology-expertise",
            title: "Add Technology Expertise",
            shortDescription: "Add teammates with Technology expertise.",
            description:
              "You need collaborators who can provide specific information about your digital environment — including infrastructure, various technologies, policies and procedures and more.",
            additionalDetails:
              "Consider adding your Chief Technology Officer (CTO), the head of information technology (IT), leadership from your data science team (if you have one), your webmaster, etc. (Don't forget to tag yourself if appropriate.)",
            status: 0,
          },
          {
            key: "add-cybersecurity-expertise",
            title: "Add Cybersecurity Expertise",
            shortDescription: "Add teammates with Cybersecurity expertise",
            description:
              "Cybersecurity matters play a key role in Pre-Underwriting, so your team needs members who can provide specific information about the tools, policies and procedures used to protect your network. These teammates might include Chief Information Security Officer (CISO), Chief Technology Officer (CTO), the head of information technology (IT), etc. (Don't forget to tag yourself if appropriate.)",
            status: 0,
          },
          {
            key: "add-operations-process-expertise",
            title: "Add Operations and Process Expertise",
            shortDescription: "Add teammates with Operations and Process expertise.",
            description:
              "Your team should also include leaders with general expertise on how your company runs, and those who work closely with tools designed for sales and marketing, production, finance and accounting, and other function-specific systems. You may need their perspective on exactly which tools they use and how. (Tag yourself if you also have this type of expertise.)",
            status: 0,
          },
          {
            key: "add-hr-training-expertise",
            title: "Add HR and Training Expertise",
            shortDescription: "Add teammates with HR and Training expertise.",
            description:
              "HR teams use and maintain a lot of data that could be exploitable by bad actors (personally identifiable information, etc.) These folks are also usually involved in Training coworkers on cybersecurity policies and procedures. Add in one or more people from your HR and Training leadership, so they can bring that expertise to your Pre-underwriting team.",
            status: 0,
          },
          {
            key: "add-legal-contract-expertise",
            title: "Add Legal and Contract Expertise",
            shortDescription: "Add teammates with Legal and Contract expertise.",
            description:
              "Most companies these days use a lot of SaaS (Software As a Service) tools to do business. These contracts and other software licensing agreements generally include terms regarding how data is protected, backed up, etc. You’ll want people with this expertise on your team to help confirm your Digital Asset Inventory and complete your Risk Control Check.",
            status: 0,
          },
          {
            key: "add-cyber-insurance-broker",
            title: "Add your Cyber Insurance Broker",
            shortDescription: "Your Broker is a key member of your Pre-Underwriting team.",
            description:
              "Naturally, your Broker is a key member of your Pre-Underwriting team. Connect with them via SecondSight Workbench so they can monitor your progress and view reports when you’re done.",
            additionalDetails: "If your Broker is already a SecondSight user, we’ve added them for you below.",
            status: 0,
          },
        ],
      },
      {
        title: "Build Your Company Profile",
        key: "build-company-profile",
        description:
          "Lay the groundwork for your Digital Asset Inventory and Risk Control Check by telling us a little about your company and the scope of your cybersecurity program. This helps SecondSight tailor your Workbench to your unique needs.",
        visible: false,
        details: [
          {
            key: "policies-planning",
            title: "Tell us about your cyber insurance goals and current coverage",
            shortDescription: "Tell us a little bit more about your insurance goals.",
            description: "Tell us a little bit more about your insurance goals.",
            status: 0,
          },
          {
            key: "company-details",
            title: "Please tell us a bit about your company",
            shortDescription: "To help us personalize your experience.",
            description: "",
            // description: 'To help us personalize your experience, we need to know your company’s primary physical address and phone number, as well as a direct contact number for the person responsible for cyber insurance in your organization.',
            status: 0,
          },
          {
            key: "company-websites",
            title: "Provide the URLs of all company websites",
            shortDescription:
              "We use publicly available information from your various websites to pre-populate some of your workflows (and save you time).",
            description: "",
            // description: 'We use publicly available information from your various websites to pre-populate some of your workflows (and save you time). Your URLs also tell us about various entry points to your digital environment that need to be protected from bad actors.',
            status: 0,
          },
          {
            key: "company-logo",
            title: "Add company logo",
            shortDescription: "Upload your Company logo so we can customize your reports.",
            description: "Upload your Company logo so we can customize your reports.",
            status: 0,
          },
          {
            key: "industry-details",
            title: "Describe the nature of your business",
            shortDescription:
              "Please tell us your primary industry so we can tailor your Workbench to your unique needs.",
            description:
              "Digital risk and cyber insurance are both highly specific to your industry. Please tell us your primary industry so we can tailor your Workbench to your unique needs.",
            status: 0,
          },
          {
            key: "employees-details",
            title: "Please indicate the number of employees in each category below",
            shortDescription:
              "Please let us know roughly how many employees are in your organization, as well as the size of your cybersecurity team.",
            description:
              "Employees and their behaviors are another facet of digital risk. Please let us know roughly how many employees are in your organization, as well as the size of your cybersecurity team.",
            status: 0,
          },
          {
            key: "revenue-growth",
            title: "Tell us about your company’s revenue and growth",
            shortDescription: "Tell us some information about the organization’s revenue and growth over the years.",
            description: "Tell us some information about the organization’s revenue and growth over the years.",
            status: 0,
          },
          {
            key: "revenue-products",
            title: "Revenue and Products",
            shortDescription: "Tell us some information about the organization’s revenue from products.",
            description: "Tell us some information about the organization’s revenue from products.",
            status: 0,
          },
          {
            key: "revenue-customers",
            title: "Revenue and Customers",
            shortDescription: "Tell us some information about the organization’s revenue coming from customers.",
            description: "Tell us some information about the organization’s revenue coming from customers.",
            status: 0,
          },
          {
            key: "org-recent-incidents-experienced",
            title: "Has your organization experienced any recent incidents?",
            status: 0,
          },
          {
            key: "cyber-security-organization",
            title: "Add Cybersecurity Contact",
            shortDescription:
              "Let’s start by gathering contact information for the people in charge of cybersecurity at your organization. If they’re not already added as a team member in SecondSight Workbench, you can add a new member.",
            description:
              "Let’s start by gathering contact information for the people in charge of cybersecurity at your organization. If they’re not already added as a team member in SecondSight Workbench, you can add a new member.",
            status: 0,
          },
          {
            key: "cybersecurity-investment",
            title: "How big is your cybersecurity investment?",
            shortDescription:
              "Understanding how your cybersecurity budget fits within your overall IT budget, as well as its relationship to revenue, provides insight into your organization’s overall investment in cyber hygiene.",
            description:
              "Understanding how your cybersecurity budget fits within your overall IT budget, as well as its relationship to revenue, provides insight into your organization’s overall investment in cyber hygiene. (Don’t worry: This information is kept secure and will never be shared without your permission.)",
            status: 0,
          },
        ],
      },
      {
        title: "Your Organizational Landscape",
        key: "describe-organizational-landscape",
        description:
          "Answer a few questions about the scale and scope of your digital business, so we can create a basic inventory framework for you.",
        visible: false,
        details: [
          {
            key: "email-security",
            title: "How does your organization use email?",
            shortDescription: "Send invitations to other administrators for this process.",
            description: "Which email platform(s) does your organization use?",
            status: 0,
          },
          {
            key: "email-security-controls",
            title: "Email Security Controls",
            shortDescription:
              "For the email platform(s) you selected, does your organization use any of the following controls?  ",
            description:
              "For the email platform(s) you selected, does your organization use any of the following controls?  ",
            status: 0,
          },
          {
            key: "phishing",
            title: "Training",
            shortDescription:
              "Please indicate what training your organization uses to help prevent email phishing fraud.",
            description: "Please indicate what training your organization uses to help prevent email phishing fraud.",
            status: 0,
          },
          {
            key: "physical-hardware-on-premise",
            title: "Physical Hardware Used On-Premise",
            shortDescription:
              "Let us know which types of computing systems are used onsite at your place(s) of business.",
            description: "Let us know which types of computing systems are used onsite at your place(s) of business.",
            status: 0,
          },
          {
            key: "devices-systems-used-remotely",
            title: "Devices and Systems Used Remotely",
            shortDescription:
              "When staff or contractors are working remotely or virtually, which of the following are they able to use?",
            description:
              "When staff or contractors are working remotely or virtually, which of the following are they able to use?",
            status: 0,
          },
          {
            key: "applications-infrastructure-cloud",
            title: "Applications & Infrastructure In the Cloud",
            shortDescription: "Please indicate the types of cloud platforms used in your organization.",
            description: "Please indicate the types of cloud platforms used in your organization.",
            status: 0,
          },
          {
            key: "on-premise-infrastructure-software",
            title: "On-Premise Infrastructure Software",
            shortDescription:
              "Tell us which types of infrastructure applications and other systems are hosted, used, and maintained at your physical location(s).",
            description:
              "Tell us which types of infrastructure applications and other systems are hosted, used, and maintained at your physical location(s).",
            status: 0,
          },
          {
            key: "legacy-software",
            title: "Legacy Software Still in Use",
            shortDescription: "Your Broker is a key member of your Pre-Underwriting team.",
            description:
              "If your organization uses any systems that are no longer supported by the developer, please indicate the controls used.",
            status: 0,
          },
          {
            key: "org-data-backed-up",
            title: "How is data backed up at your organization",
            status: 0,
          },
          {
            key: "backup-capabilities",
            title: "Backup Capabilities",
            shortDescription:
              "Please select all types of backup used by your organization to help ensure business continuity.",
            description:
              "Please select all types of backup used by your organization to help ensure business continuity.",
            status: 0,
          },
          {
            key: "backups-and-business-continuity",
            title: "Backups & Business Continuity",
            shortDescription:
              "If your organization uses any systems that are no longer supported by the developer, please indicate the controls used.",
            description:
              "If your organization uses any systems that are no longer supported by the developer, please indicate the controls used.",
            status: 0,
          },
          {
            key: "systems-records-for-protect-them",
            title: "What records are on your systems, and how do you protect them?",
            status: 0,
          },
          {
            key: "privacy-concerns",
            title: "Privacy and Data Concerns",
            shortDescription: "Fill us in on how the organization handles Privacy and Data.",
            description: "Fill us in on how the organization handles Privacy and Data.",
            status: 0,
          },
          {
            key: "personally-identifiable-information",
            title: "Personally Identifiable Information",
            shortDescription: "Tell us what types of PII your organization handles.",
            description: "Tell us what types of PII your organization handles.",
            status: 0,
          },
          {
            key: "media-usage",
            title: "How does your organization use media?",
            shortDescription: "Fill us in on how the organization handles Media.",
            description: "Fill us in on how the organization handles Media.",
            status: 0,
          },
          {
            key: "financial-controls",
            title: "Financial Controls",
            shortDescription: "Fill us in on how the organization handles payment and fund transfers.",
            description: "Fill us in on how the organization handles payment and fund transfers.",
            status: 0,
          },
          {
            key: "telecommunication",
            title: "Telecommunications",
            shortDescription: "Tell us how your organization handles telecommunications.",
            description: "Tell us how your organization handles telecommunications.",
            status: 0,
          },
          {
            key: "payment-cards",
            title: "Payment Cards",
            shortDescription: "Tell us how your organization handles payment cards.",
            description: "Tell us how your organization handles payment cards.",
            status: 0,
          },
          {
            key: "service-accounts",
            title: "Service Accounts",
            shortDescription: "Does your organization have any digital services that can access your data?",
            description: "Does your organization have any digital services that can access your data?",
            status: 0,
          },
        ],
      },
      {
        title: "Your Cybersecurity Capabilities",
        key: "describe-cybersecurity-capabilities",
        description: "Help us understand your technical capacity, so we can pre-engineer your Risk Control Check.",
        visible: false,
        details: [
          {
            key: "cyber-security-vendors",
            title: "Cybersecurity Vendors",
            shortDescription: "Do you outsource any of your security efforts?",
            description: "Do you outsource any of your security efforts?",
            status: 0,
          },
          {
            key: "org-security-handle",
            title: "How does your organization handle security?",
            status: 0,
          },
          {
            key: "network-security-one",
            title: "Network Security",
            shortDescription: "Answer basic questions about how access is managed on your network.",
            description: "Answer basic questions about how access is managed on your network.",
            status: 0,
          },
          {
            key: "network-security-two",
            title: "How is your organization using MFA?",
            shortDescription: "",
            description: "",
            status: 0,
          },
          {
            key: "network-security-capabilities",
            title: "Network Security Capabilities",
            shortDescription: "Indicate which types of network security capabilities are used in your environment.",
            description: "Indicate which types of network security capabilities are used in your environment.",
            status: 0,
          },
          {
            key: "network-security-controls",
            title: "Network Security Controls",
            shortDescription:
              "Indicate which types of network security capabilities controls are used in your environment.",
            description: "Indicate which types of network security capabilities controls are used in your environment.",
            status: 0,
          },
          {
            key: "general-security-controls",
            title: "General Security Controls",
            shortDescription: "Tell us some basic information about your general security controls.",
            description: "Tell us some basic information about your general security controls.",
            status: 0,
          },
          {
            key: "vulnerability-scanning",
            title: "Vulnerability Scanning and Controls",
            shortDescription: "Describe your policies and procedures for protecting your network against threats.",
            description: "Describe your policies and procedures for protecting your network against threats.",
            status: 0,
          },
        ],
      },
    ],
    coAdmins: [],
    teamMembers: [],
    brokersList: [],
    brokerOrganizationsList: [], // for get company names and url
    upgradePlan: [
      {
        id: "single-user",
        amount: 29,
        totalUser: 1,
        duration: "yearly",
        description: "Price for 1 User",
      },
      {
        id: "multiple-users",
        amount: 125,
        totalUser: 5,
        duration: "yearly",
        description: "Pack of 5 Users",
      },
      {
        id: "infinity-users",
        amount: 500,
        duration: "yearly",
        description: "Infinite Users",
      },
    ],
    creditCardDetails: null,
    paymentSubmitted: true,
    companyProfile: {
      full_time_employees: null,
      full_time_it_employees: null,
      full_time_cyber_security_employees: null,
      annual_it_budget_percentage: null,
      annual_cyber_security_budget_percentage: null,
      growth_revenue: {
        fiscal_year_end: null,
        report_month: null,
      },
    },
    isLoadingBrokersList: false,
    currentQuestionnaireVersion: null,
    // signal: null,
    debounce: null,
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    setFetchingAnswers(state, payload) {
      state.fetchingAnswers = payload;
    },

    // setSignal(state, signal) {
    //   state.signal = signal
    // },

    // clearSignal(state) {
    //   if (state.signal) {
    //     state.signal.abort(); // Abort the signal when clearing it
    //   }
    //   state.signal = null;
    // },
  },
  actions: {
    /* Action that saves a questionnaire */
    async saveQuestionnaire({ commit, dispatch, state }, payload) {
      try {
        const userInfo = localService.getItem("userInfo");
        const { data } = await axios.post("/company_setup", payload);

        if (!userInfo.user.company_setup_guide_started) {
          userInfo.user.company_setup_guide_started = true; // for hide welcome screen
          localService.setItem("userInfo", userInfo);
          vm.$store.commit("login/setState", { key: "currentUser", value: userInfo });
        }

        if (data.success) {
          commit("setState", { key: "currentQuestionnaireVersion", value: data.company_setup.version });
        }

        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async inviteUserForCSG({ commit }, payload) {
      const { data } = await axios.post("organization_management/client", payload);
      return data;
    },

    async resendInvitation({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("organization_management/client/reinvite", payload);
        const messageData = {
          message: data.message,
          messageType: data.success ? "success" : "error",
          messageTitle: data.success ? "Success" : "Error",
        };
        dispatch("showMessage", messageData, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getAllMembers({ commit }, organizationId = null) {
      let url = "/organization_management/client";
      if (organizationId) {
        url = `/organization_management/${organizationId}/client`;
      }
      const { data } = await axios.get(url);
      if (!data.success) {
        return;
      }
      commit("setState", { key: "teamMembers", value: data.results });
    },
    async getBrokerOrganizations({ commit }) {
      const { data } = await axios.get("/brokers");
      if (!data.success) {
        return;
      }
      commit("setState", { key: "brokerOrganizationsList", value: data.results });
    },
    async getBrokersList({ commit }, organizationId = null) {
      commit("setState", { key: "isLoadingBrokersList", value: true });
      let url = "/linked_brokers";
      if (organizationId) {
        url = `${organizationId}/linked_brokers`;
      }
      const { data } = await axios.get(url);
      if (!data.success) {
        return;
      }
      commit("setState", { key: "brokersList", value: data.brokers });
      commit("setState", { key: "isLoadingBrokersList", value: false });
    },
    async inviteCyberInsuranceBroker({ commit }, payload) {
      const { data } = await axios.post("invite_brokers", {
        brokers: payload,
      });
      return data;
    },
    updateCyberInsuranceBroker({ commit }, payload) {
      return { success: true, member: payload };
    },
    async deleteCyberInsuranceBroker({ commit }, brokerId) {
      const { data } = await axios.delete(`/client_broker/${brokerId}`);
      if (!data.success) {
        return;
      }
      return { success: true, member: data };
    },
    purchaseUserSlot({ commit }, payload) {
      return payload;
    },

    /* Action that saves a questionnaire */
    async getQuestionnaire({ commit, dispatch }, organizationId) {
      try {
        commit("setFetchingAnswers", true);
        let url = "/company_setup";
        if (organizationId) url += `/${organizationId}`;
        const { data } = await axios.get(url);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setFetchingAnswers", false);
      }
    },

    async updateQuestionnaireDataByQuestion({ commit, dispatch, rootState, state }, data) {
      if (rootState.companySetup.currentQuestionnaireVersion != data.previous_version) {
        rootState.companySetup.currentQuestionnaireVersion = data.previous_version;
        let allScreens = companySetupGuide.data().allScreens;
        let questionnaire = data.questionnaire;
        let screen = null;
        let selectedScreenData = null;
        let selectedScreenKey = Object.keys(questionnaire)[0];

        if (rootState.buildTeam.hasOwnProperty(selectedScreenKey)) {
          selectedScreenData = rootState.buildTeam[selectedScreenKey];
        } else if (rootState.buildCompanyProfile.hasOwnProperty(selectedScreenKey)) {
          selectedScreenData = rootState.buildCompanyProfile[selectedScreenKey];
        } else if (rootState.organizationalLandscape.hasOwnProperty(selectedScreenKey)) {
          selectedScreenData = rootState.organizationalLandscape[selectedScreenKey];
        } else if (rootState.cyberSecurityCapabilities.hasOwnProperty(selectedScreenKey)) {
          selectedScreenData = rootState.cyberSecurityCapabilities[selectedScreenKey];
        }

        if (!selectedScreenData) {
          return;
        }

        let updatedData = null;
        // LOOP THROUGH SCREENS AND UPDATE THE VUEX STORE
        Object.keys(allScreens).forEach((keyName) => {
          screen = allScreens[keyName];
          if (questionnaire[screen]) {
            updatedData = companySetupGuide.methods.setPayloadFromServer(selectedScreenData, questionnaire[screen]);
            if (rootState.buildTeam.hasOwnProperty(selectedScreenKey)) {
              rootState.buildTeam[selectedScreenKey] = updatedData;
            } else if (rootState.buildCompanyProfile.hasOwnProperty(selectedScreenKey)) {
              rootState.buildCompanyProfile[selectedScreenKey] = updatedData;
            } else if (rootState.organizationalLandscape.hasOwnProperty(selectedScreenKey)) {
              rootState.organizationalLandscape[selectedScreenKey] = updatedData;
            } else if (rootState.cyberSecurityCapabilities.hasOwnProperty(selectedScreenKey)) {
              rootState.cyberSecurityCapabilities[selectedScreenKey] = updatedData;
            }

            rootState.companySetup.companySetupSteps.map((val) => {
              if (val.details && val.details.find((e) => e.key == keyName)) {
                val.details.find((e) => e.key == keyName).status = updatedData.completed ? 1 : 0;
              }
            });

            rootState.quickStart.quickStartSteps.map((val) => {
              if (val.details && val.details.find((e) => e.key == keyName)) {
                val.details.find((e) => e.key == keyName).status = updatedData.completed ? 1 : 0;
              }
            });
          }
        });
      }
    },
  },
  getters: {
    getField,
    teamMembers: (state) => state.teamMembers,
    brokersList: (state) => state.brokersList,
  },
};

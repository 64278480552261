import isEmail from 'validator/lib/isEmail';

export default {
  data: () => ({
    requiredKeys: [
      'organisationName',
      'addressLine1',
      'postalCode',
      'businessStructure',
      'businessDescription',
      'naicsCode',
      'noOfEmployees',
      'fullYearRevenue',
    ]
  }),

  computed: {
    checkEmail() {
      return (value) => {
        return isEmail(value, {
          allow_utf8_local_part: false,
        });
      }
    }
  },

  methods: {
    isFormComplete(form) {
      const errors = [];
      for (let key in form) {
        if (this.requiredKeys.includes(key)) {
          if (form[key] === '') {
            errors.push(`${key.toLowerCase()} is required`)
          }
        }
      }
      return {
        valid: errors.length ? false : true,
        errors
      }
    },

    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },

    onBlur(type, formName) {
      this.elevate = false
      if (formName === 'signupForm') this['signupForm'][type] = this.signupForm[type]?.trim();

      if (formName === 'paymentForm') {
        switch (type) {
          case 'number': return this.paymentForm.card[type] = this.paymentForm.card[type]?.trim();
          case 'expireDate': return this.paymentForm.card[type] = this.paymentForm.card[type]?.trim();
          case 'cvc': return this.paymentForm.card[type] = this.paymentForm.card[type]?.trim();
          case 'name': return this.paymentForm.billing_details[type] = this.paymentForm.billing_details[type]?.trim();
          case 'line1': return this.paymentForm.billing_details.address[type] = this.paymentForm.billing_details.address[type]?.trim();
          case 'country': return this.paymentForm.billing_details.address[type] = this.paymentForm.billing_details.address[type]?.trim();
          case 'state': return this.paymentForm.billing_details.address[type] = this.paymentForm.billing_details.address[type]?.trim();
          case 'city': return this.paymentForm.billing_details.address[type] = this.paymentForm.billing_details.address[type]?.trim();
          case 'postal_code': return this.paymentForm.billing_details.address[type] = this.paymentForm.billing_details.address[type]?.trim();
          case 'promo_code': return this.paymentForm[type] = this.paymentForm[type]?.trim();
        }
      }
    },

    trim(str) {
      return str?.trim()
    },

    hasSpecialChars(str) {
      const restrict = '!@#$%^*()_={}[]|/<>:;"~`?'.split('');
      return !restrict.some(v => str.includes(v))
    },

    isValidUSAPhoneNumber(phoneNumber) {
      // Define a regular expression to match the pattern
      const regex = /^\([2-9]\d{2}\) \d{3}-\d{4}$/;
      return regex.test(phoneNumber);
    },

    isNotFreeTypeEmailAddress(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!yopmail.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
      return regex.test(email);
    }
  },
}
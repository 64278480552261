<template>
  <v-carousel
    height="850"
    color="#fff"
    class="carousel"
    hide-delimiter-background
    :cycle="isContinuous"
    :continuous="isContinuous"
    :show-arrows-on-hover="false"
    :show-arrows="false"
    :interval="8000"
    @change="handleCycleChange"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <div class="d-flex justify-center align-center flex-column fill-height">
        <img :src="slide.image" />
        <div class="detail">
          <span class="outfit-bold pr-1">{{ slide.title }}</span>
          <span v-html="slide.detail" class="mb-2"></span>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "S2RAppCarousel",

  props: ["slides"],

  data() {
    return {
      isContinuous: true,
      cycleComplete: false,
    };
  },

  methods: {
    handleCycleChange(val) {
      if (this.cycleComplete && val === 0) this.isContinuous = false;
      if (val === 2) this.cycleComplete = true;
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  * {
    color: #fff !important;
  }

  .detail {
    font-size: 20px;
    text-align: center;
  }

  ::v-deep .mdi-circle::before {
    color: #fff !important;
  }

  img {
    height: 650px;
    width: 650px;
    object-fit: contain;

    @media (width <= 1280px) {
      height: 500px;
      width: 500px;
    }
  }
}
</style>


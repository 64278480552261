import { getField, updateField } from "vuex-map-fields"

export const networkSecurityOneState = () => {
  return [
    {
      id: 1,
      question: 'We allow access to our corporate network from remote locations',
      choice: null,
      visible: true,
      topLine: false,
      show: [{ id: 2, showOn: 'yes', key: 'mfa_is_enabled_for_remote_access' }],
      key: 'we_allow_access_to_our_corporate_network_from_remote_locations'
    },
    {
      id: 2,
      question: 'MFA is enabled for remote access',
      choice: null,
      visible: false,
      show: [
        { id: 3, showOn: 'yes', key: 'mfa_is_enabled_for_remote_access_answer_yes' },
        { id: 7, showOn: 'yes', key: 'is_this_mfa_solution_configured_to_ensure_a_compromise_of_a_single_device_will_only_compromise_that_device' },
        { id: 8, showOn: 'yes', key: 'mfa_is_enforced_for_all_user_accounts_other_than_domain_administrator_accounts_when_accessing_the_network_remotely' },
        { id: 9, showOn: 'yes', key: 'is_mfa_enforced_when_3rd_party_service_providers_access_the_corporate_network_from_a_remote_location' }
      ],
      key: 'mfa_is_enabled_for_remote_access'
    },
    {
      id: 3,
      question: '',
      type: 'hasVendorsWithType',
      choice: [{ name: '', type: null, website: '' }],
      visible: false,
      topLine: false,
      showButtons: false,
      key: 'mfa_is_enabled_for_remote_access_answer_yes'
    },
    {
      id: 7,
      question: 'Is this MFA solution configured to ensure a compromise of a single device will only compromise that device?',
      choice: null,
      visible: false,
      key: 'is_this_mfa_solution_configured_to_ensure_a_compromise_of_a_single_device_will_only_compromise_that_device'
    },
    {
      id: 8,
      question: 'MFA is enforced for all user accounts other than Domain Administrator accounts when accessing the network remotely.',
      choice: null,
      visible: false,
      key: 'mfa_is_enforced_for_all_user_accounts_other_than_domain_administrator_accounts_when_accessing_the_network_remotely'
    },
    {
      id: 9,
      question: 'Is MFA enforced when 3rd party service providers access the corporate network from a remote location?',
      choice: null,
      visible: false,
      key: 'is_mfa_enforced_when_3rd_party_service_providers_access_the_corporate_network_from_a_remote_location'
    },
    {
      id: 4,
      question: 'We use MFA to protect all local and remote access to privileged user accounts',
      choice: null,
      visible: true,
      show: [
        { id: 10, showOn: 'yes', key: 'we_use_mfa_to_protect_all_local_and_remote_access_to_privileged_user_accounts_answer_yes' },
        { id: 11, showOn: 'yes', key: 'is_this_mfa_solution_configured_to_ensure_a_compromise_of_a_single_device_will_only_compromise_that_device' }
      ],
      key: 'we_use_mfa_to_protect_all_local_and_remote_access_to_privileged_user_accounts'
    },
    {
      id: 10,
      question: '',
      type: 'hasVendorsWithType',
      choice: [{ name: '', type: null, website: '' }],
      visible: false,
      showButtons: false,
      topLine: false,
      key: 'we_use_mfa_to_protect_all_local_and_remote_access_to_privileged_user_accounts_answer_yes'
    },
    {
      id: 11,
      question: 'Is this MFA solution configured to ensure a compromise of a single device will only compromise that device?',
      choice: null,
      key: 'is_this_mfa_solution_configured_to_ensure_a_compromise_of_a_single_device_will_only_compromise_that_device'
    },
    {
      id: 5,
      question: 'We permit ordinary users local administration rights to their devices',
      choice: null,
      visible: true,
      key: 'we_permit_ordinary_users_local_administration_rights_to_their_devices'
    },
    {
      id: 6,
      question: 'We provide password management software for our employees',
      choice: null,
      visible: true,
      key: 'we_provide_password_management_software_for_our_employees'
    },
    {
      id: 12,
      question: 'Does your organization record and track all software and hardware assets deployed across your organization?',
      choice: null,
      visible: true,
      show: [
        {
          id: 13,
          showOn: 'yes',
          key: 'please_provide_the_name_of_the_tool_used_for_tracking_your_software_and_hardware'
        }
      ],
      key: 'does_your_organization_record_and_track_all_software_and_hardware_assets_deployed_across_your_organization'
    },
    {
      id: 13,
      heading: 'Please provide the name of the tool used for tracking your software and hardware (if any):',
      question: '',
      choice: null,
      visible: false,
      showButtons: false,
      topLine: false,
      optional: true,
      key: 'please_provide_the_name_of_the_tool_used_for_tracking_your_software_and_hardware'
    },
  ]
};

export default {
  namespaced: true,

  state: () => ({
    cyberSecurityOrganizationQuestions: {
      questions: [
        {
          id: 1,
          question: 'We outsource our Network Security',
          choice: null,
          visible: false,
          show: [{ id: 3, showOn: 'yes', key: 'we_outsource_our_network_security_answer_yes' }],
          topLine: false,
          bottomLine: true,
          key: 'we_outsource_our_network_security',
          quickStartHidden: true,
          indicationSetupHidden: true
        },
        {
          id: 3,
          question: '',
          type: 'hasVendors',
          topLine: false,
          choice: [{ name: '', website: '' }],
          visible: false,
          showButtons: false,
          key: 'we_outsource_our_network_security_answer_yes',
          indicationSetupHidden: true
        },
        {
          id: 2,
          heading: 'Cybersecurity Point of Contact',
          question: 'Is your Cybersecurity Point of Contact someone from your team?',
          choice: null,
          visible: true,
          topLine: false,
          show: [
            {
              id: 4,
              showOn: 'no',
              key: 'is_your_cybersecurity_point_of_contact_someone_from_your_team_answer_no'
            },
            {
              id: 5,
              showOn: 'yes',
              key: 'is_your_cybersecurity_point_of_contact_someone_from_your_team_answer_yes'
            }
          ],
          key: 'is_your_cybersecurity_point_of_contact_someone_from_your_team',
          indicationSetupHidden: true
        },
        {
          id: 4,
          question: '',
          requiredFormData: {
            name: {
              key: 'name',
              validationType: 'isRequired'
            },
            email: {
              key: 'email',
              validationType: 'isEmail'
            },
            address: {
              key: 'address',
              validationType: 'isRequired'
            },
            city: {
              key: 'city',
              validationType: 'isRequired'
            },
            zip: {
              key: 'zip',
              validationType: 'isRequired'
            },
            phone: {
              key: 'phone',
              validationType: 'isPhoneNumber'
            },
          },
          formData: {
            name: '',
            email: '',
            address: '',
            city: '',
            zip: '',
            phone: '',
          },
          visible: false,
          showButtons: false,
          key: 'is_your_cybersecurity_point_of_contact_someone_from_your_team_answer_no',
          indicationSetupHidden: true
        },
        {
          id: 5,
          question: '',
          hiddenIndicationFormData: [
            'address',
            'city',
            'zip',
          ],
          requiredFormData: {
            // name: {
            //   key: 'name',
            // },
            // email: {
            //   key: 'email',
            //   validationType: 'isEmail'
            // },
            address: {
              key: 'address',
            },
            city: {
              key: 'city',
            },
            zip: {
              key: 'zip',
            },
            phone: {
              key: 'phone',
              validationType: 'isPhoneNumber'
            },
          },
          formData: {
            selectedMember: '',
            membersList: [],
            // name: '',
            // email: '',
            address: '',
            city: '',
            zip: '',
            phone: ''
          },
          visible: false,
          showButtons: false,
          key: 'is_your_cybersecurity_point_of_contact_someone_from_your_team_answer_yes'
        }
      ]
    },

    cyberSecurityVendorsQuestions: {
      questions: [
        {
          id: 1,
          question: 'We outsource our Network Security',
          choice: null,
          visible: true,
          topLine: false,
          key: 'we_outsource_our_network_security',
          show: [
            {
              id: '2',
              showOn: 'yes',
              key: 'we_outsource_our_network_security_answer_yes'
            }
          ]
        },

        {
          id: 2,
          question: '',
          type: 'hasVendors',
          topLine: false,
          choice: [{ name: '', website: '' }],
          visible: false,
          showButtons: false,
          withBackground: true,
          key: 'we_outsource_our_network_security_answer_yes'
        },

        {
          id: 3,
          question: 'We outsource our Data Center Hosting',
          choice: null,
          visible: true,
          key: 'we_outsource_our_data_center_hosting',
          show: [
            {
              id: '4',
              showOn: 'yes',
              key: 'we_outsource_our_data_center_hosting_answer_yes'
            }
          ]
        },

        {
          id: 4,
          question: '',
          type: 'hasVendors',
          topLine: false,
          choice: [{ name: '', website: '' }],
          visible: false,
          showButtons: false,
          withBackground: true,
          key: 'we_outsource_our_data_center_hosting_answer_yes'
        },

        {
          id: 5,
          question: 'We outsource our Managed Security',
          choice: null,
          visible: true,
          key: 'we_outsource_our_managed_security',
          show: [
            {
              id: '6',
              showOn: 'yes',
              key: 'we_outsource_our_managed_security_answer_yes'
            }
          ]
        },

        {
          id: 6,
          question: '',
          type: 'hasVendors',
          topLine: false,
          choice: [{ name: '', website: '' }],
          visible: false,
          showButtons: false,
          withBackground: true,
          key: 'we_outsource_our_managed_security_answer_yes'
        },

        {
          id: 7,
          question: 'We outsource our Alert Log Monitoring',
          choice: null,
          visible: true,
          key: 'we_outsource_our_alert_log_monitoring',
          show: [
            {
              id: '8',
              showOn: 'yes',
              key: 'we_outsource_our_alert_log_monitoring_answer_yes'
            }
          ]
        },

        {
          id: 8,
          question: '',
          type: 'hasVendors',
          topLine: false,
          choice: [{ name: '', website: '' }],
          visible: false,
          showButtons: false,
          withBackground: true,
          key: 'we_outsource_our_alert_log_monitoring_answer_yes'
        },
      ]
    },

    networkSecurityOneQuestions: {
      questions: networkSecurityOneState()
    },

    networkSecurityTwoQuestions: {
      questions: [
        {
          id: 1,
          question: 'All Internal & Remote Admin Access to Directory Services (active directory, LDAP, etc.)',
          choice: null,
          visible: true,
          topLine: false,
          key: 'all_internal_&_remote_admin_access_to_directory_services_active_directory_ldap_etc'
        },
        {
          id: 2,
          question: 'All Internal & Remote Access to Network Backup Environments',
          choice: null,
          visible: true,
          key: 'all_internal_&_remote_access_to_network_backup_environments'
        },
        {
          id: 3,
          question: 'All Internal & Remote Access to Network Infrastructure',
          choice: null,
          visible: true,
          key: 'all_internal_&_remote_access_to_network_infrastructure'
        },
        {
          id: 4,
          question: 'All Internal & Remote Access to the organization’s endpoints and servers',
          choice: null,
          visible: true,
          key: 'all_internal_&_remote_access_to_the_organization’s_endpoints_and_servers'
        },
        {
          id: 5,
          question: 'Is MFA required on Mission-Critical Systems?',
          choice: null,
          visible: true,
          key: 'is_mfa_required_on_missioncritical_systems'
        }
      ]
    },

    networkSecurityCapabilitiesQuestions: {
      questions: [
        {
          id: 1,
          question: 'Do you have Endpoint Detection & Response (EDR)?',
          choice: null,
          visible: true,
          topLine: false,
          show: [
            {
              id: 12,
              showOn: 'yes',
              key: 'do_you_have_endpoint_detection_&_response_edr_answer_yes'
            }
          ],
          key: 'do_you_have_endpoint_detection_&_response_edr'
        },
        {
          id: 12,
          parentId: 1,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          autoCompleteOptions: [
            'BitDefender',
            'Carbon Black Cloud',
            'Cisco AMP',
            'CrowdStrike Falcon Insight',
            'Cybereason Defense Platform',
            'Cynet360',
            'Endgame Endpoint Protection',
            'FireEye Endpoint Security',
            'Fortinet FortiEDR',
            'Intercept X',
            'Malwarebytes Endpoint Protection and Response',
            'McAfee MVision EDR',
            'RedCanary',
            'RSA Netwitness',
            'SentinelOne',
            'SolarWinds',
            'Sophos Intercept X',
            'Symantec EDR',
            'Symantec Endpoint Security (SES) Complete',
            'Windows Defender Endpoint',
          ],
          key: 'do_you_have_endpoint_detection_&_response_edr_answer_yes'
        },

        {
          id: 2,
          question: 'Do you have Endpoint Protection (EPP)?',
          choice: null,
          visible: true,
          key: 'do_you_have_endpoint_protection_epp',
          show: [
            {
              id: 15,
              showOn: 'yes',
              key: 'do_you_have_endpoint_protection_epp_answer_yes'
            }
          ],
        },

        {
          id: 15,
          parentId: 2,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_endpoint_protection_epp_answer_yes'
        },

        {
          id: 3,
          question: 'Do you have Managed Detection & Response (MDR)?',
          choice: null,
          visible: true,
          key: 'do_you_have_managed_detection_&_response_mdr',
          show: [
            {
              id: 16,
              showOn: 'yes',
              key: 'do_you_have_managed_detection_&_response_mdr_answer_yes'
            }
          ],
        },

        {
          id: 16,
          parentId: 3,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_managed_detection_&_response_mdr_answer_yes'
        },

        {
          id: 4,
          question: 'Do you have Network Detection & Response?',
          choice: null,
          visible: true,
          key: 'do_you_have_network_detection_&_response',
          show: [
            {
              id: 17,
              showOn: 'yes',
              key: 'do_you_have_network_detection_&_response_answer_yes'
            }
          ],
        },

        {
          id: 17,
          parentId: 4,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_network_detection_&_response_answer_yes'
        },

        {
          id: 5,
          question: 'Do you have Application Solution Containment?',
          choice: null,
          visible: true,
          show: [
            {
              id: 13,
              showOn: 'yes',
              key: 'do_you_have_application_solution_containment_answer_yes'
            }
          ],
          key: 'do_you_have_application_solution_containment'
        },

        {
          id: 13,
          parentId: 5,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_application_solution_containment_answer_yes'
        },

        {
          id: 6,
          question: 'Do you have Privileged Access Management (PAM)?',
          choice: null,
          visible: true,
          show: [
            {
              id: 14,
              showOn: 'yes',
              key: 'do_you_have_privileged_access_management_pam_answer_yes'
            }
          ],
          key: 'do_you_have_privileged_access_management_pam'
        },

        {
          id: 14,
          parentId: 6,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_privileged_access_management_pam_answer_yes'
        },

        {
          id: 7,
          question: 'Do you have Security Operations Center (SOC)?',
          choice: null,
          visible: true,
          key: 'do_you_have_security_operations_center_soc',
        },

        {
          id: 8,
          question: 'Do you have Protective DNS?',
          choice: null,
          visible: true,
          key: 'do_you_have_protective_dns',
          show: [
            {
              id: 19,
              showOn: 'yes',
              key: 'do_you_have_protective_dns_answer_yes'
            }
          ],
        },

        {
          id: 19,
          parentId: 8,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_protective_dns_answer_yes'
        },

        {
          id: 9,
          question: 'Do you have Security Information & Event Management (SIEM)?',
          choice: null,
          visible: true,
          key: 'do_you_have_security_information_&_event_management_siem',
          show: [
            {
              id: 20,
              showOn: 'yes',
              key: 'do_you_have_security_information_&_event_management_siem_answer_yes'
            }
          ],
        },

        {
          id: 20,
          parentId: 9,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_security_information_&_event_management_siem_answer_yes'
        },

        {
          id: 10,
          question: 'Do you have Next-Generation Antivirus (NGAV)?',
          choice: null,
          visible: true,
          key: 'do_you_have_nextgeneration_antivirus_ngav',

          show: [
            {
              id: 21,
              showOn: 'yes',
              key: 'do_you_have_nextgeneration_antivirus_ngav_answer_yes'
            }
          ],
        },

        {
          id: 21,
          parentId: 10,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          autoCompleteOptions: [
            'BitDefender',
            'Carbon Black Cloud',
            'Check Point Software Technologies',
            'Cisco',
            'CrowdStrike Falcon Prevent',
            'Cylance',
            'ESET',
            'Fortinet',
            'F-Secure',
            'Kaspersky',
            'Malwarebytes',
            'McAfee',
            'Microsoft',
            'Palo Alto Networks',
            'Panda Security',
            'SentinelOne',
            'Sophos',
            'Symantec',
            'TrendMicro',
          ],
          key: 'do_you_have_nextgeneration_antivirus_ngav_answer_yes'
        },

        {
          id: 11,
          question: 'Do you have Virtual Network Computing (VNC) or Remote Desktop Protocol (RDP)?',
          choice: null,
          visible: true,
          key: 'do_you_have_virtual_network_computing_vnc_or_remote_desktop_protocol_rdp',
          show: [
            {
              id: 22,
              showOn: 'yes',
              key: 'do_you_have_virtual_network_computing_vnc_or_remote_desktop_protocol_rdp_answer_yes'
            }
          ],
        },

        {
          id: 22,
          parentId: 11,
          question: '',
          type: 'hasVendors',
          choice: [
            {
              name: '',
              website: ''
            }
          ],
          visible: false,
          showButtons: false,
          topLine: false,
          key: 'do_you_have_virtual_network_computing_vnc_or_remote_desktop_protocol_rdp_answer_yes'
        },
      ]
    },

    networkSecurityControlsQuestions: {
      questions: [
        {
          id: 1,
          visible: false,
          heading: 'Endpoint Detection & Response (EDR)',
          isTitleBold: true,
          showButtons: false,
          question: 'What is the estimated percent of endpoints that are covered by EDR?',
          choice: null,
          key: 'select_the_estimated_percent_of_endpoints_that_are_covered_by_edr',
          show: [
            {
              id: 18,
              showOn: '0,100',
              type: 'range',
              key: 'which_systems_has_it_been_deployed_on_and_which_has_it_not__explain'
            }
          ]
        },

        {
          id: 18,
          visible: false,
          isTitleBold: true,
          topLine: false,
          bottomLine: false,
          showButtons: false,
          question: 'Which systems has it been deployed on, and which has it not? Please explain:',
          choice: null,
          key: 'which_systems_has_it_been_deployed_on_and_which_has_it_not__explain'
        },

        {
          id: 22,
          visible: false,
          question: 'Does your organization enforce application whitelisting/blacklisting?',
          choice: null,
          bottomLine: false,
          topLine: false,
          key: 'Does_your_organization_enforce_application_whitelisting_blacklisting'
        },

        {
          id: 2,
          visible: false,
          question: 'Can users access the Insured’s network with their own device (Bring Your Own Device)?',
          choice: null,
          bottomLine: false,
          topLine: false,
          show: [
            {
              id: 3,
              showOn: 'yes',
              key: "for_users_that_can_access_the_network_from_their_own_devices_has_edr_been_installed_and_configured_on_those_devices"
            },
            {
              id: 4,
              showOn: 'yes',
              key: "how_is_your_company's_edr_monitored"
            }
          ],
          key: 'can_users_access_the_insured’s_network_with_their_own_device_bring_your_own_device'
        },

        {
          id: 3,
          visible: false,
          question: 'For users that can access the network from their own devices, has EDR been installed and configured on those devices?',
          choice: null,
          bottomLine: false,
          topLine: false,
          key: 'for_users_that_can_access_the_network_from_their_own_devices_has_edr_been_installed_and_configured_on_those_devices'
        },

        {
          id: 4,
          visible: false,
          question: "How is your company's EDR monitored?",
          isTitleBold: true,
          showButtons: false,
          topLine: false,
          choice: null,
          options: ['Monitored Internally', 'Monitored by a 3rd party'],
          key: "how_is_your_company's_edr_monitored"
        },

        {
          id: 5,
          visible: false,
          heading: 'Next-Generation Antivirus (NGAV)',
          isTitleBold: true,
          dottedLine: true,
          showButtons: false,
          question: 'How is your company’s NGAV monitored?',
          choice: null,
          options: ['Monitored Internally', 'Monitored by a 3rd party'],
          key: 'how_is_your_company’s_ngav_monitored'
        },

        {
          id: 6,
          heading: 'Privileged Access Management (PAM)',
          question: 'Are all privileged access account managed using a PAM solution?',
          choice: null,
          visible: false,
          dottedLine: true,
          key: 'are_all_privileged_access_account_managed_using_a_pam_solution',
          show: [
            {
              id: 23,
              showOn: 'yes',
              key: "is_access_protected_by_mfa"
            }
          ]
        },

        {
          id: 23,
          question: 'Is access protected by MFA?',
          choice: null,
          visible: false,
          topLine: false,
          key: 'is_access_protected_by_mfa'
        },

        {
          id: 7,
          question: 'Does the PAM Tool require checkout and password rotation for privileged credentials?',
          choice: null,
          visible: false,
          topLine: false,
          key: 'does_the_pam_tool_require_checkout_and_password_rotation_for_privileged_credentials'
        },

        {
          id: 8,
          heading: 'Security Operations Center (SOC)',
          showButtons: true,
          dottedLine: true,
          question: 'What type of SOC operations is in place?',
          choice: null,
          buttonConfig: ['24/7', 'Working Hours Only', 'Other'],
          visible: false,
          show: [
            { id: 9, showOn: '24/7', key: 'what_hours_is_monitoring_occurring' },
            { id: 10, showOn: 'Working Hours Only', key: 'is_there_oncall_personnel_during_nonbusiness_hours' }
          ],
          key: 'what_type_of_soc_operations_is_in_place'
        },
        {
          id: 9,
          showButtons: false,
          question: 'What hours is monitoring occurring?',
          choice: null,
          isTitleBold: true,
          visible: false,
          key: 'what_hours_is_monitoring_occurring'
        },
        {
          id: 10,
          question: 'Is there on-call personnel during non-business hours?',
          choice: null,
          visible: false,
          key: 'is_there_oncall_personnel_during_nonbusiness_hours'
        },
        {
          id: 11,
          isTitleBold: true,
          showButtons: false,
          question: 'How is the SOC operation managed?',
          choice: null,
          options: ['Internal', 'Third-Party', 'Both'],
          visible: false,
          topLine: false,
          key: 'how_is_the_soc_operation_managed'
        },
        {
          id: 12,
          question: 'Does the SOC have the authority and ability to remediate security events?',
          choice: null,
          visible: false,
          topLine: false,
          key: 'does_the_soc_have_the_authority_and_ability_to_remediate_security_events'
        },
        {
          id: 13,
          heading: 'Protective DNS',
          question: 'Are host-based and network firewalls configured to disallow inbound connections by default?',
          choice: null,
          dottedLine: true,
          visible: false,
          key: 'are_hostbased_and_network_firewalls_configured_to_disallow_inbound_connections_by_default'
        },
        {
          id: 14,
          showButtons: false,
          question: 'Where does the applicant have a firewall? (check all that apply):',
          choice: [],
          options: [
            'At network perimeter',
            'Internally within the network to protect sensitive resources'
          ],
          visible: false,
          topLine: false,
          key: 'where_does_the_applicant_have_a_firewall_check_all_that_apply:'
        },
        {
          id: 15,
          heading: 'Virtual Network Computing (VNC) or Remote Desktop Protocol (RDP)',
          question: 'For RDP exposed externally, is MFA enforced?',
          choice: null,
          dottedLine: true,
          visible: false,
          key: 'for_rdp_exposed_externally_is_mfa_enforced',
          show: [
            {
              id: 19,
              showOn: ['yes'],
              key: 'for_rdp_exposed_externally_is_mfa_enforced_yes'
            }
          ]
        },
        {
          id: 19,
          question: '',
          type: 'hasVendorsWithType',
          choice: [{ name: '', type: null, website: '' }],
          visible: false,
          topLine: false,
          showButtons: false,
          key: 'for_rdp_exposed_externally_is_mfa_enforced_yes'
        },
        {
          id: 16,
          question: 'Are all Server Message Block (SMB) inbound communications to servers denied (except where business need identified)?',
          choice: null,
          visible: false,
          topLine: false,
          key: 'are_all_server_message_block_smb_inbound_communications_to_servers_denied_except_where_business_need_identified'
        },
        {
          id: 17,
          question: 'Is your Remote Desktop Protocol (RDP), Virtual Network Computing (VNC), AnyDesk, TeamViewer, or other remote desktop software internal only or exposed to the internet?',
          choice: null,
          buttonConfig: ['Internal only', 'Exposed to the internet'],
          visible: false,
          topLine: false,
          key: 'is_your_remote_desktop_protocol_rdp_virtual_network_computing_vnc_anydesk_teamviewer_or_other_remote_desktop_software_internal_only_or_exposed_to_the_internet',
          show: [
            {
              id: 20,
              showOn: ['Internal only'],
              key: 'for_rdp_exposed_internally_is_mfa_enforced_rdp_internal_only'
            }
          ]
        },
        {
          id: 20,
          question: 'For RDP exposed internally, is MFA enforced?',
          choice: null,
          visible: false,
          topLine: false,
          key: 'for_rdp_exposed_internally_is_mfa_enforced_rdp_internal_only',
          show: [
            {
              id: 21,
              showOn: ['yes'],
              key: 'for_rdp_exposed_internally_is_mfa_enforced_rdp_internal_only_yes'
            }
          ]
        },
        {
          id: 21,
          question: '',
          type: 'hasVendorsWithType',
          choice: [{ name: '', type: null, website: '' }],
          visible: false,
          topLine: false,
          showButtons: false,
          key: 'for_rdp_exposed_internally_is_mfa_enforced_rdp_internal_only_yes'
        },
      ]
    },

    generalSecurityQuestions: {
      questions: [
        {
          id: 1,
          question: 'Does your organization restrict user rights on computer systems such that individuals (including third-party service providers) have access only to those areas of the network or information that is necessary for them to perform their duties?',
          choice: null,
          visible: true,
          topLine: false,
          key: 'does_your_organization_restrict_user_rights_on_computer_systems_such_that_individuals_including_thirdparty_service_providers_have_access_only_to_those_areas_of_the_network_or_information_that_is_necessary_for_them_to_perform_their_duties'
        },
        {
          id: 2,
          question: 'Does your organization ensure employees utilize least privilege at all times and do not operate as local administrators?',
          choice: null,
          visible: true,
          key: 'does_your_organization_ensure_employees_utilize_least_privilege_at_all_times_and_do_not_operate_as_local_administrators'
        },
        {
          id: 3,
          showButtons: false,
          isTitleBold: true,
          question: 'What percent of employees have local admin rights?',
          choice: '',
          visible: true,
          key: 'what_percent_of_employees_have_local_admin_rights'
        },
        {
          id: 4,
          showButtons: false,
          isTitleBold: true,
          question: 'Why do they require local admin rights?',
          choice: '',
          visible: true,
          key: 'why_do_they_require_local_admin_rights'
        },
        {
          id: 5,
          question: 'Is there an exception process to review and approve local administrator rights?',
          choice: null,
          visible: true,
          key: 'is_there_an_exception_process_to_review_and_approve_local_administrator_rights'
        },
        {
          id: 6,
          question: 'Does your organization have centralized log collection and management that allows for review of all access and activity on the network?',
          choice: null,
          visible: true,
          show: [{ id: 19, showOn: 'yes', key: 'does_your_organization_have_centralized_log_collection_and_management_that_allows_for_review_of_all_access_and_activity_on_the_network_answer_yes' }],
          key: 'does_your_organization_have_centralized_log_collection_and_management_that_allows_for_review_of_all_access_and_activity_on_the_network'
        },
        {
          id: 19,
          question: '',
          choice: null,
          visible: false,
          showButtons: false,
          key: 'does_your_organization_have_centralized_log_collection_and_management_that_allows_for_review_of_all_access_and_activity_on_the_network_answer_yes'
        },
        {
          id: 7,
          question: 'Does your organization segment the corporate network based on the classification level of information stored on said systems?',
          choice: null,
          visible: true,
          key: 'does_your_organization_segment_the_corporate_network_based_on_the_classification_level_of_information_stored_on_said_systems'
        },
        {
          id: 8,
          showButtons: false,
          isTitleBold: true,
          question: 'If the network is segmented by some other criteria, please explain:',
          choice: '',
          visible: true,
          key: 'if_the_network_is_segmented_by_some_other_criteria_please_explain:'
        },
        {
          id: 9,
          question: 'Does your organization have a data classification policy in place?',
          choice: null,
          visible: true,
          key: 'does_your_organization_have_a_data_classification_policy_in_place'
        },
        {
          id: 10,
          question: 'Is data currently classified under sensitive, proprietary, confidential, etc. tiers?',
          choice: null,
          visible: true,
          key: 'is_data_currently_classified_under_sensitive_proprietary_confidential_etc_tiers'
        },
        {
          id: 11,
          showButtons: false,
          isTitleBold: true,
          question: 'What controls are in place to limit access to sensitive data, please explain?',
          choice: '',
          visible: true,
          key: 'what_controls_are_in_place_to_limit_access_to_sensitive_data_please_explain'
        },
        {
          id: 12,
          question: 'Does your organization implement PowerShell best practices as outlined in the Environment Recommendations by Microsoft?',
          choice: null,
          visible: true,
          key: 'does_your_organization_implement_powershell_best_practices_as_outlined_in_the_environment_recommendations_by_microsoft'
        },
        {
          id: 13,
          showButtons: false,
          isTitleBold: true,
          question: 'How does the organization monitor for and block potentially malicious PowerShell usage, please explain:',
          choice: '',
          visible: true,
          key: 'how_does_the_organization_monitor_for_and_block_potentially_malicious_powershell_usage_please_explain:'
        },
        {
          id: 14,
          question: 'Does your organization have a formal vendor management process to perform due diligence and ongoing monitoring for vendors with access to the organization’s systems or data?',
          choice: null,
          visible: true,
          key: 'does_your_organization_have_a_formal_vendor_management_process_to_perform_due_diligence_and_ongoing_monitoring_for_vendors_with_access_to_the_organization’s_systems_or_data'
        },
        {
          id: 15,
          showButtons: false,
          isTitleBold: true,
          question: 'Is the information security team involved in the due diligence process for any new vendor that may have access to the organizations systems or data? Please explain:',
          choice: '',
          visible: true,
          key: 'is_the_information_security_team_involved_in_the_due_diligence_process_for_any_new_vendor_that_may_have_access_to_the_organizations_systems_or_data_please_explain:'
        },
        {
          id: 16,
          showButtons: false,
          isTitleBold: true,
          question: 'Is there a process to perform ongoing monitoring of vendors? Please explain:',
          choice: '',
          visible: true,
          key: 'is_there_a_process_to_perform_ongoing_monitoring_of_vendors_please_explain:'
        },
        {
          id: 17,
          question: 'Does your organization enforce a process for the timely installation of software updates/patches?',
          choice: null,
          visible: true,
          show: [{ id: 20, showOn: 'yes', key: 'does_your_organization_enforce_a_process_for_the_timely_installation_of_software_updates_patches' }],
          key: 'does_your_organization_enforce_a_process_for_the_timely_installation_of_software_updates_patches'
        },
        {
          id: 20,
          question: 'Are critical updates/patches installed within thirty (30) days of release?',
          choice: null,
          visible: false,
          key: 'are_critical_updates_patches_installed_within_thirty_30_days_of_release'
        },
        {
          id: 18,
          optional: true,
          showButtons: false,
          isTitleBold: true,
          question: 'Any additional information? (Optional)',
          choice: null,
          visible: true,
          key: 'any_additional_information_optional'
        }
      ]
    },

    vulnerabilityScanningQuestions: {
      questions: [
        {
          id: 1,
          question: 'We use a hardened baseline configuration across all (or mostly all) of our devices',
          choice: null,
          visible: true,
          topLine: false,
          key: 'we_use_a_hardened_baseline_configuration_across_all_or_mostly_all_of_our_devices'
        },
        {
          id: 2,
          showButtons: false,
          isTitleBold: true,
          question: 'What percent of your network is covered by scheduled vulnerability scans?',
          choice: null,
          visible: true,
          key: 'what_percent_of_your_network_is_covered_by_scheduled_vulnerability_scans'
        },
        {
          id: 3,
          showButtons: false,
          isTitleBold: true,
          question: 'In the past two years, how often have you conducted vulnerability scanning of the devices on your network?',
          choice: null,
          visible: true,
          options: [
            'Never or Not Regularly',
            'Annually',
            '2-3 times per year',
            'Quarterly or More'
          ],
          key: 'in_the_past_two_years_how_often_have_you_conducted_vulnerability_scanning_of_the_devices_on_your_network'
        },
        {
          id: 4,
          showButtons: false,
          isTitleBold: true,
          question: 'In the past two years, what has your critical patch cadence been?',
          choice: null,
          visible: true,
          options: [
            '1-3 days',
            '4-7 days',
            '8-30 days',
            'More than 30 days',
            'Unsure'
          ],
          key: 'in_the_past_two_years_what_has_your_critical_patch_cadence_been'
        },
        {
          id: 5,
          question: 'Does your patching program extend to other platforms like third-party applications, web browsers, and mobile applications?',
          choice: null,
          visible: true,
          key: 'does_your_patching_program_extend_to_other_platforms_like_thirdparty_applications_web_browsers_and_mobile_applications'
        },
        {
          id: 6,
          showButtons: false,
          isTitleBold: true,
          question: 'How often have you conducted penetration testing on your network?',
          choice: null,
          visible: true,
          options: [
            'Never or Not Regularly',
            'Annually',
            '2-3 times per year',
            'Quarterly or More'
          ],
          key: 'how_often_have_you_conducted_penetration_testing_on_your_network'
        },
        {
          id: 7,
          showButtons: false,
          isTitleBold: true,
          question: 'In the past two years, what is the average time that your organization has taken to remediate Critical Common Vulnerabilities and Exposures (Critical CVEs) (CVSS version 3.1 Base Score 9.0-10.0) on your network?',
          choice: null,
          visible: true,
          options: [
            'Unknown',
            'Greater than 2 weeks',
            'Less than 2 weeks',
            'Less than 1 week',
            'Less than 48 hours'
          ],
          key: 'in_the_past_two_years_what_is_the_average_time_that_your_organization_has_taken_to_remediate_critical_common_vulnerabilities_and_exposures_critical_cves_cvss_version_31_base_score_90100_on_your_network'
        },
        {
          id: 8,
          showButtons: false,
          isTitleBold: true,
          question: 'Any additional information?',
          choice: null,
          visible: true,
          optional: true,
          key: 'any_additional_information'
        }
      ]
    }
  }),

  mutations: {
    updateField,
  },

  actions: {},

  getters: {
    getField,
  },
}

<template>
  <v-col cols="12" :class="[customClass ? customClass : 'd-flex justify-space-between px-md-8']">
    <div class="d-flex align-center">
      <template v-if="hideLogo">
        <div v-if="getAppLogoUrl()">
          <img :src="require(`@/assets/images/${getAppLogoUrl()}`)" style="max-height: 50px" />
        </div>

        <div v-else>
          <img :src="require('@/assets/images/s2-logo.png')" style="max-width: 26px" />
          <span class="outfit-bold font-700 text-18 text-light-black pl-2">{{ getAppTitle() }}</span>
        </div>
      </template>
    </div>

    <div>
      <v-btn
        outlined
        class="outfit-bold text-12 font-700 light-grey-outlined"
        :class="'tight-spacing px-5'"
        v-if="showCreateAccount"
        @click="handleLinkClick('signup')"
      >
        <span class="text-light-black text-none">Create an account</span>
      </v-btn>

      <v-btn
        v-if="showLogin"
        outlined
        class="outfit-bold text-12 font-700 light-grey-btn-outlined"
        :class="isBroker && 'text-capitalize tight-spacing px-9'"
        rounded
        color="rgba(17, 16, 16, 0.16)"
        @click="handleLinkClick('login')"
      >
        <span class="text-light-black">Login</span>
      </v-btn>

      <v-btn
        v-if="showSignup"
        outlined
        class="outfit-bold ml-3 text-12 font-700 light-grey-btn-outlined"
        :class="isBroker && 'tight-spacing px-9'"
        rounded
        color="rgba(17, 16, 16, 0.16)"
        @click="handleLinkClick('broker-signup')"
      >
        <span class="text-light-black text-none">I'm a Broker</span>
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import appTitleMixin from "@/Mixins/appTitle";
import commonMixins from "@/Mixins/common";
import { isJLPCSubDomain } from "@/router/general";
import { hideBranding } from "@/helpers";

export default {
  mixins: [appTitleMixin, commonMixins],

  props: ["showCreateAccount", "showLogin", "isBroker", "customClass", "showSignup"],

  data() {
    return {
      hideBranding,
    };
  },

  computed: {
    hideLogo() {
      if (this.$route.path === "/signup") return true;
      return !this.hideBranding;
    },
  },

  methods: {
    handleLinkClick(page) {
      if (page == "login") {
        this.$store.dispatch("login/logout");
      }

      if (isJLPCSubDomain() && page == "signup") {
        page = "privacy-exposure";
      }

      if (page === "broker-signup") {
        page = "broker/signup";
      }

      this.$router.push({ path: `/${page}` }).catch((e) => console.warn("."));
    },
  },
};
</script>
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./plugins/axios";
import Notifications from "vue-notification";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import { wysiwygSettings } from "./config/constants";
import { VueMaskDirective } from "v-mask";
import VueClipboards from "vue-clipboards";
import pusher from "./plugins/pusher";

Vue.directive("mask", VueMaskDirective);
Vue.use(VueClipboards);
Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(wysiwyg, wysiwygSettings);
Vue.prototype.$axios = api;

router.beforeEach((to, from, next) => {
  if (!pusher.isRegistered) {
    pusher.registerChannels();
  }
  next();
});

const vm = new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const urlParams = new URLSearchParams(window.location.search);
    const QUERY_SHOW_FUTURE = urlParams.get("SHOW_FUTURE");
    if (QUERY_SHOW_FUTURE) {
      Vue.prototype.$showFuture = QUERY_SHOW_FUTURE === "true"; // First priority will be given to query params.
    } else {
      Vue.prototype.$showFuture = process.env.VUE_APP_SHOW_FUTURE === "true"; // Second priority will be given to .env variable.
    }
    this.$store.commit("setState", { key: "showFuture", value: this.$showFuture }); // Is also accesible from the store
  },
  render: (h) => h(App),
}).$mount("#app");

export { vm };

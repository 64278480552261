<template>
  <v-container fluid>
    <v-row class="without-auth-main-bg d-block">
      <!-- DE-BRANDIFY -->
      <!-- <background-shapes v-if="!hideBranding && !isBrokerLogin" /> -->
      <top-bar-without-auth :show-create-account="true" />
      <v-col cols="12" class="d-flex align-baseline justify-center mt-md-14 pt-14">
        <v-row
          :class="[
            'pa-0 align-baseline justify-center',
            $vuetify.breakpoint.mdAndUp ? ' login-signup-form-container' : '',
          ]"
        >
          <h2
            :class="[
              'outfit-bold font-700  font-700 text-light-black mb-5',
              $vuetify.breakpoint.mdAndUp ? 'text-40' : 'text-30',
            ]"
          >
            Welcome <span v-if="!hideBranding">to {{ getAppTitle() }}</span>
          </h2>
          <v-col cols="12" md="12" class="px-6 login-signup-form-outer">
            <div class="outfit-bold font-700 text-20 text-light-black">Login to continue</div>
            <login-form
              :key="`view-${view}`"
              :outlined="true"
              :show-outside-label="true"
              :show-forgot-password-link="true"
              @handleForgotLink="forgotPassword"
              @updateView="updateView"
            />
          </v-col>

          <!-- DE-BRANDIFY -->
          <!-- <img
            v-if="!hideBranding && !isBrokerLogin"
            :src="require('@/assets/images/shapes/green-circle.png')"
            style="position: absolute; left: -26px; top: 44px; z-index: 0; max-width: 48px"
          /> -->
        </v-row>
      </v-col>
      <app-footer />
    </v-row>
    <login-mfa-form />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { getMsalConfig } from "@/config/msalConfig";
import appSettings from "../../../app.settings";
import AppFooter from "@/components/Base/AppFooter";
import validationMixin from "@/Mixins/validation";
import appTitleMixin from "@/Mixins/appTitle";
import LoginForm from "@/components/Base/LoginForm";
import LoginMfaForm from "@/components/Base/LoginMfaForm";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import BackgroundShapes from "@/components/General/BackgroundShapes.vue";
import { hideBranding } from "../../helpers";

export default {
  components: {
    BackgroundShapes,
    TopBarWithoutAuth,
    AppFooter,
    LoginForm,
    LoginMfaForm,
  },
  mixins: [validationMixin, appTitleMixin],
  data: () => ({
    hideBranding,
  }),
  computed: {
    ...mapGetters("login", ["isLoading"]),

    ...mapFields("login", [
      "view",
      "email",
      "password",
      "passwordType",
      "mfa_dialog",
      "twoFactor",
      "recoveryCodeForm",
      "authCode",
      "recoveryCode",
    ]),

    appInfo() {
      return appSettings;
    },

    isBrokerLogin() {
      return this.$route.name === "BrokerLogin";
    },
  },
  beforeMount() {
    if (this.$route.params?.accessToken) {
      if (this.$route.params?.allocatedToken) {
        this.loginUsingToken({
          accessToken: this.$route.params?.accessToken,
          allocatedToken: this.$route.params?.allocatedToken,
          type: this.$route.params?.type,
        });
      } else {
        this.loginUsingToken({
          accessToken: this.$route.params?.accessToken,
          type: this.$route.params?.type,
        });
      }
    }
  },
  mounted() {
    this.checkViewInUrl();
  },
  methods: {
    loginUsingMfa() {
      if (this.$refs.twoFactorForm.validate()) {
        this.$store.dispatch("login/loginMfa", { email: this.email, otp: this.authCode, password: this.password });
      }
    },
    loginUsingRecoveryCode() {
      if (this.$refs.recoveryCodeForm.validate()) {
        this.$store.dispatch("login/loginUsingRecovery", {
          email: this.email,
          recovery_code: this.recoveryCode,
          password: this.password,
        });
      }
    },
    handleLinkClick(page) {
      if (page && page == "signup") {
        this.$router.push({ path: "/signup" });
      } else {
        if (this.view === "login") {
          return this.forgotPassword();
        }
        this.showLogin();
      }
    },
    updateView() {
      this.view = "login";
    },
    forgotPassword() {
      this.view = "forgot";
      this.handleRouteData();
    },
    showLogin() {
      this.view = "login";
      this.handleRouteData();
    },
    handleRouteData() {
      let path = `${this.$route.path}?view=${this.view}`;
      this.$router.replace({ path });
    },
    checkViewInUrl() {
      if (this.$route.query && this.$route.query.view === "forgot") {
        return (this.view = "forgot");
      }
      return (this.view = "login");
    },
    loginUsingToken(token) {
      this.$store.dispatch("login/loginUsingToken", token);
    },
  },
};
</script>

<style scoped lang="scss">
.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}
</style>

import localService from "../services/localService";
import { allowedEmails, jlpcBrokerId } from "../helpers/index";
let IS_JLPC_SUB_DOMAIN = process.env.VUE_APP_JLPC_SUB_DOMAIN === "true";
let IS_FRP_SUB_DOMAIN = process.env.VUE_APP_FRP_SUB_DOMAIN === "true";

/* THIS WILL GET THE USER INFORMATION AND TOKEN */
function getUserInformationAndToken() {
  if (localService.getItem("userInfo")) {
    return {
      token: localService.getItem("userInfo") ? localService.getItem("userInfo")["access_token"] : null,
      userInfo: localService.getItem("userInfo") ? localService.getItem("userInfo") : null,
    };
  } else return {};
}

/* THIS WILL CHECK IF USER IS LOGGED IN OR NOT */
function checkValidity() {
  if (getUserInformationAndToken()["token"] && getUserInformationAndToken()["userInfo"]) return true;
  return false;
}

/* THIS WILL CHECK THE LOGGED IN USER'S ROLE */
function checkRole() {
  if (Object.keys(getUserInformationAndToken())?.length > 0) {
    const {
      userInfo: {
        user: { role },
      },
    } = getUserInformationAndToken();
    return role.toLowerCase();
  }
  return {};
}

/* THIS FUNCTION WILL CHECK IF USER ACCEPTED TERMS */
function didUserAcceptTerms() {
  /* 
    This comes handly when no terms are published 
    by the admin - it simply skips the terms page 
  */
  if (process.env.VUE_APP_BYPASS_TERMS_PAGE && process.env.VUE_APP_BYPASS_TERMS_PAGE === "YES") {
    return true;
  }

  const userInfo = getUserInformationAndToken()["userInfo"];

  /* 
    Check if this user was included as a edit user,If yes then simply 
    return as true because edit users do not accept terms.
  */
  if (allowedEmails && allowedEmails.includes(userInfo?.user?.email)) {
    return true;
  }

  /* Check if the role of logged in user is not client, then return true */
  if (userInfo && userInfo.user && userInfo.user.role !== "client" && userInfo.user.role !== "broker") {
    return true;
  }

  /* Check if the role of logged in user is client and org type legacy */
  if (userInfo && userInfo.user && userInfo.user.role == "client" && userInfo.user.org_type == "legacy") {
    return true;
  }

  /* 
    Otherwise user is client and check if user accepted the latest published 
    terms or not,if not then take them to terms page before any page.
  */
  let accepted;
  if (userInfo && userInfo.user) {
    accepted = userInfo.user.terms_accepted;
    if (userInfo.user.role == "client" && (!userInfo.user.terms_version || userInfo.user.terms_version < "2.6.0")) {
      accepted = false;
    }
    if (accepted) {
      return true;
    }
    return false;
  }
}

function didUserCompletedGetStarted() {
  if (process.env.VUE_APP_BYPASS_GETTING_STARTED_PAGE && process.env.VUE_APP_BYPASS_GETTING_STARTED_PAGE === "YES") {
    return true;
  }
  const userInfo = getUserInformationAndToken()["userInfo"];
  /* Check if the role of logged in user is client and org type legacy */ if (
    userInfo &&
    userInfo.user &&
    userInfo.user.role == "client" &&
    userInfo.user.org_type == "legacy"
  ) {
    return true;
  }

  if (userInfo && userInfo.user) {
    if (userInfo.user?.role == "salesperson") {
      return true;
    }

    if (userInfo.user?.role == "broker" && userInfo.user?.has_members == true && userInfo.user.client_title) {
      return true;
    }

    let isUserOrgUser = userInfo.user.organization_user_id === userInfo.user.entity_id;
    if (userInfo.user.organization_user_id == userInfo.user.entity_id) {
      if (userInfo.user.client_title) {
        if (!didUserCompletedGetStartedDelegation(userInfo)) {
          return false;
        } else if (!didUserCompletedGetStartedInviteMembers(userInfo)) {
          return false;
        }
        return true;
      }
    } else {
      if (userInfo.user.client_title && userInfo.user.organization_responsibility) {
        if (!didUserCompletedGetStartedDelegation(userInfo) && isUserOrgUser) {
          return false;
        } else if (!didUserCompletedGetStartedInviteMembers(userInfo) && isUserOrgUser) {
          return false;
        }
        return true;
      }
    }
    return false;
  }
}

function didUserCompletedGetStartedDelegation(userInfo) {
  if (userInfo.user.role == "client" && userInfo.user.client_role == "admin") {
    if (userInfo.user.onboard_delegate_completed) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

function didUserCompletedGetStartedInviteMembers(userInfo) {
  if (userInfo.user.role == "client" && userInfo.user.client_role == "admin") {
    if (userInfo.user.onboard_invite_members_completed) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

function getDomainDetails() {
  let result = {};
  let regexParse = new RegExp("([a-z-0-9]{2,63}).([a-z.]{2,5})$");
  let urlParts = regexParse.exec(window.location.hostname);
  result.domain = urlParts[1];
  result.type = urlParts[2];
  result.subdomain = window.location.hostname.replace(result.domain + "." + result.type, "").slice(0, -1);
  return result;
}

function isJLPCSubDomain() {
  let domainDetails = getDomainDetails();
  let subdomain = domainDetails.subdomain;
  if (subdomain.includes("jlpc") || IS_JLPC_SUB_DOMAIN) {
    return true;
  }
  return false;
}

function isFRPSubDomain() {
  let domainDetails = getDomainDetails();
  let subdomain = domainDetails.subdomain;
  if (subdomain.includes("frp") || IS_FRP_SUB_DOMAIN) {
    return true;
  }
  return false;
}

function isSBA() {
  const domain_lower = getDomainDetails()?.subdomain?.toLowerCase();
  let is_sba = false;
  if (domain_lower.includes("sba")) {
    is_sba = true;
  }
  if (domain_lower.includes("plan")) {
    is_sba = true;
  }
  return is_sba;
}

function isInsuretrust() {
  const domain_lower = getDomainDetails()?.subdomain?.toLowerCase();
  let is_insuretrust = false;
  if (domain_lower.includes("insuretrust")) {
    is_insuretrust = true;
  }
  return is_insuretrust;
}

function hasJLPCBrokerId() {
  const userInfo = getUserInformationAndToken()["userInfo"];
  if (!jlpcBrokerId) return false;
  return jlpcBrokerId.includes(userInfo.user?.organization_id);
}

function redirectUserFromJlpcToMain() {
  const userInfo = getUserInformationAndToken()["userInfo"];
  if (!userInfo.user?.is_jackson_lewis_client) {
    const domainDetails = getDomainDetails();
    let subDomain = domainDetails.subdomain;
    if (subDomain != "localhos") {
      subDomain = subDomain.replace("jlpc-", "").replace("-jlpc", "");
      window.location = `${window.location.protocol}//${subDomain}.${domainDetails.domain}.${domainDetails.type}`;
    }
  }
}

function deleteUserInformationAndToken() {
  localService.removeItem("userInfo");
}

function hasBrokerWorkbenchUrl() {
  const domainDetails = getDomainDetails();
  if (domainDetails.domain?.includes("brokerworkbench") || domainDetails.subdomain?.includes("brokerworkbench")) {
    return true;
  }
  return false;
}

export {
  checkRole,
  checkValidity,
  getUserInformationAndToken,
  didUserAcceptTerms,
  didUserCompletedGetStarted,
  getDomainDetails,
  isJLPCSubDomain,
  hasJLPCBrokerId,
  redirectUserFromJlpcToMain,
  deleteUserInformationAndToken,
  isSBA,
  isInsuretrust,
  hasBrokerWorkbenchUrl,
  isFRPSubDomain,
};

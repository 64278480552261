<template>
  <v-row>
    <v-col cols="12" class="px-6">
      <p class="font-700 text-center">Second Sight Offerings</p>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Product name</th>
              <th class="text-left">Description</th>
              <th class="text-left">Qty</th>
              <th class="text-left">Subscription Price</th>
              <th class="text-left">Total</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Company Workbench Annual Subscription</td>
              <td>
                Simplify your cyber insurance application process with the Company Setup Guide, Digital Asset Inventory, limited external scanning, advanced reporting, and access to risk tracker.
              </td>
              <td>1</td>
              <td>$499</td>
              <td>$499</td>
            </tr>
            <tr>
              <td>Company Workbench Onetime Credit</td>
              <td>
                Company Workbench credit for customers of Jackson-Lewis.
              </td>
              <td>1</td>
              <td>-$499</td>
              <td>-$499</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-col cols="12" class="px-10 mt-14">
      <p>This constitutes a PO In accordance with the terms of the Main Customer Agreement (“MCA”) between Customer and SecondSight, and the other Incorporated Agreements, and is considered an Incorporated Agreement.   All capitalized, undefined terms in this PO have the meanings given to them in the MCA or in other Incorporated Agreements. Customer may not receive Second Sight Offerings detailed above without accepting this PO and agreeing to be bound by the MCA and other Incorporated Agreements. The terms of the Incorporated Agreements shall not be deemed modified or amended except as expressly provided in this PO.</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "JacksonLewisPurchaseOrderForm",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
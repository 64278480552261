<template>
  <app-dialog :persistent="true" :dialog="dialog.open" @cancel-dialog="$emit('cancel-dialog')">
    <v-card>
      <v-card-title> <span class="text-h6" v-html="dialog.title"></span> </v-card-title>
      <v-card-text>
        <iframe style="width: 100%; height: 600px" :src="`https://cdn.filestackcontent.com/preview/${dialog.file}`">
        </iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog.open = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>

<script>
import AppDialog from "@/components/Base/AppDialog";
export default {
  components: {
    AppDialog,
  },
  props: {
    dialog: {
      type: Object,
      default: () => ({
        open: false,
        title: null,
        file: null,
      }),
    },
  },
};
</script>
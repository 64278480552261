import axios from "../plugins/axios";
import localService from "../services/localService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "../main";
import { uuid } from "vue-uuid";

export default {
  namespaced: true,
  state: () => ({
    /* Static Data */
    tableHeaders: [
      {
        text: "Name",
        value: "name",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
        filterable: true,
        align: "left",
      },
      {
        text: "Company",
        value: "organization_name",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        text: "",
        value: "options",
        sortable: false,
        filterable: false,
        align: "right",
      },
    ],
    userTableHeaders: [
      {
        text: "Name",
        value: "name",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
        filterable: true,
        align: "left",
      },
      {
        text: "Role",
        value: "client_role",
        sortable: true,
        filterable: true,
        align: "left",
      },
      {
        text: "",
        value: "options",
        sortable: false,
        filterable: false,
        align: "right",
      },
    ],
    /* Static Data */

    /* State */
    currentOrganization: {},
    email: null,
    name: null,
    role: null,
    company: null,
    organization: null,
    website: null,
    users: [],
    clientUserLoading: true,
    clients: [],
    organizations: [],
    brokerOrganizations: [],
    organizationIntent: null,
    roles: ["Viewer", "Contributor", "Admin"],
    delegateInvitedMembers: [],
    clientDelegations: [],
    clientDelegationsLoading: true,
    totalPayableAmount: 499,
    // Company users
    selectedFilters: [],
    userInfoDrawer: false,
    selectedUser: null,
    deleteDialog: false,
    userSettings: {},
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    deleteOrganizationClients: (state, client) => {
      var foundIndex = state.users.findIndex((x) => x.entity_id == client.entity_id);
      state.users.splice(foundIndex, 1);
    },

    updateOrganizationClients: (state, client) => {
      var foundIndex = state.users.findIndex((x) => x.entity_id == client.entity_id);

      if (foundIndex != -1) {
        Vue.set(state.users, foundIndex, client);
      } else {
        state.users.push(client);
      }
    },

    updateBrokersPurchases(state, payload) {
      var foundIndex = state.brokerOrganizations.findIndex((x) => x.entity_id == payload.organization_id);
      if (!state.brokerOrganizations[foundIndex].purchases.length) {
        state.brokerOrganizations[foundIndex].purchases = [];
      }
      state.brokerOrganizations[foundIndex].purchases.push(payload);
    },

    updateInvitedDelegateMembers(state, payload) {
      state.delegateInvitedMembers.push(payload);
    },

    updateClientList(state, data) {
      state.clients = [...state.clients, ...data];
    },
  },
  actions: {
    async getCurrentOrganization({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/me/organization");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "currentOrganization", value: data.organization });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getOrganizations({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/organizations");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "organizations", value: data.results });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getBrokerOrganizations({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/broker_organization_info");
        if (!data.success) {
          return;
        }
        commit("setState", {
          key: "brokerOrganizations",
          value: data.organizations,
        });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getClients({ commit }, payload) {
      try {
        let url = "/client";
        if (payload && payload.page && payload.page_size) {
          url += `?page=${payload.page}&page_size=${payload.page_size}`;
        }
        if (!payload.fetch_more) {
          commit("startLoading", null, { root: true });
        }
        const { data } = await axios.get(url);
        if (!data.success) {
          return;
        }
        commit("updateClientList", data.results);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async updateProfilePicture({ commit, dispatch, rootState }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.put("/update_profile_picture", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        let currentUser = rootState.login.currentUser;
        currentUser.user = { ...currentUser.user, ...data.user };
        commit("setState", { key: "login/currentUser", value: currentUser });
        localService.setItem("userInfo", currentUser);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async updateUser({ commit, dispatch, rootState }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.put("/update_username", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        let currentUser = rootState.login.currentUser;
        currentUser.user = { ...currentUser.user, name: data.user.name };
        commit("setState", { key: "login/currentUser", value: currentUser });
        localService.setItem("userInfo", currentUser);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async createClient({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/client", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("setState", { key: "name", value: null });
        commit("setState", { key: "email", value: null });
        commit("setState", { key: "role", value: null });
        if (payload.clickwraps) {
          dispatch(
            "clickwrap/newUserAssignClickwrap",
            {
              user_id: data.user.entity_id,
              clickwraps: payload.clickwraps.map((clickwrap) => clickwrap.entity_id),
            },
            { root: true }
          );
        }
        dispatch("getClients");
        return data;
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async deleteClient({ dispatch }, payload) {
      try {
        const { data } = await axios.delete(`/client/${payload.entity_id}`);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log("e", e);
      }
    },
    async getClientUsers({ commit }, organizationId = null) {
      try {
        commit("startLoading", null, { root: true });
        let url = "/organization_management/client";
        if (organizationId) {
          url = `/organization_management/${organizationId}/client`;
        }
        const { data } = await axios.get(url);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "users", value: data.results });
        commit("setState", { key: "clientUserLoading", value: false });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async createClientUser({ commit, dispatch, rootState, state }, payload) {
      try {
        commit("startLoading", null, { root: true });
        let payloadData = Object.assign({}, payload);
        if (payloadData.role) {
          payloadData.role = payload.role.toLowerCase();
        }
        let customSuccessNotification = false;
        if (payloadData.custom_success_notification) {
          customSuccessNotification = true;
          delete payloadData.custom_success_notification;
        }
        const { data } = await axios.post("/organization_management/client", payloadData);
        if (!data.success) {
          return;
        }
        if (customSuccessNotification) {
          dispatch(
            "showMessage",
            { message: `${payloadData.name} was invited`, messageType: "success", messageTitle: "Success" },
            { root: true }
          );
        } else {
          dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        }
        commit("setState", { key: "name", value: null });
        commit("setState", { key: "email", value: null });
        commit("setState", { key: "role", value: null });
        dispatch("getClientUsers");
        return data;
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async inviteClientBulkUser({ commit }, payload) {
      const { data } = await axios.post("/organization_management/clients", payload);
      if (!data.success) {
        return;
      }
      return data;
    },
    async deleteClientUser({ commit, dispatch, state }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/organization_management/client/${payload.entity_id}`);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        dispatch("getClientUsers");
        return data;
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getClientAllDelegations({ commit }, organizationId = null) {
      let url = "/organization_management/client_delegations";
      if (organizationId) {
        url = `/organization_management/${organizationId}/client_delegations`;
      }
      const { data } = await axios.get(url);
      commit("setState", {
        key: "clientDelegations",
        value: data.delegations || [],
      });
      commit("setState", { key: "clientDelegationsLoading", value: false });
    },
    async setUserOnboard({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/user_onboard", payload);
        if (!data.success) {
          return;
        }

        let userInfo = localService.getItem("userInfo");
        userInfo.user.client_title = payload.client_title;
        if (userInfo.user.organization_user_id == userInfo.user.entity_id) {
          userInfo.user.organization_responsibility = payload.intent;
          userInfo.user.coverage_due_date = payload.coverage_due_date;
        } else {
          userInfo.user.organization_responsibility = payload.organization_responsibility;
        }
        localService.setItem("userInfo", userInfo);
        commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });

        return { success: true };
      } catch (e) {
        console.log("e", e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async completeGetStartedUserOverlay({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/user_onboard", payload);
        if (!data.success) {
          return;
        }
        let userInfo = localService.getItem("userInfo");
        userInfo.user.client_title = payload.client_title;
        if (userInfo.user.organization_user_id == userInfo.user.entity_id) {
          userInfo.user.intent = payload.intent;
          userInfo.user.organization_responsibility = payload.intent;
          userInfo.user.coverage_due_date = payload.coverage_due_date;
        } else {
          userInfo.user.organization_responsibility = payload.organization_responsibility;
        }
        localService.setItem("userInfo", userInfo);
        commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });
        if (userInfo?.user?.role == "broker") {
          vm.$nextTick(() => {
            vm.$router.push({ path: "/broker/members" });
          });
        } else {
          if (userInfo?.user?.role == "client" && userInfo?.user?.client_role == "admin") {
            vm.$nextTick(() => {
              vm.$router.push({ path: "/user/getting-started/delegate" });
            });
          } else {
            vm.$nextTick(() => {
              vm.$router.push({ path: "/user/readiness-checklist" });
            });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async inviteDelegateTeamMember({ commit, dispatch }, payload) {
      let data = Object.assign({}, payload);
      data.entity_id = uuid.v4();
      commit("updateInvitedDelegateMembers", data);
      return data;
    },

    deleteOrganizationClients({ commit }, payload) {
      commit("deleteOrganizationClients", payload);
    },

    updateOrganizationClients({ commit }, payload) {
      commit("updateOrganizationClients", payload);
    },

    async getQuickStartStatus({ commit }, payload) {
      const { data } = await axios.get("/company_setup/status/quick_start");
      if (!data.success) {
        return;
      }
      return data;
    },

    async getUserSettings({ commit }) {
      const { data } = await axios.get("settings");
      commit("setState", { key: "userSettings", value: data.settings });
      return data;
    },

    async saveUserSettings({ commit }, payload) {
      const { data } = await axios.post("settings", payload);
      if (!data.success) return;
      commit("setState", { key: "userSettings", value: data.settings });
      return data;
    },

    async trackPolicyClick({ commit }, handle) {
      const { data } = await axios.post(`/track/policies/${handle}`);
      if (!data.success) return;
      return data;
    },
  },

  getters: {
    getField,
    clientTableHeaders: (state) => state.tableHeaders,
    clients: (state) => state.clients,
    organizations: (state) => state.organizations,
    brokerOrganizations: (state) => state.brokerOrganizations,
    userTableHeaders: (state) => state.userTableHeaders,
    adminClientTableHeaders: (state) => state.adminClientTableHeaders,
    users: (state) => state.users,
    organizationIntent: (state) => state.organizationIntent,
  },
};

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,

    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: "#39CA56",
        radio: "#676666",
        "disabled-link": "#c0c0c0",
        "active-link": "#696969",
        "light-text": "#676666",
        "lighter-text": "#cccccc",
      },
    },
  },
});

import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/General/Login.vue";
import SignupPage from "../views/General/Signup.vue";
import BrokerSignupPage from "../views/Broker/Signup.vue";
import SubscriptionPage from "../views/General/Subscription.vue";
import ClientSubscriptionPage from "../views/Client/Welcome/Subscription.vue";
import JlpcSignupPage from "../views/Jlpc/Signup.vue";
import JlpcConfirmationPage from "../views/Jlpc/Confirmation.vue";
import JlpcThanknYouPage from "@/views/Jlpc/ThankYou.vue";
import JlpcReportsUploadPage from "@/views/Jlpc/ReportsUpload.vue";
import JlpcReportsDownloadPage from "@/views/Jlpc/ReportsDownload.vue";
import localService from "@/services/localService";
import Terms from "../views/Terms.vue";

import {
  checkRole,
  checkValidity,
  didUserAcceptTerms,
  getUserInformationAndToken,
  didUserCompletedGetStarted,
  isJLPCSubDomain,
  redirectUserFromJlpcToMain,
  hasJLPCBrokerId,
  deleteUserInformationAndToken,
  isSBA,
  isInsuretrust,
  hasBrokerWorkbenchUrl,
} from "./general";

Vue.use(VueRouter);

let SHOW_FUTURE = process.env.VUE_APP_SHOW_FUTURE === "true";

const urlParams = new URLSearchParams(window.location.search);
const showFuture = urlParams.get("SHOW_FUTURE");

if (showFuture) {
  SHOW_FUTURE = true;
}

/* 
  This is the terms edit page route, it's shared across all layouts 
  because it depends on the email which is provided through the env file. 
*/

/* OTHER NORMAL ROUTES */

const routes = [
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/General/Logout.vue"),
    beforeEnter(to, from, next) {
      next();
    },
  },

  {
    path: "/login/:accessToken?/:allocatedToken?/:type?",
    name: "Login",
    component: LoginPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/signup/invitation/:invitationToken?",
    name: "InvitationSignup",
    component: SignupPage,
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");
      if (userInfo && userInfo.user && !userInfo.user.password_updated) {
        // added this check for logout existing user and can auto login after reset-password
        deleteUserInformationAndToken();
      }
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/signup",
    name: "Signup",
    component: SignupPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        if (hasBrokerWorkbenchUrl()) {
          next("/broker/signup");
        } else {
          next();
        }
      }
    },
  },

  {
    path: "/download/policy/:handle",
    name: "DownloadPolicy",
    component: () => import(/* webpackChunkName: "DownloadPolicy" */ "../views/DownloadPolicy/DownloadPolicy.vue"),
    beforeEnter(to, from, next) {
      const isValid = checkValidity();

      if (!isValid) {
        localService.setItem("s2rDefaultRedirectUrl", to.fullPath);
        return next("/login");
      }

      return next();
    },
  },

  {
    path: "/declined/:token",
    name: "Declined",
    component: () => import(/* webpackChunkName: "DeclinedPage" */ "../views/Client/CyberCoverage/Declined.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        deleteUserInformationAndToken();
        next();
      } else {
        next();
      }
    },
  },

  {
    path: "/signon",
    name: "Signon",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/General/Signon.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/privacy-exposure",
    component: JlpcSignupPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/privacy-exposure/confirmation/:email/:confirmed?",
    component: JlpcConfirmationPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/privacy-exposure/download-report",
    name: "JLPC Reports Download",
    params: { hideSideBar: true },
    component: JlpcReportsDownloadPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/confirm-email/:token",
    component: JlpcThanknYouPage,
    beforeEnter(to, from, next) {
      if (!isJLPCSubDomain()) {
        next("/");
      }
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/broker/signup/:invitationToken?/:userDetails?",
    name: "BrokerSignupPage",
    component: BrokerSignupPage,
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");

      if (userInfo && userInfo.user && !userInfo.user.password_updated) {
        // added this check for logout existing user and can auto login after reset-password
        deleteUserInformationAndToken();
      }

      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/wholesaler/signup/:invitationToken?/:userDetails?",
    name: "WholesalerSignupPage",
    component: BrokerSignupPage,
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");

      if (userInfo && userInfo.user && !userInfo.user.password_updated) {
        // added this check for logout existing user and can auto login after reset-password
        deleteUserInformationAndToken();
      }

      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/broker/signup/:userType/:invitationToken?/:userDetails?",
    name: "BrokerSignupPageUserType",
    component: BrokerSignupPage,
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");

      if (userInfo && userInfo.user && !userInfo.user.password_updated) {
        // added this check for logout existing user and can auto login after reset-password
        deleteUserInformationAndToken();
      }

      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/signup/thank-you/:email/:invited?",
    name: "Signup Thanks",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/General/SignupThankYou.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: SubscriptionPage,
    beforeEnter(to, from, next) {
      let userDetails = getUserInformationAndToken().userInfo;
      if (checkValidity() && userDetails.user.payment_complete == 1) {
        next(from.path);
      } else {
        next();
      }
    },
  },

  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next();
      } else {
        next(from.path);
      }
    },
  },

  {
    path: "/reset-password/:token/:uid",
    name: "Reset Password",
    component: () => import("../views/General/ResetPassword.vue"),
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");
      if (userInfo && userInfo.user && !userInfo.user.password_updated) {
        // added this check for logout existing user and can auto login after reset-password
        deleteUserInformationAndToken();
      }
      if (checkValidity()) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    beforeEnter(to, from, next) {
      /*
        This will run if user is not logged in and
        tries to go to an authenticated page.
      */

      if (!checkValidity()) {
        const isSBADomain = isSBA();

        if (isSBADomain) {
          return next("/alpha/single/step/1");
        }
        return next("/login");
      }

      //Remove extra property from localstorage if any like for reset-password
      let userInfo = localService.getItem("userInfo");
      if (userInfo && userInfo.user && userInfo.user.password_updated) {
        // that key was added for logout existing user and can auto login after reset-password
        delete userInfo.user.password_updated;
        localService.setItem("userInfo", userInfo);
      }
      /*
        This will run when both the above conditions
        are satisfied and take the user to it's specified page.
      */
      switch (checkRole()) {
        case "admin":
          if (isJLPCSubDomain()) {
            redirectUserFromJlpcToMain();
            return next("/logout");
          }
          return next("/admin/companies");
        case "salesperson":
          if (!didUserAcceptTerms()) {
            return next("/terms");
          }

          if (isJLPCSubDomain()) {
            redirectUserFromJlpcToMain();
            return next("/logout");
          }
          return next("/salesperson/add-client");
        case "client":
          if (!didUserAcceptTerms()) {
            return next("/terms");
          }

          let userDetails = getUserInformationAndToken().userInfo;

          if (isJLPCSubDomain()) {
            if (!userDetails?.user?.is_jackson_lewis_client) {
              redirectUserFromJlpcToMain();
              return next("/logout");
            }
            return next("/user/privacy-exposure/report");
          }

          // if (userDetails.user && userDetails.user.client_role == 'admin' && !userDetails.user.company_setup_guide_started) {
          //   return next("/user/welcome");
          // }

          if (!didUserAcceptTerms()) {
            return next("/terms");
          }

          if (userDetails.user.org_type == "legacy") {
            return next("/user/manage-files");
          } else {
            return next("/user/home");
            // return next("/user/company-setup");
          }
        case "broker":
          return handleBrokerAndWholesaler();

        case "wholesaler":
          return handleBrokerAndWholesaler();
      }

      function handleBrokerAndWholesaler() {
        // FOR NEW MSHIFT USERS
        const isMshiftUser = userInfo?.user.is_mshift_organization;
        const hasAcceptedTerms = userInfo?.user.terms_accepted;
        if (isMshiftUser && !hasAcceptedTerms) {
          return next("/broker/members");
        }

        // NORMAL EXISTING BROKER FLOW
        if (!didUserAcceptTerms()) {
          return next("/terms");
        }

        if (isJLPCSubDomain()) {
          if (!hasJLPCBrokerId()) {
            redirectUserFromJlpcToMain();
            return next("/logout");
          }
          return next("/broker/reports-upload");
        }

        if (isInsuretrust()) {
          return next("/broker/risk-dashboard");
        }

        return next("/broker/members");
      }
    },
  },

  {
    path: "*",
    component: () => import(/* webpackChunkName: "Main" */ "../views/404/index.vue"),
  },

  /* 
    Admins Layout
  */
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "Admin" */ "../layout/Admin.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      if (!checkValidity()) {
        return next("/login");
      }

      if (checkRole() === "admin") {
        if (!didUserAcceptTerms()) {
          return next("/terms");
        }
        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "companies",
        name: "Companies",
        meta: { requiresAuth: true, id: "companies" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/CustomerPipeline.vue"),
      },
      {
        path: "audit-master/:auditId?/:controlId?/:facetId?",
        name: "Audit Master",
        meta: { requiresAuth: true, id: "audit-master" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/AuditMaster/Index.vue"),
      },
      {
        path: "risk-control-master",
        name: "Risk Control Master",
        meta: { requiresAuth: true, id: "risk-control-master" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/RiskControlMaster.vue"),
      },
      {
        path: "scope-master",
        name: "Scope Master",
        meta: { requiresAuth: true, id: "scope-master" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/ScopeMaster.vue"),
      },
      {
        path: "add-salesperson",
        name: "Sales Management",
        meta: { requiresAuth: true, id: "sales-management" },
        component: () =>
          import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/Salesperson/CreateSalesperson.vue"),
      },
      {
        path: "view-all-salespersons",
        name: "See all salespersons",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ViewAllSalespersons" */ "../views/Admin/Salesperson/ViewAll.vue"),
      },
      {
        path: "view-all-clients",
        name: "See all clients (Admin)",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ViewAllClients" */ "../views/Admin/Salesperson/ViewAllClients.vue"),
      },
      {
        path: "view-files/:email/:entity_id",
        name: "View Files",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ViewFiles" */ "../views/Admin/Clients/SubmittedFiles.vue"),
      },
      {
        path: "brokerages",
        name: "Brokerages",
        meta: { requiresAuth: true, id: "brokerages-management" },
        component: () => import("../views/Admin/Brokerages/Index.vue"),
      },
      {
        path: "brokers",
        name: "Brokers",
        meta: { requiresAuth: true, id: "brokers-management" },
        component: () => import("../views/Admin/Brokers/Index.vue"),
      },
      {
        path: "mfa",
        name: "Admin MFA Setup",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "MfaSetup" */ "../views/General/MfaSetup.vue"),
      },
      // CWB Instance View
      {
        path: ":orgId/user/home",
        name: "CWB Client Home",
        meta: { requiresAuth: true },
        component: () => import("../views/Client/Home/Index.vue"),
      },
      {
        path: ":orgId/user/company-setup",
        name: "CWB Instance Company Setup",
        meta: { requiresAuth: true },
        component: () => import("../views/Client/CompanySetup/Index.vue"),
      },
      {
        path: ":orgId/user/company-setup/:stage/:step",
        name: "CWB Instance Company Build Team",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/CompanySetup/Stages.vue"),
      },
      {
        path: ":orgId/user/add-user",
        name: "CWB Instance Create User",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          next();
        },
        component: () => import("../views/Client/User/CreateUser.vue"),
      },
      {
        path: ":orgId/user/view-all-users",
        name: "CWB Instance See all users",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ViewAllUsers" */ "../views/Client/User/ViewAllUsers.vue"),
      },
      {
        path: ":orgId/user/audit-results",
        name: "CWB Instance Audit Results",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "ManageFiles" */ "../views/Client/AuditResults.vue"),
      },
      {
        path: ":orgId/user/audit-report",
        name: "CWB Instance Audit Report",
        meta: { requiresAuth: true, id: "reports" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "ManageFiles" */ "../views/Client/AuditReport.vue"),
      },
      {
        path: ":orgId/user/manage-files",
        name: "CWB Instance Manage Files",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "SubmitFiles" */ "../views/Client/ManageFiles.vue"),
      },
      {
        path: ":orgId/user/verification-workflow",
        name: "CWB Instance Verification Workflow",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/VerificationWorkflow.vue"),
      },
      {
        path: ":orgId/user/primary-contact",
        name: "CWB Instance Primary Contact",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/PrimaryContact.vue"),
      },
      {
        path: ":orgId/user/readiness-checklist",
        name: "CWB Instance Readiness Checklist",
        meta: { requiresAuth: true, id: "workbench" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/ReadinessChecklist.vue"),
      },
      {
        path: ":orgId/user/enrichment-workflow",
        name: "CWB Instance Enrichment Workflow",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/EnrichmentWorkflow.vue"),
      },
      {
        path: ":orgId/user/digital-asset",
        name: "CWB Instance Digital Asset",
        meta: { requiresAuth: true, id: "digital-asset" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/DigitalAsset.vue"),
      },
      {
        path: ":orgId/user/threat-vulnerability",
        name: "CWB Instance ThreatVulnerability",
        meta: { requiresAuth: true, id: "threat-vulnerability" },
        component: () =>
          import(/* webpackChunkName: "ThreatVulnerability" */ "../views/ThreatVulnerability/ThreatVulnerability.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: ":orgId/user/privacy-exposure",
        name: "CWB Instance PrivacyExposure",
        meta: { requiresAuth: true, id: "privacy-exposur" },
        component: () =>
          import(/* webpackChunkName: "PrivacyExposure" */ "../views/PrivacyExposure/PrivacyExposure.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: ":orgId?/user/insurability-assessment",
        name: "CWB Instance InsurabilityAssessment",
        meta: { requiresAuth: true, id: "insurability-assessment" },
        component: () =>
          import(
            /* webpackChunkName: "InsurabilityAssessment" */ "../views/InsurabilityAssessment/InsurabilityAssesment.vue"
          ),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: ":orgId/user/applications",
        name: "CWB Instance Applications",
        meta: { requiresAuth: true, id: "applications" },
        component: () => import(/* webpackChunkName: "Applications" */ "../views/Applications/Applications.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: "analytics",
        name: "Admin Analytics",
        meta: { requiresAuth: true, id: "analytics" },
        component: () => import(/* webpackChunkName: "Applications" */ "../views/Admin/Analytics/Index.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: "frp_import",
        name: "FRP Import",
        meta: { requiresAuth: true, id: "frp_import" },
        component: () => import(/* webpackChunkName: "Applications" */ "../views/Admin/CsvImport.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
      {
        path: "analytics/:analyticsId",
        name: "Admin Analytics Details",
        meta: { requiresAuth: true, id: "analytics" },
        component: () => import(/* webpackChunkName: "Applications" */ "../views/Admin/Analytics/AnalyticsDetails.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },
    ],
  },

  /* 
    Salesperson Layout 
  */
  {
    path: "/salesperson",
    component: () => import(/* webpackChunkName: "Salesperson" */ "../layout/SalesPerson.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      if (!checkValidity()) {
        return next("/login");
      }
      if (checkRole() === "salesperson") {
        if (!didUserAcceptTerms()) {
          return next("/terms");
        }
        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "add-client",
        name: "Create Client",
        meta: { requiresAuth: true, id: "clients" },
        component: () => import(/* webpackChunkName: "CreateClient" */ "../views/Salesperson/Client/CreateClient.vue"),
      },
      {
        path: "view-all-clients",
        name: "See all clients (Salesperson)",
        meta: { requiresAuth: true, id: "view-client-files" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Salesperson/Client/ViewAll.vue"),
      },
      {
        path: "manage-client-files",
        name: "Manage Client Files",
        meta: { requiresAuth: true, id: "manage-client-files" },
        component: () =>
          import(/* webpackChunkName: "ClientFiles" */ "../views/Salesperson/ClientFiles/ClientFiles.vue"),
      },
      {
        path: "manage-bulk-purchases",
        name: "Manage Bulk Purchases",
        meta: { requiresAuth: true, id: "manage-bulk-purchases" },
        component: () =>
          import(/* webpackChunkName: "SubmitFiles" */ "../views/Salesperson/BulkPurchases/ManageBulkPurchases.vue"),
      },
      {
        path: "view-all-brokers",
        name: "See all brokers (Salesperson)",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "CreateSalesperson" */ "../views/Salesperson/BulkPurchases/ViewAll.vue"),
      },
      {
        path: "mfa",
        name: "Salesperson MFA Setup",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "MfaSetup" */ "../views/General/MfaSetup.vue"),
      },
    ],
  },

  /* 
    User Layout 
  */
  {
    path: "/user",
    component: () => import(/* webpackChunkName: "User" */ "../layout/User.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      if (!checkValidity()) {
        return next("/login");
      }

      if (checkRole() === "client" || checkRole() === "salesperson") {
        const defaultRedirectUrl = localService.getItem("s2rDefaultRedirectUrl");

        if (defaultRedirectUrl) {
          localService.removeItem("s2rDefaultRedirectUrl");
          return next(defaultRedirectUrl);
        }

        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "getting-started/:eventType?",
        name: "Getting Started",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else

          if (didUserCompletedGetStarted()) {
            return next("/");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/GettingStarted.vue"),
      },
      {
        path: "company-setup",
        name: "Company Setup",
        meta: { requiresAuth: true, id: "company-setup-guide" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else

          // if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/CompanySetup/Index.vue"),
      },
      {
        path: "indication-setup/:status?",
        name: "Company Indication Setup",
        meta: { requiresAuth: true, id: "company-indication-setup-guide" },
        beforeEnter(to, from, next) {
          next();
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/IndicationSetup/Index.vue"),
      },
      {
        path: "indication-setup/:stage/:step",
        name: "Company Indication Build Team",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          next();
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/IndicationSetup/Stages.vue"),
      },
      {
        path: "quick-start",
        name: "Quick Start",
        meta: { requiresAuth: true, id: "quick-start" },
        hidden: true,
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else

          // if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/QuickStart/Index.vue"),
      },
      {
        path: "home",
        name: "ClientHome",
        meta: { requiresAuth: true, id: "client-home" },
        beforeEnter(to, from, next) {
          next();
        },
        component: () => import("../views/Client/Home/Index.vue"),
      },
      {
        path: "subscription",
        name: "User-Subscription",
        component: ClientSubscriptionPage,
        beforeEnter(to, from, next) {
          // let userDetails = getUserInformationAndToken().userInfo;
          // if (checkValidity() && userDetails.user.payment_complete == 1) {
          //   next(from.path);
          // } else {
          //   next();
          // }
          next();
        },
      },
      {
        path: "company-setup/:stage/:step",
        name: "Company Build Team",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next('/user/terms');
          // } else

          // if (!didUserCompletedGetStarted()) {
          //   return next('/user/getting-started');
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/CompanySetup/Stages.vue"),
      },
      {
        path: "quick-start/:stage/:step",
        name: "Quick Start Stage",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next('/user/terms');
          // } else

          // if (!didUserCompletedGetStarted()) {
          //   return next('/user/getting-started');
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/QuickStart/Stages.vue"),
      },
      {
        path: "add-user",
        name: "Create User",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/User/CreateUser.vue"),
      },
      {
        path: "view-all-users",
        name: "See all users",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ViewAllUsers" */ "../views/Client/User/ViewAllUsers.vue"),
      },
      {
        path: "audit-results",
        name: "Audit Results",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "ManageFiles" */ "../views/Client/AuditResults.vue"),
      },
      {
        path: "audit-report",
        name: "Audit Report",
        meta: { requiresAuth: true, id: "reports" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "ManageFiles" */ "../views/Client/AuditReport.vue"),
      },
      {
        path: "manage-files",
        name: "Manage Files",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "SubmitFiles" */ "../views/Client/ManageFiles.vue"),
      },
      {
        path: "verification-workflow",
        name: "Verification Workflow",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/VerificationWorkflow.vue"),
      },
      {
        path: "primary-contact",
        name: "Primary Contact",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/PrimaryContact.vue"),
      },
      {
        path: "readiness-checklist",
        name: "Readiness Checklist",
        meta: { requiresAuth: true, id: "workbench" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Client/ReadinessChecklist.vue"),
      },
      {
        path: "enrichment-workflow",
        name: "Enrichment Workflow",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/EnrichmentWorkflow.vue"),
      },
      {
        path: "digital-asset/:asset_id?",
        name: "Digital Asset",
        meta: { requiresAuth: true, id: "digital-asset" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/DigitalAsset.vue"),
      },
      // {
      //   path: "controls-certification",
      //   name: "Controls Certification",
      //   meta: { requiresAuth: true },
      //   beforeEnter(to, from, next) {
      //     // if (!didUserAcceptTerms()) {
      //     //   return next("/user/terms");
      //     // } else if (!didUserCompletedGetStarted()) {
      //     //   return next("/user/getting-started");
      //     // } else {
      //     next();
      //     // }
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "DigitalAssets" */ "../views/Client/Audit/ControlsCertification.vue"
      //     ),
      // },
      {
        path: "company",
        name: "Company",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/Company.vue"),
      },
      {
        path: "third-party-audit",
        name: "Third Party Audit(s)",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/ThirdPartyAudit.vue"),
      },
      {
        path: "remediation-planning",
        name: "Remediation Planning",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/RemediationPlanning.vue"),
      },
      {
        path: "reporting",
        name: "Reporting",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/user/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/user/getting-started");
          // } else {
          next();
          // }
        },
        component: () => import(/* webpackChunkName: "DigitalAssets" */ "../views/Client/Audit/Reporting.vue"),
      },
      {
        path: "mfa",
        name: "User MFA Setup",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "MfaSetup" */ "../views/General/MfaSetup.vue"),
      },
      {
        path: "subscription-builder/:step",
        name: "Subscription Builder",
        meta: { requiresAuth: true, id: "subscrption-builder" },
        component: () => import("../views/SubscriptionBuilder/SubscriptionBuilder.vue"),
      },
      {
        path: "privacy-exposure/report",
        name: "JL View Report",
        meta: { requiresAuth: true, id: "privacy-exposure-report" },
        component: () => import(/* webpackChunkName: "SubscriptionBuilder" */ "../views/Jlpc/ReportsView.vue"),
        beforeEnter(to, from, next) {
          if (!isJLPCSubDomain()) {
            next("/");
          }
          next();
        },
      },

      {
        path: "applications",
        name: "Applications",
        meta: { requiresAuth: true, id: "applications" },
        component: () => import(/* webpackChunkName: "Applications" */ "../views/Applications/Applications.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },

      {
        path: "privacy-exposure",
        name: "PrivacyExposure",
        meta: { requiresAuth: true, id: "privacy-exposur" },
        component: () =>
          import(/* webpackChunkName: "PrivacyExposure" */ "../views/PrivacyExposure/PrivacyExposure.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },

      {
        path: "threat-vulnerability",
        name: "ThreatVulnerability",
        meta: { requiresAuth: true, id: "threat-vulnerability" },
        component: () =>
          import(/* webpackChunkName: "ThreatVulnerability" */ "../views/ThreatVulnerability/ThreatVulnerability.vue"),
        beforeEnter(to, from, next) {
          next();
        },
      },

      {
        path: "insurability-assessment",
        name: "InsurabilityAssessment",
        meta: { requiresAuth: true, id: "insurability-assessment" },
        component: () =>
          import(
            /* webpackChunkName: "InsurabilityAssessment" */ "../views/InsurabilityAssessment/InsurabilityAssesment.vue"
          ),
        beforeEnter(to, from, next) {
          next();
        },
      },
    ],
  },

  /* 
    Broker Layout 
  */
  {
    path: "/broker",
    component: () => import(/* webpackChunkName: "User" */ "../layout/Broker.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const isValid = checkValidity();
      const role = checkRole();

      if (!isValid) {
        localService.setItem("s2rDefaultRedirectUrl", to.fullPath);
        return next("/login");
      }

      if (["broker", "salesperson", "wholesaler"].includes(role)) {
        const defaultRedirectUrl = localService.getItem("s2rDefaultRedirectUrl");
        if (defaultRedirectUrl) {
          localService.removeItem("s2rDefaultRedirectUrl");
          return next(defaultRedirectUrl);
        }
        return next();
      }

      return next("/");
    },
    children: [
      {
        path: "getting-started",
        name: "Broker Getting Started",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/broker/terms");
          // } else if (didUserCompletedGetStarted()) {
          //   return next("/");
          // }
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/OnboardCarousel.vue"),
      },
      {
        path: "allowance",
        name: "Allowance",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/broker/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/broker/getting-started");
          // } else {
          // next();
          // }
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/Allowance.vue"),
      },
      {
        path: "purchased-token",
        name: "Purchased Tokens",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/broker/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/broker/getting-started");
          // } else {
          // next();
          // }
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/PurchasedToken.vue"),
      },
      // {
      //   path: "allocated-token",
      //   name: "Allocated Tokens",
      //   meta: { requiresAuth: true, id: "credits" },
      //   beforeEnter(to, from, next) {
      //     // if (!didUserAcceptTerms()) {
      //     //   return next("/broker/terms");
      //     // } else if (!didUserCompletedGetStarted()) {
      //     //   return next("/broker/getting-started");
      //     // } else {
      //     // next();
      //     // }
      //     if (isJLPCSubDomain() && hasJLPCBrokerId()) {
      //       next('/broker/reports-upload');
      //     } else {
      //       next();
      //     }
      //   },
      //   component: () =>
      //     import(
      //         /* webpackChunkName: "CreateUser" */ "../views/Broker/AllocatedToken.vue"
      //     ),
      // },
      {
        path: "risk-dashboard",
        name: "Risk Dashboard",
        meta: { requiresAuth: true, id: "risk-dashboard" },
        beforeEnter(to, from, next) {
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/Wholesaler/RiskDashboard.vue"),
      },
      {
        path: "members",
        name: "Members",
        meta: { requiresAuth: true, id: "readiness-planogram" },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/broker/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/broker/getting-started");
          // } else {
          // next();
          // }
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/Members.vue"),
      },
      {
        path: "members/import",
        name: "Import Customers",
        meta: { requiresAuth: true },
        beforeEnter(to, from, next) {
          // if (!didUserAcceptTerms()) {
          //   return next("/broker/terms");
          // } else if (!didUserCompletedGetStarted()) {
          //   return next("/broker/getting-started");
          // } else {
          // next();
          // }
          if (isJLPCSubDomain() && hasJLPCBrokerId()) {
            next("/broker/reports-upload");
          } else {
            next();
          }
        },
        component: () => import(/* webpackChunkName: "CreateUser" */ "../views/Broker/ImportMembers.vue"),
      },
      {
        path: "mfa",
        name: "Broker MFA Setup",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "MfaSetup" */ "../views/General/MfaSetup.vue"),
      },
      {
        path: "reports-upload",
        name: "JLPC Reports Upload",
        params: { hideSideBar: true },
        component: JlpcReportsUploadPage,
        beforeEnter(to, from, next) {
          if (!isJLPCSubDomain()) {
            next("/");
          }
          next();
        },
      },
    ],
  },

  // ALPHA MODULE

  {
    path: "/alpha/signup",
    name: "AlphaBrokerSignupPage",
    component: BrokerSignupPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next("/alpha/step/1");
      } else {
        next();
      }
    },
  },

  {
    path: "/alpha/signup/thank-you/:email?",
    name: "Signup Thanks Alpha",
    component: () => import(/* webpackChunkName: "Alpha-Signup" */ "../views/General/SignupThankYou.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next("/alpha/step/1");
      } else {
        next();
      }
    },
  },

  {
    path: "/alpha/step/:step",
    name: "AlphaBrokerStep",
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Alpha-Steps" */ "../views/Alpha/AlphaView.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next();
      } else {
        next(from.path);
      }
    },
  },

  // SINGLE PLAYER

  {
    path: "/alpha/single/step/:step",
    name: "AlphaBrokerSingleStep",
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Alpha-Single-Steps" */ "../views/Alpha/AlphaSingleView.vue"),
    beforeEnter(to, from, next) {
      next();
      // if (from.path === '/broker/signup') {
      //   next()
      // } else {
      //   next('/broker/signup')
      // }
    },
  },

  {
    path: "/alpha/single/meeting",
    name: "AlphaBrokerSingleMeeting",
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Alpha-Schedule" */ "../views/Alpha/ScheduleTime.vue"),
    beforeEnter(to, from, next) {
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

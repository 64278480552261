import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";
import axios from "../plugins/axios";

const brokerData = () => {
  return {
    selectedBrokerage: null,
    newBrokerage: "",
    selectedLocalOffice: null,
    newLocalOffice: "",
    brokerEmail: "",
    uploadedFiles: [],
    isCyberPrecheckBroker: false,
    shareBookAccounts: [{ email: "" }],
  };
};

const brokerageData = () => {
  return {
    name: "",
    primaryContact: "",
    mainOfficeAddress: {
      address: null,
      city: null,
      state: null,
      zipCode: null,
    },
    officePhone: null,
    logo: null,
    properties: [""],
  };
};

export default {
  namespaced: true,
  state: () => ({
    // COMPANIES PAGE
    organizationListLoading: false,
    organizationList: [],
    selectedCompanyFilters: [],
    selectedJoinedFilter: null,
    selectedLastActivityFilter: null,
    selectedStatusFilters: [],
    selectedBrokerageFilters: [],
    selectedView: "grid",
    brokerages: [],
    customerPipelinePanel: false,
    customerSource: null,
    selectedOrganization: null,
    organizationControlInformation: null,

    // BROKERS PAGE
    brokerList: [],
    brokerListLoading: false,
    brokerSidePanel: false,
    selectedBroker: null,
    selectedBrokerClients: [],
    selectedBrokerLocalOffice: null,
    addBrokerDialog: false,
    currentStep: 1,
    totalSteps: 4,
    brokerageLocalOffices: [],
    brokerData: brokerData(),
    addContributorDialog: false,
    locatedClient: null,
    colleagueList: [],
    contributorsList: [],
    addBrokerLocalOfficeDialog: false,

    // BROKERAGES PAGE
    brokerageData: brokerageData(),
    editBrokerage: false,
    brokeragesList: [],
    brokerageSidePanel: false,
    selectedBrokerage: null,
    addBrokerageDialog: false,
    addEditLocalOfficeDialog: false,
    localOfficeData: null,
    selectedBrokerageScreenFilter: null,

    // ANALYTICS PAGE
    ownersList: [],
    ownerListLoading: false,
    selectedOwnerFilters: [],
    analyticsList: [],
    analyticsListLoading: false,
    selectedAnalytics: null,
    selectedCohortDetails: null,
  }),

  mutations: {
    updateField,

    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    resetAddBrokerData(state) {
      state.brokerData = brokerData();
    },

    resetAddBrokerageData(state) {
      state.editBrokerage = false;
      state.brokerageData = brokerageData();
    },

    deleteBroker(state, entityId) {
      let index = state.brokerList.findIndex((e) => e.entity_id == entityId);
      state.brokerList.splice(index, 1);
    },

    updateBroker(state, data) {
      let index = state.brokerList.findIndex((e) => e.entity_id == data.entity_id);
      if (index < 0) {
        state.brokerList.push(data);
      } else {
        vm.$set(state.brokerList, index, data);
      }
    },

    updateSelectedBroker(state, data) {
      // for update data if side panel open for display selected broker details
      if (state.selectedBroker?.entity_id == data.broker?.entity_id) {
        state.selectedBroker = data.broker;
        state.selectedBrokerClients = data.members;
        state.selectedBrokerLocalOffice = data.local_office;
      }
    },

    setBrokerageLocalOffices(state, data) {
      state.brokerageLocalOffices = data;
    },

    updateBrokeragesList(state, data) { // add pending data which is getting in batches from api
      state.brokeragesList = [...state.brokeragesList, ...data];
    },

    updateBrokerageList(state, data) {
      let index = state.brokeragesList.findIndex((e) => e.entity_id == data.entity_id);
      if (index < 0) {
        state.brokeragesList.push(data);
      } else {
        vm.$set(state.brokeragesList, index, data);
        state.selectedBrokerage = data;
      }
    },

    setColleagueList(state, payload) {
      state.colleagueList = payload;
    },

    setContributorsList(state, payload) {
      state.contributorsList = payload;
    },

    addBrokerageLocalOffice(state, data) {
      if (state.selectedBrokerage) {
        state.selectedBrokerage?.local_offices?.push(data);
      }
    },

    updateBrokerageLocalOffice(state, data) {
      if (state.selectedBrokerage) {
        let index = state.selectedBrokerage?.local_offices?.findIndex((e) => e.entity_id == data.entity_id);
        if (index > -1) {
          vm.$set(state.selectedBrokerage.local_offices, index, data);
        }
      }
    },

    updateOrganizationList(state, data) {
      const brokerageDict = {};
      const organizationList = [];
      data.results.forEach((el) => {
        const { brokerages, ...rest } = el;
        const brokerageIds = brokerages.map((brokerage) => {
          const { organization_id, organization_name } = brokerage;
          brokerageDict[organization_id] = { label: organization_name, id: organization_id };
          return organization_id;
        });
        organizationList.push({ ...rest, brokerages, brokerageIds });
      });
      state.organizationList = [...state.organizationList, ...organizationList];
      state.brokerages = [...state.brokerages, ...Object.values(brokerageDict)];
    },

    updateBrokersList(state, data) {
      state.brokerList = [...state.brokerList, ...data];
    }
  },

  getters: {
    getField,
    organizationList: (state) => state.organizationList,
  },

  actions: {
    async getOrganizations({ commit }, payload) {
      try {
        let url = '/organization_information';
        if(payload && payload.page && payload.page_size) {
          url += `?page=${payload.page}&page_size=${payload.page_size}`
        }
        if (!payload.fetch_more) {
          commit("setState", {key: "organizationListLoading", value: true});
        }
        const { data } = await axios.get(url);
        if (data.success) {
          commit("updateOrganizationList", data);
        }
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "organizationListLoading", value: false });
      }
    },

    async deleteOrganization({ commit, dispatch }, payload) {
      const { data } = await axios.delete(`/organization/${payload.entity_id}`);
      if (!data.success) return;
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      return data;
    },

    async getBrokersList({ commit }, payload) {
      let url = '/admin/broker';
      if(payload && payload.page && payload.page_size) {
        url += `?page=${payload.page}&page_size=${payload.page_size}`
      }
      if (!payload.fetch_more) {
        commit("setState", {key: "brokerListLoading", value: true});
      }
      const { data } = await axios.get(url);
      if (!data.success) {
        commit("setState", { key: "brokerListLoading", value: false });
        return;
      }
      commit("updateBrokersList", data.brokers);
      commit("setState", { key: "brokerListLoading", value: false });
      return data;
    },

    async getBrokerDetailsByEntity({ commit }, entityId) {
      const { data } = await axios.get(`/admin/broker/${entityId}`);
      if (!data.success) {
        return;
      }
      return data;
    },

    async deleteBrokerAccount({ commit, dispatch }, entityId) {
      commit("deleteBroker", entityId);
      const { data } = await axios.delete(`/admin/broker/${entityId}`);
      if (!data.success) {
        return;
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
    },

    async checkIfUserExists({ dispatch }, payload) {
      const { data } = await axios.post(`/admin/check_user`, payload);
      if (!data.success) return;
      return data;
    },

    async localOfficeBrokerage({ commit }, organization_id) {
      const { data } = await axios.get(`/local_office/organization/${organization_id}`);
      if (!data.success) return;
      commit("setBrokerageLocalOffices", data.local_offices);
    },

    async createBroker({ dispatch }, payload) {
      const { data } = await axios.post(`/admin/broker`, payload);
      if (!data.success) return data;
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      return data;
    },

    async getCustomerSource({ dispatch, commit }, organizationId) {
      const { data } = await axios.get(`/organization/source/${organizationId}`);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      commit("setState", { key: "customerSource", value: data });
      return data;
    },

    async fetchOrganizationControlInformation({ dispatch, commit }, organizationId) {
      const { data } = await axios.get(`/organization_control_information/${organizationId}`);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      commit("setState", { key: "organizationControlInformation", value: data.results });
      return data;
    },

    async importCompanies({ dispatch }, payload) {
      const { data } = await axios.post("/admin/broker/import_companies", payload);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      return data;
    },

    async getBrokerageData({ dispatch, commit }, payload) {
      let url = '/admin/brokerage';
      if(payload && payload.page && payload.page_size) {
        url += `?page=${payload.page}&page_size=${payload.page_size}`
      }
      if (!payload.fetch_more) {
        commit("startLoading", null, { root: true });
      }
      const { data } = await axios.get(url);

      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      commit("updateBrokeragesList", data.brokerages);
      return data;
    },

    async createBrokerage({ dispatch, commit }, payload) {
      const { data } = await axios.post("/admin/brokerage", payload);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
    },

    async updateBrokerage({ dispatch, commit }, payload) {
      let payloadData = Object.assign({}, payload);
      const organizationId = payload.organization_id;
      delete payload.organization_id;
      const { data } = await axios.post(`/admin/brokerage/${organizationId}`, payloadData);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
    },

    async getBrokerageDetailsByOrgId({ dispatch, commit }, organizationId) {
      const { data } = await axios.get(`/admin/brokerage/${organizationId}`);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      return data;
    },

    async getLocalOfficeDetailsById({ dispatch, commit }, entityId) {
      const { data } = await axios.get(`/admin/local_office_setup/${entityId}`);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      return data.local_office_setup_data;
    },

    async updateLocalOffice({ dispatch, commit }, payload) {
      let payloadData = Object.assign({}, payload);
      const entityId = payloadData.entity_id;
      delete payloadData.entity_id;
      const { data } = await axios.post(`/admin/local_office_setup/${entityId}`, payloadData);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      commit("updateBrokerageLocalOffice", data.local_office_setup_data);
      return data;
    },

    async addLocalOfficeByBrokerageId({ dispatch, commit }, payload) {
      const { data } = await axios.post(`/admin/brokerage/${payload.brokerageId}/local_office`, payload.data);
      if (!data.success) {
        return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
      }
      dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      commit("addBrokerageLocalOffice", data.local_office_setup_data);
      return data;
    },

    async getBrokerContributors({ commit }, brokerId) {
      try {
        const { data } = await axios.get(`/admin/broker/${brokerId}/contributor`);
        commit("setContributorsList", data.broker_contributors);
        return data;
      } catch (error) {
        console.log("An error occurred while fetching broker contributors:", error);
      }
    },

    async addBrokerContributor({ dispatch }, payload) {
      try {
        const { data } = await axios.post(`/admin/broker/${payload.brokerId}/contributor`, payload.data);
        if (!data.success) {
          return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
        }
        return data;
      } catch (error) {
        console.log("An error occurred while adding broker contributor:", error);
      }
    },

    async deleteBrokerContributor({ dispatch }, payload) {
      try {
        const endpoint = `/admin/broker/${payload.brokerId}/contributor/${payload.contributorBrokerId}`;
        const { data } = await axios.delete(endpoint);

        if (!data.success) {
          return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
        }

        const message = {
          messageType: data.success ? "success" : "error",
          messageTitle: data.success ? "Success" : "Error",
          ...data,
        };

        dispatch("showMessage", message, { root: true });
      } catch (error) {
        console.log("An error occurred while deleting broker contributor:", error);
      }
    },

    async getColleagueList({ commit }, brokerId) {
      try {
        const { data } = await axios.get(`/admin/broker/${brokerId}/colleague`);
        commit("setColleagueList", data.brokers);
        return data;
      } catch (error) {
        console.log("An error occurred while fetching colleague list:", error);
      }
    },

    async inviteBroker({ dispatch }, payload) {
      try {
        const { data } = await axios.post(`/admin/invite_broker`, payload);
        if (!data.success) return false;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return true;
      } catch (error) {
        console.log("An error occurred while fetching colleague list:", error);
      }
    },

    async changeBrokerLocalOffice({ dispatch }, payload) {
      try {
        const { data } = await axios.post(`/admin/broker/${payload.brokerId}/local_office`, payload.data);
        if (!data.success) {
          return dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
        }
        return data;
      } catch (error) {
        console.log("An error occurred while changing broker local office:", error);
      }
    },

    async getOwnersList({ commit }, payload) {
      try {
        const data = {
          success: true,
          owners: [
            {
              id: 12345,
              name: "Chapin Smith",
            },
            {
              id: 12346,
              name: "Jasmine Romo",
            },
          ],
        };
        commit("setState", { key: "ownersList", value: data.owners });
      } catch (error) {
        console.log("An error occurred while changing broker local office:", error);
      }
    },
    async getAnalyticsList({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "analyticsListLoading", value: true });
        const data = {
          success: true,
          analytics: [
            {
              entity_id: "102dd7ec6a4574ac09c655da142e40da1234",
              title: "Broker PMF",
              description: "Broker invites two colleagues, two clients, and makes a market submission.",
              status: 28,
              test_length: 60,
              success_criteria: 80,
              owner: {
                entity_id: "12345",
                name: "Chapin Smith",
              },
              created: "2024-02-17T10:08:03",
              cohort_details: {
                year: "2023",
                details: [
                  {
                    month: "Jan",
                    30: {
                      status: 3,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: true,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 10,
                      details: [
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 12,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 16,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: true,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 18,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 20,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: true,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: false,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 23,
                      details: [
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    240: {
                      status: 25,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    270: {
                      status: 26,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: true,
                        },
                        {
                          user: "Liam Carter",
                          status_success: false,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    300: {
                      status: 31,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    330: {
                      status: 34,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    365: {
                      status: 36,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Feb",
                    30: {
                      status: 2,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 7,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 11,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 16,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 17,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 22,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 27,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    240: {
                      status: 34,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    270: {
                      status: 39,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    300: {
                      status: 41,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    330: {
                      status: 44,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Mar",
                    30: {
                      status: 3,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 15,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 19,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 24,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 28,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 37,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 41,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    240: {
                      status: 46,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    270: {
                      status: 53,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    300: {
                      status: 54,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Apr",
                    30: {
                      status: 5,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 11,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 14,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 22,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 27,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 29,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 36,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    240: {
                      status: 41,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    270: {
                      status: 50,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "May",
                    30: {
                      status: 4,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 22,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 25,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 33,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 43,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 50,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 56,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    240: {
                      status: 63,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Jun",
                    30: {
                      status: 3,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 39,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 43,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 50,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 55,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 58,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    210: {
                      status: 63,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Jul",
                    30: {
                      status: 5,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 40,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 44,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 52,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 54,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    180: {
                      status: 58,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Aug",
                    30: {
                      status: 7,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 56,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 60,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 68,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    150: {
                      status: 76,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Sep",
                    30: {
                      status: 12,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 77,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 79,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    120: {
                      status: 89,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Oct",
                    30: {
                      status: 6,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 81,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    90: {
                      status: 86,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Nov",
                    30: {
                      status: 9,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                    60: {
                      status: 92,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                  {
                    month: "Dec",
                    30: {
                      status: 9,
                      details: [
                        {
                          user: "Jasmine Romo",
                          status_success: false,
                        },
                        {
                          user: "Liam Carter",
                          status_success: true,
                        },
                        {
                          user: "Penelope Nguyen",
                          status_success: true,
                        },
                        {
                          user: "Xavier Patel",
                          status_success: true,
                        },
                        {
                          user: "Grace Thompson",
                          status_success: false,
                        },
                        {
                          user: "Elijah Khan",
                          status_success: true,
                        },
                        {
                          user: "Isabella Chang",
                          status_success: true,
                        },
                        {
                          user: "Noah Sullivan",
                          status_success: false,
                        },
                        {
                          user: "Ava Ramirez",
                          status_success: true,
                        },
                        {
                          user: "Lucas Walker",
                          status_success: false,
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        };
        commit("setState", { key: "analyticsList", value: data.analytics });
      } catch (error) {
        console.log("An error occurred while changing broker local office:", error);
      } finally {
        commit("setState", { key: "analyticsListLoading", value: false });
      }
    },
  },
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: () => ({
    fetchingInsurabilityReportData: false,
    insurabilityReports: {},
    insurabilityReportViewer: false,
    selectedInsurabilityReport: {},
    insurabilityReportOrganizationDetails: {},
    insurabilityReportData: {},
    unCategorizedInsurabilityReportData: {},
    categorizedInsurabilityReportData: {},
  }),

  mutations: {
    updateField,

    SET_INSURABILITY_REPORTS(state, payload) {
      state.insurabilityReports = payload;
    },

    SET_REPORT_ORGANIZATION_DETAILS(state, payload) {
      state.insurabilityReportOrganizationDetails = payload;
    },

    SET_REPORT_DATA(state, payload) {
      state.insurabilityReportData = payload;
    },

    UPDATE_REPORTS_DATA(state, payload) {
      const { insurabilityReports } = state;
      const year = payload.year;
      const entity_id = payload.entity_id;

      if (!Object.keys(insurabilityReports).length) {
        state.insurabilityReports = {
          [year]: [payload],
        };
      } else {
        const updated = insurabilityReports[year].map((el) =>
          el.entity_id === entity_id ? { ...el, ...payload } : el
        );
        state.insurabilityReports[year] = updated;
      }
    },
  },

  actions: {
    // GET INSURABILITY REPORT ITEMS
    async getInsurabilityReports({ commit }, organizationId = null) {
      try {
        let url = "/insurability_reports";
        if (organizationId) {
          url = `/insurability_reports/${organizationId}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_INSURABILITY_REPORTS", data.reports);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    // TRIGGER INSURABILITY REPORT
    async triggerInsurabilityReport({ commit, dispatch }, showSuccessMessage = true) {
      try {
        const { data } = await axios.post("/insurability_report/trigger");
        if (!data.success) return;

        if (showSuccessMessage)
          dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });

        return data;
      } catch (e) {
        console.log(e);
      }
    },

    // GET REPORT BY ID
    async getInsurabilityReport({ commit }, payload) {
      try {
        let url = `/insurability_report/${payload.entity_id}`;
        if (payload.organizationId) {
          url = `/insurability_report/${payload.entity_id}/${payload.organizationId}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_REPORT_DATA", data.report);
        commit("SET_REPORT_ORGANIZATION_DETAILS", data.organization_detail);
        return data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    getField,
  },
};

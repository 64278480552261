import versionInfo from '../version';

export default {
  computed: {
    versionInfo() {
      return versionInfo.version
    },
    showVersion(){
      return versionInfo.showVersion
    },
    termsVersion() {
      return versionInfo.termsVersion
    }
  }
}
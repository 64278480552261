import { getField, updateField } from "vuex-map-fields"
import axios from "../plugins/axios"
import localService from "@/services/localService";

export default {
  namespaced: true,

  state: () => ({
    showLocalOfficeDialog: false,
    currentStep: 0,
    localOfficeSetup: {
      brokerage: {
        main_brokerage_name: null,
        main_brokerage_website: null
      },
      office_details: {
        name: null,
        address: {
          address_line: null,
          city: null,
          state: null,
          zip_code: null
        },
        phone: null,
        website: null
      },
      team_members: []
    },
    localOfficeSetupData: null
  }),

  mutations: {
    updateField,

    setLocalOfficeSetup(state, payload) {
      state.localOfficeSetupData = payload;
    },

    updateLocalOfficeSetup(state, payload) {
      if(state.localOfficeSetupData?.entity_id == payload.entity_id) {
        state.localOfficeSetupData = payload;
      }
    }
  },

  actions: {
    async getLocalOfficeSetup({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/local_office");
        if (!data.success || !data.local_office_setup_data || !Object.keys(data.local_office_setup_data).length) return;
        commit('setLocalOfficeSetup', data.local_office_setup_data)
        return data.local_office_setup_data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async saveLocalOfficeSetup({ commit, dispatch, state }, payload) {
      try {
        const { data } = await axios.post("/local_office", {questionnaire: payload});
        if (!data.success) {
          return;
        }
        dispatch(
          "showMessage",
          { ...data, messageType: "success", messageTitle: "Success" },
          { root: true }
        );
        let userInfo = localService.getItem("userInfo");
        userInfo.user.local_office_id = data.local_office_setup_data?.entity_id;
        localService.setItem("userInfo", userInfo);
        commit(
          "login/setState",
          { key: "currentUser", value: userInfo },
          { root: true }
        );
        return data;
      } catch (e) {
        console.log(e);
      }
    }
  },

  getters: {
    getField,
  }
}
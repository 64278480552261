import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";
import localService from "@/services/localService";
import { vm } from "../main";

export default {
  namespaced: true,
  state: () => ({
    auditControls: {},
    auditControlsLoading: false,
    // controlCertificationDetailList: [],
    controlCertificationDetailListLoading: false,
    auditList: [],
    auditListLoading: false,
    auditAssessments: [],
    selectedControlCertification: null,
    selectedAuditAssessment: null,
    controlCertification: null,
    startLoading: false,
    auditData: null,
    isUploadingAudit: false,
  }),

  mutations: {
    updateField,

    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    updateAuditChecklistDelegate(state, payload) {
      let foundIndex = state.auditControls?.checklist?.findIndex((e) => e.entity_id == payload.delegation_id);
      if (foundIndex > -1) {
        state.auditControls.checklist[foundIndex].delegate_user_id = payload.user_id;
      }
    },

    updateAuditControlDelegate(state, payload) {
      let foundIndex = state.controlCertificationDetailList.findIndex((e) => e.entity_id == payload.delegation_id);
      vm.$set(state.controlCertificationDetailList[foundIndex], "delegated_user_id", payload.user_id);
    },

    setControlCertification(state, payload) {
      state.controlCertification = payload;
    },

    setAuditData(state, payload) {
      state.auditData = payload;
    },

    updateControlCertificationAnswer(state, answer) {
      // state.controlCertification.assessment has multiple controls
      // each control has multiple tests
      // each test has an answer, and we must find the answer
      // that matches answer.entity_id with test.answer_entity_id
      // and update the answer
      let controls = state.controlCertification.assessment.controls;
      outerLoop: for (let i = 0; controls.length; i++) {
        for (let j = 0; j < controls[i].tests.length; j++) {
          if (controls[i].tests[j].answer_entity_id == answer.entity_id) {
            controls[i].tests[j].answer = answer;
            break outerLoop;
          }
        }
      }
    },

    addControlCertificationEvidence(state, evidence) {
      // state.controlCertification.assessment has multiple controls
      // each control has multiple tests
      // each test has an array of evidence, and we must find the test
      // that matches evidence.answer_id with test.answer_entity_id
      // and add the evidence to the test.evidence array
      let controls = state.controlCertification.assessment.controls;
      outerLoop: for (let i = 0; controls.length; i++) {
        for (let j = 0; j < controls[i].tests.length; j++) {
          if (controls[i].tests[j].answer_entity_id == evidence.answer_id) {
            controls[i].tests[j].evidence.push(evidence);
            break outerLoop;
          }
        }
      }
    },

    removeControlCertificationEvidence(state, evidence) {
      // state.controlCertification.assessment has multiple controls
      // each control has multiple tests
      // each test has an array of evidence, and we must find the test
      // that matches evidence.answer_id with test.answer_entity_id
      // and remove the evidence from the test.evidence array
      let controls = state.controlCertification.assessment.controls;
      outerLoop: for (let i = 0; controls.length; i++) {
        for (let j = 0; j < controls[i].tests.length; j++) {
          if (controls[i].tests[j].answer_entity_id == evidence.answer_id) {
            let evidenceIndex = controls[i].tests[j].evidence.findIndex((e) => e.entity_id == evidence.entity_id);
            controls[i].tests[j].evidence.splice(evidenceIndex, 1);
            break outerLoop;
          }
        }
      }
    },
  },

  getters: {
    getField,

    auditListLoading(state) {
      return state.auditListLoading;
    },

    auditList(state) {
      return state.auditList;
    },

    controlsCertification(state) {
      return state.controlsCertification;
    },

    auditData(state) {
      return state.auditData;
    },
  },

  actions: {
    async uploadAuditFile({ commit, dispatch }, file) {
      try {
        commit("setState", { key: "isUploadingAudit", value: true });
        let formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const { data } = await axios.post("/audit", formData, config);
        if (data.success) {
          dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        }
        commit("setState", { key: "isUploadingAudit", value: false });
        return data;
      } catch (e) {
        console.log(e);
        commit("setState", { key: "isUploadingAudit", value: false });
      }
    },
    async getAudits({ commit, dispatch }) {
      commit("setState", { key: "auditListLoading", value: true });
      commit("setState", { key: "auditControlsLoading", value: true });
      const { data } = await axios.get("/audit");
      if (data.success) {
        commit("setState", { key: "auditList", value: data.data });
      }
      commit("setState", { key: "auditListLoading", value: false });
    },
    async getAuditAssessments({ commit, dispatch }) {
      const { data } = await axios.get("/audit/assessment");
      if (data.success) {
        commit("setState", { key: "auditAssessments", value: data.data });
      }
    },
    async updateAuditAssessment({ commit, dispatch }, auditId) {
      const { data } = await axios.post(`/audit/${auditId}/assessment`);
      return data;
    },
    async getAuditAssessmentTests({ commit, dispatch }, payload) {
      let skipLoader = payload.skipLoader;
      delete payload.skipLoader;
      if (!skipLoader) {
        commit("setState", {
          key: "controlCertificationDetailListLoading",
          value: true,
        });
      }
      payload.url = `/audit/assessment/${payload.assessment_id}/${payload.control_id}/tests`;
      const { data } = await axios.get(`/audit/assessment/${payload.assessment_id}/${payload.control_id}/tests`);
      if (data.success) {
        commit("setState", {
          key: "controlCertificationDetailList",
          value: data.data,
        });
      }
      if (!skipLoader) {
        commit("setState", {
          key: "controlCertificationDetailListLoading",
          value: false,
        });
      }
    },
    async updateAuditAssessmentAnswer({ commit, dispatch }, payload) {
      const { data } = await axios.patch(`/audit/assessment`, payload);
      return data;
    },
    async uploadAuditAssessmentEvidence({ commit, dispatch }, attachments) {
      const { data } = await axios.post(`/audit/evidence`, {
        evidences: attachments,
      });
      return data;
    },
    async deleteAuditAssessment({ commit, dispatch }, payload) {
      const { data } = await axios.delete(`/audit/evidence/${payload.entity_id}`);
    },
    async deleteAuditAttachment({ commit, dispatch }, payload) {
      const { data } = await axios.delete(`/audit/attachment/${payload.entity_id}`);
      if (data.success) {
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      }
      return data;
    },
    async submitAuditAnswer({ commit, dispatch }, payload) {
      let data = {};
      if (payload.submitted || payload.entity_id) {
        data = await axios.patch(`/audit/answer`, payload);
      } else {
        data = await axios.post(`/audit/answer`, payload);
      }
      data = data.data;
      if (data && data.success && data.answer?.submitted) {
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      }
      return data;
    },
    async unSubmitAuditAnswer({ commit, dispatch }, payload) {
      const { data } = await axios.patch(`/audit/answer`, payload);
      console.log("data ");
      if (data && data.success) {
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      }
      return data;
    },
    async getAuditControls({ commit }, assessmentId) {
      const { data } = await axios.get(`audit/${assessmentId}/controls`);
      return data;
    },

    async getAuditData({ commit }, organizationId = null) {
      try {
        commit("startLoading", true, { root: true });
        let url = "/audit/export?data=1";
        if (organizationId) {
          url = `/audit/${organizationId}/export?data=1`;
        }
        const { data } = await axios.get(url);
        commit("setAuditData", data.data);
      } catch (e) {
        console.log(e);
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteAuditOverlay({ commit, dispatch }, payload) {
      const { data } = await axios.delete(`overlay/${payload.entity_id}`);
      if (!data.success) return;
      let userInfo = localService.getItem("userInfo");
      let index = userInfo.user.overlays.findIndex((e) => e.entity_id == payload.entity_id);
      userInfo.user.overlays.splice(index, 1);
      localService.setItem("userInfo", userInfo);
      commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });
    },

    async delegateAuditChecklist({ commit }, payload) {
      const { data } = await axios.post(`/organization_management/delegate/audit_control`, payload);
      commit("updateAuditChecklistDelegate", payload);
      return payload;
    },

    async delegateAuditControl({ commit }, payload) {
      const { data } = await axios.post(`/organization_management/delegate/audit_answer`, payload);
      return data;
    },

    async fetchControlCertification({ commit }, config = {}) {
      const { data } = await axios.get("/audit/full_audit_assessment/1", config);
      commit("setControlCertification", data);
      return data;
    },

    async getallClientDelegations(organizationId = null) {
      let url = "/organization_management/client_delegations";
      if (organizationId) {
        url = `/organization_management/${organizationId}/client_delegations`;
      }
      return data;
    },

    updateControlCertificationAnswer({ commit }, payload) {
      commit("updateControlCertificationAnswer", payload);
    },

    addControlCertificationEvidence({ commit }, payload) {
      commit("addControlCertificationEvidence", payload);
    },

    removeControlCertificationEvidence({ commit }, payload) {
      commit("removeControlCertificationEvidence", payload);
    },
  },
};

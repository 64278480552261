<template>
  <v-container fluid class="pa-0" style="background: #fff !important;">
    <top-bar-without-auth />
    <v-divider class="mt-2" />
    <v-row class="px-14 pt-12 pb-8">
      <v-col cols="12">
        <h2 class="outfit-bold font-700 text-36 text--black mb-3">Thank you for confirming your email.</h2>
        <p calss="outfit-regular font-400 text-16 text-grey">Jackson Lewis will be in touch soon to discuss the results of your assessment.</p>
        <v-btn
          large
          depressed
          rounded
          color="#3ACA56"
          @click="redirectTo('login')"
        >
          <span class="outfit-bold font-700 text-12 white--text text-capitalize">I got it!</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center mt-4">
        <img :src="require('@/assets/images/jlpc/green-black-group-icon.png')" class="my-14" style="max-width: 116px;"/>
      </v-col>
    </v-row>
    <app-footer />
  </v-container>
</template>

<script>
import AppFooter from "../../components/Jlpc/AppFooter.vue";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import localService from "@/services/localService";
export default {
  components: {
    TopBarWithoutAuth,
    AppFooter,
  },

  data: () => ({
    email: '',
    confirmed: false
  }),

  mounted() {
    this.$store.dispatch('login/jlpcConfirmEmail', this.$route.params.token);
  },

  methods: {
    redirectTo(page) {
      localService.removeItem('jl_s2r_signUpUserInfo');
      this.$router.push(`/${page}`);
    }
  }
};
</script>


import axios from 'axios';
import localService from '../services/localService';
import { vm } from '../main';
import store from '../store';

const startLoading = () => {
  vm.$store.commit("startLoading", null, { root: true })
}
const stopLoading = () => {
  vm.$store.commit("stopLoading", null, { root: true })
}
const notifyIfError = (response) => {
  if (!response) return;

  const { data, status } = response;

  if (status && status !== 200) {
    console.error(`Encountered status code: ${status} with this as message: ${data.message}`)
    return vm.$store.dispatch("showMessage", {
      message: 'Something went wrong, please check your information and try again',
      messageType: "error",
      messageTitle: "Error"
    }, { root: true });
  }


  if (!data?.success && response?.request?.responseType !== "blob") {
    if(response?.config?.url == '/mshift/broker_status') { // API not deployed in production so temporary added condition for hide error message notification
      console.log('broker status api error: ', data.message);
      return;
    }
    // M-SHIFT ERRORS HAVE "ErrorCode" property
    if (data.hasOwnProperty('ErrorCode')) return;
    vm.$store.dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
  }
}

const GET_API_URL = () => {
  return process.env.VUE_APP_API_URL;
}

const options = {
  baseURL: GET_API_URL(),
  headers: {
    'Content-Type': 'application/json'
  }
};

const instance = axios.create({ ...options });

// Create an AbortController for each request
// const createAbortController = () => {
//   const abortController = new AbortController();
//   return abortController;
// };

instance.interceptors.request.use((config) => {
  // send withoutLoading = true in config of the api call if you wish to have a different api loader
  // otherwise by default it will be treated as false and loading will be shown

  !config.withoutLoading && startLoading();

  if (localStorage.getItem('userInfo')) {
    const token = localService.getItem('userInfo')['access_token'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // if in the store state 'salesperson/user' is set, append, the 'salesperson/user' organization_id to the config.url
    if (store.state.salesperson.organization) {
      config.headers.organizationId = store.state.salesperson.organization.entity_id;
    }
  }

  // Add an AbortController instance to the request config
  // config.abortController = createAbortController();

  return config;
});

instance.interceptors.response.use((response) => {
  stopLoading();
  notifyIfError(response)
  return response;
}, (error) => {
  stopLoading();
  if (error.response?.status === 401) {
    localService.clear();
    vm.$router.push({ path: '/login' });
  } else {
    notifyIfError(error.response);
  }
  return Promise.reject(error);
});

export default instance;
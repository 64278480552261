<template>
  <v-footer style="background: #000" class="text-white d-block pl-14 pt-8 pb-0">
    <a href="https://www.secondsight.ai/"><img :src="require('@/assets/images/jlpc/white-s2-logo.png')" class="d-block" style="max-width: 140px;"/></a>
    <div class="my-3">
      <img :src="require('@/assets/images/jlpc/yt-icon.png')" class="mr-2" style="max-width: 40px;"/>
      <a href="https://www.linkedin.com/company/second-sight-for-cyberinsurance" target="_blank"><img :src="require('@/assets/images/jlpc/in-icon.png')" style="max-width: 40px;"/></a>
    </div>

    <div class="d-flex align-center justify-space-between">
      <p class="white--text cursor-pointer outfit-regular text-10 font-400">&copy; {{ currentYear }} SecondSight</p>
      <p v-if="showVersion" class="text-right pa-2 version-text grey--text">
        Version {{ versionInfo }}
      </p>
    </div>
  </v-footer>
</template>

<script>
import versionInfoMixin from '@/Mixins/versionInfo';
import moment from 'moment';
export default {
  mixins: [versionInfoMixin],
  computed: {
    currentYear() {
      return moment().format('YYYY')
    }
  }
}
</script>
<template>
  <v-footer padless absolute color="transparent">
    <v-col :class="[`text-${direction}`, 'pa-2', 'outfit-regular', `text-${size}`, 'font-400']" cols="12">
      <span class="footer-text-color text-decoration-underline cursor-pointer" @click="privacyPolicyDialog = true">
        Privacy Policy
      </span>
      <span class="footer-text-color" v-if="showVersion"> - Version {{ versionInfo }}</span>
    </v-col>
    <privacy-policy :show-dialog="privacyPolicyDialog" @cancel-dialog="privacyPolicyDialog = false" />
  </v-footer>
</template>

<script>
import versionInfoMixin from "@/Mixins/versionInfo";
import PrivacyPolicy from "@/components/Terms/PrivacyPolicy.vue";

export default {
  components: { PrivacyPolicy },
  mixins: [versionInfoMixin],
  props: {
    direction: {
      type: String,
      default: () => "right",
    },

    size: {
      type: String,
      default: () => "16",
    },
  },
  data() {
    return {
      privacyPolicyDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.footer-text-color {
  color: #888787 !important;
}
</style>
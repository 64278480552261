import { getField, updateField } from "vuex-map-fields";

export const phishingTrainingState = () => {
  return [
    {
      id: 11,
      question:
        "Does your organization publish and distribute written policies and procedures regarding computer and information security to its employees?",
      choice: null,
      visible: true,
      topLine: false,
      key: "does_your_organization_publish_and_distribute_written_policies_and_procedures_regarding_computer_and_information_security_to_its_employees",
    },
    {
      id: 12,
      question:
        "Does your organization conduct computer and information security training for every employee that has access to computer systems or sensitive data?",
      choice: null,
      visible: true,
      key: "does_your_organization_conduct_computer_and_information_security_training_for_every_employee_that_has_access_to_computer_systems_or_sensitive_data",
    },
    {
      id: 1,
      question: "We offer phishing training to employees and independent contractors",
      choice: null,
      visible: true,
      show: [
        {
          id: 9,
          showOn: "yes",
          key: "how_often_is_phishing_training_performed",
        },
        {
          id: 3,
          showOn: "yes",
          key: "communications_are_sent_to_employees_when_realworld_phishing_attempts_occur",
        },
        {
          id: 4,
          showOn: "yes",
          key: "phishing_is_covered_as_part_of_security_awareness_training",
        },
      ],
      key: "we_offer_phishing_training_to_employees_and_independent_contractors",
    },
    {
      id: 9,
      question: "How often is phishing training performed?",
      choice: null,
      visible: false,
      isTitleBold: true,
      showButtons: false,
      withBackground: true,
      topLine: false,
      options: ["Not regularly", "Annually", "More than two times per year", "More than four times per year"],
      key: "how_often_is_phishing_training_performed",
    },

    {
      id: 2,
      question: "Phishing simulations are included in our training",
      choice: null,
      visible: true,
      key: "phishing_simulations_are_included_in_our_training",
      show: [
        {
          id: 15,
          showOn: "yes",
          key: "how_often_are_phishing_simulations_run",
        },
      ],
    },

    {
      id: 15,
      question: "How often are phishing simulations run?",
      choice: null,
      visible: false,
      isTitleBold: true,
      showButtons: false,
      withBackground: true,
      topLine: false,
      options: ["Not regularly", "Annually", "More than two times per year", "More than four times per year"],
      key: "how_often_are_phishing_simulations_run",
    },

    {
      id: 3,
      question: "Communications are sent to employees when real-world phishing attempts occur",
      choice: null,
      visible: false,
      key: "communications_are_sent_to_employees_when_realworld_phishing_attempts_occur",
    },
    {
      id: 4,
      question: "Phishing is covered as part of security awareness training",
      choice: null,
      visible: false,
      show: [
        {
          id: 10,
          key: "describe_how_employees_are_educated_on_security_risks_and_organizational_policies",
          showOn: "yes",
        },
      ],
      key: "phishing_is_covered_as_part_of_security_awareness_training",
    },

    {
      id: 10,
      question: "Describe how employees are educated on security risks and organizational policies",
      choice: null,
      visible: false,
      withBackground: true,
      showButtons: false,
      isTitleBold: true,
      topLine: false,
      key: "describe_how_employees_are_educated_on_security_risks_and_organizational_policies",
    },

    {
      id: 5,
      question: "We require additional training for employees who fail phishing email simulations",
      choice: null,
      visible: true,
      key: "we_require_additional_training_for_employees_who_fail_phishing_email_simulations",
    },
    {
      id: 6,
      question: "We conduct regular security awareness training at least 2x per year",
      choice: null,
      visible: true,
      key: "we_conduct_regular_security_awareness_training_at_least_2x_per_year",
    },
    {
      id: 7,
      question:
        "Are all employees that are responsible for disbursing or transmitting funds provided anti-fraud training, including detection of social engineering, phishing, business email compromise, and other scams on at least an annual basis?",
      choice: null,
      visible: true,
      key: "are_all_employees_that_are_responsible_for_disbursing_or_transmitting_funds_provided_antifraud_training_including_detection_of_social_engineering_phishing_business_email_compromise_and_other_scams_on_at_least_an_annual_basis",
    },
    {
      id: 13,
      question:
        'Are all employees <span class="outfit-bold">with</span> financial or accounting responsibilities, including disbursing or transmitting funds, provided anti-fraud and social engineering training, including detection of phishing, business email compromise, and other scams on at least an annual basis?',
      choice: null,
      visible: true,
      key: "are_all_employees_with_financial_or_accounting_responsibilities_including_disbursing_or_transmitting_funds_provided_anti_fraud_and_social_engineering_training_including_detection_of_phishing_business_email_compromise_and_other_scams_on_at_least_an_annual_basis",
    },
    {
      id: 14,
      question:
        'Are all employees <span class="outfit-bold">without</span> financial or accounting responsibilities provided anti-fraud and social engineering training, including detection of phishing, business email compromise, and other scams on at least an annual basis?',
      choice: null,
      visible: true,
      key: "are_all_employees_without_financial_or_accounting_responsibilities_provided_anti_fraud_and_social_engineering_training_including_detection_of_phishing_business_email_compromise_and_other_scams_on_at_least_an_annual_basis",
    },
    {
      id: 8,
      question: "Additional information:",
      choice: null,
      showButtons: false,
      visible: true,
      optional: true,
      key: "any_additional_information",
    },
  ];
};

export default {
  namespaced: true,

  state: () => ({
    // EMAIL SECURITY
    emailSecurityQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          choice: [],
          visible: false,
          showButtons: false,
          key: "chosen_email_security_platforms",
          show: [
            {
              key: "do_you_maintain_your_own_proprietary_or_opensource_email_service",
              id: 2,
              showOn: ["others"],
            },
            {
              key: "does_the_insured_allow_access_to_their_corporate_email_through_a_noncorporate_device_or_web_application_google_chrome_safari_etc",
              id: 4,
              showOn: ["office365", "gSuite", "exchange", "others"],
            },
            {
              key: "does_organization_flag_external_emails",
              id: 9,
              showOn: ["office365", "gSuite", "exchange", "others"],
            },
            {
              key: "does_organization_disable_macros",
              id: 10,
              showOn: ["office365", "gSuite", "exchange", "others"],
            },
            {
              key: "does_organization_block_executable_attachments",
              id: 11,
              showOn: ["office365", "gSuite", "exchange", "others"],
            },
            {
              key: "does_organization_use_microsoft_365_windows_thread_protection_add_on_similar_products",
              id: 12,
              showOn: ["office365"],
            },
            {
              key: "does_your_organization_have_controls_to_require_all_fund_and_wire_transfers_over_25000_to_be_authorized_and_verified_by_a_secondary_means_of_communication_before_execution",
              id: 16,
              showOn: ["exchange"],
            },
          ],
        },
        {
          id: 6,
          question:
            "Does your organization conduct Security Awareness training for every employee who has access to computer systems or sensitive data?",
          choice: null,
          visible: true,
          indicationSetupHidden: true,
          topLine: false,
          key: "does_organization_coduct_security_awareness_tranining_every_employee_who_access_computer_sensitive_data",
          show: [
            {
              key: "how_often_employees_perform_security_awareness_training",
              id: 7,
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 7,
          question: "How often do employees perform Security Awareness training?",
          choice: null,
          visible: false,
          csgHidden: true,
          withBackground: true,
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          options: ["Not regularly", "Annually", "More than two times per year", "More than four times per year"],
          key: "how_often_employees_perform_security_awareness_training",
        },
        {
          id: 17,
          question: "Are your organization user’s required to complete security awareness training at least annually?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "are_your_organization_user_required_to_complete_security_training_annually",
        },
        {
          id: 8,
          question: "Does your organization utilize phishing exercises as part of the security awareness program?",
          choice: null,
          visible: true,
          // csgHidden: true,
          topLine: false,
          key: "does_organization_utilize_phishing_exercises_as_part_security_awareness_program",
        },
        {
          id: 2,
          question: "Do you maintain your own proprietary or open-source email service?",
          choice: null,
          key: "do_you_maintain_your_own_proprietary_or_opensource_email_service",
          visible: false,
          topLine: false,
          show: [
            {
              key: "do_you_maintain_your_own_proprietary_or_opensource_email_service_answer_no",
              id: 3,
              showOn: "no",
            },
          ],
        },
        {
          id: 3,
          question: "",
          type: "hasVendors",
          choice: [{ name: "", website: "" }],
          visible: false,
          showButtons: false,
          topLine: false,
          bottomLine: true,
          key: "do_you_maintain_your_own_proprietary_or_opensource_email_service_answer_no",
        },
        {
          visible: false,
          id: 4,
          question:
            "Does the insured allow access to their corporate email through a non-corporate device or web application (Google Chrome, Safari, etc.)?",
          choice: null,
          topLine: false,
          indicationSetupHidden: true,
          key: "does_the_insured_allow_access_to_their_corporate_email_through_a_noncorporate_device_or_web_application_google_chrome_safari_etc",
          show: [
            {
              key: "is_mfa_enabled_on_the_email_services_defined_above",
              id: 5,
              showOn: ["yes"],
            },
          ],
        },
        {
          visible: false,
          id: 5,
          question: "Is MFA enabled on the email services defined above?",
          choice: null,
          withBackground: true,
          topLine: false,
          questionCls: "pt-2",
          key: "is_mfa_enabled_on_the_email_services_defined_above",
        },
        {
          id: 12,
          question:
            "Does your organization use Microsoft 365 Windows Defender/Advanced Threat Protection add-on or a similar product",
          choice: null,
          topLine: false,
          visible: false,
          // csgHidden: true,
          indicationSetupHidden: true,
          key: "does_organization_use_microsoft_365_windows_thread_protection_add_on_similar_products",
          show: [
            {
              key: "does_organization_use_microsoft_365_windows_thread_protection_add_on_similar_products_select_product",
              id: 13,
              showOn: ["no"],
            },
          ],
        },
        {
          id: 13,
          question: "",
          choice: null,
          topLine: false,
          visible: false,
          // csgHidden: true,
          showButtons: false,
          withBackground: true,
          parentCls: "withParentBackground top-border-radius",
          questionCls: "my-0",
          key: "does_organization_use_microsoft_365_windows_thread_protection_add_on_similar_products_select_product",
          options: [
            "AppRiver",
            "Avanan Cloud Email Security",
            "Barracuda Essentials",
            "Microsoft Defender",
            "Mimecast Email Security with Threat Protection",
            "Proofpoint Email Security and Protection",
            "Spam Titan Email Security",
            "Symantec Email Security Cloud",
            "Other",
          ],
          formData: {
            describe_other: null,
          },
        },
        {
          id: 15,
          question: "Does your organization have the March 2021 Microsoft Exchange Server Security Update installed?",
          choice: null,
          topLine: false,
          visible: false,
          csgHidden: true,
          withBackground: true,
          parentCls: "withParentBackground bottom-border-radius",
          questionCls: "my-0",
          key: "does_organization_have_march_2021_microsoft_exchange_server_security_update_installed",
        },
        {
          id: 9,
          question: "Does your organization flag external emails?",
          choice: null,
          topLine: false,
          visible: true,
          indicationAdditionalInfoShow: true,
          key: "does_organization_flag_external_emails",
        },
        {
          id: 10,
          question: "Does your organization disable macros?",
          choice: null,
          topLine: false,
          visible: true,
          indicationAdditionalInfoShow: true,
          key: "does_organization_disable_macros",
        },
        {
          id: 18,
          question: "Does your organization block executable attachments?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_block_executable_attachments",
        },
        {
          id: 19,
          question:
            "Does your organization have controls in place which require all fund and wire transfers to be authorized and verified by a secondary means of communication prior to execution?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_have_controls_in_place_which_require_fund_wire_transfers_authorized_secondary_means_communication_prior_execution",
        },
        {
          id: 11,
          question: "Does your organization block executable attachments?",
          choice: null,
          topLine: false,
          visible: true,
          // csgHidden: true,
          key: "does_organization_block_executable_attachments",
        },
        {
          id: 16,
          question:
            "Does your organization have controls to require all fund and wire transfers over $25,000 to be authorized and verified by a secondary means of communication before execution?",
          choice: null,
          topLine: false,
          visible: false,
          // csgHidden: true,
          indicationSetupHidden: true,
          key: "does_your_organization_have_controls_to_require_all_fund_and_wire_transfers_over_25000_to_be_authorized_and_verified_by_a_secondary_means_of_communication_before_execution",
        },
      ],
    },

    // EMAIL SECURITY CONTROLS
    emailSecurityControlsQuestions: {
      questions: [
        {
          id: 17,
          question: "Who is your organization's email security provider?",
          topLine: false,
          choice: [{ choice: null }],
          type: "hasProviders",
          visible: true,
          isTitleBold: true,
          showButtons: false,
          options: [
            "Avanan",
            "Barracuda",
            "Cisco",
            "Microsoft Defender",
            "Mimecast",
            "Proofpoint",
            "SonicWall",
            "Symantec",
            "Trend Micro",
            "Other",
          ],
          key: "who_organization_email_security_provider",
          withBackground: true,
        },
        {
          id: 1,
          question: "Screening for malicious attachments",
          choice: null,
          visible: true,
          topLine: false,
          key: "screening_for_malicious_attachments",
        },
        {
          id: 2,
          question: "Screening for malicious links",
          choice: null,
          visible: true,
          key: "screening_for_malicious_links",
        },
        {
          id: 3,
          question: "Quarantine service on suspicious emails",
          choice: null,
          visible: true,
          key: "quarantine_service_on_suspicious_emails",
        },
        {
          id: 4,
          question: "Detonation and evaluation of attachments in sandbox",
          choice: null,
          visible: true,
          key: "detonation_and_evaluation_of_attachments_in_sandbox",
        },
        {
          id: 5,
          question: "Tagging of external emails",
          choice: null,
          visible: true,
          key: "tagging_of_external_emails",
        },
        {
          id: 6,
          question:
            "Employees are trained to place extra scrutiny on attachments and links that come from external emails",
          choice: null,
          visible: true,
          key: "employees_are_trained_to_place_extra_scrutiny_on_attachments_and_links_that_come_from_external_emails",
        },
        {
          id: 7,
          question: "Domain Keys Identified Mail (DKIM)",
          choice: null,
          visible: true,
          key: "domain_keys_identified_mail_dkim",
        },
        {
          id: 8,
          question: "Sender policy framework (SPF) strictly enforced",
          choice: null,
          visible: true,
          key: "sender_policy_framework_spf_strictly_enforced",
        },
        {
          id: 9,
          question: "Domain based messaging authentication, reporting and confirmation (DMARC)",
          choice: null,
          visible: true,
          key: "domain_based_messaging_authentication_reporting_and_confirmation_dmarc",
        },
        {
          id: 10,
          question: "We use additional email filtering tool(s)",
          choice: null,
          visible: true,
          show: [
            {
              id: 16,
              key: "we_use_additional_email_filtering_tools_answer_yes",
              showOn: "yes",
            },
          ],
          formData: {
            description: null,
          },
          key: "we_use_additional_email_filtering_tools",
        },
        {
          id: 16,
          question: "",
          type: "hasVendors",
          choice: [{ name: "", website: "" }],
          visible: false,
          topLine: false,
          showButtons: false,
          key: "we_use_additional_email_filtering_tools_answer_yes",
        },
        {
          id: 11,
          question: "We use Web Filtering to block access to known malicious websites",
          choice: null,
          visible: true,
          key: "we_use_web_filtering_to_block_access_to_known_malicious_websites",
        },
        {
          id: 12,
          question: "We disable macros in their office productivity software by default",
          choice: null,
          visible: true,
          key: "we_disable_macros_in_their_office_productivity_software_by_default",
        },
        {
          id: 13,
          question: "Users are allowed to enable macros",
          choice: null,
          visible: true,
          key: "users_are_allowed_to_enable_macros",
        },
        {
          id: 14,
          question:
            "We have disabled legacy email protocols that use basic authentication such as IMAP, POP3, and SMTP",
          choice: null,
          visible: true,
          key: "we_have_disabled_legacy_email_protocols_that_use_basic_authentication_such_as_imap_pop3_and_smtp",
        },
        {
          id: 15,
          isTitleBold: true,
          question: "What Microsoft 365 license does the insured use for their users?",
          choice: null,
          showButtons: false,
          visible: true,
          key: "what_microsoft_365_license_does_the_insured_use_for_their_users",
        },
      ],
    },

    // PHISHING TRAINING QUESTIONS
    phishingTrainingQuestions: {
      questions: phishingTrainingState(),
    },

    // PHYSICAL HARDWARE
    chosenPhysicalHardware: {
      options: [],
    },

    physicalHardwareQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          choice: [],
          visible: true,
          showButtons: false,
          topLine: false,
          key: "physical_hardware",
          show: [
            {
              id: 2,
              showOn: ["laptops"],
              key: "laptops_data_stored_encrypted",
            },
          ],
        },
        {
          id: 2,
          heading: "Laptops",
          question: "Is the data stored on laptops encrypted?",
          choice: null,
          visible: false,
          topLine: false,
          withBackground: true,
          key: "laptops_data_stored_encrypted",
        },
      ],
    },

    // BACKUP CAPABILITIES
    chosenBackupCapabilities: {
      options: [],
    },

    backupCapabilitiesQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          choice: [],
          visible: true,
          showButtons: false,
          topLine: false,
          key: "on_premise_infrastructure",
          show: [
            {
              id: 2,
              showOn: ["local"],
              key: "local_on_premise_what_location_backup_stored",
            },
            {
              id: 3,
              showOn: ["offsite"],
              key: "office_what_location_backup_stored",
            },
            {
              id: 4,
              showOn: ["offline"],
              key: "offline_air_what_location_backup_stored",
            },
            {
              id: 5,
              showOn: ["cloudBased"],
              key: "cloud_based_vendors",
            },
            {
              id: 6,
              showOn: ["secondaryDataCenter"],
              key: "secondary_data_center_what_location_backup_stored",
            },
            {
              id: 7,
              showOn: ["backupTapes"],
              key: "backup_tapes_data_stored_encrypted",
            },
            {
              id: 8,
              showOn: ["other"],
              key: "backup_other_description",
            },
          ],
        },
        {
          id: 2,
          heading: "Local & On-Premise",
          question: "",
          visible: true,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "local_on_premise_what_location_backup_stored",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 3,
          heading: "Offsite",
          question: "At what location are the backups stored?",
          choice: null,
          visible: true,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "office_what_location_backup_stored",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 4,
          heading: "Offline (Air-Gapped)",
          question: "",
          visible: true,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "offline_air_what_location_backup_stored",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 5,
          heading: "Cloud-Based",
          question: "",
          type: "hasVendors",
          choice: [{ name: "", website: "" }],
          visible: false,
          showButtons: false,
          withBackground: true,
          topLine: false,
          key: "cloud_based_vendors",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 6,
          heading: "Secondary Data Center",
          question: "",
          visible: true,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "secondary_data_center_what_location_backup_stored",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 7,
          heading: "Backup Tapes",
          question: "Is the data stored on backup tapes encrypted?",
          choice: null,
          visible: false,
          topLine: false,
          withBackground: true,
          key: "backup_tapes_data_stored_encrypted",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
        {
          id: 8,
          heading: "Other",
          question: "Please describe:",
          choice: null,
          visible: false,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "backup_other_description",
          formData: {
            fullBackup: false,
            incremental: false,
            differential: false,
            mirror: false,
            other: false,
            other_description: null,
          },
        },
      ],
    },

    // BACKUPS AND BUSINESS CONTINUITY
    backupContinuityQuestions: {
      questions: [
        {
          id: 1,
          question: "Do you do full or partial backups?",
          choice: null,
          buttonConfig: ["Full", "Partial"],
          visible: true,
          topLine: false,
          key: "do_you_do_full_or_partial_backups",
        },
        {
          id: 2,
          question: "All our backups are encrypted",
          choice: null,
          extraButtons: ["most", "some"],
          visible: true,
          key: "all_our_backups_are_encrypted",
        },
        {
          id: 3,
          question: "For your encrypted backups, do you maintain an offline backup of your decryption key(s)?",
          choice: null,
          visible: true,
          key: "for_your_encrypted_backups_do_you_maintain_an_offline_backup_of_your_decryption_keys",
        },
        {
          id: 4,
          question: "Our organization's backups have MFA enabled for both internal and external access.",
          choice: null,
          visible: true,
          key: "our_backups_have_mfa_enabled",
        },
        {
          id: 5,
          question:
            "Our organization's backup environments use separate credentials from other administrator credentials.",
          choice: null,
          visible: true,
          key: "our_backups_environments_use_separate_credentials",
        },
        {
          id: 6,
          question: "Our backups environments are scanned for Malware",
          choice: null,
          visible: true,
          key: "our_backups_environments_are_scanned_for_malware",
        },
        {
          id: 7,
          question:
            "Our backup environments have been successfully tested to support a full recovery in the last six months",
          choice: null,
          visible: true,
          key: "our_backup_environments_have_been_successfully_tested_to_support_a_full_recovery_in_the_last_six_months",
        },
        {
          id: 8,
          question:
            "Is your cloud-based backup service a “syncing service”? (E.g., DropBox, OneDrive, SharePoint, Google Drive)",
          choice: null,
          visible: true,
          key: "is_your_cloudbased_backup_service_a_“syncing_service”_eg_dropbox_onedrive_sharepoint_google_drive",
        },
        {
          id: 9,
          question: "Are any of your backups immutable?",
          choice: null,
          buttonConfig: ["all", "some", "none"],
          visible: true,
          key: "are_any_of_your_backups_immutable",
        },
        {
          id: 10,
          question: "What is the retention period?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          options: ["Less than 30 days", "1 - 3 months", "3 - 6 months", "6 - 12 months", "More than 1 year"],
          visible: true,
          key: "what_is_the_retention_period",
        },
        {
          id: 11,
          question: "How frequently are backups performed?",
          choice: null,
          buttonConfig: ["daily", "weekly", "monthly"],
          visible: true,
          key: "how_frequently_are_backups_performed",
        },
        {
          id: 12,
          question: "How frequently do you perform a test restoration from backups?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          options: ["Never/not regularly", "Annually", "2-3 times per year", "Quarterly or more often"],
          visible: true,
          key: "how_frequently_do_you_perform_a_test_restoration_from_backups",
        },
        {
          id: 13,
          question: "Have you determined how long it would take to restore all of your data from the cloud?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          options: ["Yes, less than 48 hours", "Yes, 2 to 7 days", "Yes, more 7 days", "No"],
          visible: true,
          key: "have_you_determined_how_long_it_would_take_to_restore_all_of_your_data_from_the_cloud",
        },
        {
          id: 14,
          question:
            "How long would it take your organization to restore essential systems from backups in the event of a widespread ransomware attack?",
          choice: null,
          isTitleBold: true,
          options: ["Unknown", "0-12 Hours", "12-24 hours", "1-3 days", "4-6 days", "1 week +"],
          visible: true,
          showButtons: false,
          key: "how_long_would_it_take_your_organization_to_restore_essential_systems_from_backups_in_the_event_of_a_widespread_ransomware_attack",
        },
        {
          id: 15,
          question:
            "We have redundant backup copies stored in 2+ locations, with one offline (offline includes cloud-based backups)",
          choice: null,
          visible: true,
          key: "we_have_redundant_backup_copies_stored_in_2+_locations_with_one_offline_offline_includes_cloudbased_backups",
        },
        {
          id: 16,
          question: "Our organization has a disaster recovery plan that includes responding to cybersecurity threats.",
          choice: null,
          visible: true,
          key: "our_organization_has_disaster_recovery_plan_includes_responding_cybersecurity_threats",
          show: [
            {
              id: 24,
              showOn: ["yes"],
              key: "our_organization_has_disaster_recovery_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
            },
          ],
        },
        {
          id: 24,
          question: "When was the last test?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "our_organization_has_disaster_recovery_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
        },
        {
          id: 25,
          question:
            "Our organization has a business continuity plan that includes responding to cybersecurity threats.",
          choice: null,
          visible: true,
          key: "our_organization_has_business_continuity_plan_includes_responding_cybersecurity_threats",
          show: [
            {
              id: 26,
              showOn: ["yes"],
              key: "our_organization_has_business_continuity_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
            },
          ],
        },
        {
          id: 26,
          question: "When was the last test?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "our_organization_has_business_continuity_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
        },
        {
          id: 27,
          question: "Our organization has an incident response plan for network intrusions and virus incidents.",
          choice: null,
          visible: true,
          key: "our_organization_has_incident_response_plan_includes_responding_cybersecurity_threats",
          show: [
            {
              id: 28,
              showOn: ["yes"],
              key: "our_organization_has_incident_response_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
            },
          ],
        },
        {
          id: 28,
          question: "When was the last test?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "our_organization_has_incident_response_plan_includes_responding_cybersecurity_threats_when_was_the_last_test",
        },
        {
          id: 29,
          question:
            "If your organization has a business continuity plan, does the plan contain recovery time objectives for the amount of time within which business processes and continuity must be restored?",
          choice: null,
          visible: true,
          key: "if_your_organization_has_business_continuity_plan_recovery_time_which_business_process_continuity_restored",
          show: [
            {
              id: 30,
              showOn: ["yes"],
              key: "what_are_current_stated_tested_recovery_time_objectives",
            },
          ],
        },
        {
          id: 30,
          question: "What are the current stated and tested recovery time objectives",
          choice: null,
          visible: false,
          showButtons: false,
          topLine: false,
          withBackground: true,
          key: "what_are_current_stated_tested_recovery_time_objectives",
        },
        {
          id: 17,
          question: "Our organization has engaged in exercises to run through the plan (start to finish)",
          choice: null,
          visible: true,
          key: "our_organization_has_engaged_in_exercises_to_run_through_the_plan_start_to_finish",
        },
        {
          id: 18,
          question: "How frequently is it tested?",
          choice: null,
          buttonConfig: ["Every 6 months", "Annually", "Longer than a year"],
          visible: true,
          key: "how_frequently_is_it_tested",
        },
        {
          id: 19,
          question: "We have a tested Incident Response plan with the ability to contain an incident quickly",
          choice: null,
          visible: true,
          key: "we_have_a_tested_incident_response_plan_with_the_ability_to_contain_an_incident_quickly",
          show: [
            {
              id: 20,
              showOn: ["yes"],
              key: "when_was_the_last_test",
            },
          ],
        },
        {
          id: 20,
          question: "When was the last test?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "we_have_a_tested_incident_response_plan_with_the_ability_to_contain_an_incident_quickly_when_was_the_last_test",
        },
        {
          id: 21,
          question: "Our organization has conducted a cybersecurity incident tabletop exercise in the last two years",
          choice: null,
          visible: true,
          key: "our_organization_has_conducted_a_cybersecurity_incident_tabletop_exercise_in_the_last_two_years",
        },
        {
          id: 22,
          question: "Our cybersecurity incident tabletop exercise included threat of ransomware",
          choice: null,
          visible: true,
          key: "our_cybersecurity_incident_tabletop_exercise_included_threat_of_ransomware",
        },
        {
          id: 23,
          question: "Any additional Information?",
          isTitleBold: true,
          showButtons: false,
          choice: null,
          visible: true,
          optional: true,
          key: "any_additional_information",
        },
      ],
    },

    privacyConcernsQuestions: {
      questions: [
        {
          id: 1,
          question: "Has your organization designated a Chief Privacy Officer (CPO)?",
          choice: null,
          visible: true,
          topLine: false,
          key: "has_your_organization_designated_a_chief_privacy_officer_cpo",
          show: [
            {
              id: 2,
              showOn: ["yes", "no"],
              key: "is_someone_on_your_workbench_team_responsible_for_privacy_issues",
            },
          ],
        },

        {
          id: 2,
          question: "Is someone on your Workbench team responsible for Privacy issues?",
          choice: null,
          visible: false,
          topLine: false,
          withBackground: true,
          formVisibleOn: "yes",
          requiredFormData: {
            teamMember: {
              key: "teamMember",
            },
            userEmail: {
              key: "userEmail",
            },
            userFirstName: {
              key: "userFirstName",
            },
            userLastName: {
              key: "userLastName",
            },
            otherEmail: {
              key: "otherEmail",
            },
            otherFirstName: {
              key: "otherFirstName",
            },
            otherLastName: {
              key: "otherLastName",
            },
          },
          formData: {
            teamMember: null,
            userEmail: "",
            userFirstName: "",
            userLastName: "",
            otherEmail: "",
            otherFirstName: "",
            otherLastName: "",
            otherTitle: "",
          },
          key: "is_someone_on_your_workbench_team_responsible_for_privacy_issues",
        },

        {
          id: 4,
          question: "Has your organization designated a Chief Information Security Officer (CISO)?",
          choice: null,
          visible: true,
          key: "has_your_organization_designated_a_chief_information_security_officer_ciso",
          show: [
            {
              id: 5,
              showOn: ["yes", "no"],
              key: "is_someone_on_your_workbench_team_responsible_for_information_security_issues",
            },
          ],
        },

        {
          id: 5,
          question: "Is someone on your Workbench team responsible for Information Security issues?",
          choice: null,
          visible: false,
          topLine: false,
          withBackground: true,
          formVisibleOn: "no",
          requiredFormData: {
            teamMember: {
              key: "teamMember",
            },
            userEmail: {
              key: "userEmail",
            },
            userFirstName: {
              key: "userFirstName",
            },
            userLastName: {
              key: "userLastName",
            },
            otherEmail: {
              key: "otherEmail",
            },
            otherFirstName: {
              key: "otherFirstName",
            },
            otherLastName: {
              key: "otherLastName",
            },
          },
          formData: {
            teamMember: null,
            userEmail: "",
            userFirstName: "",
            userLastName: "",
            otherEmail: "",
            otherFirstName: "",
            otherLastName: "",
            otherTitle: "",
          },
          key: "is_someone_on_your_workbench_team_responsible_for_information_security_issues",
        },

        {
          id: 6,
          question: "",
          visible: false,
          topLine: false,
          showButtons: false,
          withBackground: true,
          requiredFormData: {
            firstName: {
              key: "firstName",
            },
            lastName: {
              key: "lastName",
            },
            email: {
              key: "email",
            },
          },
          formData: {
            firstName: "",
            lastName: "",
            email: "",
            title: "",
          },
          key: "has_your_organization_designated_a_chief_information_security_officer_ciso_no_option_selected",
        },

        {
          id: 7,
          question:
            "Do you or any other person or organization proposed for this insurance have knowledge of any security breach, privacy breach, privacy-related event or incident or allegations of breach of privacy that may give rise to a claim?",
          choice: null,
          visible: true,
          key: "do_you_or_any_other_person_or_organization_proposed_for_this_insurance_have_knowledge_of_any_security_breach_privacy_breach_privacy-related_event_or_incident_or_allegations_of_breach_of_privacy_that_may_give_rise_to_a_claim",
        },

        {
          id: 8,
          question:
            "Does your organization require third parties with which it shares personally identifiable or confidential information to indemnify your organization for legal liability arising out of the release of such information due to the fault or negligence of the third party?",
          choice: null,
          visible: true,
          bottomLine: true,
          key: "does_your_organization_require_third_parties_with_which_it_shares_personally_identifiable_or_confidential_information_to_indemnify_your_organization_for_legal_liability_arising_out_of_the_release_of_such_information_due_to_the_fault_or_negligence_of_the_third_party",
        },

        {
          id: 9,
          question:
            "Has your organization received any claims or complaints with respect to privacy, breach of information or network security, or, unauthorized disclosure of information defamation, content infringement, identity theft, denial of service attacks, computer virus infections, theft of information, damage to third party networks or the ability of third parties to rely on your organization’s network?",
          choice: null,
          visible: true,
          topLine: false,
          key: "has_your_organization_received_any_claims_or_complaints_with_respect_to_privacy_breach_of_information_or_network_security_or_unauthorized_disclosure_of_information_defamation_content_infringement_identity_theft_denial_of_service_attacks_computer_virus_infections_theft_of_information,_damage_to_third_party_networks_or_the_ability_of_third_parties_to_rely_on_your_organizations_network",
        },

        {
          id: 10,
          question:
            "Has your organization been the subject of any government action, investigation or subpoena regarding any alleged violation of a privacy law or regulation?",
          choice: null,
          visible: true,
          topLine: false,
          key: "has_your_organization_been_the_subject_of_any_government_action_investigation_or_subpoena_regarding_any_alleged_violation_of_a_privacy_law_or_regulation",
          formVisibleOn: "yes",
          requiredFormData: {
            otherIncident: {
              key: "otherIncident",
            },
          },
          formData: {
            otherIncident: "",
          },
        },

        {
          id: 11,
          question:
            "Has your organization received a complaint or cease and desist demand alleging trademark, copyright, invasion of privacy, or defamation with regard to any content published, displayed or distributed by or on behalf of your organization?",
          choice: null,
          visible: true,
          key: "has_your_organization_received_a_complaint_or_cease_and_desist_demand_alleging_trademark_copyright_invasion_of_privacy_or_defamation_with_regard_to_any_content_published_displayed_or_distributed_by_or_on_behalf_of_your_organization",
          formVisibleOn: "yes",
          requiredFormData: {
            otherIncident: {
              key: "otherIncident",
            },
          },
          formData: {
            otherIncident: "",
          },
        },

        {
          id: 12,
          question:
            "Has your organization notified consumers or any other third party of a data breach, security breach, or privacy breach involving your organization?",
          choice: null,
          visible: true,
          key: "has_your_organization_notified_consumers_or_any_other_third_party_of_a_data_breach_security_breach_or_privacy_breach_involving_your_organization",
          formVisibleOn: "yes",
          requiredFormData: {
            otherIncident: {
              key: "otherIncident",
            },
          },
          formData: {
            otherIncident: "",
          },
        },

        {
          id: 13,
          question:
            "Has your organization experienced an unexpected outage or interruption of a computer network, application or system lasting greater than four (4) hours?",
          choice: null,
          visible: true,
          key: "has_your_organization_experienced_an_unexpected_outage_or_interruption_of_a_computer_network_application_or_system_lasting_greater_than_four_hours",
          formVisibleOn: "yes",
          requiredFormData: {
            otherIncident: {
              key: "otherIncident",
            },
          },
          formData: {
            otherIncident: "",
          },
        },

        {
          id: 18,
          question:
            "Has your organization experienced an actual or attempted extortion demand with respect to its computer systems?",
          choice: null,
          visible: true,
          key: "Has_your_organization_experienced_an_actual_or_attempted_extortion_demand_with_respect_to_its_computer_systems",
          show: [
            {
              id: 19,
              showOn: "yes",
              key: "details_regarding_such_incidents_or_events_attempted_extortion",
            },
          ],
        },

        {
          id: 19,
          heading: "Details regarding such incident(s) or event(s)",
          question: "",
          choice: null,
          visible: false,
          showButtons: false,
          topLine: false,
          withInvertedBackground: true,
          key: "details_regarding_such_incidents_or_events_attempted_extortion",
        },

        {
          id: 14,
          question:
            "Has your organization sustained any property damage or business interruption losses as a result of a cyber-attack?",
          choice: null,
          visible: true,
          key: "has_your_organization_sustained_any_property_damage_or_business_interruption_losses_as_a_result_of_a_cyber_attack",
        },

        {
          id: 15,
          question:
            "Has your organization sustained any losses due to wire transfer fraud, telecommunications fraud, or phishing fraud?",
          choice: null,
          visible: true,
          key: "has_your_organization_sustained_any_losses_due_to_wire_transfer_fraud_telecommunications_fraud_or_phishing_fraud",
        },

        {
          id: 16,
          question:
            "Has your organization sustained an unscheduled network outage or interruption lasting longer than 4 hours?",
          choice: null,
          visible: true,
          key: "has_your_organization_sustained_an_unscheduled_network_outage_or_interruption_lasting_longer_than_4_hours",
          show: [
            {
              id: 17,
              showOn: "yes",
              key: "did_your_organization_experience_an_interruption_in_business_as_a_result_of_such_an_outage_or_interruption",
            },
          ],
        },

        {
          id: 17,
          question:
            "Did your organization experience an interruption in business as a result of such an outage or interruption?",
          choice: null,
          visible: false,
          topLine: false,
          withInvertedBackground: true,
          key: "did_your_organization_experience_an_interruption_in_business_as_a_result_of_such_an_outage_or_interruption",
        },
      ],
    },

    // PERSONALLY IDENTIFIABLE QUESTIONS
    personallyIdentifiableQuestions: {
      questions: [
        {
          id: 1,
          question: "Social Security Numbers",
          choice: null,
          visible: true,
          key: "social_security_numbers",
          show: [
            {
              id: 2,
              showOn: "yes",
              key: "count_of_social_security_numbers",
            },
          ],
        },

        {
          id: 2,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_social_security_numbers",
        },

        {
          id: 3,
          question: "Consumer Financial Information",
          choice: null,
          visible: true,
          key: "consumer_financial_information",
          show: [
            {
              id: 4,
              showOn: "yes",
              key: "count_of_consumer_financial_information",
            },
          ],
        },

        {
          id: 4,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_consumer_financial_information",
        },

        {
          id: 5,
          question: "Payment Card Information",
          choice: null,
          visible: true,
          key: "payment_card_information",
          show: [
            {
              id: 6,
              showOn: "yes",
              key: "count_of_payment_card_information",
            },
          ],
        },

        {
          id: 6,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_payment_card_information",
        },

        {
          id: 7,
          question: "Protected Health Information",
          choice: null,
          visible: true,
          key: "protected_health_information",
          show: [
            {
              id: 8,
              showOn: "yes",
              key: "count_of_protected_health_information",
            },
          ],
        },

        {
          id: 8,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_protected_health_information",
        },

        {
          id: 9,
          question: "Private or Sensitive Information",
          choice: null,
          visible: true,
          key: "private_or_sensitive",
          show: [
            {
              id: 10,
              showOn: "yes",
              key: "count_of_private_or_sensitive_information_on_paper",
            },
            {
              id: 15,
              showOn: "yes",
              key: "count_of_private_or_sensitive_information_stored_electronically",
            },
          ],
        },

        {
          id: 10,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_private_or_sensitive_information_on_paper",
        },

        {
          id: 15,
          question: "",
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
          key: "count_of_private_or_sensitive_information_stored_electronically",
        },

        {
          id: 11,
          question: "Biometric Information",
          choice: null,
          visible: true,
          key: "biometric_information",
          show: [
            {
              id: 12,
              showOn: "yes",
              key: "has_your_organization_reviewed_the_policies_relating_to_collecting_storing_and_destroying_such_information_or_data_with_a_qualified_attorney_and_confirmed_compliance_with_applicable_federal_state_local_and_foreign_laws",
            },
          ],
        },

        {
          id: 12,
          question:
            "Has your organization reviewed the policies relating to collecting, storing, and destroying such information or data with a qualified attorney and confirmed compliance with applicable federal, state, local, and foreign laws?",
          choice: null,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "has_your_organization_reviewed_the_policies_relating_to_collecting_storing_and_destroying_such_information_or_data_with_a_qualified_attorney_and_confirmed_compliance_with_applicable_federal_state_local_and_foreign_laws",
          formVisibleOn: "yes",
          requiredFormData: {
            records: {
              key: "records",
            },
          },
          formData: {
            records: "",
          },
        },

        {
          id: 13,
          question: "Other",
          choice: null,
          visible: true,
          key: "other",
          show: [
            {
              id: 14,
              showOn: "yes",
              key: "other_description",
            },
          ],
        },

        {
          id: 14,
          question: "",
          requiredFormData: {
            records: {
              key: "records",
            },
          },
          formData: {
            description: "",
            records: "",
          },
          topLine: false,
          visible: false,
          showButtons: false,
          key: "other_description",
        },
      ],
    },

    //  MEDIA USAGE QUESTIONS
    mediaUsageQuestions: {
      questions: [
        {
          id: 1,
          question:
            "Please describe the media activities of your organization or by others on behalf of your organization (check all that apply):",
          topLine: false,
          showButtons: false,
          visible: true,
          choice: [],
          options: [
            "Television",
            "Radio",
            "Print",
            `Applicant's Website(s)`,
            "Internet Advertising",
            "Social Media",
            "Marketing Materials",
            "Audio or Video Streaming",
            "Other",
            "None",
          ],
          key: "please_describe_the_media_activities_of_your_organization_or_by_others_on_behalf_of_your_organization",
          show: [
            {
              id: 2,
              key: "please_describe_the_media_activities_of_your_organization_or_by_others_on_behalf_of_your_organization_answer_other",
              showOn: ["Other"],
            },
          ],
          indicationSetupHidden: true,
        },

        {
          id: 2,
          question: "If other, please describe:",
          choice: null,
          visible: false,
          withBackground: true,
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          key: "please_describe_the_media_activities_of_your_organization_or_by_others_on_behalf_of_your_organization_answer_other",
        },

        {
          id: 7,
          question:
            "Does your organization use multimedia material provided by others, does the Applicant always obtain the necessary rights, licenses, releases, and consents prior to publishing?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_use_multimedia_material_provided_by_others__applicant_always_obtain_necessary_rights_licenses_releases_consents_prior_to_publishing",
        },

        {
          id: 3,
          question:
            "Does your organization have a formal review process in place to screen any published or broadcast material (including digital content) for intellectual property and privacy compliance prior to any publication, broadcast, distribution or use?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_have_a_formal_review_process_in_place_to_screen_any_published_or_broadcast_material_(including_digital_content)_for_intellectual_property_and_privacy_compliance_prior_to_any_publication,_broadcast,_distribution_or_use",
          show: [
            {
              id: 4,
              showOn: "yes",
              key: "are_such_reviews_conducted_by_or_under_the_supervision_of_a_qualified_attorney",
            },
          ],
        },

        {
          id: 4,
          question: "Are such reviews conducted by or under the supervision of a qualified attorney?",
          choice: null,
          visible: false,
          withBackground: true,
          topLine: false,
          key: "are_such_reviews_conducted_by_or_under_the_supervision_of_a_qualified_attorney",
        },

        {
          id: 5,
          question: "Does your organization allow user-generated content to be displayed on its website(s)?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_allow_user-generated_content_to_be_displayed_on_its_website",
        },

        {
          id: 6,
          question:
            "Does your organization enforce procedures to remove content (including third-party content) that may infringe or violate any intellectual property or privacy right?",
          visible: true,
          topLine: false,
          choice: null,
          key: "does_your_organization_enforce_procedures_to_remove_content_that_may_infringe_or_violate_any_intellectual_property_or_privacy_right",
          indicationSetupHidden: true,
        },
      ],
    },

    // FINANCIAL CONTROLS QUESTIONS
    financialControlsQuestions: {
      questions: [
        {
          id: 1,
          question: "Does your organization send and/or receive wire transfers?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_send_and_or_receive_wire_transfers?",
        },

        {
          id: 2,
          question: "A wire request documentation form?",
          choice: null,
          visible: false,
          topLine: false,
          key: "a_wire_request_documentation_form",
        },

        {
          id: 3,
          question: "A protocol for obtaining proper written authorization for wire transfers?",
          choice: null,
          visible: false,
          key: "a_protocol_for_obtaining_proper_written_authorization_for_wire_transfers",
        },

        {
          id: 4,
          question: "Separation of authority protocol?",
          choice: null,
          visible: false,
          key: "separation_of_authority_protocol",
        },

        {
          id: 5,
          question:
            "A protocol for confirming all payment or funds transfer instructions/requests from a new vendor, client or customer via direct call to that vendor, client or customer using only the telephone number provided by the vendor, client or customer before the payment or funds transfer instruction/request was received?",
          choice: null,
          visible: false,
          key: "a_protocol_for_confirming_all_payment_or_funds_transfer_instructions_requests_from_a_new_vendor_client_or_customer_via_direct_call_to_that_vendor_client_or_customer_using_only_the_telephone_number_provided_by_the_vendor_client_or_customer_before_the_payment_or_funds_transfer_instruction_request_was_received",
        },

        {
          id: 6,
          question:
            "A protocol for confirming any vendor, client or customer account information change requests (including requests to change bank account numbers, contact information or mailing addresses) via direct call to that vendor, client or customer using only the telephone number provided by the vendor, client or customer before the change request was received?",
          choice: null,
          visible: false,
          key: "a_protocol_for_confirming_any_vendor_client_or_customer_account_information_change_requests_including_requests_to_change_bank_account_numbers_contact_information_or_mailing_addresses_via_direct_call_to_that_vendor_client_or_customer_using_only_the_telephone_number_provided_by_the_vendor_client_or_customer_before_the_change_request_was_received",
        },

        {
          id: 7,
          question:
            "Does your organization confirm the instructions via a method other than the original means of the instruction?",
          choice: null,
          visible: false,
          key: "does_your_organization_confirm_the_instructions_via_a_method_other_than_the_original_means_of_the_instruction",
        },

        {
          id: 8,
          question:
            "Does your organization’s procedures require a review of all requests by a supervisor or next-level approver before processing fund transfer instructions?",
          choice: null,
          visible: false,
          topLine: true,
          bottomLine: true,
          key: "does_your_organizations_procedures_require_a_review_of_all_requests_by_a_supervisor_or_next_level_approver_before_processing_fund_transfer_instructions",
        },

        {
          id: 9,
          question:
            "Does your organization first confirm all requested changes requested by the vendor/supplier with a person other than the requestor prior to making any changes?",
          choice: null,
          visible: false,
          topLine: false,
          key: "does_your_organization_first_confirm_all_requested_changes_requested_by_the_vendor_supplier_with_a_person_other_than_the_requestor_prior_to_making_any_changes",
        },

        {
          id: 10,
          question:
            "Does your organization confirm requested changes via a method other than the original means of request?",
          choice: null,
          visible: false,
          key: "does_your_organization_confirm_requested_changes_via_a_method_other_than_the_original_means_of_request",
        },

        {
          id: 11,
          question:
            "Does your organization’s processes and procedures require review of all requests by a supervisor or next-level approver?",
          choice: null,
          visible: false,
          key: "does_your_organizations_processes_and_procedures_require_review_of_all_requests_by_a_supervisor_or_next_level_approver",
        },
      ],
    },

    // TELECOMMUNICATION SCREEN QUESTIONS
    telecommunicationQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          heading: "Please identify your organization’s telecommunications carrier:",
          type: "hasVendors",
          choice: [{ name: "", website: "" }],
          visible: true,
          topLine: false,
          showButtons: false,
          key: "please_identify_your_telecommunications_carrier",
        },

        {
          id: 2,
          question:
            "Has your organization established strong alphanumeric passwords for administrative controls of your telecommunications system?",
          choice: null,
          visible: true,
          topLine: false,
          key: "have_you_established_strong_alphanumeric_passwords_for_administrative_controls_of_your_telecommunications_system",
        },

        {
          id: 3,
          question:
            "Has your organization configured your telecommunications system to disable (check all that apply):",
          showButtons: false,
          visible: true,
          choice: [],
          optional: true,
          options: [
            "Remote system administration and Internet Protocol (IP) access",
            "Dialing via remote system access (DISA)",
          ],
          key: "have_you_configured_your_telecommunications_system_to_disable",
        },
      ],
    },

    // PAYMENT CARDS SCREEN QUESTIONS
    paymentCardsQuestions: {
      questions: [
        {
          id: 1,
          question: "Does your organization accept payment cards for goods sold or services rendered?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_the_applicant_accept_payment_cards_for_goods_sold_or_services_rendered",
        },
        {
          id: 2,
          question: "How many payment card transactions does your organization transact per year?",
          isTitleBold: true,
          choice: null,
          visible: false,
          showButtons: false,
          topLine: false,
          key: "how_many_payment_card_transactions_does_the_applicant_transact_per_year",
        },

        {
          id: 3,
          question:
            "Is your organization compliant with applicable data security standards issued by financial institutions your organization transacts business with (e.g. PCI standards)?",
          choice: null,
          visible: false,
          key: "is_the_applicant_compliant_with_applicable_data_security_standards_issued_by_financial_institutions_the_applicant_transacts_business_with_pci_standards",
          show: [
            {
              id: 4,
              showOn: "no",
              key: "if_the_applicant_is_not_compliant_with_applicable_data_security_standards_please_describe_the_current_status_of_any_compliance_work_and_the_estimated_date_of_completion",
            },
          ],
        },

        {
          id: 4,
          question:
            "If your organization is not compliant with applicable data security standards, please describe the current status of any compliance work and the estimated date of completion:",
          choice: null,
          visible: false,
          showButtons: false,
          isTitleBold: true,
          topLine: false,
          withInvertedBackground: true,
          key: "if_the_applicant_is_not_compliant_with_applicable_data_security_standards_please_describe_the_current_status_of_any_compliance_work_and_the_estimated_date_of_completion",
        },

        {
          id: 5,
          question:
            "Is payment card data encrypted at the point of sale (e.g., payment card reader or e-commerce payment portal) through transmission to the payment processor?",
          choice: null,
          visible: false,
          key: "is_payment_card_data_encrypted_at_the_point_of_sale_payment_card_reader_or_ecommerce_payment_portal_through_transmission_to_the_payment_processor",
        },
      ],
    },

    // CHOSEN DEVICES AND SYSTEMS QUESTIONS
    chosenDevicesAndSystemsQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          choice: [],
          visible: true,
          showButtons: false,
          topLine: false,
          key: "devices_and_systems_used_remotely",
          show: [
            {
              id: 2,
              showOn: ["desktopsAndLaptops"],
              key: "is_the_data_stored_on_remote_desktops_and_laptops_encrypted",
            },
            {
              id: 3,
              showOn: ["portableMedia"],
              key: "is_the_data_stored_on_portable_media_encrypted",
            },
          ],
        },

        {
          id: 2,
          heading: "Desktops & Laptops",
          withBackground: true,
          topLine: false,
          question: "Is the data stored on remote desktops and laptops encrypted?",
          choice: null,
          visible: false,
          key: "is_the_data_stored_on_remote_desktops_and_laptops_encrypted",
        },

        {
          id: 3,
          heading: "Portable Media",
          withBackground: true,
          topLine: false,
          question: "Is the data stored on portable media encrypted?",
          choice: null,
          visible: false,
          key: "is_the_data_stored_on_portable_media_encrypted",
        },
      ],
    },

    applicationInfrastructureQuestions: {
      questions: [
        // {
        //   id: 1,
        //   question: 'Does your organization use a cloud provider to store data or host applications?',
        //   choice: null,
        //   visible: true,
        //   topLine: false,
        //   key: 'does_your_organization_use_a_cloud_provider_to_store_data_or_host_applications'
        // },

        {
          id: 2,
          question: "",
          choice: [],
          visible: true,
          showButtons: false,
          topLine: false,
          key: "applications_and_infrastructure_in_the_cloud",
          show: [
            {
              id: 3,
              showOn: ["saas"],
              key: "does_your_organization_maintain_an_up_to_date_inventory_of_saas_applications_that_are_used_by_the_business",
            },
            {
              id: 4,
              showOn: ["paas"],
              key: "does_your_organization_enable_mfa_for_your_cloud_based_applications_and_infrastructure",
            },
            {
              id: 6,
              showOn: ["saas", "paas", "iaas"],
              key: "please_specify_the_cloud_provider_storing_the_largest_quantity_of_sensitive_customer_and/or_employee_records_including_medical_records_personal_health_information_social_security_numbers_bank_account_details_and_credit_card_numbers_for_your_organization",
            },
          ],
        },

        {
          id: 3,
          heading: "Saas",
          withBackground: true,
          question:
            "Does your organization maintain an up-to-date inventory of SaaS applications that are used by the business?",
          choice: null,
          visible: false,
          topLine: false,
          key: "does_your_organization_maintain_an_up_to_date_inventory_of_saas_applications_that_are_used_by_the_business",
        },

        {
          id: 4,
          heading: "PaaS",
          withBackground: true,
          topLine: false,
          question: "Does your organization enable MFA for your cloud-based applications and infrastructure?",
          extraButtons: ["some"],
          choice: null,
          visible: false,
          key: "does_your_organization_enable_mfa_for_your_cloud_based_applications_and_infrastructure",
        },

        {
          id: 5,
          question: "",
          choice: [],
          visible: false,
          showButtons: false,
          key: "which_platforms_as_a_service_does_your_organization_leverage",
        },

        {
          id: 6,
          withBackground: true,
          topLine: false,
          isTitleBold: true,
          question:
            "Please specify the cloud provider storing the largest quantity of sensitive customer and/or employee records (e.g., including medical records, personal health information, social security numbers, bank account details, and credit card numbers) for your organization.",
          showButtons: false,
          choice: null,
          visible: false,
          key: "please_specify_the_cloud_provider_storing_the_largest_quantity_of_sensitive_customer_and/or_employee_records_including_medical_records_personal_health_information_social_security_numbers_bank_account_details_and_credit_card_numbers_for_your_organization",
        },
      ],
    },

    // SERVICE ACCOUNT QUESTIONS
    serviceAccountQuestions: {
      questions: [
        {
          id: 1,
          question: "How many service accounts with Domain Administrator Privileges does your organization have?",
          isTitleBold: true,
          showButtons: false,
          topLine: false,
          choice: null,
          options: ["0", "1-5", "6-10", "more than 10"],
          visible: true,
          show: [
            {
              id: 2,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_configure_service_accounts_using_the_principle_of_least_privilege",
            },
            {
              id: 3,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_have_specific_monitoring_rules_in_place_for_services_accounts_to_alert_our_soc_of_abnormal_behaviors",
            },
            {
              id: 4,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_have_configured_service_accounts_to_deny_interactive_logins",
            },
            {
              id: 5,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_require_service_account_passwords_to_be_25_characters_and_above_or_randomly_generated",
            },
            {
              id: 6,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_require_passwords_of_services_accounts_to_be_rotated_regularly",
            },
            {
              id: 7,
              showOn: ["1-5", "6-10", "more than 10"],
              key: "we_manage_passwords_for_service_accounts_through_our_pam_solution_or_a_password_vault",
            },
          ],
          key: "how_many_service_accounts_with_domain_administrator_privileges_does_your_organization_have",
        },
        {
          id: 2,
          question: "We configure service accounts using the principle of least privilege",
          choice: null,
          visible: false,
          key: "we_configure_service_accounts_using_the_principle_of_least_privilege",
        },
        {
          id: 3,
          question:
            "We have specific monitoring rules in place for services accounts to alert our SOC of abnormal behavior(s)",
          choice: null,
          extraButtons: ["no SOC"],
          visible: false,
          key: "we_have_specific_monitoring_rules_in_place_for_services_accounts_to_alert_our_soc_of_abnormal_behaviors",
        },
        {
          id: 4,
          question: "We have configured service accounts to deny interactive logins",
          choice: null,
          visible: false,
          key: "we_have_configured_service_accounts_to_deny_interactive_logins",
        },
        {
          id: 5,
          question: "We require service account passwords to be 25 characters and above or randomly generated",
          choice: null,
          visible: false,
          key: "we_require_service_account_passwords_to_be_25_characters_and_above_or_randomly_generated",
        },
        {
          id: 6,
          question: "We require passwords of services accounts to be rotated regularly",
          choice: null,
          visible: false,
          key: "we_require_passwords_of_services_accounts_to_be_rotated_regularly",
        },
        {
          id: 7,
          question: "We manage passwords for service accounts through our PAM solution or a password vault",
          choice: null,
          visible: false,
          key: "we_manage_passwords_for_service_accounts_through_our_pam_solution_or_a_password_vault",
        },
        {
          id: 8,
          question: "Any additional information?",
          isTitleBold: true,
          showButtons: false,
          choice: null,
          visible: true,
          optional: true,
          key: "any_additional_information_optional",
        },
      ],
    },

    //ON-PREMISE INFRASTRUCTURE QUESTIONS
    onPremiseInfrastructureQuestions: {
      questions: [
        {
          id: 1,
          question: "",
          choice: [],
          visible: true,
          showButtons: false,
          topLine: false,
          key: "on_premise_infrastructure",
          show: [
            {
              id: 2,
              showOn: ["databases", "applications", "developmentEnvironments", "servers"],
              key: "is_the_data_stored_encrypted",
            },
          ],
        },
        {
          id: 2,
          topLine: false,
          question: "Is the data stored encrypted ?",
          choice: null,
          visible: false,
          key: "is_the_data_stored_encrypted",
        },
      ],
    },

    //LEGACY SOFTWARE STILL IN USE QUESTIONS
    legacySoftwareInUseQuestions: {
      questions: [
        {
          id: 1,
          question: "We have an asset discovery solution that continuously maps devices on our internal network",
          choice: null,
          visible: true,
          topLine: false,
          key: "asset_discovery_solution_map_devices_internal_network",
        },
        {
          id: 2,
          question: "We maintain an up-to-date asset database",
          choice: null,
          visible: true,
          show: [
            {
              id: 3,
              showOn: "yes",
              key: "maintain_asset_database_upto_date_answer_yes",
            },
          ],
          key: "maintain_asset_database_upto_date",
        },
        {
          id: 3,
          question: "",
          type: "hasVendors",
          choice: [{ name: "", website: "" }],
          visible: false,
          showButtons: false,
          topLine: false,
          key: "maintain_asset_database_upto_date_answer_yes",
        },
        {
          id: 4,
          question: "We have an up-to-date configuration management database (CMDB)",
          choice: null,
          visible: true,
          key: "configuration_management_database_upto_date",
        },
        {
          id: 5,
          question: "We have end-of-life software that we use or maintain",
          choice: null,
          visible: true,
          key: "end_of_life_software_use_maintain",
          show: [
            {
              id: 6,
              showOn: "yes",
              key: "end_of_life_software_segregated_from_network",
            },
            {
              id: 7,
              showOn: "yes",
              key: "purchased_additional_support_software_use_maintain",
            },
            {
              id: 8,
              showOn: "yes",
              key: "explain_end_of_life_platforms_in_use",
            },
            {
              id: 9,
              showOn: "yes",
              key: "explain_how_many_servers_workstations_devices_on_platform",
            },
            {
              id: 10,
              showOn: "yes",
              key: "explain_machines_store_process_transmit_support_critical_functions",
            },
            {
              id: 11,
              showOn: "yes",
              key: "explain_compensating_controls_in_use_protect_systems",
            },
            {
              id: 12,
              showOn: "yes",
              key: "explain_timeframe_when_company_migrate_off_platforms",
            },
            {
              id: 13,
              showOn: "yes",
              key: "process_decommission_unused_systems",
            },
            {
              id: 14,
              showOn: "yes",
              key: "explain_org_willing_implement_decommissioning_process_timeframe",
            },
          ],
        },
        {
          id: 6,
          question: "The end-of-life software is segregated from the rest of the network",
          choice: null,
          visible: false,
          extraButtons: ["Some"],
          key: "end_of_life_software_segregated_from_network",
        },
        {
          id: 7,
          question: "We purchased additional support for the end-of-life software we use or maintain",
          choice: null,
          visible: false,
          key: "purchased_additional_support_software_use_maintain",
        },
        {
          id: 8,
          question: "Which end-of-life platforms are in use? Please explain:",
          noBottomPadding: true,
          extraCls: "pb-0",
          isTitleBold: true,
          showButtons: false,
          choice: null,
          visible: false,
          key: "explain_end_of_life_platforms_in_use",
        },
        {
          id: 9,
          question:
            "How many servers, workstations, and devices are operating on each end-of-life platform? Please explain:",
          extraCls: "pb-0 pt-0",
          topLine: false,
          isTitleBold: true,
          showButtons: false,
          choice: null,
          visible: false,
          key: "explain_how_many_servers_workstations_devices_on_platform",
        },
        {
          id: 10,
          question:
            "Do these machines store, process, or transmit sensitive information or support critical business functions? Please explain:",
          extraCls: "pb-0 pt-0",
          topLine: false,
          choice: null,
          isTitleBold: true,
          showButtons: false,
          visible: false,
          key: "explain_machines_store_process_transmit_support_critical_functions",
        },
        {
          id: 11,
          question: "What compensating controls are in use to protect these systems? Please explain:",
          extraCls: "pb-0 pt-0",
          topLine: false,
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          key: "explain_compensating_controls_in_use_protect_systems",
        },
        {
          id: 12,
          question: "What is the timeframe for when the company will migrate off of these platforms? Please explain:",
          choice: null,
          extraCls: "pb-0 pt-0",
          topLine: false,
          isTitleBold: true,
          showButtons: false,
          visible: false,
          key: "explain_timeframe_when_company_migrate_off_platforms",
        },
        {
          id: 13,
          question: "We have a process to decommission unused systems",
          choice: null,
          visible: false,
          key: "process_decommission_unused_systems",
        },
        {
          id: 14,
          question:
            "Is your organization willing to implement a decommissioning process? What is the timeframe?  Please explain:",
          extraCls: "pb-0",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
          key: "explain_org_willing_implement_decommissioning_process_timeframe",
        },
        {
          id: 15,
          question: "Additional Information:",
          extraCls: "pb-0 pt-0",
          topLine: false,
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: true,
          optional: true,
          key: "additional_information",
        },
      ],
    },

    //  System Records Protect QUESTIONS
    systemRecordsProtectQuestions: {
      questions: [
        {
          id: 11,
          question: "Does your organization deploy End Point Detection and Response on 100% of their endpoints?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_deploy_end_point_detection_response_their_endpoints",
        },
        {
          id: 1,
          question:
            "Does your organization collect, process, store, transmit, or have access to any Payment Card Information (PCI), Personally Identifiable Information (PII), or Protected Health Information (PHI) other than employees of your organization?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: false,
          key: "does_your_organization_collect_process_store_transmit,_have_access_to_any_payment_card_information_personally_protected_health_information_other_than_employees_of_your_organization",
          show: [
            {
              id: 2,
              key: "how_many_pii_or_phi_records_does_your_organization_collect_process_store_transmit_or_have_access_to",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 2,
          question:
            "How many PII or PHI records does your organization collect, process, store, transmit, or have access to?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          isTitleBold: true,
          showButtons: false,
          withBackground: true,
          options: [
            "Less than 100,000",
            "100,001 - 250,000",
            "250,001 - 500,000",
            "500,001 - 1,000,000",
            "1,000,001 - 2,500,000",
            "2,500,001 - 5,000,000",
            "5,000,001 - 10,000,000",
            "10,000,001 or greater",
          ],
          key: "how_many_pii_or_phi_records_does_your_organization_collect_process_store_transmit_or_have_access_to",
        },
        {
          id: 3,
          question: "Does your organization accept payment cards in exchange for goods or services rendered?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_accept_payment_cards_in_exchange_for_goods_or_services_rendered",
          show: [
            {
              id: 4,
              key: "what_is_the_estimated_annual_volume_of_payment_card_transactions",
              showOn: ["yes"],
            },
            {
              id: 5,
              key: "is_your_organization_payment_card_industry_compliant_and_at_least_a_pci_merchant_level_2",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 4,
          question:
            "What is the estimated annual volume of payment card transactions (credit cards, debit cards, etc.)?",
          choice: null,
          visible: false,
          topLine: false,
          isTitleBold: true,
          // csgHidden: true,
          showButtons: false,
          withBackground: true,
          parentCls: "withParentBackground top-border-radius",
          questionCls: "my-0",
          options: [
            "Less than 100,000",
            "100,001 - 250,000",
            "250,001 - 500,000",
            "500,001 - 1,000,000",
            "1,000,001 - 2,500,000",
            "2,500,001 - 5,000,000",
            "5,000,001 - 10,000,000",
            "10,000,001 or greater",
          ],
          key: "what_is_the_estimated_annual_volume_of_payment_card_transactions",
        },
        {
          id: 5,
          question: "Is your organization's Payment Card Industry (PCI) compliant and at least a PCI Merchant level 2?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          key: "is_your_organization_payment_card_industry_compliant_and_at_least_a_pci_merchant_level_2",
          show: [
            {
              id: 6,
              key: "is_your_organization_outsourced_payment_processor_pci_compliant",
              showOn: ["no"],
            },
          ],
        },
        {
          id: 6,
          question: "Is your organization's outsourced payment processor PCI-compliant?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground bottom-border-radius",
          questionCls: "my-0",
          key: "is_your_organization_outsourced_payment_processor_pci_compliant",
        },
        {
          id: 7,
          question:
            "Does your organization deploy either end-to-end or point-to-point encryption technology on all of their point-of-sale terminals?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_deploy_either_end_to_end_or_point-to-point_encryption_technology_on_all_of_their_point-of-sale_terminals",
        },
        {
          id: 8,
          question: "Does your organization encrypt all sensitive information at rest?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_encrypt_all_sensitive_information_at_rest",
        },
        {
          id: 9,
          question: "Does your organization encrypt sensitive information stored in the cloud?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_encrypt_sensitive_information_stored_in_the_cloud",
        },
        {
          id: 10,
          question:
            "Does your organization encrypt all private and sensitive information stored on mobile devices (e.g., laptops, USB drives, tablets, etc.)?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_encrypt_all_private_and_sensitive_information_stored_on_mobile_devices",
        },
        {
          id: 12,
          question:
            "Does your organization verify all requests to change customer, vendor, or supplier details via a direct phone call?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_verify_all_request_to_change_customer_vendor_supplier_details_via_phone_calls",
        },
      ],
    },

    //  Organization Data Backed Up QUESTIONS
    orgDataBackedUpQuestions: {
      questions: [
        {
          id: 1,
          question:
            "Does your organization have procedures and tools to backup, archive, and restore sensitive data from critical systems?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_have_procedures_and_tools_to_backup_archive_and_restore_sensitive_data_from_critical_systems",
          show: [
            {
              id: 2,
              key: "how_frequently_are_your_organization_systems_backed_up",
              showOn: ["yes"],
            },
            {
              id: 3,
              key: "does_your_organization_utilize_a_local_backup_system",
              showOn: ["yes"],
            },
            {
              id: 9,
              key: "does_your_organization_utilize_a_cloud_backup_system",
              showOn: ["yes"],
            },
            {
              id: 4,
              key: "are_organization_backups_encrypted",
              showOn: ["yes"],
            },
            {
              id: 5,
              key: "are_your_organization_local_backups_isolated_from_the_network_or_air_gapped",
              showOn: ["yes"],
            },
            {
              id: 6,
              key: "does_your_organization_test_its_data_recovery_and_restoration_procedures",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 2,
          question: "How frequently are your organization's systems backed up?",
          topLine: false,
          showButtons: false,
          visible: false,
          choice: [],
          isTitleBold: true,
          // csgHidden: true,
          parentCls: "withParentBackground top-border-radius",
          questionCls: "my-0",
          extraCls: "mb-0 pb-0",
          withBackground: true,
          options: ["Daily", "Weekly", "Monthly", "Quarterly", "Other"],
          key: "how_frequently_are_your_organization_systems_backed_up",
        },
        {
          id: 3,
          question: "Does your organization utilize a local backup system?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_utilize_a_local_backup_system",
        },
        {
          id: 9,
          question: "Does your organization utilize a cloud backup system?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_utilize_a_cloud_backup_system",
        },
        {
          id: 4,
          question: "Are Organization’s backups encrypted?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          withBackground: true,
          key: "are_organization_backups_encrypted",
        },
        {
          id: 5,
          question: "Are your organization’s local backups isolated from the network or air-gapped?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          withBackground: true,
          key: "are_your_organization_local_backups_isolated_from_the_network_or_air_gapped",
        },
        {
          id: 6,
          question: "Does your organization test its data recovery and restoration procedures?",
          choice: null,
          visible: false,
          topLine: false,
          // csgHidden: true,
          parentCls: "withParentBackground bottom-border-radius",
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_test_its_data_recovery_and_restoration_procedures",
        },
        {
          id: 7,
          question:
            "Does your organization maintain a Recovery Time Objective (RTO) for critical systems, applications, and processes of 10 days or less?",
          choice: null,
          visible: true,
          topLine: false,
          // csgHidden: true,
          key: "does_your_organization_maintain_a_recovery_time_objective_for_critical_systems_applications_and_processes_of_10_days_or_less",
        },
      ],
    },

    //  Organization Security Handle QUESTIONS
    orgSecurityHandleQuestions: {
      questions: [
        {
          id: 1,
          question: "Does your organization implement any Multi-Factor Authentication (MFA)?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_implement_any_multi_factor_authentication",
          show: [
            {
              id: 2,
              key: "does_your_organization_have_mfa_enabled_on_email_access",
              showOn: ["yes"],
            },
            {
              id: 3,
              key: "does_your_organization_have_mfa_enabled_for_remote_network_access",
              showOn: ["yes"],
            },
            {
              id: 4,
              key: "does_your_organization_require_mfa_for_all_privileged_access_to_its_network",
              showOn: ["yes"],
            },
          ],
        },

        {
          id: 2,
          question: "Does your organization have MFA enabled on email access?",
          choice: null,
          visible: false,
          topLine: false,
          parentCls: "withParentBackground top-border-radius",
          isTitleBold: true,
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_have_mfa_enabled_on_email_access",
        },

        {
          id: 3,
          question: "Does your organization have MFA enabled for remote network access?",
          choice: null,
          visible: false,
          topLine: false,
          parentCls: "withParentBackground",
          isTitleBold: true,
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_have_mfa_enabled_for_remote_network_access",
        },

        {
          id: 4,
          question: "Does your organization require MFA for all privileged access to its network?",
          choice: null,
          visible: false,
          topLine: false,
          parentCls: "withParentBackground bottom-border-radius",
          isTitleBold: true,
          questionCls: "my-0",
          withBackground: true,
          key: "does_your_organization_require_mfa_for_all_privileged_access_to_its_network",
        },

        {
          id: 9,
          question: "Does your organization use firewall & antivirus software to protect their computers?",
          choice: null,
          visible: true,
          topLine: false,
          key: "does_your_organization_use_firewall_antivirus_software_to_protect_their_computer",
        },

        {
          id: 5,
          question: "Does your organization encrypt all external communications containing sensitive information?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_encrypt_all_external_communications_containing_sensitive_information",
        },
        {
          id: 6,
          question:
            "Do the organization’s agreements with third-party service providers require levels of security commensurate with the organization’s information security standard?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "do_the_organization’s_agreements_with_third_party_service_providers_require_levels_of_security_commensurate_with_the_organization_information_security_standard",
        },
        {
          id: 7,
          question: "Does your organization require a virtual private network (VPN) for access?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "des_your_organization_require_a_virtual_private_network_for_access",
        },
        {
          id: 8,
          question: "Does your organization prohibit end users from running administrative actions on endpoints?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_prohibit_end_users_from_running_administrative_actions_on_endpoints",
        },
        {
          id: 10,
          question:
            "How often does your organization apply updates to critical IT systems and applications, i.e., security patching? (Select all that apply)",
          choice: [],
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          showButtons: false,
          extraCls: "pb-0",
          options: ["Daily", "Weekly", "Monthly", "Quarterly", "Other"],
          key: "how_often_does_your_organization_apply_updates_to_critical_it_systems_and_applications_security_patching",
        },
        {
          id: 11,
          question: "Does your organization prohibit local administrator privileges for all users?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_prohibit_local_administrator_privileges_for_all_users",
        },
        {
          id: 12,
          question:
            "Does your organization have any end-of-life (EOL) or end-of-support (EOS) software on its network?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_have_any_end_of_life_or_end_of_support_software_on_its_network",
          show: [
            {
              id: 13,
              key: "is_the_software_segregated_from_the_rest_of_the_network",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 13,
          question: "Is the software segregated from the rest of the network?",
          choice: null,
          visible: false,
          topLine: false,
          indicationSetupHidden: true,
          withBackground: true,
          isTitleBold: true,
          questionCls: "pt-1 pb-1",
          key: "is_the_software_segregated_from_the_rest_of_the_network",
        },
        {
          id: 14,
          question:
            "Are all systems accessible via the internet (i.e. email servers segregated from your organization\\'s trusted network; or i.e. within a demilitarized zone (DMZ) or a third-party service provider)?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "are_all_systems_accessible_via_the_internet",
        },
        {
          id: 15,
          question: "Does your organization address Zero-Day/Critical risk vulnerabilities within 7 days?",
          choice: null,
          visible: true,
          topLine: false,
          indicationSetupHidden: true,
          csgHidden: true,
          indicationAdditionalInfoShow: true,
          key: "does_your_organization_address_zero_day_within_7_days",
        },
      ],
    },

    //  Organization Recent Incidents Experienced QUESTIONS
    orgRecentIncidentsExperiencedQuestions: {
      questions: [
        {
          id: 1,
          question:
            "In the last five (5) years, has your organization experienced any event, loss, interruption, or been the subject of any claim made for a wrongful act that would fall within the scope of the Policy for which the organization is applying?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "in_the_last_five_years_has_your_organization_experienced_any_event_loss_interruption_or_been_the_subject_of_any_claim_made_for_a_wrongful_act_that_would_fall_within_the_scope_of_the_policy_for_which_the_organization_is_applying",
          show: [
            {
              id: 2,
              key: "scope_policy_experienced_of_any_fact_event_wrongful_act_amount",
              showOn: ["yes"],
            },
            {
              id: 3,
              key: "scope_policy_experienced_of_any_fact_event_wrongful_act_type",
              showOn: ["yes"],
            },
            {
              id: 4,
              key: "scope_policy_experienced_of_any_fact_event_please_provide_claim_details",
              showOn: ["yes"],
            },
            {
              id: 5,
              key: "scope_policy_experienced_of_any_fact_event_years_since_the_last_claim",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 2,
          question: "Wrongful Act Amount",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          showButtons: false,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground top-border-radius",
          questionCls: "my-0",
          options: [
            "$0",
            "$1 - $10,000",
            "$10,001 - $25,000",
            "$25,001 - $50,000",
            "$50,001 - $100,000",
            "$100,001 - $250,000",
            "$250,001 - $500,000",
            "$500,001 or more",
          ],
          key: "scope_policy_experienced_of_any_fact_event_wrongful_act_amount",
        },
        {
          id: 3,
          question: "Wrongful Act Type",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          showButtons: false,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          options: ["Cyber Crime or Breach", "Information Breach", "Technology Threat", "Fraud Threat"],
          key: "scope_policy_experienced_of_any_fact_event_wrongful_act_type",
        },
        {
          id: 4,
          question: "Please provide claim details",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground",
          questionCls: "my-0",
          showButtons: false,
          key: "scope_policy_experienced_of_any_fact_event_please_provide_claim_details",
        },
        {
          id: 5,
          question: "How many years has it been since your last claim?",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          withBackground: true,
          isTitleBold: true,
          parentCls: "withParentBackground bottom-border-radius",
          questionCls: "my-0",
          showButtons: false,
          validationType: "isRequired",
          key: "scope_policy_experienced_of_any_fact_event_years_since_the_last_claim",
        },
        {
          id: 6,
          question:
            "Is your organization aware of any fact, circumstance, situation, event, or wrongful act that reasonably could give rise to a cyber event, loss, or a claim being made against them that would fall within the scope of the policy for which the organization is applying?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "is_your_organization_aware_of_any_fact_circumstance_situation_event_or_wrongful_act_that_reasonably_could_give_rise_to_a_cyber_event_loss_or_a_claim_being_made_against_them_that_would_fall_within_the_scope_of_the_policy_for_which_the_organization_is_applying",
          show: [
            {
              id: 7,
              key: "scope_policy_aware_of_any_fact_event_please_provide_type",
              showOn: ["yes"],
            },
            {
              id: 8,
              key: "scope_policy_aware_of_any_fact_event_please_provide_the_claim_details",
              showOn: ["yes"],
            },
          ],
        },
        {
          id: 7,
          question: "Please provide type",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          showButtons: false,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground top-border-radius",
          questionCls: "my-0",
          options: ["Cyber Crime or Breach", "Ransomware Breach", "Network Breach", "Privacy Breach"],
          key: "scope_policy_aware_of_any_fact_event_please_provide_type",
        },
        {
          id: 8,
          question: "Please provide the claim details",
          choice: null,
          visible: false,
          topLine: false,
          csgHidden: false,
          isTitleBold: true,
          withBackground: true,
          parentCls: "withParentBackground bottom-border-radius",
          questionCls: "my-0",
          showButtons: false,
          key: "scope_policy_aware_of_any_fact_event_please_provide_the_claim_details",
        },
        {
          id: 9,
          question:
            "Has your organization or any other organization proposed for this insurance sustained any unscheduled network outage or interruption lasting longer than six hours within the past twenty-four months?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "has_your_organization_or_any_other_organization_proposed_for_this_insurance_sustained_any_unscheduled_network_outage_or_interruption_lasting_longer_than_six_hours_within_the_past_twenty_four_months",
        },
        {
          id: 10,
          question:
            "Does anyone in your organization or any other person or organization proposed for this insurance know of any security breach, privacy breach, privacy-related event or incident, or allegations of breach of privacy that may give rise to a claim?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "does_anyone_in_your_organization_or_any_other_person_or_organization_proposed_for_this_insurance_know_of_any_security_breach_privacy_breach_privacy_related_event_or_incident_or_allegations_of_breach_of_privacy_that_may_give_rise_to_a_claim",
        },
        {
          id: 11,
          question:
            "Has your organization received any claims or complaints with respect to privacy, breach of information or network security, or unauthorized disclosure of information?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "has_your_organization_received_any_claims_or_complaints_with_respect_to_privacy_breach_of_information_or_network_security_or_unauthorized_disclosure_of_information",
        },
        {
          id: 12,
          question:
            "Has your organization been subject to any government action, investigation, or subpoena regarding any alleged privacy law or regulation violation?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "has_your_organization_been_subject_to_any_government_action_investigation_or_subpoena_regarding_any_alleged_privacy_law_or_regulation_violation",
        },
        {
          id: 13,
          question:
            "Has your organization notified consumers or any other third party of a data breach incident involving your organization?",
          choice: null,
          visible: true,
          topLine: false,
          csgHidden: false,
          key: "has_your_organization_notified_consumers_or_any_other_third_party_of_a_data_breach_incident_involving_your_organization",
        },
      ],
    },
  }),

  mutations: {
    updateField,
  },

  actions: {},

  getters: {
    getField,
  },
};

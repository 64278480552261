<template>
  <v-container fluid>
    <v-row class="without-auth-main-bg d-block">
      <background-shapes v-if="!hideBranding" />
      <top-bar-without-auth :showLogin="!showTerms" />
      <v-col cols="12" class="d-flex align-baseline justify-center pt-8">
        <v-row class="pa-0 login-signup-form-container align-baseline justify-center" v-if="!showTerms">
          <h2 class="outfit-bold font-700 text-40 font-700 text-light-black mb-5">Annual Subscription</h2>
          <v-col cols="12" md="12" class="px-6 login-signup-form-outer">
            <subscription-form
              :outlined="true"
              :showOutsideLabel="true"
              @submitForPayment="submitForPayment"
              @toggleTermsPolicy="toggleTermsPolicy"
            />
          </v-col>
          <img
            :src="require('@/assets/images/shapes/green-circle.png')"
            style="position: absolute; left: -26px; top: 44px; z-index: 0; max-width: 48px"
          />
        </v-row>
        <signup-payment-terms v-else @toggleTermsPolicy="toggleTermsPolicy" />
      </v-col>
      <app-footer />
    </v-row>
  </v-container>
</template>

<script>
import appSettings from "../../../app.settings";
import AppFooter from "../../components/Base/AppFooter.vue";
import localService from "@/services/localService";
import SubscriptionForm from "@/components/Common/SubscriptionForm.vue";
import { mapGetters } from "vuex";
import BackgroundShapes from "@/components/General/BackgroundShapes.vue";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import SignupPaymentTerms from "@/components/General/SignupPaymentTerms.vue";
import { hideBranding } from "../../helpers";

export default {
  name: "Subscription",
  components: {
    SignupPaymentTerms,
    TopBarWithoutAuth,
    BackgroundShapes,
    AppFooter,
    SubscriptionForm,
  },
  data: () => ({
    hideBranding,
    userInfo: null,
    elevate: false,
    showTerms: false,
  }),
  computed: {
    appInfo() {
      return appSettings;
    },
  },
  mounted() {
    let userInfo = localService.getItem("s2r_signUpUserInfo");
    if (!userInfo) {
      this.$router.push({ path: "/signup" });
      return;
    }

    this.userInfo = userInfo;
  },
  methods: {
    submitForPayment(paymentForm) {
      const user = localService.getItem("userInfo");
      this.userInfo = localService.getItem("s2r_signUpUserInfo");
      this.$store.dispatch("login/subscribe", {
        paymentForm,
        userInfo: user ?? this.userInfo,
      });
    },
    toggleTermsPolicy() {
      this.showTerms = !this.showTerms;
    },
  },
};
</script>

<style scoped lang="scss">
.main-bg {
  background: #f4f7f9;
  min-height: 100vh;
}

.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}
.payment-form-container {
  max-width: 700px;
}
</style>

import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";
import localService from "@/services/localService";
import { vm } from "../main";

const digitalAssetForm = () => {
  return {
    name: null,
    digital_asset_usage: [],
    platform: null,
    impact: null,
    user: null,
    submitted: false,
  };
};

export default {
  namespaced: true,
  state: () => ({
    dialogAssetShow: false,
    digitalAssetUsages: [],
    digitalAssetPlatforms: [
      {
        abbreviation: "PaaS",
        description:
          "You access pre-built components online instead of installing software, and have the ability to directly manage your own data. (Example: Cloudera CDP)",
        platform: "Platform as a Service",
        color: "#E8FBE1",
      },
      {
        abbreviation: "IaaS",
        description:
          "You install software, but you don't own the hardware. You access virtualized cloud resources over the internet. (Example: AWS EC2, Microsoft Azure, Google Cloud Platform)",
        platform: "Infrastructure as a Service",
        color: "#ECE3FC",
      },
      {
        abbreviation: "On-Premise",
        description:
          "You own the physical hardware and execute all software. Your company retains access to the physical machines (Example: Private Corporate Colocation)",
        platform: "On-Premise",
        color: "#FCEBF6",
      },
      {
        abbreviation: "SaaS",
        description:
          "You do not install any software. Instead, you go to a website in order to gain access. All data is stored behind the software. (Example: Salesforce, OneDrive)",
        platform: "Software as a Service",
        color: "#FAF8DF",
      },
    ],
    digitalAssets: [],
    clients: [],
    clientCreateRequest: false,
    usageList: [
      {
        description: "Collaboration",
        hint: "In this category the software and technologies your organization uses to collaborate should be identified.",
      },
      {
        description: "Communications",
        hint: "This category should contain software and technologies required for communication within your organization.",
      },
      {
        description: "Finance & Accounting",
        hint: "This category should contain the software and technology your organization uses to manage and analyze all aspects of finance and accounting.",
      },
      {
        description: "Human Resources",
        hint: "Software and technology that is used to manage human resources activities for your organization.",
      },
      {
        description: "IT & Cybersecurity",
        hint: "This category contains all software and technology your organization uses to design, develop, maintain, protect and provision software, technology and infrastructure to the users of your organization.",
      },
      {
        description: "Operations",
        hint: "This category encompasses any technology your organization uses to plan and manage work, human resources, as well as legal documents and artifacts.",
      },
      {
        description: "Proprietary",
        hint: "This category contains any homegrown developed or special technologies that might not be obvious to those outside of your organization.",
      },
      {
        description: "Sales & Marketing",
        hint: "Software and technology that is used to manage your sales activities, planning, as well as the martech stack for your organization.",
      },
    ],
    usageOverview: [],
    readinessChecklistOverview: [
      {
        complete: "0",
        description: "Digital Asset Verification",
        hint: "Confirm the digital assets your business uses.",
        due_date: "08-15-2022",
        link: "/user/verification-workflow",
      },
      {
        complete: "0",
        description: "Digital Asset Enrichment",
        hint: "Enrich your digital asset inventory with critical information to support digital risk management.",
        due_date: "08-17-2022",
        link: "/user/enrichment-workflow",
      },
      // {
      //   complete: "0",
      //   description: "Control Certification",
      //   hint: "Self-certify cybersecurity controls by organization and critical asset.",
      //   due_date: "08-17-2022",
      //   link: "/user/controls-certification",
      // },
    ],
    selectedUsage: null,
    impactItems: [
      {
        name: "Critical to our operations",
        shortName: "Critical",
        value: 1,
        hint: "Loss of access to this digital asset, even briefly (less than one day) would materially impact the ability of the business to generate revenue or conduct core operational activities.",
      },
      {
        name: "Necessary to our operations",
        shortName: "Necessary",
        value: 2,
        hint: "Losing access to this digital asset would materially impact the ability of the business to generate revenue or conduct core operational activities between 1-5 days of being unavailable.",
      },
      {
        name: "Not Important, but used in our business",
        shortName: "Not important",
        value: 3,
        hint: "Losing access to this digital asset would not materially impact the ability of the business to generate revenue or conduct core operational activities either because a workaround exists or the digital asset does not drive a core business function.",
      },
      {
        name: "Not used in our business",
        shortName: "Not used",
        value: 4,
        hint: "This digital asset is not currently used by our organization in any capacity.",
      },
    ],
    digitalAssetForm: digitalAssetForm(),
    impactFilter: null,
    clientFilter: null,
    historyAsset: null,
    platformFilter: [],
    verifiedStatusFilter: [],
    impactItemsFilter: [],
    selectedView: "grid",
    searchTerm: "",
    digitalAssetSidePanel: false,
    digitalAssetDeleteDialog: false,
    assetDelegatedTo: null,
    isEditing: false,
    isVerifying: false,
    selectedAsset: null,
    loadingAssetVerification: false,
  }),

  mutations: {
    updateField,

    // updateDigitalAsset accepts a list and does three things with every item:
    // updates the state item from the payload item
    // or removes from the state if active = False
    // adds it to the list if its not found in the state
    updateDigitalAssets(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        // first check if the asset exists in the state
        let foundIndex = state.digitalAssets.findIndex((x) => x.entity_id == payload[i].entity_id);

        let updatedItem = Object.assign({}, payload[i]);

        if (foundIndex > -1) {
          Vue.set(state.digitalAssets, foundIndex, updatedItem);
        }

        // console.log({ foundIndex, updatedItem });

        // // if the asset was found in the state but active is false, remove it
        // if (foundIndex > -1 && updatedItem.active == 0) {
        //   state.digitalAssets.splice(foundIndex, 1);
        // } else if (foundIndex > -1) {
        //   // if the asset exists in the state, update it
        //   Vue.set(state.digitalAssets, foundIndex, updatedItem);
        // } else {
        //   // if the asset does not exist in the state, add it
        //   state.digitalAssets.push(updatedItem);
        // }
      }
    },

    // mark all entries in digitalAssets as checked/unchecked by using Vue.set in a for (i = 0) loop
    checkAllDigitalAssets(state, payload) {
      for (var i = 0; i < state.digitalAssets.length; i++) {
        Vue.set(state.digitalAssets[i], "checked", payload);
      }
    },

    selectedUsage: (state, payload) => {
      state.selectedUsage = payload;
    },

    digitalAssetUsages: (state, payload) => {
      state.digitalAssetUsages = payload;
    },

    digitalAssets: (state, payload) => {
      state.digitalAssets = payload;
    },

    clients: (state, payload) => {
      state.clients = payload;
    },

    updateOrganizationClients: (state, client) => {
      state.clients.push(client);
    },

    deleteOrganizationClients: (state, client) => {
      var foundIndex = state.clients.findIndex((x) => x.entity_id == client.entity_id);
      state.clients.splice(foundIndex, 1);
    },

    addDigitalAsset(state, payload) {
      state.digitalAssets.push(payload);
    },

    setImpact(state, { id, value }) {
      var foundIndex = state.digitalAssets.findIndex((x) => x.entity_id == id);
      state.digitalAssets[foundIndex].impact = value;
    },

    updateDigitalAsset(state, payload) {
      var foundIndex = state.digitalAssets.findIndex((x) => x.entity_id == payload.entity_id);
      state.digitalAssets[foundIndex] = payload;
    },

    deleteDigitalAsset(state, payload) {
      var foundIndex = state.digitalAssets.findIndex((x) => x.entity_id == payload.entity_id);
      state.digitalAssets.splice(foundIndex, 1);
    },

    updateSubmittedDigitalAsset(state, payload) {
      var digitalAssets = Object.assign([], state.digitalAssets);
      var foundIndex = null;
      payload.selectedAsssets.map((data) => {
        foundIndex = digitalAssets.findIndex((x) => x.entity_id == data.entity_id);
        if (foundIndex > -1) digitalAssets[foundIndex].submitted = 1;
      });
      if (payload.skippedAssets) {
        payload.skippedAssets.map((data) => {
          foundIndex = digitalAssets.findIndex((x) => x.entity_id == data.entity_id);
          if (foundIndex > -1) {
            digitalAssets[foundIndex].skipSubmit = true;
            digitalAssets[foundIndex].checklist = true;
          }
        });
      }
      state.digitalAssets = digitalAssets;
    },

    updateMultipleDigitalAsset(state, digitalAssetsPayload) {
      var digitalAssets = Object.assign([], state.digitalAssets);
      var foundIndex = null;
      digitalAssetsPayload.map((data) => {
        foundIndex = digitalAssets.findIndex((x) => x.entity_id == data.entity_id);
        digitalAssets[foundIndex] = data;
        digitalAssets[foundIndex].bulkEdit = true;
        digitalAssets[foundIndex].checklist = true;
      });
      state.digitalAssets = digitalAssets;
    },

    saveHistoryAsset(state, payload) {
      let history = state.digitalAssets.find((x) => x.entity_id == payload.entity_id) ?? payload;
      state.historyAsset = Object.assign({}, history);
    },

    restoreHistoryDigitalAsset(state, payload) {
      var foundIndex = state.digitalAssets.findIndex((x) => x.entity_id == payload.entity_id);
      state.digitalAssets[foundIndex] = Object.assign({}, state.historyAsset);
      state.historyAsset = null;
    },

    addClient(state, payload) {
      state.clientCreateRequest = true;
      state.clients.push(payload);
    },

    updateClientFilter(state, payload) {
      state.clientFilter = payload;
    },

    updateAddedClient(state, payload) {
      var foundIndex = state.clients.findIndex((e) => e.email == payload.user.email && e.entity_id == payload.uuid);
      if (foundIndex > -1) {
        if (payload.delete) {
          state.clients.splice(foundIndex, 1);
        } else {
          state.clients[foundIndex] = payload.user;
        }
      }
      state.clientCreateRequest = false;
    },

    addDigitalAssetUsage(state, payload) {
      state.digitalAssetUsages.push(payload);
    },

    resetDigitalAssetForm(state) {
      state.isVerifying = false;
      state.isEditing = false;
      state.digitalAssetForm = digitalAssetForm();
      state.assetDelegatedTo = null;
    },

    resetFilter(state) {
      state.clientFilter = null;
      state.impactFilter = null;
    },

    usageOverview(state, payload) {
      state.usageOverview = payload;
    },

    readinessChecklistOverview(state, payload) {
      state.readinessChecklistOverview[0].complete = payload.digital_asset_discovery;
      state.readinessChecklistOverview[1].complete = payload.digital_asset_enrichment;
      // state.readinessChecklistOverview[2].complete =
      //   payload.controls_certification;
    },

    updateDigitalAssetDelegate(state, payload) {
      let foundIndex = state.digitalAssets.findIndex((e) => e.entity_id == payload.item_id);
      vm.$set(state.digitalAssets[foundIndex], "delegated_user_id", payload.user_id);
    },
  },

  getters: {
    getField,

    digitalAssetUsages(state) {
      return state.digitalAssetUsages;
    },

    digitalAssets(state) {
      return state.digitalAssets;
    },

    clients(state) {
      return state.clients;
    },

    clientCreateRequest(state) {
      return state.clientCreateRequest;
    },
  },

  actions: {
    setHistoryAsset({ commit }, payload) {
      commit("saveHistoryAsset", payload);
    },

    restoreHistoryAsset({ commit }, payload) {
      commit("restoreHistoryDigitalAsset", payload);
    },

    async getDigitalAssetUsages({ commit }) {
      const { data } = await axios.get("digital_assets/usage");
      commit("digitalAssetUsages", data.data);
    },

    async postDigitalAssetUsages({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("digital_assets/digital_asset_usage", {
          description: payload,
        });
        if (!data.success) {
          return;
        }
        commit("addDigitalAssetUsage", data.usage);
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getOrganizationReadiness({ commit }, organizationId = null) {
      let url = "get_organization_readiness";
      if (organizationId) {
        url = `get_organization_readiness/${organizationId}`;
      }
      const { data } = await axios.get(url);
      commit("readinessChecklistOverview", data.results);
    },

    async getDigitalAssets({ commit }, organizationId = null) {
      let url = "digital_assets/digital_asset";
      if (organizationId) {
        url = `digital_assets/${organizationId}/digital_asset`;
      }
      const { data } = await axios.get(url);
      data.data?.map((x) => (x.checked = false));
      commit("digitalAssets", data.data);
    },

    async getDigitalAssetUsageOverview({ commit }, organizationId = null) {
      let url = "digital_assets/usage_overview";
      if (organizationId) {
        url = `/digital_assets/${organizationId}/usage_overview`;
      }
      const { data } = await axios.get(url);
      commit("usageOverview", data.data);
    },

    async updateDigitalAsset({ commit }, payload) {
      if (payload.impact == 4) commit("deleteDigitalAsset", payload);
      const { data } = await axios.put("digital_assets/digital_asset", [payload]);
      return data;
    },

    async submitDigitalAsset({ commit, state, dispatch }, payload) {
      let noUpdate = false;
      let bulkEdit = false;
      let formData = [];
      let selectedAsssets = [];
      if (payload.noUpdate || typeof payload.bulkEdit != "undefined") {
        noUpdate = payload.noUpdate ? true : false;
        bulkEdit = payload.bulkEdit == true ? true : false;
        formData = Object.assign([], payload.selectedAsssets);
        selectedAsssets = Object.assign([], payload.selectedAsssets);
      } else {
        formData = Object.assign([], payload);
        selectedAsssets = Object.assign([], payload);
      }

      for (let i = 0; i < formData.length; i++) {
        formData[i] = state.digitalAssets.find((x) => x.entity_id == formData[i].entity_id);
        if (!bulkEdit) {
          formData[i].submitted = 1;
        }
        if (selectedAsssets[i].impact) {
          formData[i].impact = selectedAsssets[i].impact;
        }
        if (selectedAsssets[i].user) {
          if (selectedAsssets[i].user && selectedAsssets[i].user.email) {
            formData[i].user = state.clients.find((e) => e.email == selectedAsssets[i].user.email).entity_id;
          } else {
            formData[i].user = selectedAsssets[i].user;
          }
        }
      }

      try {
        commit("startLoading", null, { root: true });
        for (var i = formData.length - 1; i >= 0; i--) {
          formData[i].active = Number(formData[i].impact != 4);
        }
        if (!noUpdate && !bulkEdit) {
          commit("updateSubmittedDigitalAsset", {
            selectedAsssets: formData,
            skippedAssets: payload.skippedAssets,
          });
        } else if (bulkEdit) {
          commit("updateMultipleDigitalAsset", formData);
        }
        let { data } = await axios({
          method: "put",
          url: "digital_assets/digital_asset",
          data: formData,
        });

        if (!data.success) {
          return;
        }
        commit("resetFilter");
        let message = "Digital assets updated successfully.";
        dispatch("showMessage", { message: message, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async unSubmitDigitalAsset({ commit, state, dispatch }, payload) {
      let formData = [];
      let selectedAsssets = Object.assign([], payload);
      formData = Object.assign([], payload);

      for (let i = 0; i < formData.length; i++) {
        formData[i] = state.digitalAssets.find((x) => x.entity_id == formData[i].entity_id);
        formData[i].submitted = 0;
        if (selectedAsssets[i].impact) {
          formData[i].impact = selectedAsssets[i].impact;
        }
        if (selectedAsssets[i].user) {
          if (selectedAsssets[i].user && selectedAsssets[i].user.email) {
            formData[i].user = state.clients.find((e) => e.email == selectedAsssets[i].user.email).entity_id;
          } else {
            formData[i].user = selectedAsssets[i].user;
          }
        }
      }

      try {
        commit("startLoading", null, { root: true });
        console.log("formData ", formData);
        let { data } = await axios({
          method: "put",
          url: "digital_assets/digital_asset",
          data: formData,
        });

        if (!data.success) {
          return;
        }
        commit("resetFilter");
        let message = "Digital asset unverified successfully.";
        return dispatch(
          "showMessage",
          { message: message, messageType: "success", messageTitle: "Success" },
          { root: true }
        );
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    resetFilter({ commit }) {
      commit("resetFilter");
    },

    updateDigitalAssets({ commit }, payload) {
      commit("updateDigitalAssets", payload);
    },

    async saveDigitalAsset({ commit, dispatch, state }, payload) {
      payload.active = Number(payload.impact != 4);
      let formData = null;
      let requestType = "post";
      if (payload.entity_id) {
        requestType = payload.active ? "put" : "delete";
        formData = [];
        formData.push(state.digitalAssets.find((x) => x.entity_id == payload.entity_id));
      } else {
        formData = payload;
      }
      try {
        commit("startLoading", null, { root: true });
        let { data } = await axios({
          method: requestType,
          url: "digital_assets/digital_asset",
          data: formData,
        });

        if (!data.success) {
          return;
        }
        if (data.digital_asset) {
          data.digital_asset.checked = false;
        }
        if (payload.entity_id) {
          commit("updateDigitalAsset", data.digital_asset);
        } else {
          commit("addDigitalAsset", data.digital_asset);
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getOrganizationClients({ commit }, organizationId = null) {
      let url = "/organization_management/client";
      if (organizationId) {
        url = `/organization_management/${organizationId}/client`;
      }
      const { data } = await axios.get(url);
      commit("clients", data.results);
    },

    updateOrganizationClients({ commit }, payload) {
      commit("updateOrganizationClients", payload);
    },

    deleteOrganizationClients({ commit }, payload) {
      commit("deleteOrganizationClients", payload);
    },

    async postOrganizationClients({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        commit("addClient", payload);
        if (payload.filter) {
          commit("updateClientFilter", {
            email: payload.email,
            entity_id: payload.entity_id,
            uuid: payload.entity_id,
            name: payload.name,
          });
        }
        var payloadData = Object.assign({}, payload);
        var uuid = payload.entity_id;
        payloadData.entity_id = null;
        const { data } = await axios.post("organization_management/client", payload);
        if (!data.success) {
          commit("updateAddedClient", { user: payload, uuid, delete: true });
          if (payload.filter) {
            commit("updateClientFilter", null);
          }
          return;
        }
        commit("updateAddedClient", { user: data.user, uuid });
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        if (payload.filter) {
          dispatch("getOrganizationClients").then(() => {
            commit("updateClientFilter", data.user.entity_id);
          });
        }
        return data;
      } catch (e) {
        commit("updateAddedClient", { user: payload, uuid, delete: true });
        if (payload.filter) {
          commit("updateClientFilter", null);
        }
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteDigitalAssetOverlay({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete("overlay/" + payload.entity_id);
        if (!data.success) {
          return;
        }
        let userInfo = localService.getItem("userInfo");
        let index = userInfo.user.overlays.findIndex((e) => e.entity_id == payload.entity_id);
        userInfo.user.overlays.splice(index, 1);
        localService.setItem("userInfo", userInfo);
        commit("login/setState", { key: "currentUser", value: userInfo }, { root: true });
        if (payload.description == "instructional_overview") {
          vm.$router.push({ path: "/user/readiness-checklist" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async exportDigitalAsset({ commit }, organizationId = null) {
      try {
        commit("startLoading", true, { root: true });
        let url = "/digital_assets/export";
        if (organizationId) {
          url = `/digital_assets/${organizationId}/export`;
        }
        const { data } = await axios.get(url, {
          responseType: "blob",
        });
        return data;
      } catch (e) {
        console.log(e);
        commit("stopLoading", null, { root: true });
      }
    },

    setSelectedUsage({ commit }, payload) {
      commit("selectedUsage", payload);
    },

    setImpact({ commit }, { id, value }) {
      commit("setImpact", { id, value });
    },

    async postDigitalAssetCategory({ commit, state }, payload) {
      try {
        commit("startLoading", true, { root: true });
        await axios({
          method: "post",
          url: "digital_assets/digital_asset_category",
          data: payload,
        });
      } catch (e) {
        console.log(e);
        commit("stopLoading", null, { root: true });
      }
    },

    async delegateVerificationUsageChecklist({ commit }, payload) {
      const { data } = await axios.post(`/organization_management/delegate/digital_asset_usage_type`, payload);
      return data;
    },

    async delegateVerificationDigitalAsset({ commit }, payload) {
      const { data } = await axios.post(`/organization_management/delegate/digital_asset`, payload);
      return data;
    },

    checkAllDigitalAssets({ commit }, payload) {
      commit("checkAllDigitalAssets", payload);
    },

    async removeDigitalAsset({ commit }, payload) {
      commit("deleteDigitalAsset", payload);
      let { data } = await axios({
        method: "delete",
        url: "digital_assets/digital_asset",
        data: [payload],
      });
      return data;
    },
  },
};

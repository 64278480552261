export const subDomains = {
  "bennie": {
    organization_url: "https://www.bennie.com/",
    organization_name: "Bennie",
    organization_id: "765323eaf9294247b5d7f0987b57fbea",
  },
  "frp": {
    organization_url: "https://foundationrp.com/",
    organization_name: "Foundation Risk Partners",
    organization_id: "605fff4502dd44619f02512d7a45ee5a",
  },
  "higginbotham": {
    organization_url: "https://www.higginbotham.com/",
    organization_name: "Higginbotham",
    organization_id: "e1f66faa8a194eab9ffba48b024cd6a9",
  },
  "mma": {
    organization_url: "https://www.marshmclennan.com/",
    organization_name: "Marsh McLennan",
    organization_id: "e55b546f1c1b4ce9926e7e3688d3b122",
  },
}
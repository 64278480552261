class RequestQueue {
  constructor() {
    this.queue = [];
    this.pendingPromise = false;
  }

  enqueue(request) {
    return new Promise((resolve, reject) => {
      this.queue.push({ request, resolve, reject });
      this.dequeue();
    });
  }

  async dequeue() {
    if (this.pendingPromise || this.queue.length === 0) return;
    this.pendingPromise = true;
    const { request, resolve, reject } = this.queue.shift();
    try {
      const data = await request();
      resolve(data);
    } catch (err) {
      reject(err);
    } finally {
      this.pendingPromise = false;
      this.dequeue();
    }
  }

  showQueue() {
    return this.queue;
  }
}

export default RequestQueue;

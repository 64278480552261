import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    reportLoading: false,
    selectedThreatProperties: [],
    chosenThreatProperty: null,
    selectedForView: null,
    reportViewer: false,
    payDialog: false,
    threatProperties: [],
    vulnerabilities: [],
    reportOrganizationDetails: null,
    subscriptionDetails: null,
    paymentRequestSubmit: false,
    paymentStatus: null,
  }),

  mutations: {
    updateField,

    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    SET_THREAT_PROPERTIES(state, payload) {
      state.threatProperties = payload;
    },

    SET_VULNERABILITIES(state, payload) {
      state.vulnerabilities = payload;
    },

    SET_REPORT_ORGANIZATION_DETAILS(state, payload) {
      state.reportOrganizationDetails = payload;
    },

    UPDATE_THREAT_PROPERTIES(state, payload) {
      const existingElementsMap = new Map(state.threatProperties.map((el) => [el.entity_id, el]));
      payload.forEach((item) => {
        existingElementsMap.set(item.entity_id, item);
      });
      state.threatProperties = Array.from(existingElementsMap.values());
    },

    UPDATE_THREAT_REPORT_DATA(state, payload) {
      const existingElementsMap = new Map(state.threatProperties.map((el) => [el.entity_id, el]));
      const reportElement = existingElementsMap.get(payload.report_id);
      if (reportElement) {
        reportElement.reports_data = [...(reportElement.reports_data || []), payload];
        existingElementsMap.set(payload.report_id, reportElement);
      }
      state.threatProperties = Array.from(existingElementsMap.values());
    },

    UPDATE_THREAT_REPORT_SUBSCRIPTION(state, payload) {
      let threatProperties = Object.assign([], state.threatProperties);
      let index = null;
      payload.reportIds.map((reportId) => {
        index = threatProperties.findIndex((e) => e.entity_id == reportId);
        threatProperties[index].frequency = payload.frequency;
      });
      state.threatProperties = Object.assign([], threatProperties);
    },
  },

  actions: {
    async getThreatReports({ commit }, organizationId = null) {
      try {
        let url = "/threat_reports";
        if (organizationId) {
          url = `/threat_reports/${organizationId}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_THREAT_PROPERTIES", data.reports);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReportsSubscription({ commit }) {
      try {
        const { data } = await axios.get("/threat_reports/subscription");
        if (!data.success) return;
        commit("setState", { key: "subscriptionDetails", value: data.subscriptions });
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReportsSubscriptionByReportId({ commit }, reportId) {
      try {
        const { data } = await axios.get(`/threat_reports/subscription/${reportId}`);
        if (!data.success) return;
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getReportData({ commit }, payload) {
      try {
        let url = `/threat_reports/data?${payload.queryParams}`;
        if (payload.organizationId) {
          url = `/threat_reports/data/${payload.organizationId}?${payload.queryParams}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_VULNERABILITIES", data.reports_data);
        commit("SET_REPORT_ORGANIZATION_DETAILS", data.organization_detail);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async submitReportsSubscription({ commit }, payload) {
      try {
        commit("setState", { key: "paymentRequestSubmit", value: true });
        const { data } = await axios.post("/threat_reports/subscription", payload);
        commit("setState", { key: "paymentStatus", value: !data.success ? "failed" : "success" });
        if (!data.success) return;
        commit("setState", { key: "paymentRequestSubmit", value: false });
        commit("UPDATE_THREAT_REPORT_SUBSCRIPTION", { reportIds: payload.report_ids, frequency: payload.frequency });
      } catch (e) {
        commit("setState", { key: "paymentRequestSubmit", value: false });
        console.log(e);
      }
    },

    async triggerUpdateReports({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("/threat_reports/trigger", payload);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    getField,
  },
};

import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: () => ({
    policiesPlanningQuestions: {
      questions: [
        {
          id: 3,
          key: "how_much_coverage_need_want",
          topLine: false,
          question: "How much coverage do you need or want?",
          showButtons: false,
          choice: "",
          visible: false,
          quickStartHidden: true,
        },
        {
          id: 1,
          key: "cyber_insurance_goals",
          topLine: false,
          bottomLine: false,
          question: "What are your cyber insurance goals?",
          buttonConfig: ["Renewal", "New Policy"],
          choice: null,
          visible: true,
        },
        {
          id: 2,
          key: "policy_details_upload_policy",
          visible: true,
          showButtons: false,
          topLine: false,
          bottomLine: false,
          formData: {
            manual_answers: {
              renewal_date: null,
              last_policy_coverage_limit: null,
              last_deductible: null,
              last_policy_premium: null,
              last_policy_carrier: null,
            },
            renewal_new_common_answers: {
              // using for quick start section
              effective_looking_date: null,
              coverage_trying_to_acquire: null,
              deductible_interest_having: null,
            },
            uploaded_policies: [],
          },
        },
      ],
    },
    companyDetailsQuestions: {
      questions: [
        {
          id: 6,
          key: "full_business_name",
          question: "Full Business Name",
          choice: null,
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
          extraCls: "pt-0 pb-2",
        },
        {
          id: 7,
          question: "",
          formData: {
            subsidiaries: [
              {
                name: "",
              },
            ],
          },
          visible: true,
          topLine: false,
          showButtons: false,
          isTitleBold: true,
          key: "subsidiaries",
          extraCls: "pt-0 pb-2",
          csgHidden: true,
        },
        {
          id: 1,
          key: "company_established_year",
          question: "Year Your Company Was Established",
          choice: null,
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
          validationType: "isPastYear",
          extraCls: "pt-0 pb-2",
        },
        {
          id: 2,
          key: "office_address",
          question: "Office Address",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          extraCls: "pt-0 pb-2",
          visible: true,
          formData: {
            address: null,
            address2: null,
            city: null,
            state: null,
            zip_code: null,
          },
        },
        {
          id: 3,
          key: "company_phone",
          question: "Company Phone",
          extraCls: "pt-0 pb-2",
          formData: {
            answer: null,
          },
          requiredFormData: {
            answer: {
              key: "answer",
              validationType: "isPhoneNumber",
            },
          },
          // choice: null,
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
        },
        {
          id: 4,
          key: "process_management_contact",
          question: "Process Management Contact",
          formData: {
            answer: null,
          },
          requiredFormData: {
            answer: {
              key: "answer",
              validationType: "isPhoneNumber",
            },
          },
          hiddenIndicationFormData: [
            'answer'
          ],
          // choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
          indicationSetupHidden: true
        },
        {
          id: 5,
          key: "cybersecurity_contact",
          question: "Cybersecurity Contact",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          quickStartHidden: true,
          visible: false,
        },
      ],
    },

    companyWebsitesQuestions: {
      questions: [
        {
          id: 3,
          key: "does_organization_have_websites_publicly",
          question: "Does the organization have websites that are publicly available? ",
          choice: null,
          isTitleBold: false,
          topLine: false,
          visible: true,
          show: [
            { id: 1, key: "primary_web_address", showOn: "yes" },
            { id: 2, key: "company_owned_websites", showOn: "yes" },
            { id: 4, key: "honey_pot_websites", showOn: "yes" },
          ],
        },

        {
          id: 1,
          key: "primary_web_address",
          question: "Primary Website",
          choice: null,
          extraCls: "pt-0 pb-2",
          type: "hasWebAddress",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: false,
        },

        {
          id: 2,
          key: "company_owned_websites",
          question: "",
          extraCls: "pt-0 pb-2",
          topLine: false,
          showButtons: false,
          visible: false,
          requiredFormData: {
            company_owned_websites: {
              key: "web_address",
              type: "Array",
              validationType: "isUrl",
            },
          },
          formData: {
            company_owned_websites: [
              {
                web_address: "",
              },
            ],
          },
        },

        {
          id: 4,
          key: "honey_pot_websites",
          question: "",
          extraCls: "pt-0 pb-2",
          topLine: false,
          showButtons: false,
          visible: false,
          requiredFormData: {
            honey_pot_websites: {
              key: "web_address",
              type: "Array",
              validationType: "isUrl",
            },
          },
          formData: {
            honey_pot_websites: [
              {
                web_address: "",
              },
            ],
          },
        },
      ],
    },

    companyLogoQuestions: {
      questions: [
        {
          id: 1,
          key: "company_logo",
          question: "",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
        },
      ],
    },
    industryDetailsQuestions: {
      questions: [
        {
          id: 1,
          key: "company_type",
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
          hiddenIndicationFormData: [
            "business_description",
            "state_of_incorporation"
          ],
          requiredFormData: {
            company_type: {
              key: "company_type",
              validationType: "isRequired",
            },
            state_of_incorporation: {
              key: "state_of_incorporation",
              validationType: "isRequired",
              indicationSetupHidden: true
            },
            naics_description: {
              key: "naics_description",
              validationType: "isRequired",
              indicationSetupHidden: true
            },
          },
          formData: {
            company_type: null,
            state_of_incorporation: null,
            naics_full: null,
            naics_code: null,
            naics_description: null,
            business_description: null,
            fein_number: null,
          },
        },
        {
          id: 3,
          key: "is_company_franchise",
          question: "Is your company a franchise?",
          choice: null,
          isTitleBold: false,
          topLine: false,
          visible: true,
          showButtons: true,
          extraCls: "flex-none",
          extraBtnCls: "flex-none ml-4",
        },
      ],
    },

    employeesDetailsQuestions: {
      questions: [
        {
          id: 1,
          key: "full_time_employees",
          question: "Full-Time Employees",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          options: ["0-100", "100-500", "500-1,000", "1,000-10,000", "10,000+"],
          visible: true,
        },
        {
          id: 2,
          key: "full_time_it_employees",
          question: "Full-Time IT Employees",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          options: ["0-100", "100-500", "500 +"],
          visible: true,
          indicationSetupHidden: true
        },
        {
          id: 3,
          key: "full_time_cyber_security_employees",
          question: "Full-Time Cybersecurity Employees",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          options: ["0-100", "100-500", "500 +"],
          visible: true,
          indicationSetupHidden: true
        },
      ],
    },

    cyberSecurityInvestmentQuestions: {
      questions: [
        {
          id: 1,
          key: "annual_it_budget_percentage",
          question: "Annual IT Budget (percentage of total revenue)",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
        },
        {
          id: 1,
          key: "annual_cyber_security_budget_percentage",
          question: "Annual Cybersecurity Budget (percentage of IT budget)",
          choice: null,
          extraCls: "pt-0 pb-2",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
        },
      ],
    },

    revenueGrowthQuestions: {
      questions: [
        {
          id: 1,
          key: "fiscal_year_end",
          question: "When does your organization’s fiscal year end?",
          isTitleBold: true,
          topLine: false,
          showButtons: false,
          visible: true,
          requiredFormData: {
            fiscal_year_end: {
              key: "fiscal_year_end",
              validationType: "isDateValidAndInFormat",
            },
          },
          formData: {
            fiscal_year_end: "",
          },
        },
        {
          id: 2,
          key: "last_month_report_revenue",
          question: "When is the last month your organization can report revenue?",
          isTitleBold: true,
          hiddenIndicationFormData: [
            'last_month_report_revenue'
          ],
          requiredFormData: {
            last_month_report_revenue: {
              key: "last_month_report_revenue",
              validationType: "isPastDate",
            },
          },
          formData: {
            last_month_report_revenue: "",
          },
          showButtons: false,
          visible: true,
          indicationSetupHidden: true
        },
        {
          id: 3,
          key: "revenue_come_from",
          question: "Where does your revenue come from?",
          choice: null,
          buttonConfig: ["USA", "Non-USA", "Both"],
          visible: true,
          show: [
            { id: 7, key: "revenue_come_from_answer_usa", showOn: "USA" },
            { id: 8, key: "revenue_come_from_answer_non_usa", showOn: "Non-USA" },
            { id: 11, key: "revenue_come_from_answer_both", showOn: "Both" },
          ],
        },
        {
          id: 7,
          key: "revenue_come_from_answer_usa",
          question: "",
          topLine: false,
          visible: false,
          showButtons: false,
          deepRequiredFormData: ["revenue_come_from_answer_usa"],
          hiddenIndicationFormData: [
            "revenue_start_end",
            "fiscal_year_last_2",
            "projected_year",
            "estimate_year",
          ],
          requiredFormData: {
            revenue_come_from_answer_usa: {
              revenue_start_end: {
                key: "revenue_start_end",
                validationType: "isRequired"
              },
              fiscal_year_last_2: {
                key: "fiscal_year_last_2",
                validationType: "isRequired"
              },
              fiscal_year_last_1: {
                key: "fiscal_year_last_1",
                validationType: "isRequired",
              },
              projected_year: {
                key: "projected_year",
                validationType: "isRequired"
              },
              estimate_year: {
                key: "estimate_year",
                validationType: "isRequired"
              },
            },
          },
          formData: {
            revenue_come_from_answer_usa: {
              revenue_start_end: null,
              fiscal_year_last_2: null,
              fiscal_year_last_1: null,
              projected_year: null,
              estimate_year: null,
            },
          },
        },
        {
          id: 8,
          key: "revenue_come_from_answer_non_usa",
          question: "",
          showButtons: false,
          topLine: false,
          visible: false,
          deepRequiredFormData: ["revenue_come_from_answer_non_usa"],
          hiddenIndicationFormData: [
            "revenue_start_end",
            "fiscal_year_last_2",
            "projected_year",
            "estimate_year",
          ],
          requiredFormData: {
            revenue_come_from_answer_non_usa: {
              revenue_start_end: {
                key: "revenue_start_end",
                validationType: "isRequired"
              },
              fiscal_year_last_2: {
                key: "fiscal_year_last_2",
                validationType: "isRequired",
              },
              fiscal_year_last_1: {
                key: "fiscal_year_last_1",
                validationType: "isRequired"
              },
              projected_year: {
                key: "projected_year",
                validationType: "isRequired"
              },
              estimate_year: {
                key: "estimate_year",
                validationType: "isRequired"
              },
            },
          },
          formData: {
            revenue_come_from_answer_non_usa: {
              revenue_start_end: null,
              fiscal_year_last_2: null,
              fiscal_year_last_1: null,
              projected_year: null,
              estimate_year: null,
            },
          },
        },
        {
          id: 11,
          key: "revenue_come_from_answer_both",
          question: "",
          topLine: false,
          visible: false,
          showButtons: false,
          deepRequiredFormData: ["revenue_come_from_answer_usa", "revenue_come_from_answer_non_usa"],
          hiddenIndicationFormData: [
            "revenue_start_end",
            "fiscal_year_last_2",
            "projected_year",
            "estimate_year",
          ],
          requiredFormData: {
            revenue_come_from_answer_usa: {
              revenue_start_end: {
                key: "revenue_start_end",
                validationType: "isRequired"
              },
              fiscal_year_last_2: {
                key: "fiscal_year_last_2",
                validationType: "isRequired"
              },
              fiscal_year_last_1: {
                key: "fiscal_year_last_1",
                validationType: "isRequired",
              },
              projected_year: {
                key: "projected_year",
                validationType: "isRequired"
              },
              estimate_year: {
                key: "estimate_year",
                validationType: "isRequired"
              },
            },
            revenue_come_from_answer_non_usa: {
              revenue_start_end: {
                key: "revenue_start_end",
                validationType: "isRequired",
              },
              fiscal_year_last_2: {
                key: "fiscal_year_last_2",
                validationType: "isRequired",
              },
              fiscal_year_last_1: {
                key: "fiscal_year_last_1",
                validationType: "isRequired",
              },
              projected_year: {
                key: "projected_year",
                validationType: "isRequired",
              },
              estimate_year: {
                key: "estimate_year",
                validationType: "isRequired",
              },
            },
          },
          formData: {
            revenue_come_from_answer_usa: {
              revenue_start_end: null,
              fiscal_year_last_2: null,
              fiscal_year_last_1: null,
              projected_year: null,
              estimate_year: null,
            },
            revenue_come_from_answer_non_usa: {
              revenue_start_end: null,
              fiscal_year_last_2: null,
              fiscal_year_last_1: null,
              projected_year: null,
              estimate_year: null,
            },
          },
        },
        {
          id: 4,
          key: "org_changes_past_twelve_month",
          question:
            "Are significant changes in the nature or size of your organization’s business anticipated over the next twelve (12) months? Or have there been any such changes within the past twelve (12) months?",
          choice: null,
          visible: true,
          quickStartHidden: true,
          show: [{ id: 9, key: "org_changes_past_twelve_month_yes", showOn: "yes" }],
        },
        {
          id: 9,
          key: "org_changes_past_twelve_month_yes",
          question: "",
          visible: false,
          topLine: false,
          showButtons: false,
          quickStartHidden: true,
          formData: {
            explain_details: null,
          },
        },
        {
          id: 5,
          key: "org_merger_acquisition_in_past_12_months",
          question:
            "Has your organization within the past twelve (12) months completed or agreed to, or does it contemplate entering into within the next twelve (12) months, a merger, acquisition, consolidation, whether or not such transactions were or will be completed?",
          choice: null,
          visible: true,
          show: [{ id: 10, key: "org_completed_past_twelve_month_yes", showOn: "yes" }],
        },
        {
          id: 10,
          key: "org_merger_acquisition_in_past_12_months_yes",
          question: "",
          visible: false,
          topLine: false,
          showButtons: false,
          formData: {
            explain_details: null,
          },
        },
        {
          id: 6,
          key: "does_organization_engage_following_business_activities",
          question: "Does the organization engage in any of the following business activities? (check all that apply):",
          visible: true,
          showButtons: false,
          // csgHidden: true,
          formData: {
            adult_content: null,
            gambling: null,
            payment_processing: null,
            medical_billing: null,
            storage_hosting: null,
            cannabis: null,
            debt_collection_agency: null,
            military_defense_contractors: null,
            online_auctions: null,
            cryptocurrency_blockchain: null,
            managed_it_service_provider: null,
            background_checks: null,
            care_provider: null,
            none_of_above: false,
          },
        },
      ],
    },

    revenueProductsQuestions: {
      questions: [
        {
          id: 1,
          key: "packaged_development_licensing",
          question: "Packaged software development & licensing",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 2,
          key: "custom_software_development",
          question: "Custom software development",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 3,
          key: "software_maintenance_support",
          question: "Software maintenance and support",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 4,
          key: "software_installation",
          question: "Computer and software systems implementation/installation/integration",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 5,
          key: "it_consulting_purchase",
          question: "IT consulting, including consulting on hardware/software design/purchase",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 7,
          key: "data_transaction_processing",
          question: "Data and transaction processing",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 8,
          key: "it_business_process_outsourcing",
          question: "IT and business process outsourcing",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 9,
          key: "media_data_subscription_license",
          question: "Media content and data sales, subscriptions and licenses",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 10,
          key: "revenue_isp_email_services",
          question: "Revenues from ISP and email services",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 11,
          key: "hosting_collocation_services",
          question: "Website hosting and collocation services",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 12,
          key: "advertising_referral_revenues",
          question: "Advertising and referral revenues",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 13,
          key: "telecommunication_services",
          question: "Telecommunication services",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 14,
          key: "tech_product_sales_maintenance",
          question: "Technology product sales and maintenance (other than software)",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 15,
          key: "application_services_provider",
          question: "Application services provider",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 16,
          key: "other_services_products",
          question: "Other services or products (provide details)",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
        {
          id: 6,
          key: "data_processing_third_parties",
          question: "Data processing, storage or hosting services to third parties?",
          choice: null,
          visible: true,
          formVisibleOn: "yes",
          requiredFormData: {
            product_revenue_expect_year: {
              key: "product_revenue_expect_year",
              validationType: "isRequired",
            },
          },
          formData: {
            product_revenue_expect_year: null,
            product_details: null,
          },
        },
      ],
    },
    revenueCustomersQuestions: {
      questions: [
        {
          id: 1,
          key: "percentage_revenue_b2b",
          question: "What percentage of your organization's revenues is business to business?",
          topLine: false,
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: true,
        },
        {
          id: 2,
          key: "percentage_revenue_d2c",
          question: "What percentage of your organization's revenues is direct to consumer?",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: true,
        },
        {
          id: 3,
          key: "org_engagement_five_annual_revenues",
          question:
            "Does the your organization have any engagements that represent more than five percent of its annual revenues?",
          choice: null,
          visible: true,
          formData: {
            additional_details: null,
          },
        },
        {
          id: 4,
          key: "average_engagement_terms_revenue",
          question: "Please indicate your organization’s average size engagement in terms of total revenue",
          isTitleBold: true,
          choice: null,
          visible: true,
          showButtons: false,
          formData: {
            four_largest_customers: [
              {
                name: null,
                product_service: null,
                revenue_from_customer: null,
              },
              {
                name: null,
                product_service: null,
                revenue_from_customer: null,
              },
              {
                name: null,
                product_service: null,
                revenue_from_customer: null,
              },
              {
                name: null,
                product_service: null,
                revenue_from_customer: null,
              },
            ],
          },
        },
        {
          id: 6,
          key: "org_written_contracts_product_engagement",
          question:
            "Does your organization have written contracts with all clients for all service/product engagements?",
          topLine: false,
          bottomLine: false,
          choice: null,
          visible: true,
          show: [{ id: 7, key: "percentage_time_written", showOn: "no" }],
        },
        {
          id: 7,
          key: "percentage_time_written",
          question: "If no, what percentage of the time are written contracts used?",
          isTitleBold: true,
          choice: null,
          visible: false,
          topLine: false,
          showButtons: false,
        },
        {
          id: 8,
          key: "service_include_scope_work",
          question: "Scope of work?",
          topLine: false,
          choice: null,
          visible: true,
        },
        {
          id: 9,
          key: "service_include_dispute_resolution",
          question: "Dispute resolution?",
          choice: null,
          visible: true,
        },
        {
          id: 10,
          key: "service_include_damages_caps",
          question: "Damages Caps?",
          choice: null,
          visible: true,
        },
        {
          id: 11,
          key: "service_include_disclaimers_warranties",
          question: "Disclaimers or Implied Warranties?",
          choice: null,
          visible: true,
        },
        {
          id: 12,
          key: "service_include_guarantees",
          question: "Guarantees?",
          choice: null,
          visible: true,
        },
        {
          id: 13,
          key: "service_include_disclaimer_damages",
          question: "Full Disclaimer of Consequential Damages?",
          choice: null,
          visible: true,
          show: [{ id: 14, key: "explain_disclaimer_not_provided", showOn: "no" }],
        },
        {
          id: 14,
          key: "explain_disclaimer_not_provided",
          question: "Please explain circumstances when such a disclaimer would not be provided",
          isTitleBold: true,
          choice: null,
          showButtons: false,
          visible: false,
        },
      ],
    },
  }),

  mutations: {
    updateField,
  },

  actions: {},

  getters: {
    getField,
  },
};

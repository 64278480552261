import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: () => ({
    tableHeaders: [
      { text: "File name", value: "filename", align: "left", filterable: true },
      {
        text: "",
        value: "url",
        align: "left",
        sortable: false,
        filterable: false,
      },
      {
        text: "",
        value: "actions",
        align: "center",
        sortable: false,
        filterable: false,
      },
    ],
    allFiles: [],
    clientOrganizationFiles: [],
    clientAuditTeamFiles: [],
    pickerCredentials: null,
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },

    updateAttachments(state, payload) {
      // if file was deleted, remove from allFiles, clientAuditTeamFiles, clientOrganizationFiles
      if (payload.active == 0) {
        // update the all files
        let index = state.allFiles.findIndex((file) => file.entity_id == payload.entity_id);

        // if file found, remove it
        if (index != -1) {
          state.allFiles.splice(index, 1);
        }

        // update the client audit team files
        index = state.clientAuditTeamFiles.findIndex((file) => file.entity_id == payload.entity_id);

        // if file found, remove it
        if (index != -1) {
          state.clientAuditTeamFiles.splice(index, 1);
        }

        // update the client organization files
        index = state.clientOrganizationFiles.findIndex((file) => file.entity_id == payload.entity_id);

        // if file found, remove it
        if (index != -1) {
          state.clientOrganizationFiles.splice(index, 1);
        }

        return;
      } else {
        // otherwise we add the file to the allFiles, clientAuditTeamFiles, clientOrganizationFiles

        state.allFiles.push(payload);

        // the the file does not exist in clientOrganizationFiles, add it to clientAuditTeamFiles
        let index = state.clientOrganizationFiles.findIndex((file) => file.entity_id == payload.entity_id);

        // if file not found, add it
        if (index == -1) {
          state.clientAuditTeamFiles.push(payload);
        }
      }
    },
  },
  actions: {
    async sendFileData({ commit, dispatch, state }, payload) {
      try {
        let endpoint;

        /* Endpoint will be decided on the user's role (client or salesperson) */
        if (payload && payload.role === "client") {
          endpoint = "/attachment/client";
        } else {
          endpoint = "/attachment";
        }

        const { data } = await axios.post(endpoint, {
          attachments: payload.attachments,
        });

        if (!data.success) {
          return;
        }

        dispatch(
          "showMessage",
          {
            ...data,
            message: `${payload.attachments.length} file/s uploaded.`,
            messageType: "success",
            messageTitle: "Success",
          },
          { root: true }
        );
        if (payload.uploadBy && payload.uploadBy == "client-org") {
          // client-org is using for client admin's uploaded by org tab
          commit("setState", {
            key: "clientOrganizationFiles",
            value: [...state.clientOrganizationFiles, ...data.results],
          });
        } else {
          commit("setState", {
            key: "allFiles",
            value: [...state.allFiles, ...data.results],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getFiles({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "allFiles", value: [] });
        commit("startLoading", null, { root: true });

        /* 
          Check if the caller of this action is a salesperson 
          or a client and construct endpoint url accordingly 
        */
        let endpointUrl;
        if (payload && payload.role === "client") {
          endpointUrl = "/attachment";
        } else {
          endpointUrl = `/attachment/${payload.clientId}`;
        }

        const { data } = await axios.get(endpointUrl);
        if (!data.success) {
          return;
        }
        // commit("setState", { key: "allFiles", value: data.results });
        return data.results;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteFromServer({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { id } = payload;

        let endpoint, data;

        /* This means we are deleting multiple files */
        if (payload && payload.type === "multiple") {
          endpoint = "/attachment";
          const response = await axios.put(endpoint, {
            entity_ids: payload.entity_ids,
          });
          data = response.data;
        } else {
          /* This means only one file is being deleted */
          endpoint = `/attachment/${id}`;
          const response = await axios.delete(endpoint);
          data = response.data;
        }

        if (!data.success) {
          return;
        }

        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        dispatch("getFiles", {
          clientId: payload.clientId,
        });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    /* This endpoint is used to get the files submitted by the client */
    async getSubmittedFilesByClient({ commit }, payload = null) {
      try {
        commit("setState", { key: "allFiles", value: [] });

        let endpointUrl;

        /* This endpoint will be called to fetch files for specific clients by salespersons */
        if (payload && payload.clientId) {
          endpointUrl = `/attachment/client/${payload.clientId}`;
        } else {
          endpointUrl = "/attachment/client";
        }

        const { data } = await axios.get(endpointUrl);
        if (!data.success) return;

        // commit("setState", { key: "allFiles", value: data.results });

        return data.results;
      } catch (e) {
        console.log(e);
      }
    },

    /* This endpoint is used to delete a file submitted by the client */
    async deleteSubmittedFileByClient({ commit, dispatch }, payload) {
      try {
        let endpointUrl = `/attachment/client/${payload.entity_id}`;

        const { data } = await axios.delete(endpointUrl);

        if (!data.success) return;

        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });

        /* This means that file remover was an admin */
        if (payload.deleteBy) {
          switch (payload.deleteBy) {
            case "sales":
              dispatch("getFiles", {
                clientId: payload.clientId,
              });
              break;
            case "client-org":
              dispatch("getSubmittedFilesByClientOrganization", {
                organizationId: payload.organization_id,
              });
              break;
            case "client-audit-team":
              dispatch("getSubmittedFilesByClientAuditTeam", {
                organizationId: payload.organization_id,
              });
              break;
          }
        } else {
          if (payload && payload.clientId) {
            return dispatch("getSubmittedFilesByClient", payload);
          }
          dispatch("getSubmittedFilesByClient");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSubmittedFilesByClientOrganization({ commit, dispatch }, payload = null) {
      try {
        commit("setState", { key: "allFiles", value: [] });
        commit("startLoading", null, { root: true });

        let endpointUrl;
        endpointUrl = `/attachment/organization/${payload.organizationId}`;

        const { data } = await axios.get(endpointUrl);
        if (!data.success) {
          return;
        }
        commit("setState", {
          key: "clientOrganizationFiles",
          value: data.results,
        });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getSubmittedFilesByClientAuditTeam({ commit, dispatch }, payload = null) {
      try {
        commit("setState", { key: "allFiles", value: [] });
        commit("startLoading", null, { root: true });

        let endpointUrl;
        endpointUrl = `/attachment/audit_team/${payload.organizationId}`;

        const { data } = await axios.get(endpointUrl);
        if (!data.success) {
          return;
        }
        commit("setState", {
          key: "clientAuditTeamFiles",
          value: data.results,
        });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    updateAttachments({ commit, dispatch }, payload) {
      // if the payload is an array, it means we are updating multiple files so we iterate

      if (Array.isArray(payload)) {
        payload.forEach((file) => {
          commit("updateAttachments", file);
        });
      } else {
        commit("updateAttachments", payload);
      }
    },

    async getPickerCredentials({ commit }) {
      let endpointUrl = `/files/picker`;
      const { data } = await axios.get(endpointUrl);
      if (!data.success) {
        return;
      }
      commit("setState", {
        key: "pickerCredentials",
        value: data.credentials,
      });
    },
  },
  getters: {
    getField,
    allFiles: (state) => state.allFiles,
    clientOrganizationFiles: (state) => state.clientOrganizationFiles,
    clientAuditTeamFiles: (state) => state.clientAuditTeamFiles,
    tableHeaders: (state) => state.tableHeaders,
    pickerCredentials: (state) => state.pickerCredentials,
  },
};

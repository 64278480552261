import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";

export default {
  namespaced: true,

  state: () => ({
    activeTab: 0,
    tabs: [
      {
        id: "in-progress",
        name: "Application In Progress",
      },
      {
        id: "archives",
        name: "Archives",
      },
    ],
    payDialog: false,
    paymentStatusDialog: false,
    paymentForm: null,
    selectedView: "list",
    search: "",
    deleteDialog: false,
    selectedForDelete: null,
    applicationItems: [],
    pdfViewer: false,
    isSignView: null,
    disclaimerDialog: false,
    selectedForView: null,
    disclaimer: false,
    signDialog: false,
    savedPaymentMethods: [],
    isBrokerAttached: false,
  }),

  mutations: {
    updateField,

    SET_APPLICATIONS(state, payload) {
      state.applicationItems = payload;
    },

    UPDATE_APPLICATIONS(state, updatedApplications) {
      let existing = structuredClone(state.applicationItems)
        .map((application) => {
          let found = updatedApplications.find((app) => app.entity_id === application.entity_id);
          return found ? found : application;
        })
        .filter((el) => el.active);
      state.applicationItems = existing;
    },

    UPSERT_APPLICATION(state, updatedApplication) {
      const index = state.applicationItems.findIndex((app) => app.entity_id === updatedApplication.entity_id);
      if (index !== -1) {
        // Replace the old object with the updated one
        state.applicationItems.splice(index, 1, updatedApplication);
      } else {
        // If object with provided ID is not found, you can choose to handle it
        state.applicationItems.push(updatedApplication);
      }
    },

    setSavedPaymentMethods(state, payload) {
      state.savedPaymentMethods = payload;
    },
  },

  actions: {
    setApplicationLoading({ commit }, { application, isLoading }) {
      application.isLoading = isLoading;
      commit("UPSERT_APPLICATION", application);
    },
    async getApplications({ commit }, organizationId = null) {
      try {
        let url = "/application";
        if (organizationId) {
          url = `/application/organization/${organizationId}`;
        }
        const { data } = await axios.get(url);
        if (!data.success) return;
        commit("SET_APPLICATIONS", data.applications);
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async archiveApplication({ commit }, applicationId) {
      try {
        const { data } = await axios.post(`/application/archive/${applicationId}`);
        if (!data.success) return;
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async unArchiveApplication({ commit }, applicationId) {
      try {
        const { data } = await axios.post(`/application/unarchive/${applicationId}`);
        if (!data.success) return;
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async deleteApplication({ commit }, applicationId) {
      try {
        const { data } = await axios.delete(`/application/${applicationId}`);
        if (!data.success) return;
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async signApplication({ commit }, payload) {
      try {
        const { data } = await axios.post(`/application/sign/${payload.applicationId}`, payload.data);
        if (!data.success) return;
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async sendApplicationForSigning({ commit }, payload) {
      try {
        const { data } = await axios.put(`/application/send/${payload.applicationId}/${payload.organizationId}`, {});
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    async getCustomerApplications({ commit }, organizationId) {
      try {
        const { data } = await axios.get(`/application/organization/${organizationId}`);
        if (!data.success) return;
        commit("SET_APPLICATIONS", data.applications);
      } catch (e) {
        console.log("[Applications.js] : ", e);
      }
    },

    //  USED TO GET THE SAVED PAYMENT METHODS
    async getSavedPaymentMethods({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/payment_methods");
        console.log("data ", data);
        if (!data.success) return false;
        commit("setSavedPaymentMethods", data.payment_methods);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET CARD DETAILS FOR UPDATE
    async getCardDetails({ commit }, paymentMethodEntityId) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/payment_method/${paymentMethodEntityId}`);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET CARD DETAILS FOR UPDATE
    async updateCardDetails({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/payment_method/${payload.paymentMethodEntityId}`, payload.data);
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    //  TODO
    //  Implement this api when needed
    //  /application/<ApplicationId>
  },

  getters: {
    getField,
  },
};

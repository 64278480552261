<template>
  <v-dialog
    v-model="showDialog"
    :persistent="persistent"
    :attach="attach"
    :scrollable="scrollable"
    :class="classList"
    :max-width="maxWidth"
    :width="width"
    :hide-overlay="hideOverlay"
    :no-click-animation="noClickAnimation"
    :fullscreen="fullScreen"
    :transition="transition"
    @keydown.esc="$emit('cancel-dialog')"
    @click:outside="$emit('cancel-outside')"
  >
    <v-sheet>
      <v-row no-gutters v-if="showCloseButton">
        <v-col cols="12" class="d-flex justify-end pt-2 pb-0 px-4">
          <v-btn large icon @click="$emit('cancel-dialog')">
            <v-icon color="#d9d9d9">mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <slot></slot>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    maxWidth: {
      type: Number,
      default: () => null,
    },
    width: {
      type: Number,
      default: () => null,
    },
    persistent: {
      type: Boolean,
      default: () => true,
    },
    scrollable: {
      type: Boolean,
      default: () => false,
    },
    attach: {
      type: Boolean,
      default: () => false,
    },
    classList: {
      type: String,
      default: () => "",
    },
    hideOverlay: {
      type: Boolean,
      default: () => false,
    },
    fullScreen: {
      type: Boolean,
      default: () => false,
    },
    noClickAnimation: {
      type: Boolean,
      default: () => false,
    },
    transition: {
      type: String,
      default: () => "dialog-bottom-transition",
    },
    showCloseButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    this.showDialog = this.dialog;
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
};
</script>

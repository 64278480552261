export const wysiwygSettings = {
  maxHeight: "800px",
  hideModules: { 
    "unorderedList": true,
    "code": true,
    "removeFormat": true,
    "table": true,
    "image": true,
    "separator": true
  },
  forcePlainTextOnPaste: true,
}

export const file_stack_api_key = process.env.VUE_APP_FILESTACK_API_KEY;
export const user_flow_token = process.env.VUE_APP_USERFLOW_TOKEN;
import { getField, updateField } from "vuex-map-fields"

export default {
  namespaced: true,

  state: () => ({
    inviteCoAdmins: {
      questions: [
        {
          id: 1,
          key: "invite_co_admins",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    technologyTeammates: {
      questions: [
        {
          id: 1,
          key: "technology_teammates",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    cyberSecurityTeammates: {
      questions: [
        {
          id: 1,
          key: "cyber_security_teammates",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    operationProcessTeammates: {
      questions: [
        {
          id: 1,
          key: "operation_process_teammates",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    hrTrainingTeammates: {
      questions: [
        {
          id: 1,
          key: "hr_training_teammates",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    legalContractTeammates: {
      questions: [
        {
          id: 1,
          key: "legal_contract_teammates",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    },
    cyberInsuranceBrokers: {
      questions: [
        {
          id: 1,
          key: "cyber_insurance_brokers",
          topLine: false,
          question: "",
          showButtons: false,
          visible: false,
          formData: {
            teamMembers: []
          }
        }
      ]
    }
  }),

  mutations: {
    updateField,
  },

  actions: {},

  getters: {
    getField,
  },
}

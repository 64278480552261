import { getField, updateField } from "vuex-map-fields"
import axios from "../plugins/axios"

export default {
  namespaced: true,

  state: () => ({
    alphaSetup: {
      markets: {
        items: [],
        other: ''
      },

      wholesalers: {
        items: [],
        other: ''
      },

      renewals: {
        files: []
      },

      analysis: {
        items: [],
        other: ''
      },

      employees: {
        files: []
      }
    },
    alphaSetupVersion: null,

    // SINGLE PLAYER ALPHA
    showBookValue: false,  
    alphaSingle: {
      firstName: '',
      lastName: '',
      email: '',
      customersInfo: {
        noOfCustomers: null,
        averageRevenue: null,
        hasCyberInsurance: null
      },
      commissionRate: 15,
      chosenStates: [],
      selectedIndustries: [],
      markets: {
        items: [],
        other: ''
      }
    }
  }),

  mutations: {
    updateField,

    setAlphaSetup(state, payload) {
      state.alphaSetup = payload;
    },

    updateAlphaSetup(state, payload) {
      state.alphaSetup = { ...state.alphaSetup, ...payload };
    },

    setAlphaSetupVersion(state, payload) {
      console.log(payload);
      state.alphaSetupVersion = payload;
    }
  },

  actions: {
    async getAlphaBrokerage({ commit, dispatch }, subDomain) {
      try {
        const { data } = await axios.get(`/brokers/search/${subDomain}`);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async saveAlphaSetup({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("/alpha/setup", payload);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async getAlphaSetup({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/alpha/setup");
        if (!data.success || !data.alpha_setup.questionnaire) return;
        commit('setAlphaSetup', data.alpha_setup.questionnaire)
        commit('setAlphaSetupVersion', data.alpha_setup.version);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async submitAlphaSetup({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("/alpha/submit", payload);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateAlphaSetup({ commit, rootState, state }, payload) {
      const { login: { currentUser: { user } } } = rootState;

      if (user.entity_id !== payload.changed_by_id && state.alphaSetupVersion !== payload.previous_version) {
        commit('updateAlphaSetup', payload.questionnaire);
      }
    }
  },

  getters: {
    getField,
  }
}
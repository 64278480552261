<template>
  <v-row no-gutters>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <div>
        <h2 :class="headingCls">{{ title }}</h2>
        <div :class="subTitleCls">{{ subTitle }}</div>
      </div>

      <div v-if="closable">
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    closable: {
      type: Boolean,
      default: () => false,
    },

    title: {
      type: String,
      default: () => "Title",
    },

    subTitle: {
      type: String,
      default: () => "",
    },

    headingCls: {
      type: String,
      default: () => "",
    },

    subTitleCls: {
      type: String,
      default: () => "",
    },
  },
};
</script>
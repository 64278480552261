export default {
  beforeCreate() {
    const appName = process.env.VUE_APP_APPLICATION_NAME;
    document.title = appName ? appName : 'S2 Readiness';
  },
  methods: {
    getAppTitle() {
      const appName = process.env.VUE_APP_APPLICATION_NAME;
      return appName ? appName : 'S2 Readiness';
    }
  }
}
import axios from '../plugins/axios';
import localService from '../services/localService';
import { getField, updateField } from 'vuex-map-fields';


function upsert(array, element) { // (1)
  const i = array.findIndex(_element => _element.entity_id === element.entity_id);
  if (i > -1) array[i] = element; // (2)
  else array.push(element);
  return array
}

export default {
  namespaced: true,
  state: () => ({
    termsTableHeaders: [
      { text: 'Name', value: 'name', filterable: false },
      { text: 'Version', value: 'content_version', filterable: true },
      { text: 'Status', value: 'status', sortable: false, filterable: false },
      { text: 'User', value: 'user', sortable: false, filterable: false },
      { text: 'Date', value: 'published_on', sortable: false, filterable: false },
      { text: '', value: 'change_description', sortable: false, filterable: false },
      { text: '', value: 'actions', align: 'right', sortable: false, filterable: false },
    ],
    response: null,
    myHTML: '',
    version: '',
    status: null,
    name: null,
    clickwraps: [],
    userClickwraps: [],
    userClickwrapsLoading: false,
    newClickwrap: {},
    newClickwrapContent: {},
    selectedClickwrap: {},
    selectedClickwrapHistory: [],
    selectedClickwrapContent: {},
    clickwrapsLoading: false
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    }
  },
  actions: {

    setSelectedClickwrap(context, payload) {
      let clickwrap = payload
      if (context.state.selectedClickwrap.entity_id == null && clickwrap) {
        // persist
        context.commit('setState', { key: 'newClickwrap', value: context.state.selectedClickwrap })
        context.commit('setState', { key: 'newClickwrapContent', value: context.state.selectedClickwrapContent })
      } 
      if (clickwrap && clickwrap.entity_id) {
        context.commit('setState', { key: 'selectedClickwrap', value: clickwrap })
        context.dispatch('loadClickwrapHistory', clickwrapId)
      } else {
        // load persisted
        context.commit('setState', { key: 'selectedClickwrap', value: context.state.newClickwrap })
        context.commit('setState', { key: 'selectedClickwrapContent', value: context.state.newClickwrapContent })
      }
    },
    async loadUserClickwraps(context, payload) {
      context.commit('setState', { key: 'userClickwrapsLoading', value: true })
      let clickwraps = payload;
      let clickwrapRequests = clickwraps.map(clickwrap => {
        let endpoint = '/clickwrap/' + clickwrap.clickwrap_id
        return axios.get(endpoint)
      })
      let clickwrapResponses = await Promise.all(clickwrapRequests)
      let userClickwraps = clickwrapResponses.map(res => {
        let { data } = res
        return data.clickwrap
      })
      userClickwraps.sort((a,b) => a.accepted - b.accepted)
      context.commit('setState', { key: 'userClickwraps', value: userClickwraps })
      context.commit('setState', { key: 'userClickwrapsLoading', value: false })
    },

    async loadClickwrapHistory({commit, dispatch, state}, payload) {
      try {
        commit('setState', { key: 'selectedClickwrapHistory', value: [] })
        let clickwrapId = payload
        if (!clickwrapId) {
          return
        }
        commit('setState', { key: 'clickwrapLoading', value: true })
        commit('startLoading', null, { root: true });
        let endpoint = '/clickwrap/history/' + clickwrapId;
        const { data } = await axios.get(endpoint);
        if (!data.success) {
          return ;
        }
        commit('setState', { key: 'selectedClickwrapContent', value: data.data[0] ? { ...data.data[0]} : {} })
        commit('setState', { key: 'selectedClickwrapHistory', value: data.data || [] })
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
        commit('setState', { key: 'clickwrapLoading', value: false })
      }
    },

    /* This action will fetch all different clickwrap agreements */
    async getAllClickwraps({ commit, dispatch }, payload = null) {
      try {
        commit('setState', { key: 'clickwrapsLoading', value: true })
        commit('startLoading', null, { root: true });
        let endpoint = '/clickwrap';
        const { data } = await axios.get(endpoint);
        if (!data.success) {
          return ;
        }
        commit('setState', { key: 'clickwraps', value: data.data })
      } catch (e) {
        console.log(e);
      } finally {
        commit('setState', { key: 'clickwrapsLoading', value: false })
        commit('stopLoading', null, { root: true });
      }
    },

    /* This action will fetch the last published clickwrap */
    async getPublishedClickWrap({ commit, dispatch }, payload = null) {
      try { 
        commit('startLoading', null, { root: true });
        let endpoint = '/clickwrap';

        if (payload && payload.role === 'client') {
          endpoint = '/clickwrap/client'  
        }

        const { data } = await axios.get(endpoint);

        if (!data.success) {
          return ;
        }

        if (!payload) {
          commit('setState', { key: 'clickwraps', value: data.clickwrap })
        }
        
        return data.clickwrap;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    /* This action will fetch the last saved clickwrap */
    async getDraftClickWrap({ commit, dispatch }) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.get('/clickwrap/draft');
        if (!data.success) {
          return ;
        }
        return data.clickwrap;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    async saveClickwrap({commit, dispatch, state}, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.post('/clickwrap', payload);
        if (!data.success) {
          return;
        }
        dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        commit('setState', { key: 'selectedClickwrap', value: data.clickwrap })
        commit('setState', { key: 'clickwraps', value: upsert([...state.clickwraps], data.clickwrap) })
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    /* This action will save the clickwrap as a draft */
    async saveClickwrapAsDraft({ commit, dispatch, state }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.post('/clickwrap/draft', payload);
        if (!data.success) {
          return ;
        }
        dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        commit('setState', { key: 'selectedClickwrap', value: data.clickwrap })
        commit('setState', { key: 'selectedClickwrapContent', value: data.draft })
        commit('setState', { key: 'clickwraps', value: upsert([...state.clickwraps], data.clickwrap) })
        if (!payload.clickwrap.entity_id && data.clickwrap.entity_id) {
          commit('setState', { key: 'newClickwrapContent', value: {} })
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    /* This action will finally save the clickwrap as a published version */
    async publishClickwrap({ commit, dispatch, state }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.post('/clickwrap/publish', payload);
        if (!data.success) {
          return;
        }
        dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        commit('setState', { key: 'selectedClickwrap', value: data.clickwrap })
        commit('setState', { key: 'selectedClickwrapContent', value: data.draft })
        commit('setState', { key: 'clickwraps', value: upsert([...state.clickwraps], data.clickwrap) })
        commit('setState', { key: 'status', value: 'published' });
        if (!payload.clickwrap.entity_id && data.clickwrap.entity_id) {
          commit('setState', { key: 'newClickwrapContent', value: {} })
        }
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    /* This action will be used to accept the terms */
    async acceptTerms({ commit, state }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.put('/clickwrap/accept', payload);
        if (!data.success) {
          return ;
        }
        let clickwrapIdx = state.userClickwraps.findIndex(i => i.clickwrap_entity_id === payload.clickwrap_entity_id)
        if (clickwrapIdx >= 0) {
          payload.accepted = 1
          commit('setState', { key: 'userClickwraps', value: upsert([...state.userClickwraps], payload) })
          let idx = this.state.login.currentUser.user.clickwrap_acceptance.findIndex(i => i.clickwrap_id == payload.clickwrap_entity_id)
          let clickwrapAcceptance = this.state.login.currentUser.user.clickwrap_acceptance
          clickwrapAcceptance[idx].accepted = 1
          let updatedUser = {...this.state.login.currentUser, user: {...this.state.login.currentUser.user, clickwrap_acceptance: clickwrapAcceptance}}
          commit('login/setState', { key: 'currentUser', value: updatedUser }, {root: true})
          updatedUser.stopReRender = true // for stop refresh userInfo for hide menu items
          localService.setItem('userInfo', updatedUser);
        }
        return true
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },
    /* Assign a clickwrap for a client */
    async assignClickwrap({ commit, dispatch }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.post('/client/map_clickwrap', payload);
        if (!data.success) {
          return ;
        }

        dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    /* Assign a clickwrap for a new client */
    async newUserAssignClickwrap({ commit, dispatch }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const { data } = await axios.post('/client/map_clickwrap', payload);
        if (!data.success) {
          return ;
        }

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },    
  },
  getters: {
    getField,
    myHTML: state => state.myHTML,
    version: state => state.version,
    response: state => state.response,
    status: state => state.status,
    name: state => state.name,
    clickwraps: state => state.clickwraps,
    clickwrapsLoading: state => state.clickwrapsLoading,
    userClickwraps: state => state.userClickwraps,
    userClickwrapsLoading: state => state.userClickwrapsLoading,
    selectedClickwrap: state => state.selectedClickwrap,
    selectedClickwrapHistory: state => state.selectedClickwrapHistory,
    selectedClickwrapContent: state => state.selectedClickwrapContent,
    newClickwrap: state => state.newClickwrap,
    termsTableHeaders: state => state.termsTableHeaders,
  }
}

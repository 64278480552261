import Pusher from "pusher-js";
import localService from "../services/localService";
import { vm } from "../main";

Pusher.logToConsole = false; //process.env.STAGE !== "production" ? true : false
let debounce = null;
const _pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
});
export default {
  isRegistered: false,
  registerChannels: function () {
    // Public Channel
    const public_channel = _pusher.subscribe("public_channel");

    public_channel.bind("logoutAllUsers", function (data) {
      vm.$store.dispatch("login/logout", {});
    });

    // Private Channel
    const userInfo = localService.getItem("userInfo");
    if (userInfo) {
      const user = userInfo["user"];
      if (user?.entity_id) {
        const channel = _pusher.subscribe(user.entity_id.replace("|", ""));

        channel.bind("logoutUser", function (data) {
          vm.$store.dispatch("login/logout", {});
        });

        channel.bind("updateAllowance", function (data) {
          vm.$store.dispatch("broker/updateAllowance", data);
        });

        // channel.bind("updateMember", function (data) {
        //   console.log('PUSHER', data);

        //   vm.$store.dispatch("broker/getBrokerMembers");
        // });

        if (user.admin_pusher_channel_id) {
          const super_admin_channel = _pusher.subscribe(user.admin_pusher_channel_id);

          super_admin_channel.bind("adminUpdateBroker", function (data) {
            if (!data.active) {
              vm.$store.commit("admin/deleteBroker", data.entity_id);
            } else {
              vm.$store.commit("admin/updateBroker", data);
            }
          });

          const superAdminChunkedEvents = {};
          super_admin_channel.bind("chunked-adminUpdateBrokerMembers", function (data) {
            vm.$nextTick(() => {
              const { message_id, chunk_data, is_final_chunk } = data;
              const superAdminChunkedEvent =
                superAdminChunkedEvents[message_id] ||
                (superAdminChunkedEvents[message_id] = { chunkedData: [], receivedFinal: false });

              superAdminChunkedEvent.chunkedData.push(chunk_data);
              superAdminChunkedEvent.receivedFinal = is_final_chunk ? true : superAdminChunkedEvent.receivedFinal;

              if (superAdminChunkedEvent.receivedFinal) {
                superAdminChunkedEvent.chunkedData = JSON.parse(superAdminChunkedEvent.chunkedData.join(""));
                vm.$store.commit("admin/updateSelectedBroker", superAdminChunkedEvent.chunkedData);
                delete superAdminChunkedEvent[message_id];
              }
            });
          });

          super_admin_channel.bind("adminUpdateBrokerages", function (data) {
            const { broker, brokerage } = data;
            vm.$store.commit("admin/updateBrokerageList", brokerage);
            vm.$store.commit("admin/updateBroker", broker);
          });
        }

        if (user.organization_id) {
          const organization_channel = _pusher.subscribe(user.organization_id);

          // organization_channel.bind("updatePurchases", function (data) {
          //   vm.$store.dispatch("broker/getPurchasedTokens");
          // });

          // organization_channel.bind("updateQuotesSubmission", function (data) {
          //   console.log("updateQuotesSubmission", data);
          // });

          // organization_channel.bind("updateQuotes", function (data) {
          //   console.log("updateQuotes", data);
          // });

          organization_channel.bind("updateOrganizationBrokerAllocations", function (data) {
            vm.$store.dispatch("broker/updateOrganizationBrokerAllocations", data);
          });

          // purchaseTokens happens when a seller sells tokens to a brokerage
          organization_channel.bind("purchaseTokens", function (data) {
            vm.$store.dispatch("broker/updatePurchasedTokens", data);
          });

          organization_channel.bind("updateAnswer", function (data) {
            let { assessment_id, entity_id } = vm.$route.query;
            if (assessment_id && entity_id) {
              clearTimeout(debounce);
              debounce = setTimeout(() => {
                vm.$store.dispatch("audit/fetchControlCertification", { withoutLoading: true });
              }, 5000);
            }
          });

          organization_channel.bind("updateClients", function (data) {
            vm.$store.dispatch("client/updateOrganizationClients", data);
          });

          organization_channel.bind("updateOrganizationClients", function (data) {
            vm.$store.dispatch("digitalAssets/updateOrganizationClients", data);
          });

          // updateDigitalAssets
          organization_channel.bind("updateDigitalAssets", function (data) {
            data.map((x) => (x.checked = false));
            vm.$store.dispatch("digitalAssets/updateDigitalAssets", data);
          });

          // deleteOrganizationClients
          organization_channel.bind("deleteOrganizationClients", function (data) {
            vm.$store.dispatch("digitalAssets/deleteOrganizationClients", data);
            vm.$store.dispatch("client/deleteOrganizationClients", data);
          });

          organization_channel.bind("updateQuoteDetail", function (data) {
            if (data?.detail) {
              let jsonData = Object.assign({}, JSON.parse(data.detail));
              jsonData.quote_type = data.quote_type;
              vm.$store.commit("mShiftUser/updateQuoteDetail", jsonData)
            }
          });

          /*           organization_channel.bind("updateOrgClients", function (data) {
            vm.$store.dispatch("digitalAssets/getOrganizationClients");
          });
          organization_channel.bind("updateOrgClients", function (data) {
            vm.$store.dispatch("digitalAssets/getDigitalAssetUsages");
          }); */
          organization_channel.bind("updateAttachments", function (data) {
            vm.$store.dispatch("files/updateAttachments", data);
          }); /* 
          organization_channel.bind("organizationTokenSignup", function (data) {
            vm.$store.dispatch("broker/getAllocatedTokens");
          }); */

          organization_channel.bind("updateBrokerMembers", function (data) {
            if (data.find((e) => e.broker_id == user.entity_id)) {
              vm.$store.dispatch("broker/updateBrokerMembers", data);
            }
          });

          // organization_channel.bind("updateCompanySetupGuide", function (data) {
          //   vm.$nextTick(() => {
          //     if (data.questionnaire && Object.keys(data.questionnaire).length) {
          //       console.log(data)
          //       vm.$store.dispatch("companySetup/updateQuestionnaireDataByQuestion", data);
          //     }
          //   })
          // });

          const chunkedEvents = {};
          organization_channel.bind("chunked-updateCompanySetupGuide", function (data) {
            vm.$nextTick(() => {
              const { message_id, chunk_data, is_final_chunk } = data;

              const chunkedEvent =
                chunkedEvents[message_id] || (chunkedEvents[message_id] = { chunkedData: [], receivedFinal: false });

              chunkedEvent.chunkedData.push(chunk_data);

              chunkedEvent.receivedFinal = is_final_chunk ? true : chunkedEvent.receivedFinal;

              if (chunkedEvent.receivedFinal) {
                chunkedEvent.chunkedData = JSON.parse(chunkedEvent.chunkedData.join(""));

                const { questionnaire } = chunkedEvent.chunkedData;

                if (questionnaire && Object.keys(questionnaire).length) {
                  vm.$store.dispatch("companySetup/updateQuestionnaireDataByQuestion", chunkedEvent.chunkedData);
                }

                delete chunkedEvents[message_id];
              }
            });
          });

          organization_channel.bind("updateAlphaSetupGuide", function (data) {
            vm.$nextTick(() => {
              if (data.questionnaire) {
                vm.$store.dispatch("alpha/updateAlphaSetup", data);
              }
            });
          });

          organization_channel.bind("updateQuotesSubmission", function (data) {
            const organization_id = vm.$store.state.broker?.selectedClient?.organization_id;
            if (!organization_id) return;

            // RUNS WHEN QUICK QUOTE
            if (data.quote_type === "quick_quotes") {
              vm.$store.commit("mShiftUser/updateQuoteStatus", {
                quick_quotes_submission: data,
                ready_for_quick_quote: true,
              });
              vm.$store.dispatch("mShiftUser/getMShiftQuotes", { organization_id }).then(async () => {
                await vm.$store.dispatch("mShiftUser/extractedCarriersList", {
                  organization_id: organization_id,
                  type: data.quote_type,
                });
              });
            }

            // RUNS WHEN FULL QUOTE
            if (data.quote_type === "full_quotes") {
              vm.$store.commit("mShiftUser/updateFullQuoteStatus", {
                full_quotes_submission: data,
                ready_for_full_quote: true,
              });
              vm.$store.dispatch("mShiftUser/getFullQuotes", { organization_id }).then(async () => {
                await vm.$store.dispatch("mShiftUser/extractedCarriersList", {
                  organization_id: organization_id,
                  type: data.quote_type,
                });
              });
            }
          });

          organization_channel.bind("updateApplications", function (data) {
            vm.$store.commit("applications/UPDATE_APPLICATIONS", data);
          });

          organization_channel.bind("updatePrivacyReportsData", function (data) {
            vm.$store.commit("privacy/UPDATE_PRIVACY_REPORT_DATA", data);
          });

          organization_channel.bind("updatePrivacyReports", function (data) {
            vm.$store.commit("privacy/UPDATE_PRIVACY_PROPERTIES", data);
          });

          organization_channel.bind("updateThreatReportsData", function (data) {
            vm.$store.commit("threats/UPDATE_THREAT_REPORT_DATA", data);
          });

          organization_channel.bind("updateThreatReports", function (data) {
            vm.$store.commit("threats/UPDATE_THREAT_PROPERTIES", data);
          });

          organization_channel.bind("updateLocalOfficeSetup", function (data) {
            vm.$store.commit("localOffice/updateLocalOfficeSetup", data);
          });

          organization_channel.bind("updateInsurabilityReports", function (data) {
            vm.$store.commit("insurability/UPDATE_REPORTS_DATA", data);
          });
        }

        this.isRegistered = true;
      }
    }
  },
};

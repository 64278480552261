<template>
  <terms
    :show-dialog="termsModal"
    :is-broker="role == 'broker'"
    :is-accept="true"
    :is-accepted="isAccepted"
    :loading="isAccepting"
    @cancel-dialog="termsModal = false"
    @accept-terms="accept"
  >
    <template #accept-checkbox>
      <div class="d-flex align-center">
        <v-checkbox v-model="isAccepted" label="I accept the terms and conditions" />
      </div>
    </template>
  </terms>
</template>

<script>
import Terms from "@/components/Terms/Terms.vue";
import UserInformationMixin from "@/Mixins/user";
import localService from "@/services/localService";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import versionInfoMixin from "@/Mixins/versionInfo";

export default {
  components: { Terms },

  name: "S2RTerms",

  mixins: [UserInformationMixin, versionInfoMixin],

  data() {
    return {
      isAccepted: false,
      isAccepting: false,
    };
  },

  computed: {
    ...mapFields(["termsModal"]),

    role() {
      if (this.currentUser && this.currentUser.user) {
        if (this.currentUser.user.role.toLowerCase() === "salesperson") {
          return "sales";
        }
        return this.currentUser.user.role;
      }
      return false;
    },
  },

  beforeMount() {
    this.termsModal = true;
  },

  methods: {
    ...mapActions("login", ["acceptTerms"]),

    async accept() {
      this.isAccepting = true;
      const response = await this.acceptTerms({ termsVersion: this.termsVersion });
      if (response.success) {
        this.currentUser.user.terms_version = this.termsVersion;
        this.currentUser.user.terms_accepted = 1;
        localService.setItem("userInfo", this.currentUser);
        this.$router.push({ path: "/" }).catch(() => {});
      }
      this.isAccepting = false;
    },
  },
};
</script>
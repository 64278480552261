<template>
  <v-container fluid class="pa-0" style="background: #fff !important;">
    <top-bar-without-auth />
    <v-divider class="mt-2" />
    <v-row class="px-14 pt-12 pb-8">
      <v-col cols="12">
        <img :src="require('@/assets/images/jlpc/aim-icon.png')" style="max-width: 32px;"/>
        <h2 class="outfit-bold font-700 text-36 text--black my-3">Please check your email.</h2>
        <p calss="outfit-regular font-400 text-16 text-grey">We have sent an email to <b>{{email}}</b>. To start your website privacy assessment, please click the button in the email.</p>
        <v-btn
          large
          depressed
          rounded
          color="#3ACA56"
          @click="redirectTo('login')"
        >
          <span class="outfit-bold font-700 text-12 white--text text-capitalize">I got it!</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="text-secondary cursor-pointer outfit-regular font-400 text-16" @click="redirectTo('privacy-exposure')">I didn’t receive an email.</p>
        <img :src="require('@/assets/images/jlpc/green-black-group-icon.png')" class="my-14" style="max-width: 116px;"/>
      </v-col>
    </v-row>
    <app-footer />
  </v-container>
</template>

<script>
import AppFooter from "../../components/Jlpc/AppFooter.vue";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import localService from "@/services/localService";
export default {
  components: {
    TopBarWithoutAuth,
    AppFooter,
  },

  data: () => ({
    email: ''
  }),

  mounted() {
    this.email = this.$route.params.email;
  },

  methods: {
    redirectTo(page) {
      if(page == 'login') {
        localService.removeItem('jl_s2r_signUpUserInfo');
      }
      this.$router.push(`/${page}`);
    }
  }
};
</script>

<template>
  <app-dialog :dialog="deleteConfirmation" @cancel-dialog="deleteConfirmation = false" :persistent="true" :maxWidth="maxWidth">
    <v-card>
      <div class="app-dark-bg white--text pl-4 pt-3 pb-3 d-flex flex-column">
        <h3 class="white--text">Confirmation</h3>
      </div>

      <v-card-text class="px-4 py-3">
        <slot name="modalBody"></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed class="cancel-button font-weight-bold" text small @click="cancel">
          Cancel
        </v-btn>

        <v-btn
          small
          color="#3ACA56"
          depressed
          outlined
          class="font-weight-bold"
          :loading="loading"
          @click="$emit('delete-clicked')"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/Base/AppDialog'

export default {
  components: { AppDialog },

  props: {
    loading: {
      type: Boolean,
      default: () => false
    },

    maxWidth: {
      type: [Number, String],
      default: () => 450
    }
  },

  data: () => ({
    deleteConfirmation: false
  }),

  methods: {
    cancel () {
      this.$emit('cancel')
      this.toggle()
    },

    toggle() {
      this.deleteConfirmation = !this.deleteConfirmation;
    }
  }
}
</script>
<template>
  <v-container fluid>
    <v-row class="without-auth-main-bg d-block mb-6">
      <background-shapes v-if="!hideBranding" />
      <v-col cols="12" class="d-flex align-baseline justify-center pt-8">
        <v-row class="pa-0 login-signup-form-container align-baseline justify-center" v-if="!showTerms">
          <h2 class="outfit-bold font-700 text-40 font-700 text-light-black mb-5">Annual Subscription</h2>
          <v-col cols="12" md="12" class="px-6 login-signup-form-outer">
            <subscription-form
              :outlined="true"
              :showOutsideLabel="true"
              @submitForPayment="submitForPayment"
              @toggleTermsPolicy="toggleTermsPolicy"
              :totalPayableAmount="totalPayableAmount"
            />
          </v-col>
          <img
            :src="require('@/assets/images/shapes/green-circle.png')"
            style="position: absolute; left: -26px; top: 44px; z-index: 0; max-width: 48px"
          />
        </v-row>
        <signup-payment-terms v-else @toggleTermsPolicy="toggleTermsPolicy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localService from "@/services/localService";
import SubscriptionForm from "@/components/Common/SubscriptionForm.vue";
import BackgroundShapes from "@/components/General/BackgroundShapes.vue";
import SignupPaymentTerms from "@/components/General/SignupPaymentTerms.vue";
import { mapFields } from "vuex-map-fields";
import { hideBranding } from "../../../helpers";

export default {
  name: "User-Subscription",
  components: {
    SignupPaymentTerms,
    BackgroundShapes,
    SubscriptionForm,
  },
  data: () => ({
    hideBranding,
    userInfo: null,
    elevate: false,
    showTerms: false,
  }),
  computed: {
    ...mapFields("login", ["currentUser"]),
    ...mapFields("companySetup", ["brokersList"]),
    ...mapFields("client", ["currentOrganization", "totalPayableAmount"]),
  },
  async beforeMount() {
    await this.$store.dispatch("companySetup/getBrokersList");
    await this.$store.dispatch("client/getCurrentOrganization");
  },
  mounted() {
    if (this.currentUser.user) {
      if (this.currentOrganization?.is_jackson_lewis_client && !this.brokersList?.length) {
        this.totalPayableAmount = 250;
      } else if (this.brokersList?.length) {
        this.totalPayableAmount = 199;
      } else {
        this.totalPayableAmount = 499;
      }
      this.userInfo = this.currentUser.user;
    }
  },
  methods: {
    submitForPayment(paymentForm) {
      const user = localService.getItem("userInfo");
      this.userInfo = localService.getItem("s2r_signUpUserInfo");
      this.$store.dispatch("login/subscribe", {
        paymentForm,
        userInfo: user ?? this.userInfo,
      });
    },
    toggleTermsPolicy() {
      this.showTerms = !this.showTerms;
    },
  },
};
</script>

<style scoped lang="scss">
.main-bg {
  background: #f4f7f9;
  min-height: 100vh;
}

.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}
.payment-form-container {
  max-width: 700px;
}
</style>

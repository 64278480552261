<template>
  <v-container fluid class="pa-0" style="background: #fff !important;">
    <v-row>
      <v-col cols="12">
        <v-row style="background: #f7f7f7 !important;"  class="px-14 pt-12 pb-8">
          <v-col cols="6" class="px-14 relative">
            <img :src="require(`@/assets/images/jlpc/${logoUrl}`)" v-if="logoUrl" style="max-width: 81px" />
            <div class="pt-14">
              <h2 class="outfit-bold font-700 text-56 text-light-black">Privacy Exposure: A Key Step Towards <span class="text-secondary">Universal Data Privacy Compliance</span></h2>
              <p class="outfit-regular font-400 text-16 mt-8 mb-0">Cookies, keyloggers and Google Analytics tend to build up on company websites like barnacles on a boat. Do you really know which tracking technologies are active on your various domains? If you don’t, your data privacy compliance could be at risk.</p>
            </div>
            <img :src="require('@/assets/images/jlpc/half-black.png')" style="position: absolute; left: -12px; top: 50%; max-height: 80px; transform: translateY(-50%);"/>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pl-0 pr-12 relative">
            <div class="relative">
              <img :src="require('@/assets/images/jlpc/full-circle-green.png')" style="position: absolute;right: -34px;top: 8px;max-width: 80px; z-index: 0;"/>
              <img :src="require('@/assets/images/jlpc/home-banner-image-1.png')" alt="banner-image-1" class="relative" style="max-width: 560px; z-index: 1" />
            </div>
            <img :src="require('@/assets/images/jlpc/round-green.png')" style="position: absolute; left: -12px; bottom: 0; max-height: 48px;"/>
          </v-col>
        </v-row>
        <v-row style="max-width: 1040px; margin: 44px auto 0;">
          <v-col cols="12" class="text-center px-14">
            <h3 class="outfit-bold font-700 text-40 text-light-black mb-3 px-6">Start your website privacy compliance check here.</h3>
            <p class="outfit-regular font-400 text-14 text-grey mb-0  px-6">Please tell us a little bit about your company and your various website domains. SecondSight will use this information to discover and analyze the various tracking technologies in place. Our insights will be encrypted and shared securely (with your permission) with your attorneys at Jackson Lewis.</p>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-card class="border-radius-16 px-6 py-4">
              <v-card-text>
                <v-form ref="signupForm">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Company Name</label>
                      <v-text-field
                        v-model="formData.companyName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Company name"
                        :rules="[(v) => !!v || 'Company Name is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Office Address</label>
                      <v-text-field
                        v-model="formData.addressLine"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Address line"
                        :rules="[(v) => !!v || 'Address is required']"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        v-model="formData.city"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="City"
                        :rules="[(v) => !!v || 'City is required']"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-autocomplete
                        v-model="formData.state"
                        color="#3ACA56"
                        :items="states"
                        solo
                        flat
                        rounded
                        item-text="code"
                        placeholder="State"
                        item-value="code"
                        iem
                        clearable
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        :rules="[
                          v => !!v || 'State is required'
                        ]"
                        outlined
                        data-cy="state"
                        ref="state"
                      >
                        <template v-slot:item="{ item }">
                          <span data-cy="searched-state">
                            <span class="font-weight-bold pr-1">{{ item.code }} - </span>
                            {{ item.name }}
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        v-model="formData.zipCode"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Zip code"
                        :rules="[
                          (v) => !!v || 'Zip code is required',
                          (v) => !!isValidPostalCode(v) || 'Postal code must be valid',
                        ]"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Company Phone</label>
                      <v-text-field
                        rounded
                        v-model="formData.companyPhone"
                        type="text"
                        :rules="[(v) => !!v || 'Company Phone is required']"
                        solo
                        flat
                        v-mask="'(###) ###-####'"
                        placeholder="(000) 000-0000"
                        color="#A09F9F"
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <div class="d-flex align-center">
                            <img :src="require('@/assets/images/us-icon.png')" style="max-width: 16px;" class="mr-2"/>
                            <span class="outfit-regular font-400 text-16 text-light-black pr-2">+1</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Your First Name</label>
                      <v-text-field
                        v-model="formData.firstName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="First name"
                        :rules="[(v) => !!v || 'First name is required']"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Your Last Name</label>
                      <v-text-field
                        v-model="formData.lastName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Last name"
                        :rules="[(v) => !!v || 'Last name is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Your Email</label>
                      <v-text-field
                        v-model="formData.email"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Email address"
                        :rules="[
                          (v) => !!v || 'Email address is required',
                          v => !v || !!checkEmail(v) || 'E-mail must be valid'
                        ]"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Your Title</label>
                      <v-text-field
                        v-model="formData.title"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Title"
                        :rules="[(v) => !!v || 'Title is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-space-between align-center">
                      <label class="d-block mb-0 outfit-bold font-700 text-14 text-light-black">Are you responsible for data privacy?</label>
                      <div class="d-flex button-container">
                        <div :class="formData.dataPrivacyResponsible.choice == 'yes' ?  'btn-shaped__active' : ''" class="btn-shaped mr-1 text-capitalize outfit-regular font-700 text-14" @click="formData.dataPrivacyResponsible.choice = 'yes'"> yes </div>
                        <div :class="formData.dataPrivacyResponsible.choice == 'no' ?  'btn-shaped__active' : ''" class="btn-shaped mr-1 text-capitalize outfit-regular font-700 text-14" @click="formData.dataPrivacyResponsible.choice = 'no'"> no </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" v-if="formData.dataPrivacyResponsible.choice == 'no'">
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">First Name</label>
                      <v-text-field
                        v-model="formData.dataPrivacyResponsible.firstName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="First name"
                        :rules="[(v) => !!v || 'First name is required']"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Last Name</label>
                      <v-text-field
                        v-model="formData.dataPrivacyResponsible.lastName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Last name"
                        :rules="[(v) => !!v || 'Last name is required']"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Email</label>
                      <v-text-field
                        v-model="formData.dataPrivacyResponsible.email"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Email address"
                        :rules="[
                          (v) => !!v || 'Email address is required',
                          v => !v || !!checkEmail(v) || 'E-mail must be valid'
                        ]"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Title</label>
                      <v-text-field
                        v-model="formData.dataPrivacyResponsible.title"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Title"
                        :rules="[(v) => !!v || 'Title is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-space-between align-center">
                      <label class="d-block mb-0 outfit-bold font-700 text-14 text-light-black">Are you responsible for security compliance?</label>
                      <div class="d-flex button-container">
                        <div :class="formData.securityComplianceResponsible.choice == 'yes' ?  'btn-shaped__active' : ''" class="btn-shaped mr-1 text-capitalize outfit-regular font-700 text-14" @click="formData.securityComplianceResponsible.choice = 'yes'"> yes </div>
                        <div :class="formData.securityComplianceResponsible.choice == 'no' ?  'btn-shaped__active' : ''" class="btn-shaped mr-1 text-capitalize outfit-regular font-700 text-14" @click="formData.securityComplianceResponsible.choice = 'no'"> no </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" v-if="formData.securityComplianceResponsible.choice == 'no'">
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">First Name</label>
                      <v-text-field
                        v-model="formData.securityComplianceResponsible.firstName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="First name"
                        :rules="[(v) => !!v || 'First name is required']"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Last Name</label>
                      <v-text-field
                        v-model="formData.securityComplianceResponsible.lastName"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Last name"
                        :rules="[(v) => !!v || 'Last name is required']"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Email</label>
                      <v-text-field
                        v-model="formData.securityComplianceResponsible.email"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Email address"
                        :rules="[
                          (v) => !!v || 'Email address is required',
                          v => !v || !!checkEmail(v) || 'E-mail must be valid'
                        ]"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Title</label>
                      <v-text-field
                        v-model="formData.securityComplianceResponsible.title"
                        flat
                        rounded
                        outlined
                        required
                        validate-on-blur
                        class="light-grey-text-field-outlined"
                        placeholder="Title"
                        :rules="[(v) => !!v || 'Title is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="d-block mb-2 outfit-bold text-16 font-700 text-light-black">Primary Website</label>
                      <v-text-field
                        rounded
                        v-model="formData.primaryWebsite"
                        type="text"
                        :rules="[
                          v => !!v || 'Primary website is required',
                          v => !!v && validURL(v) || 'URL is not correct'
                        ]"
                        solo
                        flat
                        placeholder="Enter website link"
                        color="#A09F9F"
                        required
                        class="light-grey-text-field-outlined"
                        outlined
                        validate-on-blur
                      />
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="d-flex align-center justify-space-between mb-2">
                        <label class="d-block outfit-bold text-16 font-700 text-light-black">Other Company-Owned Websites</label>
                        <span class="d-block outfit-bold text-14 font-700 text-secondary cursor-pointer" @click="addNewWebsite">+ Add Website</span>
                      </div>
                      <div>
                        <v-text-field
                          rounded
                          v-model="website.web_address"
                          type="text"
                          :rules="[
                            v => (!!v && validURL(v) || !v) || 'URL is not correct',
                            v => (!!v && !duplicateURL(v) || !v) || 'Duplicate',
                          ]"
                          solo
                          flat
                          placeholder="Enter website link"
                          color="#A09F9F"
                          required
                          outlined
                          class="light-grey-text-field-outlined validation-error-highlight-all"
                          validate-on-blur
                          @keypress.enter="addNewWebsite"
                          v-for="(website, companyIndex) in formData.company_owned_websites"
                          :key="`company-website-${companyIndex}`"
                        >
                          <template v-slot:append>
                            <v-icon class="cursor-pointer" size="18" color="#111010" @click="deleteWebsite(companyIndex)" v-if="formData.company_owned_websites.length > 1 || website.web_address">mdi-trash-can-outline</v-icon>
                          </template>
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="showFuture">
                    <v-col cols="12" class="d-flex align-center">
                      <v-checkbox
                        v-model="termsEnabled"
                        required
                        color="#D9D9D9"
                        class="dark-grey-checkbox mt-0 pt-0"
                        :on-icon="'mdi-check'"
                        hide-details
                      >
                      </v-checkbox>
                      <div class="outfit-regular font-400 text-14 text-grey">
                        I have read and agreed to the
                        <span class="outfit-bold font-700 text-decoration-underline cursor-pointer text-grey" @click="showTerms = true;">Terms & Conditions</span> and
                        <span class="outfit-bold font-700 text-decoration-underline cursor-pointer text-grey" @click="showPrivacyPolicy = true;">Privacy Policy</span>.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" md="3">
                      <v-btn
                        :loading="isLoading"
                        large
                        block
                        depressed
                        rounded
                        color="#3ACA56"
                        data-cy="signup-button"
                        :disabled="!signupFormValid"
                        @click="signup"
                      >
                        <span class="outfit-bold font-700 text-12 white--text text-capitalize">Get Started</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <terms :show-dialog="showTerms" :isJlpc="true" @cancel-dialog="showTerms = false" />
    <privacy-policy :show-dialog="showPrivacyPolicy" @cancel-dialog="showPrivacyPolicy = false" />
    <app-footer />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AppFooter from "../../components/Jlpc/AppFooter.vue";
import validationMixin from "../../Mixins/validation";
import SignupMixin from '@/Mixins/signup';
import { showFuture } from "@/helpers/index";
import isPostalCode from "validator/lib/isPostalCode";
import { usa_states, outlying_states } from '@/static/states';
import CommonMixin from '@/Mixins/common';
import companySetupGuide from "@/Mixins/companySetupGuide";
import Terms from "@/components/Terms/Terms.vue";
import PrivacyPolicy from "@/components/Terms/PrivacyPolicy.vue";
export default {
  components: {
    PrivacyPolicy,
    Terms,
    AppFooter,
  },

  mixins: [validationMixin, SignupMixin, CommonMixin, companySetupGuide ],

  data: () => ({
    dataPrivacyResponsible: null,
    securityComplianceResponsible: null,
    formData: {
      companyName: '',
      addressLine: '',
      city: '',
      state: '',
      zipCode: '',
      companyPhone: '',
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      dataPrivacyResponsible: {
        choice: null,
        firstName: '',
        lastName: '',
        email: '',
        title: ''
      },
      securityComplianceResponsible: {
        choice: null,
        firstName: '',
        lastName: '',
        email: '',
        title: ''
      },
      primaryWebsite: '',
      company_owned_websites: [{
        web_address: ''
      }]
    },
    showFuture,
    showTerms: false,
    showPrivacyPolicy: false,
    valid: false,
    elevate: false,
    termsEnabled: false,
    logoUrl: null,
    questionnairePayload: null
  }),

  computed: {
    ...mapGetters("login", ["isLoading"]),

    states() {
      return [...usa_states, ...outlying_states]
    },

    isValidPostalCode() {
      return (postal) => {
        return postal
            ? isPostalCode(
                postal,
                "US"
            )
            : null;
      };
    },

    signupFormValid() {
      let isValid = true;
      if(!this.termsEnabled) {
        return false;
      }
      Object.keys(this.formData).map((key) => {
        if(['companyName', 'addressLine', 'city', 'state', 'zipCode', 'companyPhone', 'firstName', 'lastName', 'email', 'title', 'primaryWebsite'].includes(key)) {
          if(this.trim(this.formData[key]) == "" ||
              (this.formData[key] != '' &&
                (key == 'email' && !this.checkEmail(this.formData[key])) ||
                (key == 'primaryWebsite' && !this.validURL(this.formData[key])) ||
                (key == 'zipCode' && !isPostalCode(this.formData[key],"US"))
              )
          ){
            isValid = false;
          }
        }
        if(key == 'dataPrivacyResponsible' || key == 'securityComplianceResponsible') {
          if(!this.formData[key].choice) {
            isValid = false;
          } else if(this.formData[key].choice == 'no') {
            Object.keys(this.formData[key]).map((objKey) => {
              if(['firstName', 'lastName', 'email', 'title'].includes(objKey)) {
                if(this.trim(this.formData[key][objKey]) == "" ||
                    (this.formData[key][objKey] != '' && objKey == 'email' && !this.checkEmail(this.formData[key][objKey]))
                ) {
                  isValid = false;
                }
              }
            })
          }
        }

        if(key == 'company_owned_websites') {
          this.formData[key].map((website) => {
            if(website.web_address != '' && (!this.validURL(website.web_address) || this.duplicateURL(website.web_address))) {
              isValid = false;
            }
          })
        }
      })
      return isValid;
    },
  },

  methods: {
    duplicateURL(url) {
      if(url != '' && (this.formData.primaryWebsite == url || this.formData.company_owned_websites.filter((e) => e.web_address == url)?.length > 1)) {
        return true;
      }
      return false;
    },

    async signup() {
      if (this.$refs.signupForm.validate()) {
        this.generatePayloadForSignup()
        let formData = Object.assign({}, this.formData);
        formData.questionnaire = this.questionnairePayload;
        this.$store.dispatch('login/jlpcSignup', formData);
      }
    },

    generatePayloadForSignup() {
      let officeAddressIndex = this.questionnairePayload.companyDetailsQuestions.questions.findIndex((e) => e.key == 'office_address');
      this.questionnairePayload.companyDetailsQuestions.questions[officeAddressIndex].formData = {
        address: this.formData.addressLine,
        city: this.formData.city,
        state: this.formData.state,
        zip_code: this.formData.zipCode
      }
      let companyPhoneIndex = this.questionnairePayload.companyDetailsQuestions.questions.findIndex((e) => e.key == 'company_phone');
      this.questionnairePayload.companyDetailsQuestions.questions[companyPhoneIndex].choice = this.formData.companyPhone;

      let cpoIndex = this.questionnairePayload.privacyConcernsQuestions.questions.findIndex((e) => e.key == 'has_your_organization_designated_a_chief_privacy_officer_cpo');
      this.questionnairePayload.privacyConcernsQuestions.questions[cpoIndex].choice = 'no';
      let cpoPrivacyIndex = this.questionnairePayload.privacyConcernsQuestions.questions.findIndex((e) => e.key == 'is_someone_on_your_workbench_team_responsible_for_privacy_issues');
      this.questionnairePayload.privacyConcernsQuestions.questions[cpoPrivacyIndex].choice = this.formData.dataPrivacyResponsible?.choice;
      if(this.formData.dataPrivacyResponsible?.choice == 'no') {
        this.questionnairePayload.privacyConcernsQuestions.questions[cpoPrivacyIndex].formData.otherFirstName = this.formData.dataPrivacyResponsible.firstName;
        this.questionnairePayload.privacyConcernsQuestions.questions[cpoPrivacyIndex].formData.otherLastName = this.formData.dataPrivacyResponsible.lastName;
        this.questionnairePayload.privacyConcernsQuestions.questions[cpoPrivacyIndex].formData.otherEmail = this.formData.dataPrivacyResponsible.email;
        this.questionnairePayload.privacyConcernsQuestions.questions[cpoPrivacyIndex].formData.otherTitle = this.formData.dataPrivacyResponsible.title;
      }

      let cisoIndex = this.questionnairePayload.privacyConcernsQuestions.questions.findIndex((e) => e.key == 'has_your_organization_designated_a_chief_information_security_officer_ciso');
      this.questionnairePayload.privacyConcernsQuestions.questions[cisoIndex].choice = 'no';
      let cisoPrivacyIndex = this.questionnairePayload.privacyConcernsQuestions.questions.findIndex((e) => e.key == 'is_someone_on_your_workbench_team_responsible_for_information_security_issues');
      this.questionnairePayload.privacyConcernsQuestions.questions[cisoPrivacyIndex].choice = this.formData.securityComplianceResponsible?.choice;
      if(this.formData.dataPrivacyResponsible?.choice == 'no') {
        this.questionnairePayload.privacyConcernsQuestions.questions[cisoPrivacyIndex].formData.otherFirstName = this.formData.securityComplianceResponsible.firstName;
        this.questionnairePayload.privacyConcernsQuestions.questions[cisoPrivacyIndex].formData.otherLastName = this.formData.securityComplianceResponsible.lastName;
        this.questionnairePayload.privacyConcernsQuestions.questions[cisoPrivacyIndex].formData.otherEmail = this.formData.securityComplianceResponsible.email;
        this.questionnairePayload.privacyConcernsQuestions.questions[cisoPrivacyIndex].formData.otherTitle = this.formData.securityComplianceResponsible.title;
      }

      let primaryWebIndex = this.questionnairePayload.companyWebsitesQuestions.questions.findIndex((e) => e.key == 'primary_web_address');
      this.questionnairePayload.companyWebsitesQuestions.questions[primaryWebIndex].choice = this.formData.primaryWebsite;

      let otherWebIndex = this.questionnairePayload.companyWebsitesQuestions.questions.findIndex((e) => e.key == 'company_owned_websites');
      this.questionnairePayload.companyWebsitesQuestions.questions[otherWebIndex].formData.company_owned_websites = this.formData.company_owned_websites;
    },

    handleLinkClick() {
      this.$router.push({ path: "/login" });
    },

    toggleTermsPolicy() {
      this.showPrivacyPolicy = false;
      this.showTerms = false;
    },

    addNewWebsite() {
      let stopAddNew = false;
      this.formData.company_owned_websites.map((website) => {
        if(!website.web_address|| !this.validURL(website.web_address) || this.duplicateURL(website.web_address)) {
          stopAddNew = true;
        }
      });
      if(stopAddNew) {
        return;
      }
      this.formData.company_owned_websites.push({
        web_address: ''
      });
    },

    deleteWebsite(index) {
      if(this.formData.company_owned_websites.length > 1) {
        this.formData.company_owned_websites.splice(index, 1);
      } else {
        this.formData.company_owned_websites[0].web_address = '';
      }
    }
  },

  beforeMount() {
    this.logoUrl = this.getSubDomainLogoInitial();
    this.questionnairePayload = this.getQuestionnairePayload();
  },

  mounted() {
    this.wasFormFilled('jl_s2r_signUpUserInfo', 'formData');
    if(!this.showFuture) {
      this.termsEnabled = true;
    }
  }
};
</script>

<style scoped lang="scss">
.main-bg {
  background: #f4f7f9;
  min-height: 100vh;
}

.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}
.signup-form-container {
  max-width: 700px;
}


$ease: all .2s ease-in-out;

@mixin active() {
  background-color: black;
  color: white;
  transition: $ease
}

@mixin buttonStyles() {
  background-color: rgba(17, 16, 16, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  cursor: pointer;
  transition: $ease;
  font-size: 10px;
  font-weight: 600;
}

.button-container {
  display: flex;
  .btn-shaped {
    padding: 10px 15px;
    @include buttonStyles();

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:hover {
      @include active();
    }

    &__active {
      @include active();
    }
  }
}
</style>

<template>
  <v-container fluid class="pa-0" style="background: #fff !important;">
    <top-bar-without-auth />
    <v-divider />
    <v-row class="px-14 pt-12 pb-8 mb-8">
      <v-col cols="12">
        <div class="relative" style="max-width: 560px; margin: 0 auto; z-index: 1">
          <h2 class="outfit-bold font-700 text-36 text--black text-center mb-0">Download Report for</h2>
          <h2 class="outfit-regular font-400 text-36 text--black text-center mb-3 text-capitalize" v-if="organizationName">{{ organizationName }}</h2>
          <v-card elevation="1" class="border-radius-16">
            <v-card-text class="px-7 pt-8 pb-10">
              <div class="mt-3">
                <label class="d-block mb-2 outfit-bold font-700 text-14 text-light-black">Matter Number</label>
                <v-text-field
                  v-model="clientMatterNumber"
                  color="#3ACA56"
                  solo
                  flat
                  rounded
                  placeholder="Client Matter Number"
                  iem
                  clearable
                  required
                  validate-on-blur
                  class="light-grey-text-field-outlined"
                  :rules="[v => !!v?.trim() || 'Matter Number is required']"
                  outlined
                  @blur="clientMatterNumber = clientMatterNumber?.trim()"
                />
              </div>
              <div class="text-center mt-0">
                <v-btn
                  :loading="isLoading"
                  large
                  depressed
                  rounded
                  color="#3ACA56"
                  data-cy="signup-button"
                  @click="downloadReports"
                  :disabled="!clientMatterNumber?.trim()"
                  class="px-14 disabled-dark-btn"
                >
                  <span class="outfit-bold font-700 text-12 white--text text-capitalize px-6">Download</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <app-footer />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import AppFooter from "../../components/Jlpc/AppFooter.vue";
import {getDomainDetails} from "@/router/general";
export default {
  components: {TopBarWithoutAuth, AppFooter},
  data: () => ({
    clientMatterNumber: null,
    requestId: null,
    organizationId: null,
    organizationName: null
  }),
  computed: {
    ...mapGetters("login", ["isLoading"])
  },
  beforeMount() {
    this.requestId = this.$route.query.request_id;
    this.organizationId = this.$route.query.organization_id;
    this.organizationName = this.$route.query.organization_name;
  },
  methods: {
    async downloadReports() {
      await this.$store.dispatch('jlpcUser/downloadJLReport1', {
        matter_number: this.clientMatterNumber,
        request_id: this.requestId,
        organization_id: this.organizationId
      }).then((res) => {
        window.open(res.url, 's2r-jl-report-download')
      })
      this.clientMatterNumber = null;
    }
  }
};
</script>

<style scoped lang="scss">
.v-btn.v-btn--disabled.disabled-dark-btn {
  background-color: #838383 !important;
  .v-btn__content * {
    color: #fff !important;
  }
}
</style>
import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: () => ({
    fetchingAdvisoryReport: false,
    advisoryReport: null,

    cyberItems: {
      peer_group: {
        id: "peer_group",
        title: "Behind Peer Group",
        rating: true,
        reports: [],
      },

      risk_model: {
        id: "risk_model",
        title: "Limit Adequacy Risk Model",
        rating: false,
        reports: [],
      },

      threat_assesment: {
        id: "threat_assesment",
        title: "Vulnerability & Threat Assessment",
        rating: false,
        reports: [],
      },
    },
  }),
  mutations: {
    updateField,

    updateReports(state, payload) {
      if (state.cyberItems.hasOwnProperty(payload.id)) {
        state.cyberItems[payload.id].reports = payload.reports;
      }
    },

    setReports(state, payload) {
      state.cyberItems = payload;
    },

    setAdvisoryReport(state, reports) {
      const advisoryReport = reports?.find((el) => el.category === 'advisory_report') || null;
      state.advisoryReport = advisoryReport;
    }
  },
  actions: {
    // UPLOAD MULTIPLE OR ONE REPORT(S)
    async uploadReports({ commit, dispatch, state }, { reports, id }) {
      if (!reports) throw new Error("Need array of reports for upload");

      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/brokers/customer_report", {
          reports,
        });
        const type = data.success ? "success" : "error";
        dispatch(
          "showMessage",
          { ...data, messageType: type, messageTitle: type.toUpperCase() },
          { root: true }
        );

        if (!data.success) return;

        const updatedItems = [];

        // SETTING CYBER PRE CHECK ITEMS
        if (state.cyberItems.hasOwnProperty(id)) {
          state.cyberItems[id]?.reports.forEach((report) => {
            let updatedReport = data.reports.find(
              (updatedReport) => updatedReport["handle"] === report["handle"]
            );
            updatedItems.push(updatedReport ? updatedReport : report);
          });
          commit("updateReports", { id, reports: updatedItems });
        }

        // SET ADVISORY REPORT FOR THE CUSTOMER
        commit('setAdvisoryReport', reports)
        return data
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // GET UPLOADED REPORTS
    async getReports({ commit, state }, organization_id) {
      if (!organization_id)
        throw new Error("Organization id is required to fetch reports");

      try {
        commit("startLoading", null, { root: true });

        const { data } = await axios.get(
          `/brokers/customer_report/organization/${organization_id}`
        );

        let cyberItems = structuredClone(state.cyberItems);

        Object.keys(cyberItems).forEach((key) => {
          cyberItems[key].reports = data?.reports?.filter((el) => el.category === key)
        })

        commit('setAdvisoryReport', data?.reports);
        commit("setReports", cyberItems);

      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // SINGLE DELETE
    async deleteReport({ commit, dispatch }, entity_id) {
      if (!entity_id)
        throw new Error("Entity id is required to delete a report");

      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(
          `/brokers/customer_report/${entity_id}`
        );
        const type = data.success ? "success" : "error";
        dispatch(
          "showMessage",
          { ...data, messageType: type, messageTitle: type.toUpperCase() },
          { root: true }
        );
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // BULK DELETE
    async deleteReports({ commit }, entity_ids) {
      if (!entity_ids)
        throw new Error("Need array of entity ids for bulk deletion");

      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.put(
          "/brokers/customer_report",
          entity_ids
        );
        console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getSharedCyberRatingUrl({ commit, state }, organization_id) {
      const { data } = await axios.get(`/brokers/cyber_rating_url/${organization_id}`)
      return data
    }
  },
  getters: {
    getField,
  },
};

import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";

export default {
  namespaced: true,
  state: () => ({
    advisoryData: null,
    advisoryReportLoading: false,
    advisoryReportDialog: false,
    reportData: {},
    userDetails: {},
  }),
  mutations: {
    updateField,

    SET_ADVISORY_DATA(state, payload) {
      state.advisoryData = payload;
    },

    SET_REPORT_DATA(state, payload) {
      state.reportData = payload;
    },

    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
    },
  },
  actions: {
    async getAdvisoryReport({ commit }, organization_id) {
      try {
        const { data } = await axios.get(`/advisory_report/${organization_id}`);
        if (!data.success) return;
        commit("SET_ADVISORY_DATA", data.report);
      } catch (e) {
        console.log(e);
      }
    },

    async getAdvisoryReportData({ commit }, report_id) {
      try {
        const { data } = await axios.get(`/advisory_report/data/${report_id}`);
        if (!data.success) return;
        commit("SET_REPORT_DATA", data.report_data);
        commit("SET_USER_DETAILS", data.organization_detail);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    getField,
  },
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import { uuid } from "vue-uuid";
import localService from "@/services/localService";
import { vm } from "../main";
import companySetupGuide from "@/Mixins/companySetupGuide";

export default {
  namespaced: true,
  state: () => ({
    screensList: {
      "build-company-profile": [
        "policies-planning",
        "company-details",
        "company-websites",
        "industry-details",
        "employees-details",
        "cyber-security-organization",
        "revenue-growth",
        "org-security-handle",
        "final-build-company-profile",
      ],
      "build-risk-profile": [
        "org-security-handle",
        "systems-records-for-protect-them",
        "org-data-backed-up",
        "email-security",
        "media-usage",
        "org-recent-incidents-experienced",
        "final-build-risk-profile",
      ]
    },
    indicationSetupSteps: [
      {
        title: "Company Profile",
        key: "build-company-profile",
        visible: false,
        details: [
          {
            key: "policies-planning",
            title: "Tell us about your cyber insurance goals and current coverage",
            status: 0,
          },
          {
            key: "company-details",
            title: "Please tell us a bit about your company",
            status: 0,
          },
          {
            key: "company-websites",
            title: "Provide the URLs of all company websites",
            status: 0,
          },
          {
            key: "industry-details",
            title: "Describe the nature of your business",
            status: 0,
          },
          {
            key: "employees-details",
            title: "Please indicate the number of employees in each category below",
            status: 0,
          },
          {
            key: "cyber-security-organization",
            title: "Add Cybersecurity Contact",
            status: 0,
          },
          {
            key: "revenue-growth",
            title: "Tell us about your company’s revenue and growth",
            status: 0,
          },
          {
            key: "org-security-handle",
            title: "How does your organization handle security?",
            status: 0,
          },
        ],
      },
      {
        title: "Risk Profile",
        key: "build-risk-profile",
        visible: false,
        details: [
          {
            key: "org-security-handle",
            title: "How does your organization handle security?",
            status: 0,
          },
          {
            key: "systems-records-for-protect-them",
            title: "How does your organization handle sensitive information?",
            status: 0,
          },
          {
            key: "org-data-backed-up",
            title: "How does your organization back up data?",
            status: 0,
          },
          {
            key: "email-security",
            title: "How does your organization handle communications?",
            status: 0,
          },
          {
            key: "media-usage",
            title: "How does your organization use media?",
            status: 0,
          },
          {
            key: "org-recent-incidents-experienced",
            title: "Has your organization experienced any recent incidents?",
            status: 0,
          }
        ],
      }
    ]
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    setFetchingAnswers(state, payload) {
      state.fetchingAnswers = payload;
    }
  },
  actions: {
  },
  getters: {
    getField
  },
};

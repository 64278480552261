import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import localService from "../services/localService";
import { vm } from "../main";
import { getDomainDetails } from "@/router/general";
import Cookies from "js-cookie";
export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    currentUser: {},
    isLoggedIn: false,
    sso: null,
    ssoConfig: null,
    email: "",
    password: "",
    confirmPassword: "",
    view: "login",
    valid: false,
    elevate: false,
    passwordType: "password",
    cPasswordType: "password",
    mfa_dialog: false,
    twoFactor: false,
    recoveryCodeForm: false,
    authCode: "",
    recoveryCode: "",
    readOnlyView: false,
    cwbInstanceDetails: null,
    signupForm: {
      first_name: "",
      last_name: "",
      email: "",
      broker_id: "",
      broker_name: "",
      broker_url: "",
      position: null,
      title: "",
    },
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    resetState(state) {
      state.currentUser = {};
      state.mfa_dialog = false;
      state.isLoggedIn = false;
      state.email = "";
      state.password = "";
      state.confirmPassword = "";
      state.view = "login";
      state.valid = false;
      state.elevate = false;
      state.passwordType = "password";
      state.cPasswordType = "password";
      state.mfa_dialog = false;
      state.twoFactor = false;
      state.recoveryCodeForm = false;
      state.authCode = "";
      state.recoveryCode = "";
    },
  },
  actions: {
    /* Action that login in a user */
    async login({ commit, dispatch, state }, payload) {
      try {
        // let checkCompanySetupState = false; // only for check client admin
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/login", payload);
        if (!data.success) {
          return;
        }
        // else if (data.user && data.user.role == "client" && data.user.client_role == "admin") {
        //   checkCompanySetupState = true;
        // }
        else if (data.user.mfa_enabled) {
          return { showMfa: true };
        } else if (data.user && data.user.role == "client" && data.user.subscription_expired) {
          return dispatch(
            "showMessage",
            {
              message: "Your subscription has expired",
              messageType: "error",
              messageTitle: "Error",
            },
            { root: true }
          );
        }
        let currentUser = data;

        console.log("data.user ", data);
        if (currentUser.user.custom_subdomain && window.location.hostname !== "localhost") {
          let userSubdomain = currentUser.user.custom_subdomain;
          let { subdomain, domain, type } = getDomainDetails();
          if (userSubdomain !== subdomain) {
            Cookies.set(userSubdomain, `${currentUser.user.email} ${currentUser.access_token}`, {
              domain: `.${domain}.${type}`,
            });
            window.location.hostname = `${userSubdomain}.${domain}.${type}`;
            return;
          }
        }

        if (currentUser.user.client_delegations && Object.keys(currentUser.user.client_delegations)?.length) {
          currentUser.user.onboard_delegate_completed = true;
          currentUser.user.onboard_invite_members_completed = true;
        }

        commit("setState", { key: "currentUser", value: currentUser });
        localService.setItem("userInfo", data);
        commit("setState", { key: "isLoggedIn", value: true });

        // ALPHA FLOWS
        if (currentUser.user && currentUser.user.is_alpha_organization) {
          return vm.$router.push({ path: "/alpha/step/1" });
        }

        if (currentUser.user && currentUser.user.is_alpha_single) {
          return vm.$router.push({ path: "/alpha/single/meeting" });
        }
        // ALPHA FLOWS

        if (currentUser.user && currentUser.user.role == "client") {
          const {
            organization: { is_jackson_lewis_client },
          } = await vm.$store.dispatch("client/getCurrentOrganization");
          currentUser.user.is_jackson_lewis_client = is_jackson_lewis_client;
        } else {
          currentUser.user.is_jackson_lewis_client = false;
        }

        // currentUser.user.showQuickQuotes = true;

        // if (data.user && data.user.role == "client") {
        //   const quickStartAccessData = await vm.$store.dispatch("client/getQuickStartStatus");
        //   currentUser.user.showQuickQuotes = quickStartAccessData?.enable_quick_start;
        // }

        // commit("setState", { key: "currentUser", value: currentUser });
        // localService.setItem("userInfo", currentUser);

        // if (checkCompanySetupState) {
        //   const {
        //     company_setup: { organization_id, questionnaire },
        //   } = await vm.$store.dispatch("companySetup/getQuestionnaire");
        //   if (organization_id) {
        //     currentUser.user.company_setup_guide_started = true;
        //     if (questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        //       currentUser.user.showQuickQuotes = false;
        //     }
        //     commit("setState", { key: "currentUser", value: currentUser });
        //     localService.setItem("userInfo", currentUser);
        //     vm.$router.go("/");
        //   } else {
        //     vm.$router.go("/welcome");
        //   }
        // } else {

        vm.$router.go("/");

        // }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    /* Action that login in a user using SSO token */
    async loginUsingSSO({ commit, dispatch, state }, payload) {
      try {
        // let checkCompanySetupState = false; // only for check client admin
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/login/sso", payload);
        console.log(data);
        if (!data.success) {
          return;
        }
        // else if (data.user && data.user.role == "client" && data.user.client_role == "admin") {
        //   checkCompanySetupState = true;
        // }
        else if (data.user && data.user.role == "client" && data.user.subscription_expired) {
          return dispatch(
            "showMessage",
            {
              message: "Your subscription has expired",
              messageType: "error",
              messageTitle: "Error",
            },
            { root: true }
          );
        }
        let currentUser = data;

        if (currentUser.user.custom_subdomain && window.location.hostname !== "localhost") {
          let userSubdomain = currentUser.user.custom_subdomain;
          let { subdomain, domain, type } = getDomainDetails();
          if (userSubdomain !== subdomain) {
            Cookies.set(userSubdomain, `${currentUser.user.email} ${currentUser.access_token}`, {
              domain: `.${domain}.${type}`,
            });
            window.location.hostname = `${userSubdomain}.${domain}.${type}`;
            return;
          }
        }

        if (currentUser.user.client_delegations && Object.keys(currentUser.user.client_delegations)?.length) {
          currentUser.user.onboard_delegate_completed = true;
          currentUser.user.onboard_invite_members_completed = true;
        }

        commit("setState", { key: "currentUser", value: currentUser });
        localService.setItem("userInfo", data);
        commit("setState", { key: "isLoggedIn", value: true });

        // ALPHA FLOWS
        if (currentUser.user && currentUser.user.is_alpha_organization) {
          return vm.$router.push({ path: "/alpha/step/1" });
        }

        if (currentUser.user && currentUser.user.is_alpha_single) {
          return vm.$router.push({ path: "/alpha/single/meeting" });
        }
        // ALPHA FLOWS

        if (currentUser.user && currentUser.user.role == "client") {
          const {
            organization: { is_jackson_lewis_client },
          } = await vm.$store.dispatch("client/getCurrentOrganization");
          currentUser.user.is_jackson_lewis_client = is_jackson_lewis_client;
        } else {
          currentUser.user.is_jackson_lewis_client = false;
        }

        vm.$router.go("/");
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async loginUsingToken({ commit, dispatch, state }, payload) {
      try {
        // let checkCompanySetupState = false;
        commit("setState", { key: "isLoading", value: true });
        let reqInstance = axios.create({});
        const { data } = await reqInstance.get("/me", {
          headers: {
            Authorization: `Bearer ${payload.accessToken}`,
          },
        });

        if (!data.success) {
          return;
        }
        // else if (data.user && data.user.role == "client" && data.user.client_role == "admin") {
        //   checkCompanySetupState = true;
        // }
        else if (data.user.mfa_enabled) {
          return { showMfa: true };
        } else if (data.user && data.user.role == "client" && data.user.subscription_expired) {
          return dispatch(
            "showMessage",
            {
              message: "Your subscription has expired",
              messageType: "error",
              messageTitle: "Error",
            },
            { root: true }
          );
        }
        let currentUser = data;
        currentUser["access_token"] = payload.accessToken;
        let redirectPage = null;
        if (typeof payload.allocatedToken != "undefined") {
          currentUser["allocated_token"] = payload.allocatedToken;
        }
        if (payload.type && payload.type == "risk-workbench") {
          redirectPage = "/user/digital-asset";
        }
        if (currentUser.user.client_delegations && Object.keys(currentUser.user.client_delegations)?.length) {
          currentUser.user.onboard_delegate_completed = true;
          currentUser.user.onboard_invite_members_completed = true;
        }

        // currentUser.user.showQuickQuotes = true;

        commit("setState", { key: "currentUser", value: currentUser });
        localService.setItem("userInfo", data);
        commit("setState", { key: "isLoggedIn", value: true });

        // if (data.user && data.user.role == "client") {
        //   const quickStartAccessData = await vm.$store.dispatch("client/getQuickStartStatus");
        //   currentUser.user.showQuickQuotes = quickStartAccessData?.enable_quick_start;
        // }

        // if (checkCompanySetupState) {
        //   const {
        //     company_setup: { organization_id, questionnaire },
        //   } = await vm.$store.dispatch("companySetup/getQuestionnaire");
        //   if (organization_id) {
        //     currentUser.user.company_setup_guide_started = true;
        //     if (questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        //       currentUser.user.showQuickQuotes = false;
        //     }
        //     commit("setState", { key: "currentUser", value: currentUser });
        //     localService.setItem("userInfo", data);
        //     if (redirectPage) {
        //       vm.$router.push(redirectPage);
        //     } else {
        //       vm.$router.go("/");
        //     }
        //   } else {
        //     if (redirectPage) {
        //       vm.$router.push(redirectPage);
        //     } else {
        //       vm.$router.go("/welcome");
        //     }
        //   }
        // } else {
        if (redirectPage) {
          vm.$router.push(redirectPage);
        } else {
          vm.$router.go("/");
        }
        // }
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async ssoCheck({ commit, dispatch, state }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.get("/sso_check");
        if (!data.success) {
          return;
        }
        commit("setState", { key: "sso", value: data.sso });
        commit("setState", { key: "ssoConfig", value: data.config });
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    /* Action that login in a user using MFA OTP */
    async loginMfa({ commit, dispatch, state }, payload) {
      try {
        // let checkCompanySetupState = false;
        const { data } = await axios.post("/login_mfa", payload);
        if (!data.success) {
          return;
        }
        // else if (data.user && data.user.role == "client" && data.user.client_role == "admin") {
        //   checkCompanySetupState = true;
        // }
        else if (data.user && data.user.role == "client" && data.user.subscription_expired) {
          return dispatch(
            "showMessage",
            {
              message: "Your subscription has expired",
              messageType: "error",
              messageTitle: "Error",
            },
            { root: true }
          );
        }
        let currentUser = data;
        if (currentUser.user.client_delegations && Object.keys(currentUser.user.client_delegations)?.length) {
          currentUser.user.onboard_delegate_completed = true;
          currentUser.user.onboard_invite_members_completed = true;
        }

        // currentUser.user.showQuickQuotes = true;

        commit("setState", { key: "isLoggedIn", value: true });
        commit("setState", { key: "currentUser", value: currentUser });
        localService.setItem("userInfo", data);

        // if (data.user && data.user.role == "client") {
        //   const quickStartAccessData = await vm.$store.dispatch("client/getQuickStartStatus");
        //   currentUser.user.showQuickQuotes = quickStartAccessData?.enable_quick_start;
        // }

        // if (checkCompanySetupState) {
        //   const {
        //     company_setup: { organization_id, questionnaire },
        //   } = await vm.$store.dispatch("companySetup/getQuestionnaire");
        //   if (organization_id) {
        //     currentUser.user.company_setup_guide_started = true;
        //     if (questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        //       currentUser.user.showQuickQuotes = false;
        //     }
        //     commit("setState", { key: "currentUser", value: currentUser });
        //     localService.setItem("userInfo", data);
        //     vm.$router.go("/");
        //   } else {
        //     vm.$router.go("/welcome");
        //   }
        // } else {
        vm.$router.go("/");
        // }
      } catch (e) {
        console.log(e);
      }
    },

    /* Action that login in a user using MFA Recovery Codes */
    async loginUsingRecovery({ commit, dispatch, state }, payload) {
      try {
        // let checkCompanySetupState = false;
        const { data } = await axios.post("/verify_recovery_code", payload);
        if (!data.success) {
          return;
        }
        // else if (data.user && data.user.role == "client" && data.user.client_role == "admin") {
        //   checkCompanySetupState = true;
        // }
        else if (data.user && data.user.role == "client" && data.user.subscription_expired) {
          return dispatch(
            "showMessage",
            {
              message: "Your subscription has expired",
              messageType: "error",
              messageTitle: "Error",
            },
            { root: true }
          );
        }
        let currentUser = data;
        if (currentUser.user.client_delegations && Object.keys(currentUser.user.client_delegations)?.length) {
          currentUser.user.onboard_delegate_completed = true;
          currentUser.user.onboard_invite_members_completed = true;
        }

        // currentUser.user.showQuickQuotes = true;

        commit("setState", { key: "currentUser", value: currentUser });
        commit("setState", { key: "isLoggedIn", value: true });
        localService.setItem("userInfo", data);

        // if (data.user && data.user.role == "client") {
        //   const quickStartAccessData = await vm.$store.dispatch("client/getQuickStartStatus");
        //   currentUser.user.showQuickQuotes = quickStartAccessData?.enable_quick_start;
        // }

        // if (checkCompanySetupState) {
        //   const {
        //     company_setup: { organization_id, questionnaire },
        //   } = await vm.$store.dispatch("companySetup/getQuestionnaire");
        //   if (organization_id) {
        //     currentUser.user.company_setup_guide_started = true;
        //     if (questionnaire?.extraDetails?.quickQuotes?.status == "sent_for_full_quote") {
        //       currentUser.user.showQuickQuotes = false;
        //     }
        //     commit("setState", { key: "currentUser", value: currentUser });
        //     localService.setItem("userInfo", data);
        //     vm.$router.go("/");
        //   } else {
        //     vm.$router.go("/welcome");
        //   }
        // } else {

        vm.$router.go("/");

        // }
      } catch (e) {
        console.log(e);
      }
    },

    /* Action that logs out a user */
    async logout({ commit }) {
      commit("setState", { key: "isLoggedIn", value: false });
      commit("setState", { key: "currentUser", value: {} });
      commit("resetState");
      localService.removeItem("userInfo");
      localService.removeItem("isClientFormFilled");
      localService.removeItem("s2r_signUpUserInfo");
      localService.removeItem("s2r_paymentUserInfo");
      vm.$router.push({ path: "/login" });
    },

    async resetPasswordUsingToken({ commit }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });

        const { uid, token, ...data } = payload;

        const response = await axios.post(`/reset_password/${token}/${uid}`, data);

        if (!response.data.success) return;

        commit("resetState");

        return { ...response, ...payload };
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    /* Action that resets a password */
    async resetPassword({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/reset_password", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async signup({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/signup", payload);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        localService.removeItem("s2r_signUpUserInfo");
        localService.removeItem("s2r_paymentUserInfo");
        vm.$router.push({ path: `/signup/thank-you/${payload.email}` });
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async jlpcSignup({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        localService.setItem("jl_s2r_signUpUserInfo", Object.assign({}, payload));
        let formData = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          companyName: payload.companyName,
          companyUrl: payload.primaryWebsite,
          questionnaire: payload.questionnaire,
        };
        let reqInstance = axios.create({});
        const { data } = await reqInstance.post("/signup_jlpc", formData);
        if (!data.success) {
          if (data.message?.toLowerCase() == "email is already registered") {
            dispatch("jlpcResendEmail", formData.email);
          } else {
            dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true });
          }
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        vm.$router.push(`/privacy-exposure/confirmation/${payload.email}`);
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async jlpcResendEmail({ commit, dispatch }, email) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post(`/resend_email_verification`, { email: email });
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        vm.$router.push(`/privacy-exposure/confirmation/${email}`);
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async jlpcConfirmEmail({ commit, dispatch }, token) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post(`/confirm_email/${token}`);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async subscribe({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        let userInfo = payload.userInfo;
        if (userInfo?.user?.entity_id) {
          const { data } = await axios.post("/handle_payment", {
            ...payload.paymentForm,
          });
          if (data.success) {
            let userInfo = localService.getItem("userInfo");
            userInfo.user.payment_complete = 1;
            localService.setItem("userInfo", userInfo);
            vm.$router.push("/");
          } else {
            dispatch(
              "showMessage",
              {
                message: data.message,
                messageType: "error",
                messageTitle: "Error",
              },
              { root: true }
            );
          }
        } else {
          await dispatch("signup", payload);
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async mShiftLogin({ commit, dispatch, state }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/mshift-login", payload);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "isLoading", value: false });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async alphaSignup({ commit }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/alpha/signup", payload);
        if (!data.success) return;
        commit("setState", { key: "isLoading", value: false });
        vm.$router.push(`/alpha/signup/thank-you/${payload.email}`);
        localService.removeItem("s2r_signUpUserInfo");
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async alphaSingleSignup({ commit }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("alpha/single/signup", payload);
        if (!data.success) return;
        vm.$router.push(`/signup/thank-you/${payload.email}`);
        localService.removeItem("alphaSingle");
        localService.removeItem("s2r_signUpUserInfo");
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async acceptTerms({ commit }, payload) {
      try {
        const { data } = await axios.post("/accept_terms", { terms_version: payload.termsVersion });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },

    async getOrganizationDetailsById({ commit }, organizationId) {
      const { data } = await axios.get(`/organization/${organizationId}`);
      if (!data.success) {
        return;
      }
      return data;
    },

    async onboardUser() {
      const { data } = await axios.post(`/onboarding`);
      if (!data.success) return;
      return data;
    },
  },
  getters: {
    getField,
    currentUser: (state) => state.currentUser,
    isLoading: (state) => state.isLoading,
    isLoggedIn: (state) => state.isLoggedIn,
    clientRole: (state) => state.currentUser?.user?.client_role, // using only when current logged in user is client
  },
};

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";

const salespersonForm = () => {
  return {
    email: "",
    firstName: "",
    lastName: "",
  };
};
export default {
  namespaced: true,
  state: () => ({
    /* Static Data */
    tableHeaders: [
      { text: "Name", value: "name", sortable: true, filterable: true },
      { text: "Email", value: "email", sortable: false, filterable: true },
    ],
    /* Static Data */

    /* State */
    salespersonForm: salespersonForm(),
    salespersons: [],
    organizationList: [],
    organization: null,
    addSalespersonDialog: false,
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    updateSalespersonsList(state, data) {
      state.salespersons = [...state.salespersons, ...data];
    },
    resetSalespersonForm(state) {
      state.salespersonForm = salespersonForm();
      state.addSalespersonDialog = false;
    },
  },
  actions: {
    async createSalesperson({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/salesperson", payload);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("resetSalespersonForm");
        dispatch("getSalespersons");
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getSalespersons({ commit, dispatch }, payload) {
      try {
        let url = '/salesperson';
        if(payload && payload.page && payload.page_size) {
          url += `?page=${payload.page}&page_size=${payload.page_size}`
        }
        if (!payload.fetch_more) {
          commit("startLoading", null, { root: true });
        }
        const { data } = await axios.get(url);

        if (!data.success) {
          return;
        }
        commit("updateSalespersonsList", data.results);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async deleteSalesperson({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/salesperson/${payload.entity_id}`);
        if (!data.success) {
          return;
        }
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        dispatch("getSalespersons");
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    // action to set the state.user from user in payload
    setOrganization({ commit, rootState }, payload) {
      rootState.login.currentUser.user.client_role = "admin";
      // localService.setItem("salespersonUser", payload)
      //commit('setState', { key: 'user', value: payload });
    },

    // action to call /user_search that accepts the query parameter search_term
    async searchOrganizations({ commit, dispatch }, payload) {
      if (!payload) {
        return;
      }
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/search_organization/broker?name=${payload}`);
        if (!data.success) {
          return;
        }
        commit("setState", { key: "organizationList", value: data });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    getField,
    organizationList: (state) => state.organizationList,
    organization: (state) => state.organization,
  },
};

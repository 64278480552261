import Vue from "vue";
import Vuex from "vuex";
import { vm } from "../main";
import { getField, updateField } from "vuex-map-fields";

/* NAMESPACED MODULES */
import salespersonModule from "./salesperson";
import clientModule from "./client";
import brokerModule from "./broker";
import loginModule from "./login";
import digitalAssetsModule from "./digitalAssets";
import onboardModule from "./onboard";
import clickwrapModule from "./clickwrap";
import filesModule from "./files";
import technologiesModule from "./technologies";
import audit from "./audit";
import mfaModule from "./mfa";
import adminModule from "./admin";
import companySetup from "./companySetup";
import indicationSetup from "./indicationSetup";
import buildTeamModule from "./buildTeam";
import buildCompanyProfileModule from "./buildCompanyProfile";
import cyberPreCheckModule from "./cyberPreCheck";
import organizationalLandscapeModule from "./organizationLandscape";
import cyberSecurityCapabilitiesModule from "./cyberSecurityCapabilities";
import subscriptionBuilderModule from "./subscriptionBuilder";
import jlpcUserModule from "./jlpcUser";
import mShiftUser from "./mShiftUser";
import quickStart from "./quickStart";
import alphaModule from "./alpha";
import applicationsModule from "./applications";
import localOfficeModule from "./localOffice";
import privacyModule from "./privacyExposure";
import threatModule from "./threats";
import insurabilityModule from "./insurability";
import advisoryModule from "./advisory";
import wholesaleBroker from "./wholesaleBroker";
import riskDashboard from "./riskDashboard";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    drawer: false,
    termsModal: false,
    showFuture: false,
  },

  mutations: {
    updateField,
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {
    showMessage({ commit }, payload) {
      return vm.$notify({
        type: payload.messageType,
        title: payload.messageTitle,
        text: payload.message,
      });
    },
  },

  getters: {
    getField,
    isLoading: (state) => state.isLoading,
    drawer: (state) => state.drawer,
  },

  modules: {
    login: loginModule,
    salesperson: salespersonModule,
    client: clientModule,
    broker: brokerModule,
    digitalAssets: digitalAssetsModule,
    onboard: onboardModule,
    clickwrap: clickwrapModule,
    files: filesModule,
    technologies: technologiesModule,
    audit: audit,
    mfa: mfaModule,
    admin: adminModule,
    companySetup: companySetup,
    indicationSetup: indicationSetup,
    buildTeam: buildTeamModule,
    buildCompanyProfile: buildCompanyProfileModule,
    cyberPreCheck: cyberPreCheckModule,
    organizationalLandscape: organizationalLandscapeModule,
    cyberSecurityCapabilities: cyberSecurityCapabilitiesModule,
    subscriptionBuilder: subscriptionBuilderModule,
    jlpcUser: jlpcUserModule,
    mShiftUser: mShiftUser,
    quickStart: quickStart,
    alpha: alphaModule,
    applications: applicationsModule,
    localOffice: localOfficeModule,
    privacy: privacyModule,
    threats: threatModule,
    insurability: insurabilityModule,
    advisory: advisoryModule,
    wholesaleBroker: wholesaleBroker,
    riskDashboard: riskDashboard,
  },
});

<template>
  <div>
    <img :src="require('@/assets/images/shapes/black-half-left-circle.png')" style="position: absolute; left: -10px; top: 40%; max-width: 80px;"/>
    <img :src="require('@/assets/images/shapes/black-half-down-circle.png')" style="position: absolute; right: 16%; top: 50%; max-width: 64px;" />
    <img :src="require('@/assets/images/shapes/green-shape.png')" style="position: absolute; right: 0; top: 50%; max-width: 150px;" />
  </div>
</template>

<script>
  export default {

  }
</script>
import { getField, updateField } from "vuex-map-fields";
import axios from "../plugins/axios";

const addContributorForm = () => {
  return {
    contributor_email: "",
    add_as_wholesaler: false,
    access_to_my_book: false,
    message: "",
  };
};

export default {
  namespaced: true,

  state: () => ({
    isLoadingWholesale: false,
    wholesaleContributors: [],
    wholesaleOrganizations: [],
    addContributorDialog: false,
    customerBrokerages: [],
    retailBrokerages: [],
    addContributorForm: addContributorForm(),
    selectedBrokeragesFilter: [],
  }),

  mutations: {
    updateField,

    setWholesaleContributors(state, payload) {
      state.wholesaleContributors = payload;
    },

    setWholesaleOrganizations(state, payload) {
      state.wholesaleOrganizations = payload;
    },

    setCustomerBrokerages(state, payload) {
      state.customerBrokerages = payload;
    },

    setRetailBrokerages(state, payload) {
      state.retailBrokerages = payload;
    },

    resetContributorForm(state) {
      state.addContributorForm = addContributorForm();
    },
  },

  actions: {
    async getWholesaleOrganizations({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/organizations");
        if (!data.success) return;
        commit("setWholesaleOrganizations", data.wholesale_brokerages);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getWholesaleBrokerages({ commit }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/wholesaler/brokerages");
        if (!data.success) return;
        commit("setRetailBrokerages", data.brokerages);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getRetailBrokers({ commit }, organization_id) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/wholesaler/company/brokerage/${organization_id}`);
        if (!data.success) return;
        commit("setCustomerBrokerages", data.brokerages);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getWholesaleContributors({ commit }, organization_id) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get(`/company/contributor/${organization_id}`);
        if (!data.success) return;
        commit("setWholesaleContributors", data.broker_contributors);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addWholesaleContributor({ commit, state, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/contributor/${payload.organization_id}`, payload.data);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        commit("setWholesaleContributors", [...state.wholesaleContributors, data.contributor]);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteWholesaleContributors({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.delete(`/company/contributor/${payload.company_id}/${payload.broker_user_id}`);
        if (!data.success) return;
        dispatch("showMessage", { ...data, messageType: "success", messageTitle: "Success" }, { root: true });
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async signupWholesaleBroker({ commit }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post(`/wholesaler/signup/${payload.invitation_token}`, payload.data);
        if (!data.success) return;
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    getField,
  },
};

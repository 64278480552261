<template>
  <v-app id="app">
    <notifications className="custom-notification-position" />
    <router-view />
  </v-app>
</template>
<script>
import localService from "./services/localService";
import appTitleMixin from "./Mixins/appTitle";
import AppFormTitle from "@/components/Base/AppFormTitle";
import jwtDecode from "jwt-decode";
import LoginForm from "@/components/Base/LoginForm";
import LoginMfaForm from "@/components/Base/LoginMfaForm";
import userflow from "userflow.js";
import {user_flow_token} from "@/config/constants";
import {mapFields} from "vuex-map-fields";
import {getDomainDetails} from "@/router/general";
import Cookies from 'js-cookie';
export default {
  mixins: [appTitleMixin],

  components: {
    AppFormTitle,
    LoginForm,
    LoginMfaForm,
  },

  computed: {
    ...mapFields("login", ["currentUser", "readOnlyView", "cwbInstanceDetails"]),
  },

  watch: {
    "$route.params": {
      handler() {
        if (
          this.currentUser?.user?.role == "admin" &&
          this.$route.path?.includes("user") &&
          this.$route.params?.orgId
        ) {
          this.readOnlyView = true;
          this.cwbInstanceDetails = {
            orgId: this.$route.params?.orgId,
            appBarColor: "#FF842A",
          };
        }
      },
    },
  },

  methods: {
    checkAndStoreCookie() {
      let domainDetails = getDomainDetails()
      let tokens = Cookies.get(domainDetails.subdomain)
      if (tokens) {
        let [email, accessToken] = tokens.split(' ')
        localStorage.setItem('userInfo', JSON.stringify({'access_token': accessToken}))
        let {subdomain, domain, type} = domainDetails;
        Cookies.remove(subdomain, {"domain": `.${domain}.${type}`})
        this.$store.dispatch("login/loginUsingToken", {accessToken});
      }
    },
    checkIfUserIsLoggedIn() {
      if (localService.getItem("userInfo")) {
        const userInfo = localService.getItem("userInfo");
        const token = userInfo["access_token"];

        if (userInfo && token) {
          const user = userInfo.user;
          if (user_flow_token) {
            userflow.init(user_flow_token);
            userflow.identify(user.entity_id, {
              name: user.name,
              email: user.email,
              organization_name: user.organization_name,
              client_or_broker: user.role,
              role: user.client_role,
            });
          }
          window.heap.identify(user.email);
          window.heap.addUserProperties({"User Name": user.name, "Organization Name": user.organization_name, "Account Type": user.role});
          this.$store.commit("login/setState", {
            key: "currentUser",
            value: userInfo,
          });
          return this.$store.commit("login/setState", {
            key: "isLoggedIn",
            value: true,
          });
        }

        return this.$store.commit("login/setState", {
          key: "isLoggedIn",
          value: false,
        });
      }
    },
    checkAccessTokenExpiry() {
      const userInfo = localService.getItem("userInfo");
      if (userInfo) {
        const token = userInfo["access_token"] || "";
        const access = jwtDecode(token);
        const nowInSecs = Date.now() / 1000;
        const isExpired = access.exp - nowInSecs < 0;
        if (isExpired) {
          this.$store.dispatch("login/logout");
          return;
        }
        setTimeout(() => {
          this.checkAccessTokenExpiry();
        }, 1000 * 6);
      }
    },
  },
  async created() {
    await this.checkAndStoreCookie();
    this.checkIfUserIsLoggedIn();
    this.checkAccessTokenExpiry();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/global.scss";
</style>
<template>
  <v-form ref="form" v-model="valid" lazy-validation class="custom-error-details">
    <v-row>
      <v-col v-if="heading" cols="12">
        <h2 class="text-center">{{ heading }}</h2>
      </v-col>
      <v-col cols="12" md="12">
        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2" v-if="showOutsideLabel"
          >Card number *</label
        >
        <v-text-field
          v-model="paymentForm.card.number"
          v-mask="'#### #### #### ####'"
          type="text"
          placeholder="0000 0000 0000 0000"
          rounded
          flat
          solo
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="onBlur('number', 'paymentForm')"
          @keyup.enter="submitForPayment"
          :rules="[(v) => (v && !!v.trim()) || 'Card Number is required.']"
          :class="outlined ? 'light-grey-outlined' : ''"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="6">
        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2" v-if="showOutsideLabel"
          >Expiration date *</label
        >
        <v-text-field
          v-model="paymentForm.card.expireDate"
          v-mask="'##/##'"
          type="text"
          placeholder="MM/YY"
          rounded
          flat
          solo
          :rules="[(v) => (v && !!v.trim()) || 'Expiration date is required.', checkExpiryDate]"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="onBlur('expireDate', 'paymentForm')"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
        />
      </v-col>

      <v-col cols="6" md="6">
        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2" v-if="showOutsideLabel">CVC *</label>
        <v-text-field
          v-model="paymentForm.card.cvc"
          v-mask="'####'"
          type="text"
          placeholder="123"
          rounded
          flat
          solo
          :rules="[
            (v) => (v && !!v.trim()) || 'CVC is required.',
            (value) => (value && value.replace(/ /g, '').trim().length >= 3) || 'Invalid CVC',
          ]"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="onBlur('cvc', 'paymentForm')"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2" v-if="showOutsideLabel"
          >Name on card *</label
        >
        <v-text-field
          v-model="paymentForm.billing_details.name"
          type="text"
          placeholder="Enter name on card "
          rounded
          flat
          solo
          :rules="[(v) => (v && !!v.trim()) || 'Name is required.', checkValidName]"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="onBlur('name', 'paymentForm')"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <label class="outfit-bold text-14 font-700 text-light-black d-block mb-2">Billing Address *</label>
        <v-text-field
          v-model="paymentForm.billing_details.address.line1"
          type="text"
          placeholder="Address line"
          rounded
          flat
          solo
          :class="outlined ? 'light-grey-outlined' : ''"
          :rules="[(v) => !!v || 'Address is required.']"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="elevate = false"
          @keyup.enter="submitForPayment"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="6">
        <v-autocomplete
          v-model="paymentForm.billing_details.address.country"
          :items="countries"
          item-text="name"
          placeholder="Country"
          item-value="code"
          iem
          rounded
          flat
          solo
          :class="outlined ? 'light-grey-outlined' : ''"
          required
          :rules="[(v) => !!v || 'Country is required']"
          @change="handleCountryChange"
          data-cy="country"
        >
          <template #item="{ item }">
            <span data-cy="searched-state">
              <span class="font-weight-bold pr-1">{{ item.code }} - </span>
              {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="6" md="6">
        <v-text-field
          v-model="paymentForm.billing_details.address.state"
          type="text"
          placeholder="State"
          rounded
          flat
          solo
          class="textfield-uppercase"
          :rules="[(v) => !!v || 'State is required.']"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="elevate = false"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="6">
        <v-text-field
          v-model="paymentForm.billing_details.address.city"
          type="text"
          placeholder="City"
          rounded
          flat
          solo
          :rules="[(v) => !!v || 'City is required.']"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="elevate = false"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
        >
        </v-text-field>
      </v-col>

      <v-col cols="6" md="6">
        <v-text-field
          v-model="paymentForm.billing_details.address.postal_code"
          v-mask="'######'"
          type="text"
          placeholder="Zip Code"
          rounded
          flat
          solo
          :disabled="!paymentForm.billing_details.address.country"
          :rules="[(v) => !!v || 'Zip code is required.', (v) => !!isValidPostalCode(v) || 'Postal code must be valid']"
          required
          validate-on-blur
          @focus="elevate = true"
          @blur="elevate = false"
          @keyup.enter="submitForPayment"
          :class="outlined ? 'light-grey-outlined' : ''"
          ref="zip"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="!paymentForm.token" class="align-center test-demo mt-3">
      <v-col cols="12" md="12">
        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2" v-if="showOutsideLabel">
          Promo Code
        </label>

        <div class="relative">
          <v-text-field
            id="test-field"
            v-model="paymentForm.promo_code"
            type="text"
            required
            rounded
            flat
            solo
            :hint="invalid_promo_code_reason"
            persistent-hint
            label="Promo Code"
            :disabled="valid_promo_code"
            style="padding-right: 100px !important"
            :class="outlined ? 'light-grey-outlined' : ''"
          >
            <!-- conditionally show a green checkmark in the end of the v-text-field -->
            <template #append>
              <v-icon v-if="valid_promo_code" color="green">mdi-check</v-icon>

              <v-divider vertical class="mx-1" />

              <v-icon v-if="valid_promo_code" @click="valid_promo_code = null" size="18" class="cursor-pointer">
                mdi-pencil
              </v-icon>
            </template>
          </v-text-field>
          <v-btn
            :loading="isPromoLoading"
            id="test-btn"
            :disabled="valid_promo_code || !paymentForm.promo_code?.trim()"
            color="#3ACA56"
            class="btn-continue-grey-bg ml-3 apply-coupon-btn"
            @click="apply"
            depressed
          >
            <strong>apply</strong>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" class="mt-2">
        <v-btn
          :loading="isLoading"
          large
          block
          depressed
          rounded
          color="#3ACA56"
          data-cy="payment-button"
          :disabled="!paymentFormValid"
          @click="submitForPayment"
        >
          <span class="outfit-bold font-700 text-12 white--text">Pay Now • ${{ totalAmount }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" class="mt-0 mb-5">
        <div small link text class="outfit-regular font-400 text-14 mb-0 text-grey">
          By confirming your subscription, you allow SecondSight Data Recovery Inc. to charge your card for this payment
          in accordance with their
          <span class="outfit-bold font-700 text-grey cursor-pointer" @click="$emit('toggleTermsPolicy')">terms</span
          >.<br />
          <span class="mt-2 text-grey d-block">* You can always cancel your subscription.</span>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import validationMixin from "../../Mixins/validation";
import { countries } from "@/static/countries";
import isPostalCode from "validator/lib/isPostalCode";
import validator from "validator";
import axios from "../../plugins/axios";
import localService from "@/services/localService";
import SignupMixin from "@/Mixins/signup";

export default {
  components: {},
  mixins: [validationMixin, SignupMixin],
  props: {
    heading: {
      type: String,
      default: () => null,
    },
    submitBtnText: {
      type: String,
      default: () => "Subscribe",
    },
    showTerms: {
      type: Boolean,
      default: () => true,
    },
    outlined: {
      type: Boolean,
      default: () => false,
    },
    showOutsideLabel: {
      type: Boolean,
      default: () => false,
    },
    totalPayableAmount: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      valid: false,
      isPromoLoading: false,
      valid_promo_code: null,
      invalid_promo_code_reason: "",
      paymentForm: {
        card: {
          number: "",
          exp_month: "",
          exp_year: "",
          cvc: "",
          expireDate: "",
        },
        billing_details: {
          name: "",
          address: {
            city: "",
            country: "",
            line1: "",
            line2: "",
            postal_code: "",
            state: "",
          },
        },
        promo_code: "",
        token: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters("login", ["currentUser"]),

    countries() {
      return [...countries];
    },

    isValidPostalCode() {
      return (postal) => {
        const availableLocales = validator.isPostalCodeLocales;
        let { country } = this.paymentForm.billing_details.address;

        if (postal && country) return isPostalCode(postal, availableLocales.includes(country) ? country : "any");

        return "";
      };
    },

    totalAmount() {
      let amount = this.totalPayableAmount ? this.totalPayableAmount : 499;
      if (this.paymentForm.token) {
        amount = 0;
      } else if (this.valid_promo_code) {
        amount = 199;
      }
      return amount;
    },

    paymentFormValid() {
      console.log("paymentFormValid");
      if (
        this.paymentForm.card.number == "" ||
        this.paymentForm.card.number.length < 16 ||
        this.paymentForm.card.expireDate == "" ||
        this.paymentForm.card.cvc == "" ||
        this.paymentForm.card.cvc.length < 3 ||
        this.paymentForm.billing_details.name == "" ||
        this.paymentForm.billing_details.address.line1 == "" ||
        this.paymentForm.billing_details.address.country == "" ||
        this.paymentForm.billing_details.address.state == "" ||
        this.paymentForm.billing_details.address.city == "" ||
        this.paymentForm.billing_details.address.postal_code == "" ||
        !this.isValidPostalCode(this.paymentForm.billing_details.address.postal_code) ||
        this.checkExpiryDate(this.paymentForm.card.expireDate) != true ||
        this.checkValidName(this.paymentForm.billing_details.name) != true
      ) {
        return false;
      }
      return true;
    },
  },

  mounted() {
    this.paymentForm.billing_details.address.country = "US";
    // get the parameter promocode from the url
    this.paymentForm.promo_code = this.$route.query.promocode;
    this.paymentForm.token = this.$route.query.token;

    // if we have a promo-code, call the "apply" function
    if (this.paymentForm.promo_code && !this.paymentForm.token) {
      this.apply();
    }

    this.wasFormFilled("s2r_paymentUserInfo", "paymentForm");
    if (this.currentUser && this.currentUser.allocated_token) {
      this.paymentForm.token = this.currentUser.allocated_token;
    }
  },

  methods: {
    clearPromoCode() {
      this.paymentForm.promo_code = "";
      this.valid_promo_code = null;
      this.invalid_promo_code_reason = "";
    },

    async apply() {
      try {
        this.isPromoLoading = true;
        const response = await axios.post(
          "/verify_promo_code",
          {
            promo_code: this.paymentForm.promo_code,
          },
          { withoutLoading: true }
        );

        this.valid_promo_code = response.data.success;

        if (!response.data.message) {
          this.valid_promo_code = true;
          let data = localService.getItem("s2r_signUpUserInfo");
          data.promo_code = this.paymentForm.promo_code;
          this.invalid_promo_code_reason = response.data.message;

          localService.setItem("s2r_signUpUserInfo", Object.assign({}, data));
        } else {
          this.valid_promo_code = false;
          this.invalid_promo_code_reason = response.data.message;
        }
      } catch (error) {
        if (error.response.data.success == false) {
        }
      } finally {
        this.isPromoLoading = false;
      }
    },

    checkExpiryDate(val) {
      let date = val.split("/");
      let currentYear = parseInt(new Date().getFullYear().toString().substr(2, 2), 10);
      let currentMonth = parseInt(new Date().getMonth()) + 1;
      if (
        date.length < 2 ||
        parseInt(date[0]) > 12 ||
        parseInt(date[1]) < currentYear ||
        (parseInt(date[0]) < currentMonth && parseInt(date[1]) == currentYear)
      ) {
        return "Invalid expiration date";
      }
      return true;
    },

    checkValidName(val) {
      let name = val?.split(" ");
      return name.length == 2 && name[0]?.trim() && name[1]?.trim() ? true : 'Invalid name';
    },

    submitForPayment() {
      if (this.$refs.form.validate()) {
        let paymentForm = JSON.parse(JSON.stringify(this.paymentForm));
        paymentForm.card.number = paymentForm.card.number.replace(/ /g, "").trim();
        paymentForm.card.exp_month = paymentForm.card.expireDate.split("/")[0];
        paymentForm.card.exp_year = paymentForm.card.expireDate.split("/")[1];
        paymentForm.card.cvc = paymentForm.card.cvc.replace(" ", "").trim();
        delete paymentForm.card.expireDate;
        this.$emit("submitForPayment", paymentForm);
      }
    },

    handleCountryChange(val) {
      console.log(val);
      this.paymentForm.billing_details.address.postal_code = "";
      this.$refs?.zip.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.custom-width {
  width: 48%;
}
.payment-form-container {
  max-width: 700px;
}
.buy-token-form {
  background: #f5f5f5;
  padding: 24px;
  border-radius: 14px;
}
.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.v-text-field {
  background: #fff;
}

// .btn-continue-grey-bg {
//   // background: rgba(0, 0, 0, 0.12) !important;
// }
.updated-price {
  padding: 0px 8px;
  color: #3aca56 !important;
}
.p_tag {
  color: #ff5252;
  font-size: 12px;
  padding: 0px 15px;
}
.p_varify {
  color: #3aca56 !important;
  font-size: 12px;
}

.apply-coupon-btn {
  position: absolute;
  right: 0;
  top: 0px;
  border-radius: 44px;
  padding: 16px 28px !important;
  height: auto !important;
  z-index: 100;
}
</style>

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import { uuid } from "vue-uuid";
import localService from "@/services/localService";
import { vm } from "../main";
export default {
  namespaced: true,
  state: () => ({
    fetchingAnswers: false,
    questionnaire: null,
    screensList: {
      'build-team': [
        'invite-co-admins',
        'add-expertise-to-team',
        'add-technology-expertise',
        'add-cybersecurity-expertise',
        'add-operations-process-expertise',
        'add-hr-training-expertise',
        'add-legal-contract-expertise',
        'add-cyber-insurance-broker',
        'final-build-team'
      ],
      'build-company-profile': [
        'policies-planning',
        'company-details',
        'company-websites',
        'industry-details',
        'employees-details',
        'cyber-security-organization',
        'revenue-growth',
        'final-build-company-profile'
      ],
      'describe-organizational-landscape': [
        'email-security',
        'systems-records-for-protect-them',
        'media-usage',
        'org-data-backed-up',
        'org-security-handle',
        'org-recent-incidents-experienced',
        'final-organizational-landscape'
      ],
    },
    quickStartSteps: [
      {
        title: 'Start Your Team',
        key: 'build-team',
        description: 'Enlist a few colleagues who can help collect info, answer technical questions and keep everyone on track.',
        visible: false,
        details: [
          {
            key: 'invite-co-admins',
            title: 'Invite Co-Administrators',
            shortDescription: 'Send invitations to other administrators for this process.',
            description: 'It’s a good idea to have more than one Admin responsible for managing your team and keeping everything on track.',
            status: 0
          },
          {
            key: 'add-technology-expertise',
            title: 'Add Technology Expertise',
            shortDescription: 'Add teammates with Technology expertise.',
            description: 'Consider adding your Chief Technology Officer (CTO), the head of information technology (IT), leadership from your data science team (if you have one), your webmaster, etc. (Don\'t forget to tag yourself if appropriate.)',
            status: 0
          },
          {
            key: 'add-cybersecurity-expertise',
            title: 'Add Cybersecurity Expertise',
            shortDescription: 'Add teammates with Cybersecurity expertise',
            description: 'Consider adding your Chief Information Security Officer (CISO), Chief Technology Officer (CTO), the head of information technology (IT), etc. (Don\'t forget to tag yourself if appropriate.)',
            status: 0
          },
          {
            key: 'add-operations-process-expertise',
            title: 'Add Operations and Process Expertise',
            shortDescription: 'Add teammates with Operations and Process expertise.',
            description: 'Think about adding your Chief Operating Officer (COO) or other leaders with general expertise on how your company runs and the tools used in each department. (Tag yourself if appropriate.)',
            status: 0
          },
          {
            key: 'add-hr-training-expertise',
            title: 'Add HR and Training Expertise',
            shortDescription: 'Add teammates with HR and Training expertise.',
            description: 'Add in one or more people from your HR and Training leadership, so they can help answer questions about personally identifiable information, policies, procedures, etc. (Tag yourself if that’s you.) ',
            status: 0
          },
          {
            key: 'add-legal-contract-expertise',
            title: 'Add Legal and Contract Expertise',
            shortDescription: 'Add teammates with Legal and Contract expertise.',
            description: 'Add leaders who deeply understand the legal and contractual aspects of your company’s digital environment, such as software licensing agreements, etc. (As always, tag yourself if that’s you.)',
            status: 0
          },
          {
            key: 'add-cyber-insurance-broker',
            title: 'Add your Cyber Insurance Broker',
            shortDescription: 'Your Broker is a key member of your Pre-Underwriting team.',
            description: 'Naturally, you’ll want to be able to communicate and collaborate with your Broker, so add their information here. ',
            status: 0
          }
        ]
      },
      {
        title: 'Get Your Broker Started',
        key: 'build-company-profile',
        description: 'Provide the baseline information needed to ballpark potential carriers and coverage levels',
        visible: false,
        details: [
          {
            key: 'policies-planning',
            title: 'Tell us about your cyber insurance goals and current coverage',
            status: 0
          },
          {
            key: 'company-details',
            title: 'Please tell us a bit about your company',
            status: 0
          },
          {
            key: 'company-websites',
            title: 'Provide the URLs of all company websites',
            description: 'From a security standpoint, websites are a collection of potentially vulnerable entry points into your digital environment.  To properly document your risk exposure and the steps you’ve taken to control those risks, please provide the URLs of all sites owned by your company and any subsidiaries.',
            status: 0
          },
          {

            key: 'industry-details',
            title: 'Describe the nature of your business',
            status: 0
          },
          {
            key: 'employees-details',
            title: 'Please indicate the number of employees in each category below',
            status: 0
          },
          {
            key: 'cyber-security-organization',
            title: 'Add Cybersecurity Contact',
            status: 0
          },
          {
            key: 'revenue-growth',
            title: 'Tell us about your company’s revenue and growth',
            status: 0
          }
        ]
      },
      {
        title: 'Start the Application Process',
        key: 'describe-organizational-landscape',
        description: 'Provide the baseline information most commonly requested by carriers',
        visible: false,
        details: [
          {
            key: 'email-security',
            title: 'How does your organization use email?',
            status: 0
          },
          {
            key: 'systems-records-for-protect-them',
            title: 'What records are on your systems, and how do you protect them?',
            status: 0
          },
          {
            key: 'media-usage',
            title: 'How does your organization use media?',
            status: 0
          },
          {
            key: 'org-data-backed-up',
            title: 'How is data backed up at your organization',
            status: 0
          },
          {
            key: 'org-security-handle',
            title: 'How does your organization handle security?',
            status: 0
          },
          {
            key: 'org-recent-incidents-experienced',
            title: 'Has your organization experienced any recent incidents?',
            status: 0
          }
        ]
      }
    ],
    coAdmins: [],
    teamMembers: [],
    brokersList: [],
    brokerOrganizationsList: [], // for get company names and url
    upgradePlan: [
      {
        id: 'single-user',
        amount: 29,
        totalUser: 1,
        duration: 'yearly',
        description: 'Price for 1 User'
      },
      {
        id: 'multiple-users',
        amount: 125,
        totalUser: 5,
        duration: 'yearly',
        description: 'Pack of 5 Users'
      },
      {
        id: 'infinity-users',
        amount: 500,
        duration: 'yearly',
        description: 'Infinite Users'
      }
    ],
    creditCardDetails: null,
    paymentSubmitted: true,
    companyProfile: {
      full_time_employees: null,
      full_time_it_employees: null,
      full_time_cyber_security_employees: null,
      annual_it_budget_percentage: null,
      annual_cyber_security_budget_percentage: null,
      growth_revenue: {
        fiscal_year_end: null,
        report_month: null
      }
    },
    isLoadingBrokersList: false
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    setFetchingAnswers(state, payload) {
      state.fetchingAnswers = payload;
    },

  },
  actions: {
    async inviteUserForCSG({ commit }, payload) {
      const { data } = await axios.post(
        "organization_management/client",
        payload
      );
      return data
    },

    async resendInvitation({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post(
          "organization_management/client/reinvite",
          payload
        );
        const messageData = {
          message: data.message,
          messageType: data.success ? "success" : "error",
          messageTitle: data.success ? "Success" : "Error",
        }
        dispatch("showMessage", messageData, { root: true })
        return data
      } catch (e) {
        console.log(e);
      }
    },

    async getAllMembers({ commit }) {
      const { data } = await axios.get("/organization_management/client");
      if (!data.success) {
        return;
      }
      commit("setState", { key: "teamMembers", value: data.results });
    },
    async getBrokerOrganizations({ commit }) {
      const { data } = await axios.get("/brokers");
      if (!data.success) {
        return;
      }
      commit("setState", { key: "brokerOrganizationsList", value: data.results });
    },
    async getBrokersList({ commit }, searchText) {
      commit("setState", { key: "isLoadingBrokersList", value: true });
      const { data } = await axios.get(`/linked_brokers`);
      if (!data.success) {
        return;
      }
      commit("setState", { key: "brokersList", value: data.brokers });
      commit("setState", { key: "isLoadingBrokersList", value: false });
    },
    async inviteCyberInsuranceBroker({ commit }, payload) {
      const { data } = await axios.post(
        "invite_brokers",
        {
          brokers: payload
        }
      );
      return data
    },
    updateCyberInsuranceBroker({ commit }, payload) {
      return { success: true, member: payload }
    },
    async deleteCyberInsuranceBroker({ commit }, brokerId) {
      const { data } = await axios.delete(`/client_broker/${brokerId}`);
      if (!data.success) {
        return;
      }
      return { success: true, member: data }
    },
    purchaseUserSlot({ commit }, payload) {
      return payload
    },
    /* Action that saves a questionnaire */
    async saveQuestionnaire({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post("/company_setup", payload);
        const userInfo = localService.getItem("userInfo");
        if (!userInfo.user.company_setup_guide_started) {
          userInfo.user.company_setup_guide_started = true; // for hide welcome screen
          localService.setItem("userInfo", userInfo);
          vm.$store.commit("login/setState", { key: "currentUser", value: userInfo });
        }
        if(data.success) {
          vm.$store.commit("companySetup/setState", { key: "currentQuestionnaireVersion", value: data.version });
        }
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    /* Action that saves a questionnaire */
    async getQuestionnaire({ commit, dispatch }, organizationId) {
      try {
        commit('setFetchingAnswers', true)
        let url = '/company_setup';
        if (organizationId) url += `/${organizationId}`
        const { data } = await axios.get(url);
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit('setFetchingAnswers', false)
      }
    },
  },
  getters: {
    getField,
    teamMembers: (state) => state.teamMembers,
    brokersList: (state) => state.brokersList
  },
};

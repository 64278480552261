<template>
  <v-row class="pa-0 login-signup-form-container align-baseline">
    <h2 class="outfit-bold font-700 text-40 font-700 text-light-black mb-5">Terms</h2>
    <v-col cols="12" md="12" class="pa-6 login-signup-form-outer">
      <div class="text-light-black font-400 outfit-regular text-14 pt-4" style="max-height: 600px; overflow-y: auto">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur fringilla feugiat vehicula. Nam eget tellus sed tortor tristique molestie sit amet at magna. Etiam semper id velit vel varius. Donec eleifend, justo sagittis pulvinar pulvinar, tellus lorem placerat eros, ut ullamcorper neque libero in ex. Maecenas et ipsum dolor. Sed eu scelerisque tortor. Nulla non libero a leo tempus ornare eget ac nisl. Aliquam erat volutpat. Nam tincidunt iaculis libero volutpat scelerisque. Pellentesque lacus neque, condimentum non rhoncus eget, consectetur vel erat. In hac habitasse platea dictumst. Praesent vulputate egestas placerat. Maecenas purus arcu, volutpat vitae elementum a, dignissim nec arcu.</p>
        <p>Etiam eget lacinia nulla. Integer non dui sed turpis tempus rhoncus. Vivamus elementum ultrices massa, et egestas ex porttitor eget. Sed sagittis pharetra elit et euismod. Maecenas tempus a nisi non blandit. Suspendisse sollicitudin eros quis leo faucibus pretium. In id euismod sem. Nulla a dolor pulvinar, ornare sapien sed, consectetur dui. In sed finibus nisl. In commodo turpis eu sagittis pellentesque.</p>
        <p>Quisque eget sapien id purus dignissim molestie. Nullam leo est, tincidunt vitae egestas eget, eleifend a augue. Aliquam sodales ipsum mi, ac sollicitudin justo lobortis eget. Pellentesque suscipit, elit in facilisis scelerisque, metus turpis cursus ligula, sed consectetur magna magna in eros. Nullam pulvinar, enim eu dignissim eleifend, nibh mauris ultricies mauris, et euismod dui ligula id nisi. Donec lobortis nunc vulputate, pharetra elit vitae, laoreet nisi. Nullam vestibulum molestie ullamcorper. Cras consequat libero et suscipit facilisis. Praesent sem risus, fermentum in lobortis a, venenatis eget ante. Sed facilisis velit ac nibh bibendum dapibus. Nunc venenatis libero ac nunc molestie, quis posuere augue interdum. Vivamus sit amet pharetra ipsum. Pellentesque vel tristique urna, ac consectetur neque. Quisque eleifend urna non ligula pellentesque hendrerit. Mauris sagittis tortor nec interdum mattis.</p>
        <p>Nullam tincidunt mattis enim, non mattis turpis rhoncus sit amet. Curabitur porta bibendum molestie. Vestibulum cursus sit amet leo eu dignissim. Nulla facilisi. Praesent cursus, nibh in iaculis rhoncus, nisl mauris ornare augue, eget ullamcorper elit dui eu arcu. Aenean at dolor ullamcorper, pellentesque nisi vel, elementum felis. Aenean congue lectus id nisl vulputate tempus. Etiam ut iaculis mauris. Aenean sagittis diam in lobortis sodales. Cras ultrices ultrices lacus sit amet aliquet. Nullam tincidunt pharetra efficitur. Nullam pulvinar felis et laoreet dictum. Sed eleifend laoreet egestas.</p>
        <p>Phasellus non maximus massa. Suspendisse pharetra purus quis leo fringilla condimentum. In sed suscipit odio, et congue felis. Quisque et vulputate dolor. Sed pulvinar gravida dolor, in rutrum dui feugiat vitae. Nam mattis elit mauris, laoreet lobortis libero pellentesque ac. Sed pulvinar pellentesque porta. Nam faucibus tristique auctor. Nunc quis maximus metus. Aenean at ligula eget tortor aliquam placerat sit amet eu massa. Duis at faucibus metus. Nulla id libero ex. In faucibus sodales consectetur. Sed ex erat, malesuada convallis magna sit amet, condimentum tincidunt ex. Morbi quam lorem, aliquet nec euismod sed, pulvinar at libero.</p>
      </div>
      <div class="text-right mt-3">
        <v-btn
          outlined
          rounded
          class="outfit-bold text-12 font-700 light-grey-outlined"
          @click="$emit('toggleTermsPolicy')"
        >
          Ok
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>
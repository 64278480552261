import { PublicClientApplication } from "@azure/msal-browser";

export function getMsalConfig(config) {
  return new PublicClientApplication({
    auth: {
      clientId: config.client_id,
      authority: "https://login.microsoftonline.com/" + config.tenant_id,
      redirectUri: window.location.origin + "/auth",
      postLogoutUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  });
}

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('login', [
      'currentUser'
    ]),
    email() {
      if (this.currentUser && this.currentUser.user) {
        return this.currentUser.user.email;
      }
    },
    userNameInitials() {
      if (this.currentUser && this.currentUser.user) {
        let username = this.currentUser.user?.name.trim().toUpperCase().split(' ')

        if (username && username.length >= 2){
          return username[0][0] + username[1][0]
        }
        if (username && username.length === 1){
          return username[0][0]
        }
        if(username[0] === ''){
          return '--'
        }
        if (username.length === 0) {
          return '-'
        }

        return username[0].substring(0,2)
      }
      return '--';
    },
    isAdmin() {
      return this.currentUser.user.role === 'admin';
    },
    isSalesperson() {
      return this.currentUser.user.role === 'salesperson';
    },
    isClient() {
      return this.currentUser.user.role === 'client';
    },

  }
}

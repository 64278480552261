<template>
  <div>
    <div :class="['app-input app-input__relaxed', 'd-flex', 'justify-space-between', classes]">
      <slot name="prepend-inner-icon"></slot>
      <input
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @blur="$emit('blur')"
        @input="(e) => $emit('input', e.target.value)"
        v-on:keydown.enter.prevent="$emit('enter')"
      />
      <slot name="append-inner-icon"></slot>
    </div>
    <span class="fade-in errors" v-if="showErrors">{{ error || "" }}</span>
  </div>
</template>

<script>
export default {
  name: "S2RAppInput",

  props: {
    autocomplete: {
      type: String,
      default: () => "",
    },
    showErrors: {
      type: Boolean,
      default: () => true,
    },
    error: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    classes: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped lang="scss">
.app-input {
  border: 1px solid rgba(17, 16, 16, 0.16) !important;
  border-radius: 5px;

  *:focus {
    outline: none;
  }

  input {
    width: 100% !important;
  }

  &__rounded {
    border-radius: 30px !important;
  }

  &__relaxed {
    padding: 13px 15px;
  }

  &__compact {
    padding: 8px 12px;
  }
}

.errors {
  color: red;
  font-size: 12px;
  padding-left: 2px;
}
</style>
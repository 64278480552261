import localService from "@/services/localService";

export default {
  watch: {
    signupForm: {
      handler(val) {
        localService.setItem("s2r_signUpUserInfo", val);
      },
      deep: true
    },

    paymentForm: {
      handler(val) {
        localService.setItem("s2r_paymentUserInfo", val);
      },
      deep: true
    },
  },

  methods: {
    resetSignUpForm() {
      this.signupForm = {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        companyUrl: "",
      }
    },

    resetPaymentForm() {
      this.paymentForm = {
        card: {
          number: "",
          exp_month: "",
          exp_year: "",
          cvc: "",
          expireDate: "",
        },
        billing_details: {
          name: "",
          address: {
            city: "",
            country: "US",
            line1: "",
            line2: "",
            postal_code: "",
            state: "",
          },
        },
        promo_code: "",
        token: "",
      }
    },

    wasFormFilled(localKey, objectKey) {
      if (!localKey || !objectKey) throw new Error('Arguments are missing for form method');

      const form = localService.getItem(localKey);
      if (form) this[objectKey] = form;
    },
  }
}
import NProgress from "nprogress";
import axios from "@/plugins/axios";
import { vm } from "../main";

const state = {
  mfaBarCodeImage: null,
  recoveryCodes: null,
};

const getters = {
  mfaBarCodeImage: (state) => {
    return state.mfaBarCodeImage;
  },
  recoveryCodes: (state) => {
    return state.recoveryCodes;
  },
};

const actions = {
  async getMFABarCodeImage(context, payload) {
    NProgress.start();
    return axios
      .post("/qrcode", {
        password: payload.password,
        login_type: payload.login_type,
      })
      .then(function (response) {
        const resData = response.data;
        if (resData?.success) {
          context.commit("SET_MFA_BAR_CODE_IMAGE", resData);
        }
      });
  },
  async verifyMFASetupOtp(context, payload) {
    NProgress.start();
    return new Promise((resolve) => {
      axios
        .post("/verify_otp", {
          otp: payload.otp,
        })
        .then(function (response) {
          const resData = response.data;
          if (resData && !resData.success) {
            context.dispatch(
              "showMessage",
              { ...resData, messageType: "error", messageTitle: "Error" },
              { root: true }
            );
          } else {
            context.commit("SET_RECOVERY_CODES", { codes: resData.data.codes });
            resolve(response);
          }
        });
    });
  },
  async setupMFA(context, payload) {
    NProgress.start();
    return new Promise((resolve) => {
      axios
        .post("/setup_mfa", {
          ...payload,
          otp: payload.otp,
        })
        .then(function (response) {
          const resData = response.data;
          if (resData?.success) {
            context.commit("SETUP_MFA_USER", {
              mfa_enabled: payload.mfa_enabled,
            });
            context.commit("login/setState", {
              key: "currentUser",
              value: JSON.parse(localStorage.getItem("userInfo")),
            });
            resolve(response);
          }
        });
    });
  },
  async getRecoveryCodes(context) {
    NProgress.start();
    return axios.get("/recovery_codes", {}).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true }
        );
      } else {
        context.commit("SET_RECOVERY_CODES", { codes: resData.data });
      }
    });
  },
};

const mutations = {
  SET_MFA_BAR_CODE_IMAGE(state, data) {
    state.mfaBarCodeImage = data.image;
    NProgress.done();
  },
  SET_RECOVERY_CODES(state, data) {
    state.recoveryCodes = data.codes;
    NProgress.done();
  },
  SETUP_MFA_USER(state, data) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let user = userInfo?.user || {};
    user["mfa_enabled"] = data.mfa_enabled;
    user["mfa_verified"] = true;
    userInfo.user = user;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    let status = data.mfa_enabled ? "activated" : "deactivated";
    vm.$notify({
      group: "messages",
      type: "success",
      text: `Multi-factor Authentication has been ${status} successfully`,
    });
    NProgress.done();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-row>
    <v-col cols="12" class="px-6">
      <p class="font-700 text-center">Quote for SecondSight Offerings</p>
      <v-row class="mb-3">
        <v-col cols="2" class="pb-1">
          <span  class="font-700">Purchase Date:</span>
        </v-col>
        <v-col cols="10" class="pb-1">
          <span  class="font-700">Set upon payment</span>
        </v-col>
        <v-col cols="2" class="pb-1">
          <span  class="font-700">Annual Renewal Date:</span>
        </v-col>
        <v-col cols="10" class="pb-1">
          <span  class="font-700">One year after the purchase date</span>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Product name</th>
              <th class="text-left">Description</th>
              <th class="text-left">Qty</th>
              <th class="text-left">Subscription Price</th>
              <th class="text-left">Total</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Company Workbench Annual Subscription (Free Tier)</td>
              <td>
                &nbsp;
              </td>
              <td>1</td>
              <td>$0</td>
              <td>$0</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-col cols="12" class="px-10 mt-14">
      <p>The subscriptions detailed above shall begin as of the Purchase Date, and shall continue until the next Annual Renewal Date. The subscription fees are non-refundable. Customer shall retain access to all cancelled subscriptions until the next Annual Renewal Date (the “Subscription Term”). All subscriptions shall automatically renew on the Annual Renewal Date, and on the Annual Renewal Date in each subsequent year, unless the subscriptions are cancelled through the Customer’s Designated User Account. All subscriptions shall renew at the prevailing annual pricing for such SecondSight Offerings as of the Annual Renewal Date. Customer’s credit card on file with SecondSight shall be automatically charged for any renewal fees. Existing credits may be applied to subscription fees for SecondSight Offerings, which will be detailed above, including when a third party has prepaid for such subscription. Customer will be required to pay any renewal fees in full where no applicable credit exists, including where a third party has prepaid a subscription only for the first year.</p>
      <p>If Customer purchases additional SecondSight Offerings provided on a subscription basis during the Subscription Term, such subscriptions will be valid for the same Subscription Term as the SecondSight Offerings detailed above, and will also automatically renew for an additional year on the Annual Renewal Date if not cancelled as provided above. The fees for any such additional subscriptions will be prorated based on the remaining Subscription Term. If certain additional subscription-based SecondSight Offerings are purchased within ninety (90) days of the end of the current Subscription Term, SecondSight may require that Customer agree to continue its subscriptions for the following year at SecondSight’s discretion.</p>
      <p>The subscriptions for the SecondSight Offerings detailed above are valid from the Purchase Date until the Annual Renewal Date. All subscriptions for any SecondSight Offering shall automatically renew on the Annual Renewal Date unless Customer provides notice to SecondSight of Customer’s intent to cancel such subscriptions through the Customer’s Designated User Account. The fees paid for the SecondSight Offerings are non-refundable.</p>
      <p>This document constitutes a Quote and an Invoicing Document accordance with the terms of the Main Customer Agreement (“MCA”) between Customer and SecondSight, and the other Incorporated Agreements, and is considered an Incorporated Agreement.  All capitalized, undefined terms in this Invoicing Document have the meanings given to them in the MCA or in other Incorporated Agreements. Customer may not receive Second Sight Offerings detailed above without accepting this Invoicing Documents and agreeing to be bound by the MCA and other Incorporated Agreements. The terms of the Incorporated Agreements shall not be deemed modified or amended except as expressly provided in this Invoicing Document.</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "S2RCompanyWorkbenchPurchaseOrderForm",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
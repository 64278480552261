// import NProgress from "nprogress";
// import axios from "@/plugins/axios";
// import { vm } from "../main";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: () => ({
    currentStep: 1,

    formOne: {
      email: '',
      questions: [
        {
          key: 'has_cyber_coverage',
          question: 'Do you currently have cyber coverage?',
          answer: null,
        },

        {
          key: 'has_by_broker',
          question: 'Were you sent here by your Broker?',
          answer: null,
          show: [
            {
              key: 'code',
              showOn: 'yes'
            }
          ]
        },

        {
          key: 'code',
          question: 'If so add code',
          answer: null,
          hide: true,
          hideIndex: true,
          hideTopLine: true,
          hideButtons: true,
        },
      ]
    },

    formTwo: {
      questions: [
        {
          key: 'has_collaborators',
          question: '',
          answer: null,
          hideTopLine: true,
          hideIndex: true,
          show: [
            {
              key: 'no_of_collaborators',
              showOn: 'yes'
            }
          ]
        },

        {
          key: 'no_of_collaborators',
          question: 'If so, enter the number of extra collaborators below.',
          answer: 0,
          hide: true,
          hideIndex: true,
          hideTopLine: true,
          hideButtons: true,
        },
      ]
    },

    formThree: {
      questions: [
        {
          key: 'has_kickoff',
          question: 'How about a Kickoff Workshop with SecondSight experts and your whole project team?',
          answer: null,
          hideTopLine: true,
          pricingItem: {
            title: '+ Kickoff Workshop',
            price: 2000,
            subTitle: 'One-time fee'
          }
        },

        {
          key: 'has_process_coach',
          question: 'Would you like a SecondSight Process Coach to keep your team on schedule?',
          answer: null,
          pricingItem: {
            title: '+ Process Coaching',
            price: 1500,
            subTitle: 'One-time fee'
          }
        },

        {
          key: 'has_digital_asset_inventory',
          question: 'Want SecondSight to check your Digital Asset Inventory for accuracy and completeness?',
          answer: null,
          pricingItem: {
            title: '+ Inventory Completion Check',
            price: 750,
            subTitle: 'One-time fee'
          }
        },

        {
          key: 'has_risk_engineer',
          question: 'Would you like a Risk Engineer to review your risk control evidence for better claim certainty?',
          answer: null,
          pricingItem: {
            title: '+ Risk Control Evidence Review',
            price: 1625,
            subTitle: 'One-time fee'
          }
        },
      ]
    },

    formFour: {
      questions: [
        {
          key: 'has_underwriting',
          question: '',
          answer: null,
          hideTopLine: true,
          hideIndex: true,
        }
      ]
    }
  }),

  mutations: {
    updateField
  },

  actions: {},

  getters: {
    getField,
  }
}
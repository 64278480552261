<template>
  <v-container fluid>
    <v-row class="main-bg">
      <!--  -->
      <!-- LEFT SIDE FORM -->
      <!--  -->

      <v-col cols="6" class="px-6">
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between mt-1">
            <top-bar-without-auth
              :show-login="$vuetify.breakpoint.mdAndUp"
              :show-signup="$vuetify.breakpoint.mdAndUp"
              :is-broker="true"
              custom-class="d-flex  justify-space-between pa-0"
            />
          </v-col>

          <v-row no-gutters class="d-flex justify-center mt-8">
            <v-col cols="12" md="8" sm="10">
              <div class="text-36 outfit-bold tight-line">
                Welcome to your <br />
                Company Workbench!
              </div>

              <div class="light-text--text outfit-bold text-18 my-4">Create your account below.</div>

              <v-card outlined class="rounded-xl">
                <v-card-text class="px-6">
                  <v-form ref="form" v-model="valid" lazy-validation class="custom-error-details">
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">First Name</label>
                        <v-text-field
                          v-model="signupForm.firstName"
                          type="text"
                          placeholder="First Name"
                          solo
                          rounded
                          flat
                          :rules="[(v) => (v && !!v.trim()) || 'First Name is required.']"
                          required
                          validate-on-blur
                          ref="firstName"
                          @focus="elevate = true"
                          @blur="onBlur('firstName', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Last Name</label>
                        <v-text-field
                          v-model="signupForm.lastName"
                          type="text"
                          placeholder="Last Name"
                          solo
                          rounded
                          flat
                          :rules="[(v) => (v && !!v.trim()) || 'Last Name is required.']"
                          required
                          validate-on-blur
                          @focus="elevate = true"
                          @blur="onBlur('lastName', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Email</label>
                        <v-text-field
                          ref="email"
                          v-model="signupForm.email"
                          type="email"
                          placeholder="Email"
                          solo
                          rounded
                          flat
                          :rules="[
                            (v) => (v && !!v.trim()) || 'Email is required.',
                            (v) => !v || !!checkEmail(v) || 'E-mail must be valid',
                          ]"
                          :disabled="invitationFromBroker"
                          required
                          data-cy="login-input"
                          validate-on-blur
                          @focus="elevate = true"
                          @blur="onBlur('email', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">Company Name</label>
                        <v-text-field
                          v-model="signupForm.companyName"
                          type="text"
                          placeholder="Company Name"
                          solo
                          rounded
                          flat
                          :rules="[(v) => (v && !!v.trim()) || 'Company Name is required.']"
                          required
                          validate-on-blur
                          @focus="elevate = true"
                          @blur="onBlur('companyName', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">
                          Company Website
                        </label>
                        <v-text-field
                          v-model="signupForm.companyUrl"
                          type="text"
                          placeholder="Company Url"
                          solo
                          rounded
                          flat
                          :rules="[(v) => !v || (v.trim().length && validURL(v)) || 'Company Url is invalid']"
                          required
                          validate-on-blur
                          @focus="elevate = true"
                          @blur="onBlur('companyUrl', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="showFuture">
                      <v-col cols="12" md="12">
                        <label class="d-block outfit-bold text-14 font-700 text-light-black mb-2">
                          Broker’s Email
                        </label>

                        <v-text-field
                          v-model="signupForm.broker_email"
                          type="text"
                          placeholder="Broker’s Email (optional)"
                          solo
                          rounded
                          flat
                          required
                          @focus="elevate = true"
                          :rules="[(v) => !v || !!checkEmail(v) || 'E-mail must be valid']"
                          :disabled="invitationFromBroker || disabledBrokerEmailField"
                          @blur="onBlur('broker_email', 'signupForm')"
                          @keyup.enter="signup"
                          class="light-grey-outlined"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="showFuture">
                      <v-col cols="12" class="d-flex align-center">
                        <v-checkbox
                          v-model="termsEnabled"
                          required
                          color="#39CA56"
                          class="text-primary terms-checkbox mt-0 pt-0"
                          hide-details
                        >
                        </v-checkbox>
                        <div class="outfit-regular font-400 text-14 text-grey">
                          I have read and agreed to the
                          <span
                            class="outfit-bold font-700 text-decoration-underline cursor-pointer text-grey"
                            @click="showTerms = true"
                          >
                            Terms & Conditions
                          </span>
                          .
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-btn
                          :loading="isLoading"
                          block
                          depressed
                          rounded
                          color="#3ACA56"
                          data-cy="signup-button"
                          :disabled="!signupFormValid"
                          @click="signup"
                        >
                          <span class="outfit-bold font-700 text-12 white--text text-capitalize tight-spacing">
                            Create Account
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-col>

      <!--  -->
      <!-- RIGHT SIDE CAROUSEL -->
      <!--  -->

      <v-col cols="6" class="right-bg d-flex align-center">
        <app-carousel :slides="slides" />
      </v-col>
    </v-row>

    <terms :show-dialog="showTerms" @cancel-dialog="showTerms = false" />
    <app-footer direction="left" size="14" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import appSettings from "../../../app.settings";
import AppFooter from "@/components/Base/AppFooter.vue";
import validationMixin from "@/Mixins/validation";
import SignupMixin from "@/Mixins/signup";
import BackgroundShapes from "@/components/General/BackgroundShapes.vue";
import TopBarWithoutAuth from "@/components/General/TopBarWithoutAuth.vue";
import { showFuture } from "@/helpers/index";
import { hideBranding } from "@/helpers";
import Terms from "@/components/Terms/Terms.vue";
import jwtDecode from "jwt-decode";
import versionInfoMixin from "@/Mixins/versionInfo";
import AppCarousel from "@/components/Shared/AppCarousel.vue";

export default {
  components: {
    Terms,
    TopBarWithoutAuth,
    BackgroundShapes,
    AppFooter,
    AppCarousel,
  },

  mixins: [validationMixin, SignupMixin, versionInfoMixin],

  data: () => ({
    hideBranding,
    showFuture,
    showTerms: false,
    showPrivacyPolicy: false,
    valid: false,
    signupForm: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      companyUrl: "",
      broker_email: "",
    },
    elevate: false,
    termsEnabled: false,
    invitationFromBroker: false,
    token: null,
    disabledBrokerEmailField: false,
    slides: [
      {
        image: require("@/assets/images/cwbstep1.png"),
        title: "Step 1:",
        detail: "Start managing your risk <br /> and fix digital gaps for better insurance.",
      },
      {
        image: require("@/assets/images/cwbstep2.png"),
        title: "Step 2:",
        detail: "Apply easily, get tailored recommendations, <br /> and forever improve your cyber insurance process.",
      },
      {
        image: require("@/assets/images/cwbstep3.png"),
        title: "Step 3:",
        detail: "Align your digital risk profile with your goals <br /> via continuous monitoring.",
      },
    ],
  }),

  computed: {
    ...mapFields("login", ["isLoading"]),

    appInfo() {
      return appSettings;
    },

    signupFormValid() {
      const { email, firstName, lastName, companyName, companyUrl, broker_email } = this.signupForm;

      // Trim values once to avoid repeating trim calls
      const trimmedEmail = email.trim();
      const trimmedBrokerEmail = broker_email?.trim();
      const trimmedCompanyUrl = companyUrl.trim();

      const isEmailValid = trimmedEmail && !this.checkEmail(trimmedEmail);
      const isBrokerEmailValid = !trimmedBrokerEmail || this.checkEmail(trimmedBrokerEmail);
      const isUrlValid = trimmedCompanyUrl && !this.validURL(trimmedCompanyUrl);

      const isValid =
        !firstName.trim() ||
        !lastName.trim() ||
        !companyName.trim() ||
        !this.termsEnabled ||
        (trimmedCompanyUrl && isUrlValid) ||
        !isBrokerEmailValid ||
        isEmailValid;

      return !isValid;
    },
  },

  methods: {
    ...mapActions("broker", ["acceptClientInviteByBroker"]),

    async signup() {
      if (this.$refs.form.validate()) {
        if (this.invitationFromBroker) {
          return this.handleSignupFromInvitation();
        }

        let signupForm = Object.assign({}, this.signupForm);
        if (this.$route.query && this.$route.query.token) {
          signupForm.token = this.$route.query.token;
        }
        this.$store.dispatch("login/signup", {
          ...signupForm,
          terms_accepted: this.termsEnabled,
          terms_version: this.termsVersion,
        });
      }
    },

    handleLinkClick() {
      this.$router.push({ path: "/login" });
    },

    toggleTermsPolicy() {
      this.showPrivacyPolicy = false;
      this.showTerms = false;
    },

    checkForInvitation() {
      this.invitationFromBroker = true;
      const { invitationToken } = this.$route.params;
      this.token = invitationToken;
      try {
        const { firstName, lastName, email, companyName, companyUrl, broker_email } = jwtDecode(invitationToken);
        Object.assign(this.signupForm, { firstName, lastName, email, companyName, companyUrl, broker_email });
      } catch (error) {
        console.error("Error decoding JWT:", error.message);
      }
    },

    async handleSignupFromInvitation() {
      let payload = {
        token: this.token,
        data: {
          ...jwtDecode(this.token),
          ...this.signupForm,
          terms_accepted: this.termsEnabled,
          terms_version: this.termsVersion,
        },
      };
      delete payload.data.exp;

      this.isLoading = true;
      const signupData = await this.acceptClientInviteByBroker(payload);
      if (signupData.success) {
        const { reset_token, user_uid } = signupData;
        this.$router.push({ path: `/reset-password/${reset_token}/${user_uid}` });
        localStorage.removeItem("s2r_signUpUserInfo");
        this.isLoading = false;
      }
    },
  },

  beforeMount() {
    if (this.$route.name === "InvitationSignup") {
      this.checkForInvitation();
    } else {
      this.wasFormFilled("s2r_signUpUserInfo", "signupForm");
      if (!this.showFuture) {
        this.termsEnabled = true;
      }
    }
    if (this.$route.query?.broker_email) {
      this.signupForm.broker_email = this.$route.query.broker_email;
      if (this.checkEmail(this.$route.query?.broker_email)) {
        this.disabledBrokerEmailField = true;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.main-bg {
  min-height: 100vh;
  background: #fff;
}

.heading-title {
  font-size: 40px;
  line-height: 46px;
  color: #212238;
}

.custom-width {
  width: 48%;
}

.signup-form-container {
  max-width: 700px;
}

.tight-line {
  line-height: 10px;
}

.right-bg {
  background: linear-gradient(180deg, #39ca56 35.5%, #0c9427 76%, #1c642b 100%);
}
</style>

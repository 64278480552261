<template>
  <app-dialog :dialog="showDialog" :maxWidth="1420">
    <v-card>
      <v-card-text class="pt-6">
        <div class="text-right" v-if="!isAccept">
          <v-btn icon class="black--text terms-close-icon" @click="toggleDialog">
            <v-icon size="16">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="pa-0 d-flex align-baseline ma-3">
          <v-col cols="12" md="12" class="pa-6 policy-container">
            <v-tabs v-model="activeTab" class="dark-slide-tabs secondary-tabs" :show-arrows="false">
              <v-tabs-slider color="#39CA56"></v-tabs-slider>

              <v-tab class="px-4 text-14"> Main Customer Agreement </v-tab>
              <v-tab class="px-4 text-14"> Designated User Terms of Use </v-tab>
              <v-tab class="px-4 text-14"> Secondary User Appointment </v-tab>
              <v-tab class="px-4 text-14"> Services Performance Agreement </v-tab>
              <v-tab class="px-4 text-14"> SaaS Agreement </v-tab>
              <v-tab class="px-4 text-14" v-if="!isBroker"> Secondary User Terms of Use </v-tab>
              <v-tab class="px-4 text-14" v-if="isBroker"> Broker Free Quote Form </v-tab>

              <v-tab class="px-4 text-14" v-if="!isBroker && !isJlpc"> Company Free Quote Form </v-tab>
              <v-tab class="px-4 text-14" v-if="!isBroker && !isJlpc"> Company Paid Quote Form </v-tab>
              <v-tab class="px-4 text-14" v-if="isJlpc"> Jackson-Lewis Purchase Order Form </v-tab>

              <v-tab class="px-4 text-14" v-if="!isBroker && !isJlpc"> Transmission Disclaimer </v-tab>
              <v-tab class="px-4 text-14" v-if="!isBroker && !isJlpc"> Digital Application Disclaimer </v-tab>
<!--              <v-tab class="px-4 text-14" v-if="isBroker"> Secondary User Terms of Use </v-tab>-->
            </v-tabs>

            <div
              class="text-light-black font-400 outfit-regular text-14 pt-4 pr-4"
              style="max-height: 400px; overflow-y: auto"
            >
              <main-customer-agreement v-if="activeTab == 0" />

              <designated-user-terms-of-user v-if="activeTab == 1" />

              <seconday-user-appointment v-if="activeTab == 2" />

              <service-performance-agreement v-if="activeTab == 3" />

              <broker-saas-agreement v-if="activeTab === 4 && isBroker && !isJlpc" />

              <saas-agreement v-if="activeTab === 4 && !isBroker && !isJlpc" />

              <jlpc-saas-agreement v-if="activeTab === 4 && isJlpc" />

              <secondary-user-terms v-if="activeTab === 5 && !isBroker" />

              <broker-workbench-purchase-order-form v-if="activeTab === 5 && isBroker" />

              <company-workbench-purchase-order-form v-if="activeTab === 6 && !isBroker && !isJlpc" />

              <jackson-lewis-purchase-order-form v-if="activeTab === 6 && isJlpc" />

              <company-workbench-purchase-paid-order-form v-if="activeTab === 7 && !isBroker && !isJlpc" />

              <transmission-disclaimer v-if="activeTab === 8 && !isBroker && !isJlpc" />

              <digital-application-disclaimer v-if="activeTab === 9 && !isBroker && !isJlpc" />
            </div>

               <div :class="['d-flex', isAccept ? 'justify-space-between' : 'justify-end', 'mt-5', 'align-center']">
              <slot name="accept-checkbox"></slot>

              <v-btn
                :disabled="disableOK"
                :loading="loading"
                rounded
                depressed
                color="primary"
                class="outfit-bold text-12 font-700"
                @click="toggleDialog"
              >
                Ok
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>

<script>
import AppDialog from "@/components/Base/AppDialog.vue";
import AppFormTitle from "@/components/Base/AppFormTitle.vue";
import MainCustomerAgreement from "@/components/Terms/MainCustomerAgreement.vue";
import DesignatedUserTermsOfUser from "@/components/Terms/DesignatedUserTermsOfUser.vue";
import SecondayUserAppointment from "@/components/Terms/SecondayUserAppointment.vue";
import ServicePerformanceAgreement from "@/components/Terms/ServicePerformanceAgreement.vue";
import CompanyWorkbenchPurchaseOrderForm from "./CompanyWorkbenchPurchaseOrderForm.vue";
import SaasAgreement from "./SaasAgreement.vue";
import JlpcSaasAgreement from "./JlpcSaasAgreement.vue";
import SecondaryUserTerms from "./SecondaryUserTerms.vue";
import BrokerSaasAgreement from "./BrokerSaasAgreement.vue";
import BrokerWorkbenchPurchaseOrderForm from "./BrokerWorkbenchPurchaseOrderForm.vue";
import BrokerSecondaryUserTerms from "./BrokerSecondaryUserTerms.vue";
import CompanyWorkbenchPurchasePaidOrderForm from "@/components/Terms/CompanyWorkbenchPurchasePaidOrderForm.vue";
import JacksonLewisPurchaseOrderForm from "@/components/Terms/JacksonLewisPurchaseOrderForm.vue";
import TransmissionDisclaimer from "@/components/Client/QuickStart/Terms/TransmissionDisclaimer.vue";
import DigitalApplicationDisclaimer from "@/components/Client/QuickStart/Terms/DigitalApplicationDisclaimer.vue";

export default {
  props: ["showDialog", "isBroker", "isJlpc", "isAccept", "isAccepted", "loading"],
  components: {
    DigitalApplicationDisclaimer,
    TransmissionDisclaimer,
    JacksonLewisPurchaseOrderForm,
    CompanyWorkbenchPurchasePaidOrderForm,
    ServicePerformanceAgreement,
    SecondayUserAppointment,
    DesignatedUserTermsOfUser,
    MainCustomerAgreement,
    AppFormTitle,
    AppDialog,
    CompanyWorkbenchPurchaseOrderForm,
    SaasAgreement,
    SecondaryUserTerms,
    BrokerSaasAgreement,
    BrokerWorkbenchPurchaseOrderForm,
    BrokerSecondaryUserTerms,
    JlpcSaasAgreement
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    disableOK() {
      if (this.isAccept && !this.isAccepted) return true;
      return false;
    },
  },
  methods: {
    toggleDialog() {
      if (this.isAccept) return this.handleAcceptance();

      this.$emit("cancel-dialog");
    },

    handleAcceptance() {
      if (!this.isAccepted) return;
      return this.$emit("accept-terms");
    },
  },
};
</script>


<style scoped lang="scss">
.policy-container {
  background: #ffffff;
  border: 1px solid rgba(17, 16, 16, 0.08);
  box-shadow: 0px 8px 40px rgba(17, 16, 16, 0.04);
  border-radius: 16px;
  padding: 40px;
  min-height: 200px;
  z-index: 1;
}
.terms-close-icon {
  right: 6px;
  width: 32px;
  height: 32px;
  background: #f0efef;
  top: 16px;
  position: absolute;
}
</style>

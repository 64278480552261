<template>
  <app-dialog :dialog="mfa_dialog" @cancel-dialog="mfa_dialog = false" :maxWidth="550">
    <v-card>
      <v-card-title class="d-flex flex-column justify-center align-center">
        <v-icon x-large color="#212338">mdi-shield-lock-open</v-icon>
        Authentication Required
      </v-card-title>

      <v-card-text>
        <div class="text-center my-6">Please open the app which you used to setup the two-factor authentication and type the code in here to proceed with the login.</div>
        <v-form ref="twoFactorForm" v-model="twoFactor" lazy-validation class="mt-6" @submit.prevent>
          <label class="label-text-14">Auth code</label>
          <v-text-field
            v-model="authCode"
            type="text"
            outlined
            class="font-size-input input-style mb-0"
            validate-on-blur
            :rules="[
              v => !!v || 'Auth code is required.',
              v => v?.length === 6 || 'Auth code is invalid.'
            ]"
            @keydown.enter="loginUsingMfa"
            @keydown.tab.prevent="$refs.twoFactorForm?.validate()"
            @focus="handleFocus('recoveryCodeForm')"
          />

          <v-btn
            :disabled="authCode?.length !== 6"
            :loading="loginUsingAuthCode" 
            @click="loginUsingMfa"
            block
            depressed
            rounded
            color="#3ACA56"

            class="text-primary text-14 font-700"
          >
            Verify & Login
          </v-btn> 
        </v-form>

        <div class="text-center px-10 my-4">
          <div class="or-divider"></div>
          <div class="font-weight-bold">OR</div>
        </div>

        <v-form ref="recoveryCodeForm" v-model="recoveryCodeForm" lazy-validation class="mt-6" @submit.prevent>
          <label class="label-text-14">Recovery code</label>
          <v-text-field
            v-model="recoveryCode"
            type="text"
            outlined
            class="font-size-input input-style mb-0 mt-2"
            validate-on-blur
            required
            :rules="[
              v => !!v || 'Recovery code is required.'
            ]"
            @keydown.enter="loginUsingRecoveryCode"
            @keydown.tab.prevent="$refs.recoveryCodeForm?.validate()"
            @focus="handleFocus('twoFactorForm')"
          />

          <v-btn 
            :disabled="!recoveryCode"
            block
            depressed
            rounded
            color="#3ACA56"
            class="text-primary text-14 font-700"
            :loading="loginUsingRecovery"
            @click="loginUsingRecoveryCode"
          >
            Login Using Recovery Code
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import appSettings from '../../../app.settings'
import validationMixin from '@/Mixins/validation'
import appTitleMixin from '@/Mixins/appTitle'
import AppDialog from '@/components/Base/AppDialog'

export default {
  mixins: [validationMixin, appTitleMixin],
  components: {
    AppDialog
  },
  data: () => ({
    loginUsingAuthCode: false,
    loginUsingRecovery: false,
  }),
  computed: {
    ...mapGetters("login", [
      'isLoading'
    ]),
    ...mapFields("login", [
      'view',
      'email',
      'password',
      'passwordType',
      'mfa_dialog',
      'twoFactor',
      'recoveryCodeForm',
      'authCode',
      'recoveryCode'
    ]),
    appInfo() {
      return appSettings;
    }
  },
  methods: {
    async loginUsingMfa() {
      if (!this.$refs.twoFactorForm.validate()) return;
      this.loginUsingAuthCode = true
      await this.$store.dispatch('login/loginMfa', { email: this.email, otp: this.authCode, password: this.password })
      this.loginUsingAuthCode = false
    },
    
    async loginUsingRecoveryCode() {
      if (!this.$refs.recoveryCodeForm.validate()) return;
      this.loginUsingRecovery = true
      await this.$store.dispatch('login/loginUsingRecovery', { email: this.email, recovery_code: this.recoveryCode, password: this.password })
      this.loginUsingRecovery = false
    },
    handleLinkClick(page) {
      if(page && page == 'signup') {
        this.$router.push({path: '/signup'})
      } else {
        if (this.view === 'login') {
          return this.forgotPassword()
        }
        this.showLogin();
      }
    },
    forgotPassword() {
      this.view = 'forgot'
      this.handleRouteData()
    },
    showLogin() {
      this.view = 'login'
      this.handleRouteData()
    },
    handleRouteData() {
      let path = `${this.$route.path}?view=${this.view}`
      this.$router.replace({ path })
    },
    checkViewInUrl() {
      if (this.$route.query && this.$route.query.view === 'forgot') {
        return this.view = 'forgot'
      } return this.view = 'login'
    },
    handleFocus(ref) {
      this.$refs[ref].reset()
      this.$refs[ref].resetValidation()
    }
  },
  mounted() {
    this.checkViewInUrl();
  }
}
</script>

import axios from "../plugins/axios";
import { getField, updateField } from "vuex-map-fields";
import localService from "@/services/localService";
import { vm } from "../main";

export default {
  namespaced: true,
  state: () => ({
    formProgress: 0,
    currentStep: 1,
    totalSteps: 3,
    entityId: "",
    version: "",
    organisationName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    businessStructure: "",
    businessDescription: "",
    naicsFull: "",
    naicsCode: "",
    naicsDescription: "",
    date: "",
    noOfEmployees: "",
    fullYearRevenue: "",
    websiteAddresses: "",
    noWebsiteAddress: false,
    isClientFormAlreadyFilled: false,
    titleList: [
      "Founder or Executive",
      "Risk Manager",
      "CFO",
      "Insurance Broker",
      "Technology Professional",
      "Cybersecurity Professional",
      "Other",
    ],
    brokerTitleList: [
      "CEO or Executive",
      "Broker",
      "Producer",
      "Account Manager",
      "Practice Leader",
      "Cyber or Technical Specialist",
      "Operations",
      "Sales or Marketing",
      "Other",
    ],
    intentionList: ["Secure Coverage", "Prepare for Renewal", "Reduce Digital Risk", "Model Digital Risk"],
    brokerIntentionList: ["Help my customers be ready", "Model Portfolio Risk", "Analyze Concentration Risk", "Other"],
    orgResponsibilityList: [
      "Technology",
      "Cybersecurity",
      "Sales or Marketing",
      "Operations",
      "Finance or Accounting",
      "Legal",
      "HR",
      "Other",
    ],
    delegationTypeList: [],
    delegateUserOptions: [
      {
        value: "me",
        title: "It's me",
      },
      {
        value: "another",
        title: "Another team member",
      },
    ],
    submittedDelegations: [],
    delegatedUsers: [],
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    updateSubmitDelegationList(state, payload) {
      state.submittedDelegations.push(payload);
    },
    setDelegatedUsers(state, payload) {
      state.delegatedUsers = payload;
    },
  },
  actions: {
    async submitClientOnboardingForm({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.post("/onboarding", payload);

        if (!data.success) {
          return;
        }

        return data;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getClientOnboardingForm({ commit, dispatch }) {
      try {
        commit("startLoading", null, { root: true });
        const { data } = await axios.get("/onboarding");

        if (!data.success) {
          return;
        }

        const { results } = data;

        if (Object.keys(results).length > 0) {
          const form = {
            entityId: results.entity_id,
            version: results.version,
            organisationName: results.name || "",
            city: results.district_city || "",
            noOfEmployees: results.number_of_employee || "",
            date: results.established_date?.slice(0, 10) || "",
            businessDescription: results.description_of_business || "",
            naicsCode: results.naics_code || "",
            naicsDescription: results.naics_description || "",
            naicsFull:
              results.naics_description && results.naics_code
                ? results.naics_description + ` | (${results.naics_code})`
                : "",
            noWebsiteAddress: results.website ? false : true,
            websiteAddresses: results.website ? results.website : null,
            businessStructure: results.business_structure || "",
            fullYearRevenue: results.revenue_per_year || "",
            addressLine1: results.address_line_1 || "",
            addressLine2: results.address_line_2 || "",
            postalCode: results.postal_code || "",
            state: results.state_province || "",
          };

          let requiredKeys = [
            "name",
            "district_city",
            "number_of_employee",
            "established_date",
            "description_of_business",
            "naics_code",
            "naics_description",
            "business_structure",
            "revenue_per_year",
            "address_line_1",
            "address_line_2",
            "postal_code",
            "state_province",
          ];

          let isClientFormAlreadyFilled = requiredKeys.every((key) => results[key] != null);
          commit("setState", {
            key: "isClientFormAlreadyFilled",
            value: isClientFormAlreadyFilled,
          });

          for (let key in form) {
            commit("setState", { key, value: form[key] });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
    async getDelegationTypeList({ commit, state }, payload) {
      let data = [
        {
          entity_id: "ac33ce56-163c-49a8-9c8e-676bf74254ff",
          name: "Technology",
        },
        {
          entity_id: "9c8e-163c-49a8-ac33ce56-676bf74254ff",
          name: "Cybersecurity",
        },
        {
          entity_id: "163c-ac33ce56-49a8-9c8e-676bf74254ff",
          name: "Operations & Process",
        },
        {
          entity_id: "249a8-163c-ac33ce56-9c8e-676bf74254ff",
          name: "Training & HR",
        },
        {
          entity_id: "349a8-163c-ac33ce56-9c8e-676bf74254ff",
          name: "Legal & Contracting",
        },
      ];
      commit("setState", { key: "delegationTypeList", value: data });
    },
    async submitOnboardDelegation({ commit }, payload) {
      const { data } = await axios.post("/organization_management/delegate/organization_responsibility", payload);
      commit("updateSubmitDelegationList", payload);
      return payload;
    },
    async getClientDelegations({ commit }, orgId) {
      let url = "/organization_management/client_delegations";
      if (orgId) {
        url = `/organization_management/${orgId}/client_delegations`;
      }
      const { data } = await axios.get(url);
      commit("setState", {
        key: "submittedDelegations",
        value: data.delegations?.digital_asset_usage_type || [],
      });
      commit("setDelegatedUsers", data.delegations.digital_asset);
      return data;
    },
    completeOnboardDelegation({ commit, dispatch, rootState }) {
      let currentUser = rootState.login.currentUser;
      currentUser.user.onboard_delegate_completed = true;
      commit("login/setState", { key: "currentUser", value: currentUser }, { root: true });
      localService.setItem("userInfo", currentUser);
      vm.$nextTick(() => {
        vm.$router.push({ path: "/user/getting-started/invite-members" });
      });
    },
    async inviteTeamMembers({ commit, dispatch, rootState }, payload) {
      if (typeof payload == "object") {
        await vm.$store.dispatch("client/inviteClientBulkUser", { users: payload }).then((data) => {});
      }
      let currentUser = rootState.login.currentUser;
      currentUser.user.onboard_invite_members_completed = true;
      commit("login/setState", { key: "currentUser", value: currentUser }, { root: true });
      localService.setItem("userInfo", currentUser);
      vm.$nextTick(() => {
        vm.$router.push({ path: "/user/readiness-checklist" });
      });
    },
  },
  getters: {
    getField,
    formProgress: (state) => state.formProgress,
    onboardDetails: (state) => ({
      currentStep: state.currentStep,
      totalSteps: state.totalSteps,
    }),
    titleList: (state) => state.titleList,
    brokerTitleList: (state) => state.brokerTitleList,
    intentionList: (state) => state.intentionList,
    brokerIntentionList: (state) => state.brokerIntentionList,
    orgResponsibilityList: (state) => state.orgResponsibilityList,
    delegateUserOptions: (state) => state.delegateUserOptions,
    getState: (state) => state,
  },
};

<template>
  <v-row>
    <v-col cols="12" class="pb-0 d-flex justify-end"> VERSION DATED SEPTEMBER 14, 2023 </v-col>

    <v-col cols="12">
      These Secondary User Terms of Use (these “Terms of Use”) contain important information about your obligations
      related to your use of your Secondary User Account made available by SecondSight Data Discovery, Inc., a Delaware
      corporation (“SecondSight”). Your Secondary User Account is provided in connection with and subject to Second
      Sight’s provision of SecondSight Offerings to the Customer for which you have been appointed a Secondary User. In
      addition to your full and complete compliance with these Terms of Use, your appointment as a Secondary User and
      your use of the Secondary User Account is subject to your compliance with the requirements of all Secondary Users
      as provided in the Incorporated Agreements between SecondSight and Customer. Your appointment as a Secondary User
      and your use of a Secondary User Account is subject to termination at the Customer’s discretion, and SecondSight’s
      continued approval, which approval may be terminated at SecondSight’s discretion. Nothing in these Terms of Use
      shall be construed as making you a third-party beneficiary of the Incorporated Agreements.

      <br />
      <br />

      You must read, agree to, and accept all conditions contained in these Terms of Use to remain a valid Secondary
      User and to access your Secondary User Account. By clicking accept when prompted, you acknowledge these Terms of
      Use, and agree to and consent to abide by them. You should read this entire document carefully. If you do not
      understand these Terms of Use or do not agree to them, you must not accept these Terms of Use, and you may not use
      the Secondary User Account.

      <br />
      <br />

      Subject to the conditions of the Incorporated Agreements, SecondSight, in its sole discretion, may make changes to
      these Terms of Use from time to time by notifying you of such changes by reasonable means, including through the
      use of a pop-up notification on an Application or through the Website, providing other notice through the Website,
      and/or through email to the address you have provided to SecondSight to receive notice as a Secondary User. Such
      notice will seek your affirmative acceptance of such changes and provide you with a reasonable opportunity to
      cease being a Secondary User if you do not accept such changes. Your continued use of your Secondary User Account,
      including by accessing the Website or any Application, including Software, following notice of such changes will
      otherwise constitute your acceptance of such changes. Any such changes will not apply to any dispute arising prior
      to the date on which such terms were changed. The effective date set forth at the top of these Terms of Use
      indicate when it was last changed.

      <br />
      <br />

      THESE TERMS OF USE CONTAIN A MANDATORY ARBITRATION PROVISION THAT REQUIRES THE USE OF ARBITRATION TO RESOLVE
      DISPUTES UNDER THESE TERMS OF USE RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS.

      <br />
      <br />
    </v-col>

    <v-col cols="12">
      <ol>
        <li>
          <strong>Definitions </strong>
          The following are definitions of capitalized terms used in these Terms of Use, which are not defined
          elsewhere. Other capitalized terms are defined elsewhere in these Terms of Use.

          <ol type="a">
            <li>
              “Application” means those applications or other software, however accessed and made available, together
              with any websites, provided by SecondSight for use in connection with SecondSight Offerings.
            </li>

            <li>
              “Customer” means the SecondSight customer that has entered into the Incorporated Agreements with
              SecondSight and that requested your appointment as a Secondary User.
            </li>

            <li>
              “Cyber Risk” means risk for events of financial loss, damages, or loss of use of systems or data related
              to cybersecurity crimes and torts, including from hacking, ransomware attacks, and other similar concerns,
              as well as inadvertent or negligent data loss.
            </li>

            <li>
              “Disclosed Information” means any information, data, or reports related to the SecondSight Offerings that
              is disclosed to the Secondary User in accordance with and in furtherance of the purposes for which the
              Secondary User is appointed.
            </li>

            <li>
              “Secondary User” means an individual or entity designated by the Customer and granted permission by the
              Customer to view certain data and reports related to the Customer and the results of the SecondSight
              Offerings but not otherwise authorized to act on the Customer’s behalf or bind the Customer with regard to
              SecondSight Offerings.
            </li>

            <li>“Secondary User Account” means a user account provided for any Secondary User.</li>

            <li>
              “Secondary User Approved Purpose” means the purpose identified in the Secondary User Approved Purpose
              Rider annexed to these Terms of Use.
            </li>

            <li>
              “Secondary User Terms of Use” means those terms and conditions executed by a Secondary User including, but
              not limited to, in connection with their appointment as a Secondary User, use of the Secondary User
              Account, or other use of the SecondSight Offerings.
            </li>

            <li>
              “SecondSight Offerings” means, without limitation, any services, software, and any other equipment, goods,
              products, or licenses provided by SecondSight or its agents.
            </li>

            <li>
              “Software” means SecondSight’s proprietary operations system used to analyze Cyber Risk, which may be used
              for purposes including to obtain, evaluate, or improve position to obtain insurance related to Cyber Risk.
            </li>

            <li>“Website” means https://www.secondsight.ai.</li>
          </ol>
        </li>

        <li>
          <strong>Secondary User and Secondary User Account. </strong>
          You have been identified by the Customer as a Secondary User. As a Secondary User, through your Secondary User
          Account, you may be provided with certain data and information arising out of or related to the SecondSight
          Offerings to be used in accordance with the purpose for which you were identified as a Secondary User. You
          shall not be deemed to be an agent of the Customer, and you shall not have authority to bind the Customer to
          any agreements arising out of or related to the SecondSight Offerings. Only the Secondary User may use the
          Secondary User Account; provided, however that the identified Secondary User may be an individual or an
          entity. SecondSight will presume that any user using a Secondary User Account is the validly-authorized
          Secondary User connected to such Secondary User Account. You agree and acknowledge that you will not permit
          any other user to use your Secondary User Account. You shall not use or allow your Secondary User Account to
          be used in contravention of these Terms of Use. SecondSight expressly retains, and does not waive, the right
          to bring any actions against you in your individual capacity for any damages caused by your use of the
          Secondary User Account in contravention of these Terms of Use.
        </li>

        <li>
          <strong>Disclosed Information. </strong>
          You have been identified by the Customer as a Secondary User. As a Secondary User, through your Secondary User
          Account, you may be provided with certain data and information arising out of or related to the SecondSight
          Offerings to be used in accordance with the purpose for which you were identified as a Secondary User. You
          shall not be deemed to be an agent of the Customer, and you shall not have authority to bind the Customer to
          any agreements arising out of or related to the SecondSight Offerings. Only the Secondary User may use the
          Secondary User Account; provided, however that the identified Secondary User may be an individual or an
          entity. SecondSight will presume that any user using a Secondary User Account is the validly-authorized
          Secondary User connected to such Secondary User Account. You agree and acknowledge that you will not permit
          any other user to use your Secondary User Account. You shall not use or allow your Secondary User Account to
          be used in contravention of these Terms of Use. SecondSight expressly retains, and does not waive, the right
          to bring any actions against you in your individual capacity for any damages caused by your use of the
          Secondary User Account in contravention of these Terms of Use.
        </li>

        <li>
          <strong>Indemnification. </strong>
          You agree to indemnify and hold SecondSight harmless from and against all damages, losses, and expenses of any
          kind (including reasonable attorney fees and costs) arising out of or related to: (a) your contravention of
          these Terms of Use and (b) any events of financial loss, damages, or loss of use of systems or data related to
          cybersecurity crimes and torts, including from hacking, ransomware attacks, and other similar concerns.
        </li>

        <li>
          <strong>Governing Law, Jurisdiction, and Dispute Resolution. </strong>
          These Terms of Use are governed by and construed in accordance with the internal laws of the State of Indiana
          without giving effect to any choice or conflict of law provision or rule that would require or permit the
          application of the laws of any jurisdiction other than those of the State of Indiana. Any controversy or claim
          arising out of or relating to these Terms of Use shall be settled by arbitration to be held in Indianapolis,
          Indiana and administered by the American Arbitration Association (“AAA”) in accordance with its Commercial
          Arbitration Rules. Judgment on the award rendered by the arbitrator(s) may be entered in court having
          jurisdiction. This arbitration clause shall be interpreted to the broadest extent permissible under applicable
          law, expressly including the Federal Arbitration Act (9 U.S.C. ch. 1) (“FAA”), to mandate resolution of
          disputes through arbitration by the AAA. Any legal suit, action, or proceeding arising out of or related to
          these Terms of Use will be instituted exclusively in the federal courts of the United States or the courts of
          the State of Indiana, in each case located in the city of Indianapolis and County of Marion, shall have
          exclusive jurisdiction over the entry of any legal suit, action, or proceeding (a) to enter any award rendered
          by the AAA, (b) to enforce this arbitration provision, or (c) for matters, if any, arising out of or related
          to these Terms of Use that would not be subject to arbitration pursuant to the FAA despite the express
          agreement to arbitrate. SecondSight and the Secondary User irrevocably submit to the exclusive jurisdiction of
          such courts in any such suit, action, or proceeding. Service of process, summons, notice, or other document by
          mail to such party’s address set forth herein will be effective service of process for any suit, action, or
          other proceeding brought in any such court.
        </li>

        <li>
          <strong>Notice and Contact Details. </strong>
          All notices, requests, consents, claims, demands, waivers, and other communications pursuant to the
          Incorporated Agreements shall be in writing and shall be deemed to have been given (a) when delivered by hand
          (with written confirmation of receipt); (b) when received by the addressee if sent by a nationally recognized
          overnight courier (receipt requested); (c) on the date sent by email if sent during normal business hours of
          the recipient, and on the next business day if sent after normal business hours of the recipient or (d) on the
          third (3rd) day after the date mailed, by certified or registered mail, return receipt requested, postage
          prepaid. Such communications must be sent to the SecondSight at the addresses indicated below, or to the
          Customer at the address provided by the Customer in connection with the MCA, including as provided in
          connection with the PO (or at such other address for a party as shall be specified in a notice given in
          accordance with this Section).
        </li>
      </ol>
    </v-col>

    <v-col cols="6">
      <strong>If to SecondSight:</strong>
    </v-col>

    <v-col cols="6">
      Second Sight Data Discovery, Inc. <br />
      509 S Woodscrest Drive <br />
      Bloomington, Indiana 47401 <br />
      Attention: Customer Service Dept
    </v-col>

    <v-col cols="6">
      <strong>With a copy (which shall not constitute notice) to:</strong>
    </v-col>

    <v-col cols="6">
      Mallor Grodner, LLP <br />
      511 S Woodscrest Drive <br />
      Bloomington, Indiana 47401 <br />
      Attention: Dustin L. Plummer <br />
      dplummer@lawmg.com
    </v-col>

    <v-col cols="12">
      Any party who intends to seek arbitration must first send a written notice of the dispute to the other party as
      provided above. The notice must (1) describe the nature and basis of the claim or dispute; and (2) set forth a
      demand of the specific relief sought.
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "S2RSecondaryUserTerms",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
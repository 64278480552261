var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"without-auth-main-bg d-block"},[_c('top-bar-without-auth',{attrs:{"show-create-account":true}}),_c('v-col',{staticClass:"d-flex align-baseline justify-center mt-md-14 pt-14",attrs:{"cols":"12"}},[_c('v-row',{class:[
          'pa-0 align-baseline justify-center',
          _vm.$vuetify.breakpoint.mdAndUp ? ' login-signup-form-container' : '',
        ]},[_c('h2',{class:[
            'outfit-bold font-700  font-700 text-light-black mb-5',
            _vm.$vuetify.breakpoint.mdAndUp ? 'text-40' : 'text-30',
          ]},[_vm._v(" Welcome "),(!_vm.hideBranding)?_c('span',[_vm._v("to "+_vm._s(_vm.getAppTitle()))]):_vm._e()]),_c('v-col',{staticClass:"px-6 login-signup-form-outer",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"outfit-bold font-700 text-20 text-light-black"},[_vm._v("Login to continue")]),_c('login-form',{key:`view-${_vm.view}`,attrs:{"outlined":true,"show-outside-label":true,"show-forgot-password-link":true},on:{"handleForgotLink":_vm.forgotPassword,"updateView":_vm.updateView}})],1)],1)],1),_c('app-footer')],1),_c('login-mfa-form')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }